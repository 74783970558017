import * as XLSX from "xlsx";

interface ExcelDownload {
  list: Array<any>;
  sheetName: string;
  fileName: string;
}

const excelDownload = ({ list, sheetName, fileName }: ExcelDownload) => {
  const jsonToSheetData = XLSX.utils.json_to_sheet(list);

  // ===================================================================================================================
  // ✔️ 숫자 셀 서식 적용 (콤마 표시)
  //
  // 객체의 모든 키를 배열로 가져온다.
  // 메타데이터 키는 i로 시작하는데, 메타데이터가 아니라 실제 데이터 셀만 처리한다.
  // 셀의 값이 숫자인지 확인한다.
  // 셀 타입을 숫자로 설정한다.
  // 숫자 포맷: 천 단위 구분 기호 추가
  // ===================================================================================================================

  Object.keys(jsonToSheetData).forEach((key) => {
    if (key.startsWith("!") === false) {
      const cell = jsonToSheetData[key];
      if (typeof cell.v === "number") {
        cell.t = "n"; // t는 셀의 데이터 타입을 나타냄
        cell.z = "#,##0"; // 숫자 포맷: 콤마 추가
      }
    }
  });

  // 데이터에 기반하여 각 열의 너비 계산
  const columnWidths = Object.keys(list[0] || {}).map((key) => {
    const maxLength = Math.max(
      key.length, // 헤더의 길이
      ...list.map((item) => String(item[key] || "").length) // 데이터의 최대 길이
    );
    return { wch: maxLength + 2 }; // 셀 너비에 여유 공간 추가
  });

  jsonToSheetData["!cols"] = columnWidths; // 열 너비 설정

  const workBook = XLSX.utils.book_new();
  // 엑셀에서 워크북을 하나 형성한다
  XLSX.utils.book_append_sheet(workBook, jsonToSheetData, sheetName);
  // 생성한 워크북에 데이터를 넣고 sheetName을 지정한다
  XLSX.writeFile(workBook, fileName);
  // 해당 워크북에 지정한 파일이름으로 파일을 생성한다
};

export default excelDownload;
