import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import koLocale from "date-fns/locale/ko";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { getDate } from "../../../../libs/get-date";
import { getToday } from "../../../../libs/get-today";
import isModalOpenAtom from "../../../../recoil/isModalOpen";

interface Props {
  closeModal?: () => void;
  onButtonSubmit: (paidOutDate: string) => void;
  loading: boolean;
}

interface CalendarForm {
  payoutDate: string;
}

const CreateSettlementModal = ({
  closeModal,
  onButtonSubmit,
  loading = false
}: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { handleSubmit, control } = useForm<CalendarForm>({
    defaultValues: {
      payoutDate: getToday()
    }
  });

  // ===================================================================================================================
  // 정산 생성
  // ===================================================================================================================
  const onValid = (data: CalendarForm) => {
    const payoutDate = new Date(data.payoutDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // 시간 비교를 제거하여 날짜만 비교

    if (isNaN(payoutDate.getTime()) || payoutDate < today) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "정산 지급 일자는 오늘 또는 오늘 이후의 날짜여야 합니다."
      });
      return;
    }
    onButtonSubmit(data.payoutDate);
    closeModal?.();
  };

  return (
    <Dialog open={true} fullWidth maxWidth="xs">
      <DialogTitle>정산 지급 일자 추가</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeModal?.();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={handleSubmit(onValid)}>
        <DialogContent>
          <LocalizationProvider
            adapterLocale={koLocale}
            dateAdapter={AdapterDateFns}
          >
            <Controller
              name="payoutDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="정산 지급 일자"
                  inputFormat="yyyy-MM-dd"
                  value={value}
                  onChange={(newValue) => {
                    const formattedDate = getDate(newValue);
                    onChange(formattedDate);
                  }}
                  minDate={getToday()} // 오늘 날짜 이후만 선택 가능
                  renderInput={(param) => (
                    <TextField
                      fullWidth
                      margin="dense"
                      size="small"
                      required
                      variant="standard"
                      {...param}
                    />
                  )}
                />
              )}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <MuiLoadingButton
            type="submit"
            loading={loading}
            variant="contained"
            fullWidth
            sx={{
              color: "white"
            }}
          >
            추가 완료
          </MuiLoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateSettlementModal;
