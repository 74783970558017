import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import {
  AdminRegistUserType,
  BlockUserParams,
  FileDownloadParams,
  GradeApplyType,
  GradeDataType,
  GradeReservationType,
  GroupList,
  MerchantContractDateParams,
  MerchantInitialInfo,
  MerchantMemoType,
  MerchantModifyType,
  MerchantsDetail,
  MerchantsDetailParams,
  MerchantsInfo,
  MerchantsParams,
  MerchantStatusUpdate,
  UserListInfo,
  UserMerchantList,
  UsersListParams
} from "./types";

/**
 * 가맹점 목록 조회
 */
export const getMerchants = async (params: MerchantsParams) => {
  const url = "/merchants";
  const { data } = await instanceWithAuth.get<CommonApi<MerchantsInfo[]>>(url, {
    params
  });
  return data;
};

/**
 * 가맹점 정보 상세 조회
 */
export const getMerchantsDetail = async (params: MerchantsDetailParams) => {
  const url = `/merchants/${params.merchantId}`;
  const { data } = await instanceWithAuth.get<CommonApi<MerchantsDetail>>(url);
  return data;
};

/**
 * 파일 다운로드
 */
export const downloadFile = async (params: FileDownloadParams) => {
  const { data, headers } = await instanceWithAuth.get("/file/download", {
    responseType: "blob",
    params
  });
  return { data, headers };
};

/**
 * GBT 멤버 목록 조회
 */
export const getGbtGroup = async () => {
  const url = "/groups/1";
  const { data } = await instanceWithAuth.get<CommonApi<GroupList[]>>(url);
  return data;
};

/**
 * 사용자 목록 조회
 */
export const getUsersList = async (params: UsersListParams) => {
  const url = "/users";
  const { data } = await instanceWithAuth.get<CommonApi<UserListInfo[]>>(url, {
    params
  });

  return data;
};

/**
 * 사용자별 가맹점 목록 조회
 */
export const getUsersMerchantList = async (userId: number) => {
  const url = `/merchants/user/${userId}`;
  const { data } = await instanceWithAuth.get<CommonApi<UserMerchantList[]>>(
    url
  );
  return data;
};

/**
 *  가맹점 임시 저장 목록 조회
 */
export const getTempMerchantList = async (registrant: string) => {
  const url = "/temporaries";
  const { data } = await instanceWithAuth.get<CommonApi<any[]>>(url, {
    params: { registrant }
  });
  return data;
};

/**
 *  가맹점 임시 저장 데이터 삭제
 */
export const deleteMerchantTempData = async (id: number) => {
  const url = `/merchant_temporary/${id}`;
  const { data } = await instanceWithAuth.delete<CommonApi<boolean>>(url);
  return data;
};

/**
 *  가맹점 정보 삭제
 */
export const deleteMerchantData = async (merchantId: number) => {
  const url = `/merchants/${merchantId}`;
  const { data } = await instanceWithAuth.delete<CommonApi<boolean>>(url);
  return data;
};

// =================================================================================================
// 사용자 관리
// =================================================================================================
/**
 * 가맹 현황 - 신규 가맹점 초기 입력값 저장하기
 */
export const postMerchantInitialInfo = async (params: MerchantInitialInfo) => {
  const url = "/merchant/initial/information";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

/**
 * 가맹점 심사 정보 업데이트
 */
export const putMerchantStatus = async (status: MerchantStatusUpdate) => {
  const url = "/merchant/status";
  const { data } = await instanceWithAuth.put<CommonApi<boolean>>(url, status);

  return data;
};

/**
 * 가맹점 계약일 관리
 */
export const putMerchantContractDate = async (
  params: MerchantContractDateParams
) => {
  const url = "/merchant/contract/date";
  const { data } = await instanceWithAuth.put<CommonApi<boolean>>(url, params);

  return data;
};

/**
 * 사용자 계정 차단 및 해제
 */
export const postBlockUser = async (params: BlockUserParams) => {
  const url = "/users/update/isUse";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);

  return data;
};

/**
 * 사용자 비밀번호 카운트 변경
 */
export const postUserPassTemp = async (params: any) => {
  const url = "/bypass/password/temp";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);

  return data;
};

/**
 * 관리자 회원가입
 */
export const postAdminRegistUser = async (params: AdminRegistUserType) => {
  const url = "/bypass/adminJoin/user";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);

  return data;
};

/**
 *  이메일 검증
 */
export const getVerifyEmail = async (email: string) => {
  const url = "/bypass/verify/email";
  const { data } = await instanceWithAuth.get<CommonApi<any>>(url, {
    params: { email }
  });
  return data;
};

/**
 * 가맹점 메모 등록
 */
export const patchMerchantMemo = async (params: MerchantMemoType) => {
  const url = `/v3/merchants/${params.merchantId}/memo`;
  const { data } = await instanceWithAuth.patch<CommonApi<boolean>>(
    url,
    params
  );
  return data;
};

// =================================================================================================
// 가맹점 정보 수정 요청
// =================================================================================================

/**
 * 사용자 가맹점 정보 수정 요청
 */
export const postMerchantModify = async (params: MerchantModifyType) => {
  const url = "/merchant/modify";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

// =================================================================================================
// 가맹점 등급수수료 관리
// =================================================================================================
/**
 * 가맹점 등급수수료 예약 정보 조회
 */
export const getGradeFee = async (params: any) => {
  const url = "/gradeFee";
  const { data } = await instanceWithAuth.get<CommonApi<GradeDataType[]>>(url, {
    params
  });
  return data;
};

/**
 * 가맹점 등급수수료 즉시 적용
 */
export const postGradeFee = async (params: GradeApplyType) => {
  const url = "/gradeFee/apply";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

/**
 * 가맹점 등급수수료 예약 정보 삭제
 */
export const deleteGradeFee = async (reservationId: any) => {
  const url = "/gradeFee/delete";
  const { data } = await instanceWithAuth.delete<CommonApi<boolean>>(url, {
    params: { reservationId }
  });
  return data;
};

/**
 * 가맹점 등급수수료 예약 정보 등록
 */
export const postGradeFeeReservation = async (params: GradeReservationType) => {
  const url = "/gradeFee/reservation";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};
