import { instance, instanceWithAuth } from "../Instance";
import type { Login } from "../Login/types";
import type { CommonApi } from "../type";
import type {
  UserSearchKeywordParam,
  UserSearchKeywordResult,
  UserSearchParam,
  UserSearchResult
} from "./types";

/**
 * 사용자 검색
 */
export const getUsersByQuery = async (params: UserSearchParam) => {
  const url = `/users/search`;
  const { data } = await instanceWithAuth.get<CommonApi<UserSearchResult[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 사용자 검색 v2 (검색유형 없이 키워드로 검색)
 */
export const getUsersByKeyword = async (params: UserSearchKeywordParam) => {
  const url = `/users/search/v2`;
  const { data } = await instanceWithAuth.get<
    CommonApi<UserSearchKeywordResult[]>
  >(url, { params });
  return data;
};

export const getServerTime = async () => {
  const { data } = await instance.get("/bypass/users/servertime");
  return data;
};

export const renewalToken = async () => {
  const { data } = await instanceWithAuth.post<CommonApi<Login>>(
    "/bypass/users/renewal"
  );
  return data;
};
