import LoginIcon from "@mui/icons-material/Login";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getRSA, registerUser, sendCode, verifyCode } from "../api/Register";
import LoadingButton from "../components/LoadingButton";
import PasswordStrength from "../components/PasswordStrength";
import Title from "../components/Title";
import ozicpayText from "../images/ozicpay_logo_text.png";
import { encrypting } from "../libs/encryping-password";
import passwordStrengthCheck from "../libs/password-strength-check";
import isModalOpenAtom from "../recoil/isModalOpen";
import { numberValidate } from "../libs/input-validation";
import { HelperTextMessage } from "./components/HelperTextMessage";

interface RegisterForm {
  email: string;
  password: string;
  passwordCheck: string;
  name: string;
  phoneNumber: string;
  storeName: string;
}

interface CodeForm {
  code: string;
}

const Register = () => {
  // 네비게이트
  const navigate = useNavigate();

  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // 비밀번호 표시 여부
  const [isHide, setIsHide] = useState(true);
  const [isHidePasswordCheck, setIsHidePasswordCheck] = useState(true);

  // 동의 체크박스
  const [checkPrivacy, setCheckPrivacy] = useState(false);
  const [checkMarketing, setCheckMarketing] = useState(false);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  // 회원 가입
  const {
    register,
    handleSubmit,
    getValues,
    reset: resetRegisterForm,
    watch,
    formState: { errors }
  } = useForm<RegisterForm>({
    defaultValues: {
      email: "",
      password: "",
      passwordCheck: "",
      name: "",
      phoneNumber: "",
      storeName: ""
    }
  });

  // 인증 코드 전송
  const {
    register: tokenRegister,
    handleSubmit: tokenHandleSubmit,
    formState: { dirtyFields: codeDirtyField }
  } = useForm<CodeForm>({
    defaultValues: {
      code: ""
    }
  });

  // ===================================================================================================================
  // 체크박스 상태 변경, 비밀번호 표시/숨기기 버튼 처리 핸들러 함수
  // ===================================================================================================================
  // 개인정보 수집 동의 체크박스
  const handleCheckPrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckPrivacy(event.target.checked);
  };

  // 마케팅 수신 동의 체크박스
  const handleMarketing = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckMarketing(event.target.checked);
  };

  // 비밀번호 표시
  const handleHideButton = (inputName: string) => {
    if (inputName === "password") setIsHide((prevValue) => !prevValue);
    if (inputName === "passwordCheck")
      setIsHidePasswordCheck((prevValue) => !prevValue);
  };

  // watch로 해야 계속 렌더링되면서 확인가능
  const passwordStrengthStatus = passwordStrengthCheck(watch("password"));

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 회원가입
  const { mutate, isLoading } = useMutation(registerUser, {
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    },
    onSuccess: (data) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "회원가입이 완료되었습니다."
      });
      navigate("/login");
    }
  });

  // 인증코드 전송
  const {
    mutate: sendCodeMutate,
    data: sendCodeData,
    isLoading: isSendCodeLoading
  } = useMutation(sendCode, {
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
      resetRegisterForm();
    },
    onSuccess: (data) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "인증번호가 이메일로 전송되었습니다."
      });
    }
  });

  // 비밀번호 rsa 암호와를 위한 public key 발급
  const { isFetching: isRSAFeching, refetch } = useQuery(
    ["rsa", "register"],
    getRSA,
    {
      enabled: false,
      onSuccess: (res) => {
        const { email, password, name, phoneNumber, storeName } = getValues();
        const encryptedPassword = encrypting({
          password,
          publicKey: res?.content?.public_key
        });
        if (!encryptedPassword) return;

        mutate({
          email,
          encryptedPassword,
          name,
          phoneNumber,
          publicKey: res?.content?.public_key,
          password: "",
          storeName,
          receiveMarketing: checkMarketing,
          collectPersonal: checkPrivacy
        });
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // 인증코드 검증
  // userId, 인증코드, 약관 동의 true/false 값
  const { mutate: verifyCodeMutate, isLoading: isVerifyCodeLoading } =
    useMutation(verifyCode, {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      onSuccess: (data) => {
        refetch();
      }
    });

  // ===================================================================================================================
  // 회원가입 유효성 검사 및 처리
  // ===================================================================================================================
  const onVaild = (data: RegisterForm) => {
    const { email } = data;
    // checkPrivacy가 체크되지 않았다면 이용동의 해야한다고 표시
    // 체크되어 있다면 그대로 진행
    // 로딩중인지 체크
    if (!checkPrivacy) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "개인정보 수집 및 이용에 동의해야 합니다."
      });
      return;
    }
    if (isSendCodeLoading) return;
    sendCodeMutate({ email });
  };

  // ===================================================================================================================
  // 인증 코드 처리
  // ===================================================================================================================
  const onTokenValid = (data: CodeForm) => {
    const { email } = getValues();
    if (isRSAFeching || isVerifyCodeLoading || isLoading) return;
    verifyCodeMutate({ email, ...data });
  };

  return (
    <>
      <Title title="회원가입" />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Paper
          sx={{
            maxWidth: 600,
            width: "100%",
            p: 6,
            border: "1px solid #F2F3F5",
            borderRadius: 3
          }}
        >
          <Stack
            sx={{
              mb: 5
            }}
            alignItems="center"
          >
            <Box
              component="img"
              src={ozicpayText}
              alt="ozicpay logo"
              sx={{ width: "35%" }}
            />
          </Stack>
          {sendCodeData ? (
            <form onSubmit={tokenHandleSubmit(onTokenValid)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="인증 번호"
                    required
                    fullWidth
                    helperText="이메일로 받은 인증번호를 입력해주세요."
                    {...tokenRegister("code", {
                      required: "인증 번호를 입력해주세요."
                    })}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  marginTop: 5
                }}
              >
                <LoadingButton
                  icon={<LoginIcon />}
                  fullWidth={true}
                  size="large"
                  loading={isRSAFeching || isVerifyCodeLoading || isLoading}
                  disabled={!codeDirtyField?.code}
                >
                  인증 후 가입하기
                </LoadingButton>
              </Box>
            </form>
          ) : (
            <form onSubmit={handleSubmit(onVaild)} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    label="이름"
                    required
                    fullWidth
                    size="small"
                    {...register("name", {
                      required: "이름을 입력해주세요."
                    })}
                    helperText={
                      <HelperTextMessage>
                        {errors.name?.message}
                      </HelperTextMessage>
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="가맹점명"
                    required
                    fullWidth
                    size="small"
                    {...register("storeName", {
                      required: "가맹점명을 입력해주세요."
                    })}
                    helperText={
                      <HelperTextMessage>
                        {errors.storeName?.message}
                      </HelperTextMessage>
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="이메일"
                    required
                    fullWidth
                    size="small"
                    {...register("email", {
                      required: "이메일을 입력해주세요.",
                      pattern: {
                        value:
                          /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                        message: "형식에 맞지 않는 이메일 주소입니다."
                      }
                    })}
                    helperText={
                      <HelperTextMessage>
                        {errors.email?.message}
                      </HelperTextMessage>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="연락처"
                    required
                    fullWidth
                    size="small"
                    {...register("phoneNumber", {
                      required: "연락처를 입력해주세요.",
                      maxLength: {
                        value: 12,
                        message: "12자 이하로 입력해 주세요."
                      }
                    })}
                    onInput={numberValidate}
                    placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                    helperText={
                      <HelperTextMessage>
                        {errors.phoneNumber?.message}
                      </HelperTextMessage>
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="비밀번호"
                    required
                    fullWidth
                    size="small"
                    type={isHide ? "password" : "text"}
                    autoComplete="on"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleHideButton("password")}
                          >
                            {isHide ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...register("password", {
                      required: "비밀번호를 입력해주세요.",
                      minLength: {
                        value: 8,
                        message: "비밀번호는 8자 이상이어야 합니다."
                      },
                      validate: (value) => {
                        const checkResult = passwordStrengthCheck(value);
                        return (
                          checkResult === "성공" ||
                          "사용할 수 없는 비밀번호 입니다."
                        );
                      }
                    })}
                    helperText={
                      <HelperTextMessage>
                        {errors.password?.message}
                      </HelperTextMessage>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="비밀번호 확인"
                    required
                    fullWidth
                    size="small"
                    type={isHidePasswordCheck ? "password" : "text"}
                    autoComplete="on"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleHideButton("passwordCheck")}
                          >
                            {isHidePasswordCheck ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...register("passwordCheck", {
                      required: "비밀번호 확인을 입력해주세요.",
                      validate: (value) => {
                        const { password } = getValues();
                        return (
                          password === value || "비밀번호가 일치하지 않습니다."
                        );
                      }
                    })}
                    helperText={
                      <HelperTextMessage>
                        {errors.passwordCheck?.message}
                      </HelperTextMessage>
                    }
                  />
                </Grid>
              </Grid>
              <Stack sx={{ mt: 2 }}>
                <PasswordStrength status={passwordStrengthStatus} />
              </Stack>

              <Stack
                mt={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormControlLabel
                  label="[필수] 개인정보 수집 및 이용동의"
                  control={
                    <Checkbox
                      checked={checkPrivacy}
                      onChange={handleCheckPrivacy}
                    />
                  }
                />
                <Link
                  component={ReactLink}
                  to="/terms/privacy"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                  sx={{
                    "&:hover": {
                      color: "primary.main"
                    }
                  }}
                >
                  (내용 보기)
                </Link>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormControlLabel
                  label="[선택] 광고·마케팅 수신 동의"
                  control={
                    <Checkbox
                      checked={checkMarketing}
                      onChange={handleMarketing}
                    />
                  }
                />
                <Link
                  component={ReactLink}
                  to="/terms/marketing"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                  sx={{
                    "&:hover": {
                      color: "primary.main"
                    }
                  }}
                >
                  (내용 보기)
                </Link>
              </Stack>
              <Box
                sx={{
                  mt: 3
                }}
              >
                <LoadingButton
                  icon={<LoginIcon />}
                  fullWidth={true}
                  size="large"
                  loading={isSendCodeLoading}
                >
                  이메일 인증하기
                </LoadingButton>
              </Box>
              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                mt={2}
              >
                <Link
                  component={ReactLink}
                  to="/login"
                  color="inherit"
                  underline="none"
                  sx={{
                    padding: 0.5,
                    "&:hover": { fontWeight: "600" }
                  }}
                >
                  이미 아이디가 있으신가요?
                </Link>
              </Stack>
            </form>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default Register;
