import { instanceWithAuth } from "../../../../../api/Instance";
import { CommonApi } from "../../../../../api/type";
import {
  AddGroupMemberParams,
  AddGroupParams,
  GetGroupDetailParmas,
  GetGroupListParmas,
  GroupDetailData,
  GroupListData
} from "./types";

// =====================================================================================================================
// 그룹 목록 조회
// ====================================================================================================================
/**
 * 그룹 목록 조회
 */
export const getGroups = async (params: GetGroupListParmas) => {
  const url = "/v2/groups";
  const { data } = await instanceWithAuth.get<CommonApi<GroupListData[]>>(url, {
    params
  });
  return data;
};

/**
 * 그룹 추가
 */
export const addGroup = async (params: AddGroupParams) => {
  const url = "/v2/groups";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

/**
 * 그룹 수정
 */
export const modifyGroup = async ({
  id,
  params
}: {
  id: number;
  params: AddGroupParams;
}) => {
  const url = `/v2/groups/${id}`;
  const { data } = await instanceWithAuth.put<CommonApi<boolean>>(url, params);
  return data;
};

/**
 * 그룹 삭제
 */
export const deleteGroup = async (groupIdList: number[]) => {
  const url = "/v2/groups";
  const { data } = await instanceWithAuth.delete<CommonApi<boolean>>(url, {
    data: groupIdList
  });
  return data;
};

/**
 * 그룹 상세 조회
 */
export const getGroupDetail = async (params: GetGroupDetailParmas) => {
  const url = `/v2/groups/${params.id}`;
  const { data } = await instanceWithAuth.get<CommonApi<GroupDetailData[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 그룹 구성원 추가
 */
export const addGroupMember = async ({
  id,
  params
}: {
  id: number;
  params: AddGroupMemberParams[];
}) => {
  const url = `/v2/groups/${id}/group-members`;
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

/**
 * 그룹 구성원 제거
 * */
export const deleteMember = async (idList: number[]) => {
  const url = "/v2/group-members";
  const { data } = await instanceWithAuth.delete<CommonApi<boolean>>(url, {
    data: idList
  });
  return data;
};

// =====================================================================================================================
// 청구금 등록 (저장)
// ====================================================================================================================

// export const getGroups = async () => {
//   const { data } = await instanceWithAuth.get<CommonApi<Group[]>>("/groups");
//   return data;
// };

// export const addGroup = async (params: AddGroupParams) => {
//   const { data } = await instanceWithAuth.post<CommonApi<boolean>>(
//     "/group",
//     null,
//     {
//       params,
//       headers: {
//         "Content-Type": "application/json;charset=UTF-8"
//       }
//     }
//   );
//   return data;
// };

// export const deleteGroup = async (groupId: number) => {
//   const { data } = await instanceWithAuth.delete<CommonApi<boolean>>("/group", {
//     params: { groupId: String(groupId) }
//   });
//   return data;
// };

// export const getGroupMembers = async (groupId: number | null) => {
//   const { data } = await instanceWithAuth.get<CommonApi<GroupMember[]>>(
//     `/groups/${groupId}`
//   );
//   return data;
// };

// export const getNotBelongingMembers = async (groupId: number | null) => {
//   const { data } = await instanceWithAuth.get<CommonApi<NotBelongingMember[]>>(
//     `/noGroups/${groupId}`
//   );
//   return data;
// };

// export const addGroupMember = async (
//   addGroupMemberParams: AddGroupMemberParams
// ) => {
//   const { data } = await instanceWithAuth.post<CommonApi<boolean>>(
//     "/group/member",
//     addGroupMemberParams
//   );
//   return data;
// };

// export const removeGroupMember = async (groupMemberId: number[]) => {
//   const { data } = await instanceWithAuth.delete<CommonApi<boolean>>(
//     "/group/member",
//     {
//       params: { groupMemberId: groupMemberId.join(",") }
//     }
//   );
//   return data;
// };
