import { instanceWithAuth } from "../../../../../api/Instance";
import { CommonApi } from "../../../../../api/type";
import {
  ChargeListResponse,
  ChargeSaveParams,
  ChargeSearchParmas,
  ChargeSearchResponse
} from "./types";

// =====================================================================================================================
// 청구금 목록 조회
// ====================================================================================================================
/**
 * 청구금 목록 조회
 */
export const getChargeList = async (params: ChargeSearchParmas) => {
  const url = "/v3/charges";
  const { data } = await instanceWithAuth.get<
    CommonApi<ChargeSearchResponse[]>
  >(url, { params });
  return data;
};

/**
 * 청구금 목록 조회
 */
export const getCharges = async (id: number) => {
  const { data } = await instanceWithAuth.get<CommonApi<ChargeListResponse[]>>(
    `/v3/charges/${id}`
  );

  return data;
};

// =====================================================================================================================
// 청구금 등록 (저장)
// ====================================================================================================================
export const postCharges = async (params: ChargeSaveParams) => {
  const url = `/v3/charges/${params.id}`;
  const { data: content } = await instanceWithAuth.post<CommonApi<boolean>>(
    url,
    params.charges
  );

  return content;
};
