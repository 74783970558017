import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  getMerchentContractHistory,
  getMerchentGradeFeeHistory
} from "../../api/ContractManagement";
import Skeleton from "@mui/material/Skeleton";
import { format, subDays } from "date-fns";

interface Props {
  merchantId: any;
  closeModal?: () => void;
}

const columns = [
  { id: "date", label: "선택일자" },
  { id: "contractExtensionStatus", label: "계약 상태" },
  { id: "note", label: "비고" }
];

const gradeColumns = [
  { id: "startDate", label: "시작일" },
  { id: "endDate", label: "종료일" },
  { id: "grade", label: "등급 " },
  { id: "optatumFee", label: "수수료 (%)" },
  { id: "isUse", label: "현재 적용중" }
];

const ContractHistoryModal = ({ merchantId, closeModal }: Props) => {
  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  // 계약 연장 정보 조회 스테이트
  const [params, setParams] = useState({
    merchantId: merchantId,
    page: 0,
    size: 5,
    sort: "ASC"
  });

  // 등급수수료 내역 조회 스테이트
  const [gradeParams, setGradeParams] = useState({
    merchantId: merchantId,
    page: 0,
    size: 5,
    sort: "ASC"
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 가맹점 계약 정보 조회
  const { data, isLoading } = useQuery(
    [`/merchant/contract/history/${params.merchantId}`, params],
    () => getMerchentContractHistory(params)
  );

  // 가맹점 등급수수료 내역 조회
  const { data: gradeFeeData, isLoading: gradeFeeLoading } = useQuery(
    [`/merchant/gradeFee/history/${gradeParams.merchantId}`, gradeParams],
    () => getMerchentGradeFeeHistory(gradeParams),
    {
      select: (response) => {
        return {
          ...response,
          content: response.content.map((row: any) => ({
            ...row,
            endDate: row.endDate
              ? format(subDays(new Date(row.endDate), 1), "yyyy-MM-dd") 
              : ""
          }))
        };
      }
    }
  );

  // ===================================================================================================================
  // 페이지네이션
  // ===================================================================================================================
  // 계약 연장 정보 조회 총 페이지 수
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  // 계약 연장 정보 조회 페이지 변경 함수
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prevValue) => ({ ...prevValue, page: page - 1 }));
  };

  // 등급 수수료 내역 총 페이지 수
  const totalGradePages = gradeFeeData?.pageable?.totalPages
    ? gradeFeeData?.pageable?.totalPages
    : 0;
  // 등급 수수료 내역 페이지 변경 함수
  const handleGradePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setGradeParams((prevValue) => ({ ...prevValue, page: page - 1 }));
  };

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <DialogTitle>가맹점 계약 연장 정보 조회</DialogTitle>
      <IconButton
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={4}>
          {/* 계약 연장 및 해지 내역 ---------------------------------------------------------------------------------*/}
          <Box>
            <Chip label="계약 연장/해지 내역" />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        variant="head"
                        align="center"
                        key={column.id}
                        sx={{ borderTop: "none" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.content.length < 1 ? (
                    <TableRow>
                      <TableCell colSpan={columns.length}>
                        <Typography align="center">
                          조회된 데이터가 없습니다.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {!data &&
                        isLoading &&
                        [...Array(5)].map((_, index) => (
                          <TableRow key={index}>
                            {columns.map((column) => (
                              <TableCell align="center" key={column.id}>
                                <Skeleton variant="rectangular" height={25} />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </>
                  )}
                  {data?.content &&
                    data?.content.length > 0 &&
                    !isLoading &&
                    data.content.map((i, index: number) => (
                      <TableRow key={index} hover>
                        <TableCell align="center">
                          {i.date.split("T")[0]}
                        </TableCell>
                        <TableCell align="center">
                          {i.contractExtensionStatus}
                        </TableCell>
                        <TableCell align="center">{i.note}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={params.page + 1}
              onChange={handlePage}
              sx={{ display: "flex", justifyContent: "center", mt: 2 }}
            />
          </Box>

          {/* 등급 수수료 내역 ---------------------------------------------------------------------------------------*/}
          <Box>
            <Chip label="등급 수수료 내역" />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {gradeColumns.map((column) => (
                      <TableCell
                        variant="head"
                        align="center"
                        key={column.id}
                        sx={{ borderTop: "none" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gradeFeeData && gradeFeeData.content.length < 1 ? (
                    <TableRow>
                      <TableCell colSpan={gradeColumns.length}>
                        <Typography align="center">
                          조회된 데이터가 없습니다.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {!gradeFeeData &&
                        gradeFeeLoading &&
                        [...Array(5)].map((_, index) => (
                          <TableRow key={index}>
                            {gradeColumns.map((column) => (
                              <TableCell align="center" key={column.id}>
                                <Skeleton variant="rectangular" height={25} />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </>
                  )}

                  {gradeFeeData?.content &&
                    !gradeFeeLoading &&
                    gradeFeeData.content.map((i: any, index: number) => (
                      <TableRow key={index} hover>
                        <TableCell align="center">{i.startDate}</TableCell>
                        <TableCell align="center">{i.endDate}</TableCell>
                        <TableCell align="center">{i.grade}</TableCell>
                        <TableCell align="center">{i.optatumFee}</TableCell>
                        <TableCell align="center">
                          {i.isUse ? (
                            <CheckCircleOutlineRoundedIcon color="success" />
                          ) : (
                            <ClearRoundedIcon color="error" />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={2}>
              <Pagination
                count={totalGradePages}
                variant="outlined"
                shape="rounded"
                page={gradeParams.page + 1}
                onChange={handleGradePage}
                sx={{ display: "flex", justifyContent: "center", mt: 2 }}
              />
            </Box>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default ContractHistoryModal;
