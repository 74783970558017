import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useMutation } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../../../..";
import { PaymentDetailData } from "../../../../api/v2/Payments/types";
import isModalOpen from "../../../../recoil/isModalOpen/atom";
import { postPurchasePayments } from "../libs";
import { MissingPurchasesData } from "../libs/types";

interface Props {
  selectedData: MissingPurchasesData[];
  closeModal?: () => void;
}
const PurchaseRegisterModal = ({ closeModal, selectedData }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 누락 매입 등록
  const { mutate } = useMutation(postPurchasePayments, {
    onSuccess: () => {
      queryClient.invalidateQueries(["/payments/detail"]);
      queryClient.invalidateQueries(["/missing-purchase-payments"]);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "매입 신청이 완료되었습니다."
      });
      closeModal?.();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // 누락 매입 신청
  const onSubmit = () => {
    const params = {
      // ids: selectedData.map((item) => item.id),
      missingPurchasePaymentCreateDtos: selectedData.map((item) => ({
        transactionKey: item.transactionKey,
        status: item.status
      }))
    };

    mutate(params);
  };

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>매입 신청</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText>
            매입 신청된 거래는 매일 오전 2시에 매입 전문에 포함됩니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ color: "white" }}
            onClick={onSubmit}
          >
            신청 완료
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default PurchaseRegisterModal;
