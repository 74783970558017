import * as XLSX from "xlsx";

interface ExcelDownload {
  thead: Array<any>;
  merchantTotalList: any;
  merchantsList: any;
  sheetName: string;
  fileName: string;
}

const NewExcelDownload = ({
  thead,
  merchantTotalList,
  merchantsList,
  sheetName,
  fileName
}: ExcelDownload) => {
  const sheetData = [
    thead,
    Object.values(merchantTotalList),
    [],
    thead,
    ...merchantsList.map(Object.values)
  ];

  const aoaToSheetData = XLSX.utils.aoa_to_sheet(sheetData);

  // ====================================================================
  // ✔️ 숫자 셀 서식 적용 (콤마 표시)
  //
  // 셀 데이터를 순회하며 숫자인 경우 포맷 적용
  // ====================================================================
  Object.keys(aoaToSheetData).forEach((key) => {
    if (!key.startsWith("!")) {
      const cell = aoaToSheetData[key];
      if (typeof cell.v === "number") {
        cell.t = "n"; // 데이터 타입을 숫자로 설정
        cell.z = "#,##0"; // 숫자 포맷 (천 단위 콤마 추가)
      }
    }
  });

  // 열 너비 계산
  const columnWidths = sheetData[0]?.map((_: unknown, colIndex: number) => {
    const maxLength = Math.max(
      ...sheetData.map((row) => String(row[colIndex] || "").length)
    );
    return { wch: maxLength + 2 }; // 여유 공간 추가
  });

  if (columnWidths) {
    aoaToSheetData["!cols"] = columnWidths; // 열 너비 설정
  }

  const workBook = XLSX.utils.book_new();
  // 엑셀에서 워크북을 하나 형성합니다.
  XLSX.utils.book_append_sheet(workBook, aoaToSheetData, sheetName);
  // 생성한 워크북에 데이터를 넣고 sheetName을 지정합니다.
  XLSX.writeFile(workBook, fileName);
  // 해당 워크북에 지정한 파일이름으로 파일을 생성합니다.
};

export default NewExcelDownload;
