import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../../../../..";
import ModalTriggerButton from "../../../../../components/PopupTriggerButton";
import isModalOpen from "../../../../../recoil/isModalOpen/atom";
import { deleteMember, getGroupDetail } from "../libs";
import { GroupListData } from "../libs/types";
import AddUserToGroupModal, { AUTHORITY_CODE } from "./AddUserToGroupModal";

interface Columns {
  id: string;
  label: string;
}

const columns: Columns[] = [
  { id: "checkbox", label: "" },
  { id: "id", label: "이름/대표 가맹점" },
  { id: "groupName", label: "이메일" },
  { id: "userType", label: "사용자 권한" },
  { id: "createdAt", label: "휴대폰 번호" },
  { id: "updatedAt", label: "비고" }
];

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedData: GroupListData;
}

const DetailGroupModal = ({ selectedData, isOpen, onClose }: Props) => {
  /*********************************************************************************************************************
   * 스테이트
   ********************************************************************************************************************/
  // 리코일 스테이트
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // 파라미터 스테이트
  const [params, setParams] = useState({
    id: selectedData?.id,
    page: 0,
    pageSize: 10,
    sort: "DESC"
  });

  const [checkedRows, setCheckedRows] = useState<number[]>([]);

  /*********************************************************************************************************************
   * 리액트 쿼리
   ********************************************************************************************************************/
  // 그룹 관리 목록 조회
  const { data, isLoading, refetch } = useQuery(
    [`/v2/groups/${selectedData?.id}`, params],
    () => getGroupDetail(params),
    {
      onSuccess: (data) => {
        const updatedTotalPages = data?.pageable?.totalPages || 0;
        if (params.page >= updatedTotalPages) {
          // 현재 페이지가 총 페이지 수보다 크면 마지막 페이지로 이동
          setParams((prev) => ({
            ...prev,
            page: Math.max(updatedTotalPages - 1, 0)
          }));
        }
        setCheckedRows([]); // 체크된 항목 초기화
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // 멤버 그룹 제외
  const { mutate } = useMutation(deleteMember, {
    onSuccess: () => {
      queryClient.invalidateQueries([`/v2/groups/${selectedData?.id}`]);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "그룹 멤버가 삭제되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  /*********************************************************************************************************************
   * 페이징
   ********************************************************************************************************************/
  // 총 페이지 수
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;

  // 페이지 변경 함수
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev: any) => ({ ...prev, page: page - 1 }));
  };

  /*********************************************************************************************************************
   * 그룹 멤버 제외
   ********************************************************************************************************************/
  const removeMembers = () => {
    mutate(checkedRows);
  };

  const handleCheckbox = (id: number) => {
    setCheckedRows((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const isChecked = (id: number) => checkedRows.includes(id);

  const closeModal = () => {
    refetch();
    queryClient.invalidateQueries(["/v2/groups"]);
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          {selectedData?.groupName}
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Button
              variant="outlined"
              color="error"
              disabled={checkedRows.length === 0} // 조건 추가
              onClick={removeMembers}
              size="large"
            >
              제외
            </Button>
            <ModalTriggerButton
              modalType="charge"
              modal={
                <AddUserToGroupModal
                  groupId={selectedData?.id ? selectedData?.id : 0}
                />
              }
              color="success"
            >
              사용자 추가
            </ModalTriggerButton>
            <IconButton aria-label="close" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box>
            {/* 테이블 영역 시작 */}
            <TableContainer>
              <Table
                stickyHeader
                sx={{
                  ...((!data || data?.content?.length === 0) && {
                    height: 550
                  })
                }}
              >
                {/* 테이블 헤더 */}
                <TableHead>
                  <TableRow>
                    {columns.map((column: any) => (
                      <TableCell
                        variant="head"
                        key={column.id}
                        align="center"
                        sx={{ backgroundColor: "#FBFBFB" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* 테이블 헤더 끝 */}

                {/* 테이블 바디 */}
                <TableBody>
                  {data && data.content.length < 1 ? (
                    // 데이터 없을 때
                    <TableRow>
                      <TableCell sx={{ height: 500 }} colSpan={10}>
                        <Typography align="center">
                          조회된 데이터가 없습니다.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {/* 데이터 조회 중 = 스켈레톤 */}
                      {!data &&
                        isLoading &&
                        [1, 2, 3, 4, 5].map((index) => (
                          <TableRow
                            key={index}
                            hover={true}
                            sx={{ cursor: "pointer" }}
                          >
                            {columns.map((value) => {
                              return (
                                <TableCell
                                  component="th"
                                  align="center"
                                  key={value.id}
                                  width={
                                    value.id === "checkbox"
                                      ? "50px"
                                      : value.id !== "etc"
                                      ? "150px"
                                      : "500px"
                                  }
                                  sx={{ py: 0 }}
                                >
                                  <Skeleton
                                    variant={"rectangular"}
                                    height={25}
                                  ></Skeleton>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}

                      {/* 데이터 조회 결과 */}
                      {data &&
                        data.content.length > 0 &&
                        data.content.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell
                              align="center"
                              sx={{ width: "50px", py: 0.3 }}
                            >
                              <Checkbox
                                checked={isChecked(row.id)}
                                onChange={() => handleCheckbox(row.id)}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </TableCell>
                            <TableCell align="center">
                              {!!row.name ? row.name : "-"}/
                              {!!row.storeName ? row.storeName : "-"}
                            </TableCell>
                            <TableCell align="center">{row.email}</TableCell>
                            <TableCell align="center">
                              {Object.values(AUTHORITY_CODE).find(
                                (v) => v.value === row.authority
                              )?.label ?? "-"}
                            </TableCell>
                            <TableCell align="center">
                              {row.phoneNumber}
                            </TableCell>
                            <TableCell align="center">{row.note}</TableCell>
                          </TableRow>
                        ))}
                    </>
                  )}
                </TableBody>
                {/* 테이블 바디 끝 */}
              </Table>
            </TableContainer>
            <Box my={2}>
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                page={params.page + 1}
                onChange={changePage}
                sx={{ display: "flex", justifyContent: "center" }}
              ></Pagination>
            </Box>
          </Box>
          {/* 테이블 영역 끝 */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DetailGroupModal;
