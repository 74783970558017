import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import {
  getSettlementsDetail,
  getSettlementsDetailExcel
} from "../../api/v2/Settlements.ts";
import {
  SettlementDetailData,
  SettlementsDetailParams
} from "../../api/v2/Settlements.ts/types";
import excelDownload from "../../libs/excel-download";
import { addCommas, removeCommas } from "../../libs/thousands-commas";
import isModalOpenAtom from "../../recoil/isModalOpen";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

interface SettlementUnitColumnHeadCell {
  id: keyof SettlementDetailData;
  label: string;
  isTotalData: boolean;
}

const unitColumns: SettlementUnitColumnHeadCell[] = [
  {
    id: "mallId",
    label: "가맹점 ID",
    isTotalData: false
  },
  {
    id: "mallName",
    label: "가맹점명",
    isTotalData: false
  },
  {
    id: "createdAt",
    label: "거래 일자",
    isTotalData: true
  },
  {
    id: "approvalNum",
    label: "승인 번호",
    isTotalData: false
  },
  {
    id: "cardCompany",
    label: "카드사",
    isTotalData: false
  },
  {
    id: "status",
    label: "거래 상태",
    isTotalData: false
  },
  {
    id: "paymentKey",
    label: "거래 고유KEY",
    isTotalData: false
  },
  {
    id: "price",
    label: "거래 금액",
    isTotalData: true
  },
  {
    id: "purchasePrice",
    label: "매입 금액",
    isTotalData: false
  },
  {
    id: "cardFee",
    label: "카드사 수수료",
    isTotalData: true
  },
  {
    id: "optatumFee",
    label: "옵타움 수수료",
    isTotalData: true
  },
  {
    id: "merchantFee",
    label: "가맹점 수수료",
    isTotalData: true
  },
  {
    id: "vat",
    label: "부가세",
    isTotalData: true
  },
  {
    id: "commissionFee",
    label: "총 수수료",
    isTotalData: true
  },
  {
    id: "settlementPrice",
    label: "정산 금액",
    isTotalData: true
  }
];

interface SettlementUnitModalProps {
  selectedData: any;
  closeModal?: () => void;
}

const SettlementUnitModal = ({
  selectedData,
  closeModal
}: SettlementUnitModalProps) => {
  // isConfirm 데이터 가공
  const newConfirm = selectedData.isConfirm === "확정" ? "true" : "false";
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // 파라미터 스테이트
  const [params, setParams] = useState<SettlementsDetailParams>({
    isConfirm: newConfirm,
    isPayOut: selectedData.isPayOut,
    page: 0,
    pageSize: 50,
    mallId: selectedData.mallId,
    mallName: selectedData.mallName,
    paidOutDate: selectedData.paidOutDate,
    sort: "ASC"
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================

  // 정산 개별 내역 조회
  const { data } = useQuery(
    ["/settlements/detail", params],
    () => getSettlementsDetail(params),
    {
      onError: (error: any) => {
        closeModal?.();
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      select: (res) => ({
        ...res,
        content: res.content.map((data: SettlementDetailData) => ({
          ...data,
          cardFee: `${addCommas(data.cardFee)}`,
          price: `${addCommas(data.price)}`,
          purchasePrice: `${addCommas(data.purchasePrice)}`,
          optatumFee: `${addCommas(data.optatumFee)}`,
          merchantFee: `${addCommas(data.merchantFee)}`,
          vat: `${addCommas(data.vat)}`,
          commissionFee: `${addCommas(data.commissionFee)}`,
          settlementPrice: `${addCommas(data.settlementPrice)}`
        }))
      })
    }
  );

  // 엑셀 다운로드
  const { isFetching, refetch } = useQuery(
    [
      "/settlement/detail/excel",
      {
        isConfirm: newConfirm,
        isPayOut: selectedData.isPayOut,
        mallId: selectedData.mallId,
        mallName: selectedData.mallName,
        paidOutDate: selectedData.paidOutDate
      }
    ],
    () =>
      getSettlementsDetailExcel({
        isConfirm: newConfirm,
        isPayOut: selectedData.isPayOut,
        mallId: selectedData.mallId,
        mallName: selectedData.mallName,
        paidOutDate: selectedData.paidOutDate
      }),
    {
      enabled: false,
      onSuccess: (data) => {
        const { content } = data;
        const list = content.map((d: SettlementDetailData) => ({
          "가맹점 ID": d.mallId,
          가맹점명: d.mallName,
          "거래 일자": d.createdAt,
          "승인 번호": d.approvalNum,
          카드사: d.cardCompany,
          "거래 상태": d.status,
          "거래 고유KEY": d.paymentKey,
          "거래 금액": d.price,
          "매입 금액": d.purchasePrice,
          "카드사 수수료": d.cardFee,
          "옵타움 수수료": d.optatumFee,
          "가맹점 수수료": d.merchantFee,
          부가세: d.vat,
          "총 수수료": d.commissionFee,
          "정산 금액": d.settlementPrice,
          "정산 예정일": d.soldDate
        }));
        const totalList = {
          "가맹점 ID": "합계",
          가맹점명: "",
          "거래 일자": selectedData.datePeriod,
          "승인 번호": "",
          카드사: "",
          "거래 상태": "",
          "거래 고유KEY": "",
          "거래 금액": selectedData.transactionTotal,
          "매입 금액": "",
          "카드사 수수료": selectedData.cardFee,
          "옵타움 수수료": selectedData.optatumFee,
          "가맹점 수수료": selectedData.merchantFee,
          부가세: selectedData.vat,
          "총 수수료": selectedData.commissionFee,
          "정산 금액": selectedData.settlementPrice,
          "정산 예정일": selectedData.paidOutDate
        };
        list.push(totalList);

        excelDownload({
          list,
          sheetName: `정산 개별 내역_${selectedData.mallName}`,
          fileName: `정산개별내역_${selectedData.mallName}.xlsx`
        });
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: "다운로드를 시작합니다."
        });
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  const handleExcel = () => {
    if (isFetching) return;
    refetch();
  };

  // ===================================================================================================================
  // 페이지네이션
  // ===================================================================================================================
  // 총 페이지 수
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;

  // 페이지 변경 함수
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev) => ({ ...prev, page: page - 1 }));
  };

  return (
    <Dialog open={true} maxWidth="xl" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        {selectedData.paidOutDate} 개별 내역 조회
        <Stack direction={"row"} gap={2}>
          <Button variant="outlined">정산추가</Button>
          <IconButton
            aria-label="close"
            onClick={() => {
              closeModal?.();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Box>
          {/* <MuiTable
            rows={data?.content}
            columns={unitColumns}
            id="id"
            redlineKey="status"
            redlineValue={["취소", "부분취소"]}
            totalData={{
              createdAt: selectedData.datePeriod,
              price: selectedData.transactionTotal,
              soldDate: selectedData.paidOutDate,
              cardFee: selectedData.cardFee,
              optatumFee: selectedData.optatumFee,
              merchantFee: selectedData.merchantFee,
              vat: selectedData.vat,
              commissionFee: selectedData.commissionFee,
              settlementPrice: selectedData.settlementPrice
            }}
          /> */}
          <TableContainer sx={{ maxHeight: 550, minHeight: 550 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {unitColumns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{ background: "white", whiteSpace: "nowrap" }}
                      align="center"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data?.content?.length > 0 ? (
                  <>
                    {data.content.map((row: any) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          color:
                            row.status === "취소" ? "error.main" : "inherit"
                        }}
                      >
                        {unitColumns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            sx={{
                              whiteSpace: "nowrap",
                              color:
                                row.status === "취소" ? "error.main" : "inherit"
                            }}
                          >
                            {row[column.id]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        colSpan={2} // 첫 두 개의 셀 병합
                        sx={{
                          backgroundColor: "#f2f2f2",
                          position: "sticky",
                          bottom: 0,
                          zIndex: 1,
                          whiteSpace: "nowrap"
                        }}
                      >
                        합계
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          backgroundColor: "#f2f2f2",
                          position: "sticky",
                          bottom: 0,
                          zIndex: 1,
                          whiteSpace: "nowrap"
                        }}
                      >
                        {data.content.length > 0
                          ? `${data.content[0].createdAt.split(" ")[0]} ~ ${
                              data.content[
                                data.content.length - 1
                              ].createdAt.split(" ")[0]
                            }`
                          : "N/A"}
                      </TableCell>
                      {unitColumns.slice(3).map(
                        (
                          column // 첫 두 개의 셀 제외
                        ) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            sx={{
                              backgroundColor: "#f2f2f2",
                              position: "sticky",
                              bottom: 0,
                              zIndex: 1,
                              whiteSpace: "nowrap"
                            }}
                          >
                            {column.isTotalData
                              ? selectedData[column.id]
                              : null}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={unitColumns.length}>
                      <Typography align="center">데이터가 없습니다.</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box mt={2}>
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            page={params.page + 1}
            onChange={changePage}
            sx={{ display: "flex", justifyContent: "center" }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <MuiLoadingButton
          color="primary"
          loading={isFetching}
          onClick={handleExcel}
          fullWidth={true}
          variant="contained"
          sx={{
            color: "white"
          }}
        >
          엑셀 다운로드
        </MuiLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SettlementUnitModal;
