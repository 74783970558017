import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useMutation } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import { terminateContract } from "../../api/ContractManagement";
import isModalOpen from "../../recoil/isModalOpen/atom";

interface Props {
  merchantId: any;
  closeModal?: () => void;
  onClose: () => void;
}

const TerminateContractModal = ({ closeModal, merchantId, onClose }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // 가맹점 계약 강제 해지
  const { mutate } = useMutation(terminateContract, {
    onSuccess: () => {
      queryClient.invalidateQueries(merchantId);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "계약 해지가 완료되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const onClick = () => {
    mutate(merchantId);
    closeModal?.();
    onClose();
  };

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>가맹점 계약 강제 해지</DialogTitle>

        <IconButton
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText>
            계약 해지 버튼을 누르시면 가맹점의 계약이 강제로 종료됩니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            fullWidth
            onClick={onClick}
          >
            계약 해지
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TerminateContractModal;
