import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMutation } from "@tanstack/react-query";
import koLocale from "date-fns/locale/ko";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { inputPriceFormat } from "../../../../../libs/format-utils";
import { getDate } from "../../../../../libs/get-date";
import { getToday } from "../../../../../libs/get-today";
import { addCommas } from "../../../../../libs/thousands-commas";
import isModalOpenAtom from "../../../../../recoil/isModalOpen";
import { HelperTextMessage } from "../../../../components/HelperTextMessage";
import { postSettlementV2Payout } from "../libs";

interface Props {
  closeModal?: () => void;
  settlementId: number;
  totalPayoutAmount: string;
}

interface InputForm {
  payoutDate: string;
  payoutAmount: string;
  note: string;
}

const PayoutSettlementModal = ({
  closeModal,
  totalPayoutAmount,
  settlementId
}: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors, isValid }
  } = useForm<InputForm>({
    defaultValues: {
      payoutDate: getToday(),
      payoutAmount: "0",
      note: ""
    },
    mode: "onChange"
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 정산 지급
  const { mutate, isLoading: isPayoutSettlementLoading } = useMutation(
    postSettlementV2Payout,
    {
      onSuccess: () => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: "정산 지급 되었습니다."
        });
        closeModal?.();
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // ===================================================================================================================
  // 정산 지급
  // ===================================================================================================================
  const onValid = (data: InputForm) => {
    const isInvalidData = data.payoutDate?.includes("NaN");
    if (isInvalidData) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "정산 지급 일자를 확인해주세요."
      });
      return;
    }

    // 오늘 날짜와 비교
    const today = new Date(getToday());
    const payoutDate = new Date(data.payoutDate);
    if (payoutDate < today) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "정산 지급일은 오늘 이후여야 합니다."
      });
      return;
    }

    const params = {
      id: settlementId,
      note: data.note,
      payoutAmount: Number(data.payoutAmount.replace(/,/g, "")),
      payoutDate: data.payoutDate
    };
    mutate(params);
  };

  return (
    <Dialog open={true} fullWidth maxWidth="xs">
      <DialogTitle>정산 지급</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeModal?.();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>

      <form onSubmit={handleSubmit(onValid)}>
        <DialogContent>
          <Stack gap={3.5}>
            <LocalizationProvider
              adapterLocale={koLocale}
              dateAdapter={AdapterDateFns}
            >
              <Controller
                name="payoutDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label="지급일"
                    inputFormat="yyyy-MM-dd"
                    value={value}
                    onChange={(value) => onChange(getDate(value))}
                    renderInput={(param) => (
                      <TextField
                        fullWidth
                        margin="dense"
                        size="small"
                        required
                        {...param}
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <TextField
                id="input-payoutAmount"
                label="지금 금액"
                size="small"
                fullWidth
                required
                {...register("payoutAmount", {
                  required: "지급 금액은 필수값 입니다.",
                  validate: (value) => {
                    const numericValue = Number(value.replace(/,/g, ""));
                    if (numericValue > Number(totalPayoutAmount)) {
                      return "지급 금액이 정산 금액을 초과할 수 없습니다.";
                    }
                    return true;
                  }
                })}
                onChange={(e) => {
                  setValue("payoutAmount", inputPriceFormat(e.target.value), {
                    shouldValidate: true // 실시간 검증
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      / &nbsp; <b>{addCommas(Number(totalPayoutAmount))}</b>{" "}
                      &nbsp;원
                      <Typography ml={0.5}>(정산 금액)</Typography>
                    </InputAdornment>
                  )
                }}
                helperText={
                  <HelperTextMessage>
                    {errors.payoutAmount?.message}
                  </HelperTextMessage>
                }
              />
            </Stack>

            <TextField
              id="input-note"
              label="비고 (사유)"
              fullWidth
              size="small"
              {...register("note", {
                required: "비고는 필수값 입니다.",
                maxLength: {
                  value: 300,
                  message: `최대 300자까지 입력 가능합니다.`
                }
              })}
              multiline
              minRows={4}
              maxRows={6}
              required
              helperText={
                <HelperTextMessage>{errors.note?.message}</HelperTextMessage>
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <MuiLoadingButton
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              color: "white"
            }}
            disabled={!isValid}
          >
            지급 완료
          </MuiLoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PayoutSettlementModal;
