import { instanceWithAuth } from "../../../../api/Instance";
import { CommonApi } from "../../../../api/type";
import {
  AddSettledItemParams,
  CreateSettledItemParams,
  UnSettledItemData,
  UnSettledItemParams,
  UpdateSettlementStatusParams
} from "./types";

// =====================================================================================================================
// 미정산 내역
// ====================================================================================================================
/**
 * 미정산내역 데이터 조회
 */
export const getUnSettledItems = async (params: UnSettledItemParams) => {
  const url = "/settlement-items";
  const { data } = await instanceWithAuth.get<CommonApi<UnSettledItemData>>(
    url,
    { params }
  );
  return data;
};

/**
 * 미정산내역 데이터 추가 (미정산 페이지)
 */
export const postUnSettledItems = async (params: CreateSettledItemParams) => {
  const url = `/v3/settlements`;
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

/**
 * 미정산내역 데이터 추가 (정산 페이지)
 */
export const putUnSettledItems = async ({
  id,
  settlementItems
}: AddSettledItemParams) => {
  const url = `/v3/settlements/${id}/settlement-items`;
  const { data } = await instanceWithAuth.put<CommonApi<boolean>>(
    url,
    settlementItems
  );
  return data;
};

/**
 * 미정산 내역 활성 상태 변경
 */
export const putUnSettlementStatus = async (
  params: UpdateSettlementStatusParams[]
) => {
  const url = `/settlement-items`;
  const { data } = await instanceWithAuth.put<CommonApi<boolean>>(url, params);
  return data;
};
