import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import Title from '../../components/Title';
import { getDate } from '../../libs/get-date';
import { getToday } from '../../libs/get-today';
import isModalOpenAtom from '../../recoil/isModalOpen';
import LeyutaeLayout from './Layout';
import ReyutaeTransactionDetail from './TransactionDetail';
import SearchControls from '../Common/SearchControls';

const CARD_COMPANY = [
  { name: 'ALL', value: '기본' },
  // { name: "ISP", value: "ISP" },
  { name: 'Shinhan Card', value: '신한카드' },
  { name: 'Hyundai Card', value: '현대카드' },
  { name: 'Samsung Card', value: '삼성카드' },
  { name: 'Woori Card', value: '우리카드' },
  { name: 'KB Card', value: 'KB국민카드' },
  { name: 'Lotte Card', value: '롯데카드' },
  { name: 'NH Card', value: 'NH농협카드' },
  { name: 'Hana Card', value: '하나카드' },
  { name: 'BC Card', value: '비씨카드' },
  { name: 'Citi Card', value: '씨티카드' },
  // { name: "카카오뱅크", value: "카카오뱅크" },
  // { name: "KDB산업은행", value: "KDB산업은행" },
  // { name: "SH수협은행", value: "SH수협은행" },
  // { name: "전북은행", value: "전북은행" },
  // { name: "우체국예금보험", value: "우체국예금보험" },
  // { name: "새마을금고", value: "새마을금고" },
  // { name: "저축은행SB", value: "저축은행SB" },
  // { name: "제주은행", value: "제주은행" },
  // { name: "광주은행", value: "광주은행" },
  // { name: "신협", value: "신협" },
  // { name: "JCB", value: "JCB" },
  // { name: "유니온페이", value: "유니온페이" },
  // { name: "마스터카드", value: "마스터카드" },
  // { name: "비자카드", value: "비자카드" },
  // { name: "다이너스클럽", value: "다이너스클럽" },
  // { name: "디스커버", value: "디스커버" }
];

interface FormInputs {
  startDate: string;
  endDate: string;
  cardCompany: string;
  status: string;
  approvalNum: string;
  paymentKey: string;
  transactionKey: string;
}

const LeyutaePayment = () => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 파라미터 스테이트
  // ===================================================================================================================
  const [params, setParams] = useState({
    page: 0,
    pageSize: 50,
    startDate: getToday(),
    endDate: getToday(),
    cardCompany: '기본',
    status: 'DEFAULT',
    transactionKey: '',
    paymentKey: '',
    approvalNum: '',
    sort: 'DESC',
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const defaultValues = {
    startDate: getToday(),
    endDate: getToday(),
    cardCompany: '기본',
    status: 'DEFAULT',
    transactionKey: '',
    paymentKey: '',
    approvalNum: '',
  };

  const { register, handleSubmit, control, reset, watch } = useForm<FormInputs>(
    {
      defaultValues: {
        ...defaultValues,
      },
    },
  );

  // ===================================================================================================================
  // 검색 필터
  // ===================================================================================================================
  // 조회 필터 리셋
  const handleReset = () => {
    reset({ ...defaultValues });
  };

  // 조회
  const handleForm = (data: FormInputs) => {
    const isInvalidData =
      data.startDate?.includes('NaN') || data.endDate?.includes('NaN');
    const isInvaildPeriod = data.startDate > data.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: 'Please check the date',
      });
      return;
    }
    setParams(prevParams => ({
      ...prevParams,
      ...data,
      page: 0,
    }));
  };

  // ===================================================================================================================
  // 데이터 조회
  //
  // 모든 폼 필드의 변경을 감시하면서 특정 필드들은 제외
  // watch 함수의 콜백에서 해당 필드들의 이름을 확인하고, 아닌 경우에만 handleForm을 호출한다.
  // ===================================================================================================================
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (
        name !== 'approvalNum' &&
        name !== 'transactionKey' &&
        name !== 'paymentKey'
      ) {
        handleForm(value as FormInputs);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Title title="Payments" />
      <LeyutaeLayout />
      <Stack spacing={3} pt={11} px={3}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            overflow: 'hidden',
            border: '1px solid #E4E4E7',
            borderRadius: 2,
          }}
        >
          <form onSubmit={handleSubmit(handleForm)}>
            <Grid container sx={{ p: 2 }} spacing={2}>
              <Grid item xs={12} md={1.5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label="Start date"
                        inputFormat="yyyy-MM-dd"
                        value={value}
                        onChange={value => onChange(getDate(value))}
                        renderInput={param => (
                          <TextField
                            fullWidth
                            size="small"
                            required
                            {...param}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={1.5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label="End date"
                        inputFormat="yyyy-MM-dd"
                        value={value}
                        onChange={value => onChange(getDate(value))}
                        renderInput={param => (
                          <TextField
                            fullWidth
                            size="small"
                            required
                            {...param}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={1.5}>
                <Controller
                  name="cardCompany"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="cardCompany"
                      label="Card"
                      required
                      fullWidth
                      size="small"
                      select
                      value={value}
                      onChange={onChange}
                    >
                      {CARD_COMPANY.map(c => (
                        <MenuItem key={c.value} value={c.value}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={1.5}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="status"
                      label="Transaction status"
                      required
                      fullWidth
                      size="small"
                      select
                      value={value}
                      onChange={onChange}
                    >
                      <MenuItem value="DEFAULT">ALL</MenuItem>
                      <MenuItem value="COMPLETE">Approved</MenuItem>
                      <MenuItem value="CANCEL">Cancelled</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={1.5}>
                <TextField
                  id="approvalNum"
                  label="Approval Number"
                  fullWidth
                  size="small"
                  {...register('approvalNum')}
                  onBlur={() => handleForm(watch())}
                />
              </Grid>
              <Grid item xs={12} md={1.5}>
                <TextField
                  id="transactionKey"
                  label="Transaction key"
                  fullWidth
                  size="small"
                  {...register('transactionKey')}
                  onBlur={() => handleForm(watch())}
                />
              </Grid>
              <Grid item xs={12} md={1.5}>
                <TextField
                  id="paymentKey"
                  label="Payment key"
                  fullWidth
                  size="small"
                  {...register('paymentKey')}
                  onBlur={() => handleForm(watch())}
                />
              </Grid>
              <Grid item xs={12} md={1.5}>
                <SearchControls
                  searchText="Search"
                  resetText="Reset"
                  onReset={handleReset}
                />
              </Grid>
            </Grid>
          </form>
        </Paper>

        {/* 거래 상세 조회  */}
        <Paper
          elevation={0}
          sx={{
            p: 2,
            border: '1px solid #E4E4E7',
            borderRadius: 2,
          }}
        >
          <ReyutaeTransactionDetail params={params} setParams={setParams} />
        </Paper>
      </Stack>
    </>
  );
};

export default LeyutaePayment;
export { CARD_COMPANY };
