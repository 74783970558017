import CloseIcon from "@mui/icons-material/Close";
import { DialogContent } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSetRecoilState } from "recoil";
import { getUserInfo } from "../../../../../api/Auth";
import { getMerchantsDetail } from "../../../../../api/Merchants";
import { CommonApi } from "../../../../../api/type";
import Fonts from "../../../../../components/Contract/Fonts";
import ModalTriggerButton from "../../../../../components/PopupTriggerButton";
import isModalOpen from "../../../../../recoil/isModalOpen/atom";
import { UserInformation } from "../../../../Client/MerchantRegistration";
import { getSettlementV2Statement } from "../libs";
import SendSettlementStatement from "./SendSettlementStatement";
import SettlementNoteModal from "./SettlementNoteModal";
import UpdateDocNumberModal from "./UpdateDocNumberModal";

interface SettlementStatementProps {
  closeModal?: () => void;
  settlementId: number;
  merchantId: string;
}

const SettlementStatement = ({
  closeModal,
  settlementId,
  merchantId
}: SettlementStatementProps) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===================================================================================================================
  // 회원의 role 조회
  // ===================================================================================================================
  const { data: usersRole } = useQuery<CommonApi<UserInformation>>(
    ["/users/information"],
    () => getUserInfo()
  );

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 정산 내역서 데이터 조회
  const { data } = useQuery(
    [`/v3/settlements/${settlementId}`, settlementId],
    () => getSettlementV2Statement(settlementId)
  );

  // 가맹점 상세 데이터 조회
  const { data: merchantData } = useQuery([`/merchants/${merchantId}`], () =>
    getMerchantsDetail({ merchantId })
  );

  const 정산담당자 = merchantData?.content.merchantInChargeInformation[1].email;

  // ===================================================================================================================
  // 정산 내역서 다운로드
  // ===================================================================================================================
  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "정산 내역서",
    onPrintError: () =>
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "정산 내역서 저장에 실패했습니다."
      })
  });

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{
            boxShadow: "0px 5px 14px -1px rgba(204, 204, 204, 0.5)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          정산 내역서
          <Stack direction={"row"} gap={2}>
            {usersRole?.content.role === "ADMIN" && (
              <>
                <ModalTriggerButton
                  modalType="default"
                  color="secondary"
                  modal={<SettlementNoteModal settlementId={settlementId} />}
                >
                  비고 업데이트
                </ModalTriggerButton>
                <ModalTriggerButton
                  modalType="default"
                  color="success"
                  modal={
                    <UpdateDocNumberModal
                      settlementId={settlementId}
                      documentNumber={
                        data?.content.total.documentNumber
                          ? data.content.total.documentNumber
                          : data?.content.total.defaultDocumentNumber
                      }
                    />
                  }
                >
                  문서번호 수정
                </ModalTriggerButton>
                <ModalTriggerButton
                  modalType="default"
                  color="primary"
                  modal={
                    <SendSettlementStatement
                      statementId={settlementId}
                      managerEmail={정산담당자 ?? ""}
                    />
                  }
                >
                  이메일 전송
                </ModalTriggerButton>
              </>
            )}
            <Button
              variant="contained"
              onClick={handlePrint}
              sx={{ color: "white" }}
            >
              인쇄하기
            </Button>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ mt: 2 }}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <div ref={componentRef}>
              <Fonts>
                <Box
                  sx={{
                    width: "297mm",
                    height: "209.7mm",
                    p: "15mm",
                    border: "1px solid black"
                  }}
                >
                  {/* 머리글 */}
                  <Stack justifyContent={"center"} alignItems={"center"} my={2}>
                    <Typography fontSize={22} fontWeight={"bold"}>
                      정산내역서
                    </Typography>
                  </Stack>

                  {/* 가맹점 및 회사 정보 */}
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Box>
                      <Stack
                        direction={"row"}
                        sx={{ borderTop: "1px solid black", textIndent: 4 }}
                      >
                        <Typography mr={2}>가맹점</Typography>
                        <Typography>{data?.content.total.mallName}</Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        sx={{ borderTop: "1px solid black", textIndent: 4 }}
                      >
                        <Typography mr={1}>결제수단</Typography>
                        <Typography>카드</Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        sx={{ borderTop: "1px solid black", textIndent: 4 }}
                      >
                        <Typography mr={2.25}>이메일</Typography>
                        <Typography>{data?.content.total.email}</Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        sx={{ borderTop: "1px solid black", textIndent: 4 }}
                      >
                        <Typography mr={1}>문서번호</Typography>
                        <Typography>
                          {data?.content.total.documentNumber
                            ? data?.content.total.documentNumber
                            : data?.content.total.defaultDocumentNumber}
                        </Typography>
                      </Stack>
                      <Divider color="black" />
                    </Box>

                    <Box>
                      <Typography
                        sx={{ borderTop: "1px solid black", textIndent: 4 }}
                      >
                        ㈜옵타움플랫폼
                      </Typography>
                      <Typography
                        sx={{ borderTop: "1px solid black", textIndent: 4 }}
                      >
                        582-81-01285
                      </Typography>
                      <Typography
                        sx={{ borderTop: "1px solid black", textIndent: 4 }}
                      >
                        대표이사 김 병 규 (직인생략)
                      </Typography>
                      <Typography
                        sx={{ borderTop: "1px solid black", textIndent: 4 }}
                      >
                        부산광역시 금정구 금정로 61, 3~5층
                      </Typography>
                      <Typography
                        sx={{ borderTop: "1px solid black", textIndent: 4 }}
                      >
                        TEL 1577-6281
                      </Typography>
                      <Divider color="black" />
                    </Box>
                  </Stack>

                  {/* 단위(원) */}
                  <Stack mt={4} textAlign={"right"}>
                    <Typography fontSize={12}>단위(원)</Typography>
                  </Stack>

                  {/* 본문-1 */}
                  <Stack mb={6} border={"2px solid black"}>
                    {/* 본문-1과 정산금액을 나누는 스택 */}
                    <Stack direction={"row"}>
                      {/* 본문-1을 감싸는 스택 */}
                      <Stack flex={8}>
                        {/* 본문-1-1 스택 */}
                        <Stack
                          direction={"row"}
                          borderBottom={"1px solid black"}
                        >
                          <Stack direction={"row"} flex={1}>
                            <Typography
                              fontSize={14}
                              textAlign={"center"}
                              fontWeight={"bold"}
                              flex={1}
                              borderRight={"1px solid black"}
                            >
                              가맹점명
                            </Typography>
                            <Typography
                              fontSize={14}
                              textAlign={"center"}
                              flex={2}
                              borderRight={"1px solid black"}
                            >
                              {data?.content.total.mallName}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} flex={1}>
                            <Typography
                              borderRight={"1px solid black"}
                              fontSize={14}
                              textAlign={"center"}
                              fontWeight={"bold"}
                              flex={1}
                            >
                              총 건수
                            </Typography>
                            <Typography
                              fontSize={14}
                              textAlign={"center"}
                              flex={2}
                              borderRight={"1px solid black"}
                            >
                              {data?.content.total.totalTransactionCount}
                            </Typography>
                          </Stack>
                          <Stack direction={"row"} flex={1}>
                            <Typography
                              borderRight={"1px solid black"}
                              fontSize={14}
                              textAlign={"center"}
                              fontWeight={"bold"}
                              flex={2}
                            >
                              수수료[C수수료 - D수수료]
                            </Typography>
                            <Typography
                              fontSize={14}
                              textAlign={"center"}
                              flex={1}
                            >
                              {data?.content.total.merchantFee}
                            </Typography>
                          </Stack>
                        </Stack>

                        {/* 본문-1-2 스택 */}
                        <Stack
                          direction={"row"}
                          borderBottom={"1px solid black"}
                        >
                          <Stack direction={"row"} flex={1}>
                            <Typography
                              borderRight={"1px solid black"}
                              fontSize={14}
                              flex={1}
                              textAlign={"center"}
                              fontWeight={"bold"}
                            >
                              거래기간
                            </Typography>
                            <Typography
                              fontSize={14}
                              flex={2}
                              textAlign={"center"}
                              borderRight={"1px solid black"}
                            >
                              {data?.content.total.transactionDate}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            flex={1}
                          >
                            <Typography
                              borderRight={"1px solid black"}
                              fontSize={14}
                              flex={1}
                              textAlign={"center"}
                              fontWeight={"bold"}
                            >
                              승인건수
                            </Typography>
                            <Typography
                              fontSize={14}
                              flex={2}
                              textAlign={"center"}
                              borderRight={"1px solid black"}
                            >
                              {data?.content.total.totalCompleteCount}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            flex={1}
                          >
                            <Typography
                              borderRight={"1px solid black"}
                              fontSize={14}
                              flex={2}
                              textAlign={"center"}
                              fontWeight={"bold"}
                            >
                              부가가치세
                            </Typography>
                            <Typography
                              fontSize={14}
                              flex={1}
                              textAlign={"center"}
                            >
                              {data?.content.total.vat}
                            </Typography>
                          </Stack>
                        </Stack>

                        {/* 본문-1-3 스택 */}
                        <Stack
                          direction={"row"}
                          borderBottom={"1px solid black"}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            flex={1}
                          >
                            <Typography
                              borderRight={"1px solid black"}
                              fontSize={14}
                              flex={1}
                              textAlign={"center"}
                              fontWeight={"bold"}
                            >
                              정산일자
                            </Typography>
                            <Typography
                              fontSize={14}
                              flex={2}
                              textAlign={"center"}
                              borderRight={"1px solid black"}
                            >
                              {data?.content.total.payoutDate}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            flex={1}
                          >
                            <Typography
                              borderRight={"1px solid black"}
                              fontSize={14}
                              flex={1}
                              textAlign={"center"}
                              fontWeight={"bold"}
                            >
                              취소건수
                            </Typography>
                            <Typography
                              fontSize={14}
                              flex={2}
                              textAlign={"center"}
                              borderRight={"1px solid black"}
                            >
                              {data?.content.total.totalCancelCount}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            flex={1}
                          >
                            <Typography
                              borderRight={"1px solid black"}
                              fontSize={14}
                              flex={2}
                              textAlign={"center"}
                              fontWeight={"bold"}
                            >
                              기타청구
                            </Typography>
                            <Typography
                              fontSize={14}
                              flex={1}
                              textAlign={"center"}
                            >
                              {data?.content.total.chargesAmount}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>

                      {/* 정산금액 */}
                      <Stack
                        direction={"row"}
                        flex={2}
                        borderBottom={"1px solid black"}
                      >
                        <Typography
                          fontSize={16}
                          fontWeight={"bold"}
                          flex={1}
                          borderRight={"1px solid black"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          borderLeft={"1px solid black"}
                        >
                          정산금액
                        </Typography>
                        <Typography
                          fontSize={16}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          flex={2}
                        >
                          {data?.content.total.payoutAmount}
                        </Typography>
                      </Stack>
                    </Stack>
                    {/* 비고 */}
                    <Stack direction={"row"}>
                      <Typography
                        borderRight={"1px solid black"}
                        fontSize={14}
                        flex={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        비고
                      </Typography>
                      <Typography
                        fontSize={14}
                        flex={10.4}
                        textAlign={"center"}
                      >
                        {data?.content.total.note}
                      </Typography>
                    </Stack>
                  </Stack>

                  {/* 본문-2 */}
                  <Stack border={"2px solid black"}>
                    {/* 본문-2와 합계, 기타 청구, 정산지급금액을 나누는 스택 */}
                    <Stack>
                      {/* 본문-2 날짜, 요일, 총 건수 */}
                      <Stack direction="row" textAlign="center">
                        <Stack direction="row" flex={1}>
                          <Typography
                            flex={0.5}
                            fontSize={14}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRight={"1px solid black"}
                            fontWeight={"bold"}
                          >
                            날짜
                          </Typography>
                          <Typography
                            flex={0.5}
                            fontSize={14}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRight={"1px solid black"}
                            fontWeight={"bold"}
                          >
                            요일
                          </Typography>
                          <Typography
                            flex={1}
                            fontSize={14}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRight={"1px solid black"}
                            fontWeight={"bold"}
                          >
                            총 건수
                          </Typography>
                        </Stack>

                        {/* 본문-2 승인 합계 */}
                        <Stack flex={1} borderRight={"1px solid black"}>
                          <Typography fontSize={14} fontWeight={"bold"}>
                            승인 합계 (A)
                          </Typography>
                          <Stack direction="row" borderTop={"1px solid black"}>
                            <Typography
                              flex={1}
                              fontSize={12}
                              borderRight="1px solid black"
                              fontWeight={"bold"}
                            >
                              건수
                            </Typography>
                            <Typography
                              flex={1}
                              fontSize={12}
                              fontWeight={"bold"}
                            >
                              금액
                            </Typography>
                          </Stack>
                        </Stack>

                        {/* 본문-2 취소 합계 */}
                        <Stack flex={1} borderRight={"1px solid black"}>
                          <Typography
                            fontSize={14}
                            textAlign="center"
                            fontWeight={"bold"}
                          >
                            취소 합계 (B)
                          </Typography>
                          <Stack direction="row" borderTop={"1px solid black"}>
                            <Typography
                              flex={1}
                              fontSize={12}
                              borderRight="1px solid black"
                              fontWeight={"bold"}
                            >
                              건수
                            </Typography>
                            <Typography
                              flex={1}
                              fontSize={12}
                              fontWeight={"bold"}
                            >
                              금액
                            </Typography>
                          </Stack>
                        </Stack>

                        {/* 본문-2 승인 차감 계정 */}
                        <Stack flex={1} borderRight={"1px solid black"}>
                          <Typography fontSize={14} fontWeight={"bold"}>
                            승인 차감 계정 (C)
                          </Typography>
                          <Stack direction="row" borderTop="1px solid black">
                            <Typography
                              flex={1}
                              fontSize={12}
                              borderRight="1px solid black"
                              fontWeight={"bold"}
                            >
                              수수료
                            </Typography>
                            <Typography
                              flex={1}
                              fontSize={12}
                              fontWeight={"bold"}
                            >
                              부가세
                            </Typography>
                          </Stack>
                        </Stack>

                        {/* 본문-2 취소 차감 계정 */}
                        <Stack flex={1} borderRight={"1px solid black"}>
                          <Typography fontSize={14} fontWeight={"bold"}>
                            취소차감계정 (D)
                          </Typography>
                          <Stack direction="row" borderTop="1px solid black">
                            <Typography
                              flex={1}
                              fontSize={12}
                              borderRight="1px solid black"
                              fontWeight={"bold"}
                            >
                              수수료
                            </Typography>
                            <Typography
                              flex={1}
                              fontSize={12}
                              fontWeight={"bold"}
                            >
                              부가세
                            </Typography>
                          </Stack>
                        </Stack>

                        <Stack flex={1}>
                          <Typography
                            fontSize={14}
                            borderBottom="1px solid black"
                            fontWeight={"bold"}
                          >
                            정산
                          </Typography>
                          <Typography fontSize={13} fontWeight={"bold"}>
                            A+B-C+D
                          </Typography>
                        </Stack>
                      </Stack>

                      {data?.content.details.map((d, index) => (
                        <Stack
                          key={index}
                          direction={"row"}
                          textAlign={"center"}
                          borderTop={"1px solid black"}
                        >
                          <Typography
                            flex={0.485}
                            borderRight={"1px solid black"}
                            textAlign={"center"}
                            fontSize={12}
                          >
                            {d.date}
                          </Typography>

                          <Typography
                            flex={0.5}
                            borderRight={"1px solid black"}
                            fontSize={12}
                          >
                            {d.dayOfWeek}
                          </Typography>
                          <Typography
                            flex={0.98}
                            borderRight={"1px solid black"}
                            fontSize={12}
                          >
                            {d.transactionCount}
                          </Typography>

                          {/* 승인 합계 */}
                          <Typography
                            flex={1}
                            borderRight={"1px solid black"}
                            fontSize={12}
                          >
                            {d.completeCount}
                          </Typography>
                          <Typography
                            flex={0.99}
                            borderRight={"1px solid black"}
                            fontSize={12}
                          >
                            {d.completeAmount}
                          </Typography>

                          {/* 취소 합계 */}
                          <Typography
                            flex={1}
                            borderRight={"1px solid black"}
                            fontSize={12}
                          >
                            {d.cancelCount}
                          </Typography>
                          <Typography
                            flex={1}
                            borderRight={"1px solid black"}
                            fontSize={12}
                          >
                            {d.cancelAmount}
                          </Typography>

                          {/* 승인 차감 계정 */}
                          <Typography
                            flex={1}
                            borderRight={"1px solid black"}
                            fontSize={12}
                          >
                            {d.completeMerchantFee}
                          </Typography>
                          <Typography
                            flex={1}
                            borderRight={"1px solid black"}
                            fontSize={12}
                          >
                            {d.completeVat}
                          </Typography>

                          {/* 취소 차감 계정 */}
                          <Typography
                            flex={1}
                            borderRight={"1px solid black"}
                            fontSize={12}
                          >
                            {d.cancelMerchantFee}
                          </Typography>
                          <Typography
                            flex={0.99}
                            borderRight={"1px solid black"}
                            fontSize={12}
                          >
                            {d.cancelVat}
                          </Typography>

                          {/* 정산 */}
                          <Typography flex={2} fontSize={12}>
                            {d.payoutAmount}
                          </Typography>
                        </Stack>
                      ))}

                      {/* 본문-2 - 합계 */}
                      <Stack
                        direction={"row"}
                        borderTop={"3px double black"}
                        borderBottom={"1px solid black"}
                      >
                        <Typography
                          flex={0.99}
                          fontSize={12}
                          fontWeight={"bold"}
                          textAlign={"center"}
                          borderRight={"1px solid black"}
                        >
                          합계
                        </Typography>
                        <Typography
                          flex={0.98}
                          fontSize={12}
                          fontWeight={"bold"}
                          textAlign={"center"}
                          borderRight={"1px solid black"}
                        >
                          {data?.content.detailsTotal.transactionTotalCount}
                        </Typography>
                        <Typography
                          flex={1}
                          fontSize={12}
                          fontWeight={"bold"}
                          textAlign={"center"}
                          borderRight={"1px solid black"}
                        >
                          {data?.content.detailsTotal.completeTotalCount}
                        </Typography>
                        <Typography
                          flex={0.98}
                          fontSize={12}
                          fontWeight={"bold"}
                          textAlign={"center"}
                          borderRight={"1px solid black"}
                        >
                          {data?.content.detailsTotal.completeTotalAmount}
                        </Typography>
                        <Typography
                          flex={1}
                          fontSize={12}
                          fontWeight={"bold"}
                          textAlign={"center"}
                          borderRight={"1px solid black"}
                        >
                          {data?.content.detailsTotal.cancelTotalCount}
                        </Typography>
                        <Typography
                          flex={1}
                          fontSize={12}
                          fontWeight={"bold"}
                          textAlign={"center"}
                          borderRight={"1px solid black"}
                        >
                          {data?.content.detailsTotal.cancelTotalAmount}
                        </Typography>
                        <Typography
                          flex={1}
                          fontSize={12}
                          fontWeight={"bold"}
                          textAlign={"center"}
                          borderRight={"1px solid black"}
                        >
                          {data?.content.detailsTotal.completeTotalFee}
                        </Typography>
                        <Typography
                          flex={1}
                          fontSize={12}
                          fontWeight={"bold"}
                          textAlign={"center"}
                          borderRight={"1px solid black"}
                        >
                          {data?.content.detailsTotal.completeTotalVat}
                        </Typography>
                        <Typography
                          flex={0.99}
                          fontSize={12}
                          fontWeight={"bold"}
                          textAlign={"center"}
                          borderRight={"1px solid black"}
                        >
                          {data?.content.detailsTotal.cancelTotalFee}
                        </Typography>
                        <Typography
                          flex={0.98}
                          fontSize={12}
                          fontWeight={"bold"}
                          textAlign={"center"}
                          borderRight={"1px solid black"}
                        >
                          {data?.content.detailsTotal.cancelTotalVat}
                        </Typography>
                        <Typography
                          flex={2}
                          fontSize={12}
                          fontWeight={"bold"}
                          textAlign={"center"}
                        >
                          {data?.content.detailsTotal.payoutAmount}
                        </Typography>
                      </Stack>

                      {/* 본문-2 - 기타 청구 */}
                      <Stack direction={"row"} borderBottom={"1px solid black"}>
                        <Typography
                          flex={0.99}
                          borderRight={"1px solid black"}
                          textAlign={"center"}
                          fontSize={13}
                          fontWeight={"bold"}
                        >
                          기타청구
                        </Typography>
                        <Typography
                          flex={11}
                          textAlign={"center"}
                          fontSize={12}
                          fontWeight={"bold"}
                        >
                          {data?.content.detailsTotal.chargesAmount}
                        </Typography>
                      </Stack>

                      {/* 본문-2 - 정산지급금액 */}
                      <Stack direction={"row"}>
                        <Typography
                          flex={0.99}
                          textAlign={"center"}
                          fontSize={13}
                          fontWeight={"bold"}
                          borderRight={"1px solid black"}
                        >
                          정산지급금액
                        </Typography>
                        <Typography
                          flex={11}
                          textAlign={"center"}
                          fontSize={12}
                          fontWeight={"bold"}
                        >
                          {data?.content.detailsTotal.payoutAmount}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Typography fontSize={13} mt={4} mb={1}>
                    *정산일이 공휴일인 경우 익영업일에 지급
                  </Typography>

                  {/* 바닥글 */}
                  <Typography textAlign={"center"} fontSize={14}>
                    {data?.content.total.payoutDate}
                  </Typography>
                </Box>
              </Fonts>
            </div>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default SettlementStatement;
