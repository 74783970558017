import { instanceWithAuth } from "../../../../../api/Instance";
import { CommonApi } from "../../../../../api/type";
import { AddSettledItemParams } from "../../../UnsettledPayments/libs/types";
import {
  InChargeInfoDataType,
  InChargeInfoParams,
  PayoutSettlementParams,
  SendEmailSettlementParams,
  SettlementDetailData,
  SettlementsData,
  SettlementsDetailParams,
  SettlementStatementData,
  SettlementsTotalData,
  SettlementsV2Params,
  SettlementUpdateParams
} from "./types";

// =====================================================================================================================
// 정산내역
// =====================================================================================================================
/**
 * 정산내역 데이터 조회
 */
export const getSettlementsV2 = async (params: SettlementsV2Params) => {
  const url = "/v3/settlements";
  const { data } = await instanceWithAuth.get<CommonApi<SettlementsData[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 정산내역 총 합계 데이터 조회
 */
export const getSettlementsV2Total = async (params: SettlementsV2Params) => {
  const url = "/v3/settlements/total";
  const { data } = await instanceWithAuth.get<CommonApi<SettlementsTotalData>>(
    url,
    { params }
  );
  return data;
};

/**
 * 정산내역서 데이터 조회
 */
export const getSettlementV2Statement = async (id: number) => {
  const url = `/v3/settlements/${id}`;
  const { data } = await instanceWithAuth.get<
    CommonApi<SettlementStatementData>
  >(url);
  return data;
};

/**
 * 정산 확정, 지급 일자 변경, 비고 수정, 문서 번호 수정
 */
export const confirmSettlementV2 = async (params: SettlementUpdateParams[]) => {
  const url = "/v3/settlements";
  const { data } = await instanceWithAuth.put<CommonApi<boolean>>(url, params);
  return data;
};

/**
 * 정산 개별 내역 데이터 조회
 */
export const getSettlementsV2Detail = async (
  params: SettlementsDetailParams
) => {
  const url = `/v3/settlements/${params.id}/settlement-items`;
  const { data } = await instanceWithAuth.get<CommonApi<SettlementDetailData>>(
    url,
    { params }
  );
  return data;
};

/**
 * 정산 내역서 이메일 전송
 */
// export const postSettlementPdf = async (params: SendEmailSettlementParams) => {
//   const { data } = await instanceWithAuth.post(
//     `/v3/settlements/${params.id}/send-file`,
//     params
//   );
//   return data;
// };

/**
 * 정산 내역서 & 개별 내역 엑셀 이메일 전송
 */
export const postSettlementV2PdfExcel = async (
  params: SendEmailSettlementParams
) => {
  const { data } = await instanceWithAuth.post(
    `/v3/settlements/send-file`,
    params
  );
  return data;
};

/**
 * 정산내역 데이터 제거 -> 미정산 내역으로 이동
 */
export const deleteSettlementV2Items = async ({
  id,
  settlementItems
}: AddSettledItemParams) => {
  const url = `/v3/settlements/${id}/settlement-items`;
  const { data } = await instanceWithAuth.delete<CommonApi<boolean>>(url, {
    data: settlementItems
  });
  return data;
};

/**
 * 정산 담당자 조회
 */
export const getInChargeInfo = async (params: InChargeInfoParams) => {
  const url = "/merchant-in-charge-informations";
  const { data } = await instanceWithAuth.get<
    CommonApi<InChargeInfoDataType[]>
  >(url, {
    params
  });
  return data;
};

// =====================================================================================================================
// 정산지급
// =====================================================================================================================
/**
 * 정산지급
 */
export const postSettlementV2Payout = async (
  params: PayoutSettlementParams
) => {
  const url = `/v3/settlements/${params.id}/payout-histroy`;
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};
