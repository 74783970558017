import LoginIcon from "@mui/icons-material/Login";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getUserInfo } from "../../api/Auth";
import { getRSA, updatePassword } from "../../api/Password";
import LoadingButton from "../../components/LoadingButton";
import PasswordStrength from "../../components/PasswordStrength";
import Title from "../../components/Title";
import ozicpayText from "../../images/ozicpay_logo_text.png";
import { encrypting } from "../../libs/encryping-password";
import passwordStrengthCheck from "../../libs/password-strength-check";
import isModalOpenAtom from "../../recoil/isModalOpen";
import LoginInfoAtom from "../../recoil/loginInfo";

interface ChangePasswordForm {
  password: string;
  passwordCheck: string;
}

const ChangePassword = () => {
  const navigate = useNavigate();
  const loginInfo = useRecoilValue(LoginInfoAtom);
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  const [isHide, setIsHide] = useState(true);
  const [isHidePasswordCheck, setIsHidePasswordCheck] = useState(true);

  const handleHideButton = (inputName: string) => {
    if (inputName === "password") setIsHide((prevValue) => !prevValue);
    if (inputName === "passwordCheck")
      setIsHidePasswordCheck((prevValue) => !prevValue);
  };

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { dirtyFields: registerDirtyField }
  } = useForm<ChangePasswordForm>({
    defaultValues: {
      password: "",
      passwordCheck: ""
    }
  });
  const { mutate, isLoading } = useMutation(updatePassword, {
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    },
    onSuccess: (data) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "비밀번호가 변경되었습니다."
      });
      if (loginInfo.loginUser.role === "ADMIN") {
        navigate("/admin", {
          replace: true
        });
      } else {
        navigate("/", {
          replace: true
        });
      }
    }
  });
  const { isFetching: isRSAFeching, refetch } = useQuery(
    ["rsa", "register"],
    getRSA,
    {
      enabled: false,
      onSuccess: (res) => {
        const { password } = getValues();
        const encryptedPassword = encrypting({
          password,
          publicKey: res?.content?.public_key
        });
        if (!encryptedPassword) return;
        mutate({
          encryptedPassword,
          publicKey: res?.content?.public_key
        });
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );
  const onVaild = (data: ChangePasswordForm) => {
    if (isLoading || isRSAFeching) return;
    refetch();
  };
  const onInValid = (errors: FieldErrors<ChangePasswordForm>) => {
    const { password, passwordCheck } = errors;
    const errorMessage = password?.message || passwordCheck?.message;
    setIsModalOpen({
      value: true,
      position: "top",
      alertSeverity: "error",
      message: errorMessage!
    });
  };
  const { data } = useQuery(["getUserInfo"], getUserInfo);
  useEffect(() => {
    // passwordUpdateFlag가 "N"인 사람이 페이지에 들어오면 "/"나 "/admin"으로 리다이렉트
    if (data && data?.content && data.content.passwordUpdateFlag === "N") {
      data.content.role === "ADMIN"
        ? navigate("/admin", { replace: true })
        : navigate("/", { replace: true });
    }
  }, [data, navigate]);
  const passwordStrengthStatus = passwordStrengthCheck(watch("password"));

  return (
    <>
      <Title title="비밀번호 변경" />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 2
        }}
      >
        <Paper
          sx={{
            maxWidth: 500,
            width: "100%",
            py: 10,
            px: 8,
            border: "1px solid #F2F3F5",
            borderRadius: 3
          }}
        >
          <Stack
            sx={{
              marginBottom: 5
            }}
            alignItems="center"
          >
            <Box
              component="img"
              src={ozicpayText}
              alt="ozicpay logo"
              sx={{ width: "50%" }}
            />
          </Stack>
          <form onSubmit={handleSubmit(onVaild, onInValid)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="비밀번호"
                  required
                  fullWidth
                  type={isHide ? "password" : "text"}
                  autoComplete="on"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleHideButton("password")}
                        >
                          {isHide ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  {...register("password", {
                    required: "비밀번호를 입력해주세요.",
                    minLength: {
                      value: 8,
                      message: "비밀번호는 8자 이상이어야 합니다."
                    },
                    validate: (value) => {
                      const checkResult = passwordStrengthCheck(value);
                      return (
                        checkResult === "성공" ||
                        "사용할 수 없는 비밀번호 입니다."
                      );
                    }
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="비밀번호 확인"
                  required
                  fullWidth
                  type={isHidePasswordCheck ? "password" : "text"}
                  autoComplete="on"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleHideButton("passwordCheck")}
                        >
                          {isHidePasswordCheck ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  {...register("passwordCheck", {
                    required: "비밀번호 확인을 입력해주세요.",
                    validate: (value) => {
                      const { password } = getValues();
                      return (
                        password === value || "비밀번호가 일치하지 않습니다."
                      );
                    }
                  })}
                />
              </Grid>
            </Grid>
            <Stack>
              <PasswordStrength status={passwordStrengthStatus} />
            </Stack>
            <Box
              sx={{
                marginTop: 5
              }}
            >
              <LoadingButton
                icon={<LoginIcon />}
                fullWidth={true}
                size="large"
                loading={isLoading || isRSAFeching}
                disabled={
                  !registerDirtyField?.password ||
                  !registerDirtyField?.passwordCheck
                }
              >
                비밀번호 변경하기
              </LoadingButton>
            </Box>
          </form>
        </Paper>
      </Box>
    </>
  );
};

export default ChangePassword;
