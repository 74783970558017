import { instanceWithAuth } from "../../../../api/Instance";
import { CommonApi } from "../../../../api/type";
import {
  getMissingPurchasesParams,
  getPurchaseParams,
  MissingPurchasesData,
  PostPurchaseParams,
  PurchasePaymentsData
} from "./types";

// =====================================================================================================================
// 누락 매입 신청, 철회
// =====================================================================================================================
/**
 * 누락 매입 신청
 */
export const postPurchasePayments = async (params: PostPurchaseParams) => {
  const url = `/missing-purchase-payments`;
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

/**
 * 누락 매입 등록 철회
 */
export const deletePurchasePayments = async (params: PostPurchaseParams) => {
  const url = `/missing-purchase-payments`;
  const { data } = await instanceWithAuth.delete<CommonApi<boolean>>(url, {
    data: params
  });
  return data;
};

// =====================================================================================================================
// 누락 매입 조회
// =====================================================================================================================
// 매입 누락 거래 목록 조회
export const getMissingPurchases = async (
  params: getMissingPurchasesParams
) => {
  const url = "/purchase/missing";
  const { data } = await instanceWithAuth.get<
    CommonApi<MissingPurchasesData[]>
  >(url, {
    params
  });
  return data;
};

// 매입 등록 거래 목록 조회
export const getPurchasePaymnets = async (params: getPurchaseParams) => {
  const url = "/missing-purchase-payments";
  const { data } = await instanceWithAuth.get<
    CommonApi<PurchasePaymentsData[]>
  >(url, {
    params
  });
  return data;
};
