import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AdminLayout from '../../components/Layout/Admin/Layout';
import Title from '../../components/Title';
import useRenewal from '../../hooks/useRenewal';
import { getDate } from '../../libs/get-date';
import { getToday } from '../../libs/get-today';
import { getOzicpayHistory } from '../../api/History';
import { GetHistoryDataType, GetHistoryParams } from '../../api/History/types';
import DetailHistoryModal from '../../components/History/DetailHistoryModal';
import SearchControls from '../../components/Common/SearchControls';

interface Columns {
  id: keyof GetHistoryDataType;
  label: string;
}

const columns: Columns[] = [
  { id: 'id', label: 'ID' },
  { id: 'apiUrl', label: 'API URL' },
  { id: 'tableName', label: '테이블명' },
  { id: 'name', label: '변경자' },
  { id: 'methodType', label: '변경타입' },
  { id: 'createDate', label: '변경날짜' },
];

interface HistoryForm {
  startDate: string;
  endDate: string;
  methodType: string;
  name: string;
  tableName: string;
}

const Histories = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // 파라미터 스테이트
  const [params, setParams] = useState<GetHistoryParams>({
    startDate: getToday(),
    endDate: getToday(),
    methodType: '',
    name: '',
    tableName: '',
    page: 0,
    pageSize: 10,
  });

  // 모달 스테이트
  const [detailModal, setDetailModal] = useState({
    isOpen: false,
    before: '',
    after: '',
    methodType: '',
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { handleSubmit, control, reset } = useForm<HistoryForm>({
    defaultValues: {
      startDate: params.startDate,
      endDate: params.endDate,
      methodType: params.methodType,
      name: params.name,
      tableName: params.tableName,
    },
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 이력관리 데이터 조회
  const { data, isLoading } = useQuery(
    ['/ozicpay_history', params],
    () => getOzicpayHistory(params),
    {
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
    },
  );

  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams(prev => ({ ...prev, page: page - 1 }));
  };

  // ===================================================================================================================
  // 가맹점 신청 현황 조회 및 초기화
  // ===================================================================================================================
  // 초기화
  const handleReset = () => {
    reset();
    setParams({
      startDate: getToday(),
      endDate: getToday(),
      methodType: '',
      name: '',
      tableName: '',
      page: 0,
      pageSize: 10,
    });
  };

  // 조회
  const onClickSubmit = (formData: HistoryForm) => {
    setParams(prev => ({
      ...prev,
      startDate: formData.startDate,
      endDate: formData.endDate,
      methodType: formData.methodType === '전체' ? '' : formData.methodType,
      name: formData.name,
      tableName: formData.tableName,
    }));
  };

  // ===================================================================================================================
  // 모달 열기, 닫기
  // ===================================================================================================================
  const handleRowClick = (
    before: string,
    after: string,
    methodType: string,
  ) => {
    setDetailModal({ methodType, before, after, isOpen: true });
  };

  const closeModal = () => {
    setDetailModal({
      methodType: '',
      before: '',
      after: '',
      isOpen: false,
    });
  };

  const handleBlur = (key: keyof GetHistoryParams, value: string) => {
    setParams(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <Title title="이력 관리" />
      <AdminLayout>
        <Paper
          sx={{
            p: 3,
            border: '1px solid #F2F3F5',
            borderRadius: 3,
            mb: 3,
          }}
        >
          <form onSubmit={handleSubmit(onClickSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={1.5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label="시작 날짜"
                        inputFormat="yyyy-MM-dd"
                        value={value}
                        onChange={value => {
                          onChange(getDate(value));
                          setParams(prev => ({
                            ...prev,
                            startDate: getDate(value),
                          }));
                        }}
                        renderInput={param => (
                          <TextField fullWidth size="small" {...param} />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={1.5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label="종료 날짜"
                        inputFormat="yyyy-MM-dd"
                        value={value}
                        onChange={value => {
                          onChange(getDate(value));
                          setParams(prev => ({
                            ...prev,
                            endDate: getDate(value),
                          }));
                        }}
                        renderInput={param => (
                          <TextField fullWidth size="small" {...param} />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={1.5}>
                <Controller
                  name="tableName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="tableName"
                      label="테이블명"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={event =>
                        handleBlur('tableName', event.target.value)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={1.5}>
                <Controller
                  name="methodType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="methodType"
                      label="변경타입"
                      select
                      fullWidth
                      size="small"
                      value={value || '전체'}
                      onChange={event => {
                        onChange(event.target.value);
                        setParams(prev => ({
                          ...prev,
                          methodType:
                            event.target.value === '전체'
                              ? ''
                              : event.target.value,
                        }));
                      }}
                    >
                      <MenuItem value={'전체'}>전체</MenuItem>
                      <MenuItem value={'CREATE'}>CREATE</MenuItem>
                      <MenuItem value={'MODIFY'}>MODIFY</MenuItem>
                      <MenuItem value={'DELETE'}>DELETE</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={1.5}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="name"
                      label="변경자"
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      onBlur={event => handleBlur('name', event.target.value)}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={4.5}>
                <SearchControls onReset={handleReset} />
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
          이력 관리
        </Typography>
        <Paper
          sx={{
            border: '1px solid #F2F3F5',
            borderRadius: 3,
            overflow: 'hidden',
          }}
        >
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                ...((!data || data?.content?.length === 0) && {
                  height: 550,
                }),
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      align="center"
                      sx={{ background: '#FBFBFB' }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.content.length === 0 ? (
                  <TableRow>
                    <TableCell sx={{ height: 500 }} colSpan={7}>
                      <Typography align="center">
                        조회된 데이터가 없습니다.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {!data &&
                      isLoading &&
                      [...Array(7)].map((_, index) => (
                        <TableRow key={index}>
                          {columns.map(column => (
                            <TableCell align="center" key={column.id}>
                              <Skeleton variant="rectangular" height={25} />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    {data &&
                      data.content.length > 0 &&
                      data.content.map(row => (
                        <TableRow
                          key={row.id}
                          hover
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleRowClick(
                              row.before,
                              row.after,
                              row.methodType,
                            )
                          }
                        >
                          {columns.map(column => (
                            <TableCell
                              align="center"
                              key={column.id}
                              sx={{ py: 1.5 }}
                            >
                              {column.id === 'createDate'
                                ? row.createDate.split('T').at(0)
                                : row[column.id]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* 이력 상세 정보 모달창 */}
          {detailModal.isOpen && (
            <DetailHistoryModal
              beforeData={detailModal.before}
              afterData={detailModal.after}
              isOpen={detailModal.isOpen}
              methodType={detailModal.methodType}
              closeModal={closeModal}
            />
          )}

          <Box my={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={params.page + 1}
              onChange={changePage}
              sx={{ display: 'flex', justifyContent: 'center' }}
            ></Pagination>
          </Box>
        </Paper>
      </AdminLayout>
    </>
  );
};
export default Histories;
