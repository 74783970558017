import { alpha, createTheme } from "@mui/material/styles";
import grey from "@mui/material/colors/grey";

function customShadows() {
  return {
    card: `0 0 2px 0 ${alpha(grey[500], 0.08)}, 0 6px 24px -4px ${alpha(
      grey[500],
      0.08
    )}`
  };
}

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // 스크롤바 스타일
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: 15
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#e7e7e7",
            border: "3px solid transparent",
            backgroundClip: "content-box"
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            m: "6px 0"
          }
        }
      }
    },
    // mui paper 섀도우 컬러 세팅
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: customShadows().card
        }
      }
    },
    // mui table row hover 컬러 세팅
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#fcfcfc"
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {}
      }
    }
  },
  typography: {
    fontFamily: "SUIT-Regular, Arial, sans-serif"
  },
  palette: {
    background: {
      default: "#FFFFFF"
    },
    primary: {
      main: "#FF6634"
    },
    secondary: {
      main: "#35BA9B"
    },
    success: {
      main: "#3AADD9"
    },
    error: {
      main: "#D94452"
    },
    info: {
      main: "#DCDCDC"
    },
    warning: {
      main: "#FDCD56"
    }
  }
});
