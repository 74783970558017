import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useMutation, useQuery } from '@tanstack/react-query';
import koLocale from 'date-fns/locale/ko';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import {
  completeSettlements,
  getSettlements,
  getSettlementsTotal,
} from '../../api/v2/Settlements.ts';
import {
  SettlementsData,
  SettlementsParams,
} from '../../api/v2/Settlements.ts/types';
import AdminLayout from '../../components/Layout/Admin/Layout';
import LoadingButton from '../../components/LoadingButton';
import ModalTriggerButton from '../../components/PopupTriggerButton';
import SettlementCalendarModal from '../../components/Settlements/SettlementCalendarModal';
import SettlementChargeModal from '../../components/Settlements/SettlementChargeModal';
import SettlementConfirmModal from '../../components/Settlements/SettlementConfirmModal';
import SettlementStatement from '../../components/Settlements/SettlementStatement';
import SettlementUnitModal from '../../components/Settlements/SettlementUnitModal';
import SettlementsTotal from '../../components/Settlements/SettlementsTotal';
import MuiTable from '../../components/Table/MuiTable';
import Title from '../../components/Title';
import useRenewal from '../../hooks/useRenewal';
import excelDownload from '../../libs/excel-download';
import { getDate } from '../../libs/get-date';
import { getToday } from '../../libs/get-today';
import isModalOpenAtom from '../../recoil/isModalOpen';
import { useMerchantNames } from '../../hooks/useMerchantNames';
import SearchControls from '../../components/Common/SearchControls';

interface FormInputs extends SettlementsParams {
  isPayOut: string;
}

interface SettlementColumnHeadCell {
  id: keyof SettlementsData;
  label: string;
  isTotalData: boolean;
  isSort: boolean;
}

const columns: SettlementColumnHeadCell[] = [
  {
    id: 'paidOutDate',
    label: '정산 지급 일자',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'isConfirm',
    label: '정산 확정',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'datePeriod',
    label: '거래일자(주단위)',
    isTotalData: true,
    isSort: true,
  },
  {
    id: 'mallId',
    label: '가맹점 ID',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'mallName',
    label: '가맹점명',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'businessName',
    label: '사업자 등록증 상호명',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'transactionPrice',
    label: '거래합계',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'transactionCount',
    label: '건수',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'completePrice',
    label: '승인합계',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'completeCount',
    label: '건수',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'cancelPrice',
    label: '취소합계',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'cancelCount',
    label: '건수',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'cardFee',
    label: '카드사 수수료',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'optatumFee',
    label: '옵타움 수수료',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'merchantFee',
    label: '가맹점 수수료',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'vat',
    label: '부가세',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'commissionFee',
    label: '총 수수료',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'settlementPrice',
    label: '정산 금액',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'actualAmount',
    label: '실 정산 금액',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'claimAmount',
    label: '청구금액',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'difference',
    label: '차액',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'settlementLimit',
    label: '남은한도',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'unpaid',
    label: '미지급 금액',
    isTotalData: false,
    isSort: false,
  },
];

const defaultFormValues = {
  startDate: getToday(),
  endDate: getToday(),
  isPayOut: '전체',
  mallId: '',
  mallName: null,
  sort: 'ASC',
};

/* 정산내역(구) */
const Settlements = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  // const [settlementData, setSettlementData] = useRecoilState(SettlementsState);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [settlementData, setSettlementData] =
    useState<FormInputs>(defaultFormValues);
  const [selected, setSelected] = useState<SettlementsData[]>([]);

  // 가맹점명 입력값 스테이트
  const [searchTerm, setSearchTerm] = useState('');
  const { search } = useLocation();

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  // form 기본 값
  const { register, handleSubmit, control, reset, getValues, setValue } =
    useForm<FormInputs>({
      defaultValues: {
        ...defaultFormValues,
      },
    });
  // 폼 초기화
  const handleReset = () => {
    reset(defaultFormValues);
  };

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 현재 운영중인 가맹점 목록 조회
  const { uniqueMerchantsNames, isMerchantsLoading } = useMerchantNames();

  const handleInputChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  // 정산 내역 조회
  const { isLoading, data, refetch } = useQuery(
    ['/settlements', settlementData],
    () => getSettlements(settlementData),
    {
      keepPreviousData: true,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'error',
          message: error?.response?.data?.message,
        });
      },
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setSelected([]);
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
      select: data => ({
        content: data.content.map((data: any, index: number) => ({
          ...data,
          id: index,
          actualAmount: data.actualAmount,
          cancelPrice: data.cancelPrice,
          cardFee: data.cardFee,
          claimAmount: data.claimAmount,
          commissionFee: data.commissionFee,
          completePrice: data.completePrice,
          difference: data.difference,
          settlementPrice: data.settlementPrice,
          transactionPrice: data.transactionPrice,
          unpaid: data.unpaid,
          isConfirm: data.isConfirm ? '확정' : '미확정',
          isPayOut: data.isPayOut ? 'true' : 'false',
          mallId: data.mallId,
          mallName: data.mallName,
          merchantFee: data.merchantFee,
          optatumFee: data.optatumFee,
          paidOutDate: data.paidOutDate,
          settlementLimit: data.settlementLimit,
          vat: data.vat,
        })),
      }),
    },
  );

  // 총 합계 데이터 조회
  const { isLoading: totalIsLoading, data: totalData } = useQuery(
    ['/settlement/total', settlementData],
    () => getSettlementsTotal(settlementData),
    {
      keepPreviousData: true,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'error',
          message: error?.response?.data?.message,
        });
      },
      refetchOnWindowFocus: false,
      select: totalData => ({
        ...totalData.content,
      }),
    },
  );

  useEffect(() => {
    if (search !== '') {
      const receivedDate = search.split('=')[1];
      setSettlementData(prev => ({
        ...prev,
        startDate: receivedDate,
        endDate: receivedDate,
      }));
      setValue('startDate', receivedDate);
      setValue('endDate', receivedDate);
    }
  }, [search, setValue]);

  // ===================================================================================================================
  // 정산 지급
  // ===================================================================================================================
  const { mutate, isLoading: isPayoutSettlementLoading } = useMutation(
    completeSettlements,
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'error',
          message: error?.response?.data?.message,
        });
      },
      onSuccess: data => {
        refetch();
        setSelected([]);
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'success',
          message: '정산 지급 되었습니다.',
        });
      },
    },
  );

  // 정산 페이지 조회
  const handleForm = (data: FormInputs) => {
    const isInvalidData =
      data.startDate?.includes('NaN') || data.endDate?.includes('NaN');
    const isInvaildPeriod = data.startDate > data.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '정산 지급 일자를 확인해주세요.',
      });
      return;
    }
    if (isLoading) return;
    setSettlementData(prevValue => ({
      ...prevValue,
      ...data,
    }));
  };

  // const totalPages = data?.pageable?.totalPages
  //   ? data?.pageable?.totalPages
  //   : 0;
  // const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
  //   setSettlementTotal((prevValue) => ({ ...prevValue, page: page - 1 }));
  // };

  // ===================================================================================================================
  // 정산 상세 내역 조회 - 엑셀 다운로드
  // ===================================================================================================================
  const handleExcel = () => {
    const values = getValues();
    const isInvalidData =
      values.startDate?.includes('NaN') || values.endDate?.includes('NaN');
    const isInvaildPeriod = values.startDate > values.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '정산 지급 일자를 확인해주세요.',
      });
      return;
    }
    const content = data?.content;
    if (!content || content.length === 0) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '데이터가 없습니다.',
      });
      return;
    } else {
      const list = content.map((d: any) => ({
        '정산 지급 일자': d.paidOutDate,
        '정산 확정': d.isConfirm,
        '거래일자(주단위)': d.datePeriod,
        '가맹점 ID': d.mallId,
        가맹점명: d.mallName,
        '사업자 등록증 상호명': d.businessName,
        거래합계: d.transactionPrice,
        '거래합계 건수': d.transactionCount,
        승인합계: d.completePrice,
        '승인합계 건수': d.completeCount,
        취소합계: d.cancelPrice,
        '취소합계 건수': d.cancelCount,
        '카드 수수료': d.cardFee,
        '옵타움 수수료': d.optatumFee,
        '가맹점 수수료': d.merchantFee,
        부가세: d.vat,
        '총 수수료': d.commissionFee,
        '정산 금액': d.settlementPrice,
        '실 정산 금액': d.actualAmount,
        '청구 금액': d.claimAmount,
        차액: d.difference,
        '남은 한도': d.settlementLimit,
        '미지급 금액': d.unpaid,
      }));

      // 상세 조회 테이블 아래 총합계 row도 같이 저장
      const totalList = {
        '정산 지급 일자': '합계',
        '정산 확정': '',
        '거래일자(주단위)': totalData?.paidOutDate,
        '가맹점 ID': '',
        가맹점명: '',
        '사업자 등록증 상호명': '',
        거래합계: totalData?.transactionPrice,
        '거래합계 건수': '',
        승인합계: '',
        '승인합계 건수': '',
        취소합계: '',
        '취소합계 건수': '',
        '카드 수수료': '',
        '옵타움 수수료': '',
        '가맹점 수수료': '',
        부가세: '',
        '총 수수료': '',
        '정산 금액': '',
        '실 정산 금액': '',
        '청구 금액': '',
        차액: '',
        '남은 한도': '',
        '미지급 금액': '',
      };
      const combinedList = [...list, totalList];
      excelDownload({
        list: combinedList,
        sheetName:
          settlementData.mallName === ''
            ? '정산 내역 상세 조회'
            : `정산 내역 상세 조회_${settlementData.mallName}`,
        fileName:
          settlementData.mallName === ''
            ? '정산 내역 상세 조회.xlsx'
            : `정산 내역 상세 조회_${settlementData.mallName}.xlsx`,
      });
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'success',
        message: '다운로드를 시작합니다.',
      });
    }
  };

  const isUnitBtnDisabled = selected.length !== 1;
  const isSettlementPayout = selected.length === 0;

  // 정산 지급
  const handlePayoutSettlements = (paidOutDate: string) => {
    if (isPayoutSettlementLoading) return;
    const mallIds = selected.map(list => list.mallId);
    mutate({
      mallIds,
      dueDates: [selected[0].paidOutDate],
      paidOutDate: paidOutDate,
    });
  };

  // 정산 지급
  const isValidToTriggerSettlementPayoutBtn = () => {
    if (selected.length === 0) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '정산 내역을 선택해주세요.',
      });
      return false;
    }
    const dueDate = selected[0].paidOutDate;
    const isPossibleSettlement = selected.every(selectedData => {
      if (selectedData.paidOutDate === dueDate) {
        return true;
      } else {
        return false;
      }
    });
    if (!isPossibleSettlement) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '정산 지급 일자가 동일해야 합니다.',
      });
    }
    return isPossibleSettlement;
  };

  const 지급상태일때 = getValues('isPayOut') === '지급';

  return (
    <>
      <Title title="정산" />
      <AdminLayout>
        <Stack spacing={3}>
          <Paper
            sx={{
              p: 3,
              border: '1px solid #F2F3F5',
              borderRadius: 3,
              overflow: 'hidden',
            }}
          >
            <form onSubmit={handleSubmit(handleForm)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <LocalizationProvider
                    adapterLocale={koLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label="시작 날짜"
                          inputFormat="yyyy-MM-dd"
                          value={value}
                          onChange={value => onChange(getDate(value))}
                          renderInput={param => (
                            <TextField
                              fullWidth
                              size="small"
                              required
                              {...param}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={2}>
                  <LocalizationProvider
                    adapterLocale={koLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label="종료 날짜"
                          inputFormat="yyyy-MM-dd"
                          value={value}
                          onChange={value => onChange(getDate(value))}
                          renderInput={param => (
                            <TextField
                              fullWidth
                              size="small"
                              required
                              {...param}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="isPayOut"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="input-isPayOut"
                        label="정산 지급 상태"
                        required
                        fullWidth
                        size="small"
                        select
                        value={value}
                        onChange={onChange}
                      >
                        <MenuItem value="전체">전체</MenuItem>
                        <MenuItem value="미지급">미지급</MenuItem>
                        <MenuItem value="지급">지급</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="mallName"
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <Autocomplete
                        {...field}
                        id="input-mallName"
                        value={value}
                        autoSelect
                        autoComplete
                        options={uniqueMerchantsNames}
                        onChange={(_, newValue) => onChange(newValue)}
                        loading={isMerchantsLoading}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="가맹점명"
                            fullWidth
                            size="small"
                            // onChange={handleInputChange}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    id="input-mallId"
                    label="가맹점 ID"
                    fullWidth
                    size="small"
                    {...register('mallId')}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <SearchControls loading={isLoading} onReset={handleReset} />
                </Grid>
              </Grid>
            </form>
          </Paper>

          {/* 정산 내역 총합계 */}
          {totalData && (
            <SettlementsTotal data={totalData} isLoading={totalIsLoading} />
          )}

          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={'end'}
              mb={1}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                정산 내역 상세 조회
              </Typography>
              <Stack direction="row" spacing={2}>
                {/* 정산 상태 변경 버튼 */}
                {!지급상태일때 && (
                  <ModalTriggerButton
                    disabled={
                      isUnitBtnDisabled || selected[0]?.isPayOut === 'true'
                    }
                    size="small"
                    modalType="charge"
                    modal={
                      <SettlementConfirmModal
                        mallId={selected[0]?.mallId}
                        paidOutDate={selected[0]?.paidOutDate}
                        isConfirm={selected[0]?.isConfirm}
                      />
                    }
                  >
                    정산 상태 변경
                  </ModalTriggerButton>
                )}

                {/* 정산내역서 미리보기 */}
                <ModalTriggerButton
                  disabled={
                    selected[0]?.isConfirm === '미확정' || isUnitBtnDisabled
                  }
                  size="small"
                  modalType="charge"
                  modal={
                    <SettlementStatement
                      isPayOut={selected[0]?.isPayOut}
                      mallId={selected[0]?.mallId}
                      paidOutDate={selected[0]?.paidOutDate}
                    />
                  }
                >
                  정산내역서 미리보기
                </ModalTriggerButton>

                {/* 청구금 등록 & 조회 모달 */}
                <ModalTriggerButton
                  disabled={isUnitBtnDisabled}
                  size="small"
                  modalType="charge"
                  modal={
                    <SettlementChargeModal
                      mallId={selected[0]?.mallId}
                      dueDate={selected[0]?.paidOutDate}
                      settlementId={selected[0]?.settlementCompleteId}
                      isConfirm={selected[0]?.isConfirm}
                      onButtonSubmit={refetch}
                    />
                  }
                >
                  {지급상태일때 ? '청구금 조회' : '청구금 등록'}
                </ModalTriggerButton>

                {/* 개별 내역 조회 모달 */}
                <ModalTriggerButton
                  disabled={isUnitBtnDisabled}
                  size="small"
                  modal={<SettlementUnitModal selectedData={selected[0]} />}
                >
                  개별 내역 조회
                </ModalTriggerButton>

                {/* 정산 지급 모달 */}
                {!지급상태일때 && (
                  <ModalTriggerButton
                    disabled={
                      isSettlementPayout ||
                      selected[0]?.isPayOut === 'true' ||
                      selected[0]?.isConfirm === '미확정'
                    }
                    isValidToTrigger={isValidToTriggerSettlementPayoutBtn}
                    size="small"
                    modal={
                      <SettlementCalendarModal
                        onButtonSubmit={handlePayoutSettlements}
                        loading={isPayoutSettlementLoading}
                      />
                    }
                  >
                    정산 지급
                  </ModalTriggerButton>
                )}

                {/* 엑셀 다운로드 모달 */}
                <LoadingButton
                  icon={<SaveIcon />}
                  size="medium"
                  fullWidth={false}
                  color="secondary"
                  variant="outlined"
                  loading={false}
                  handleClick={handleExcel}
                >
                  EXCEL 다운로드
                </LoadingButton>
              </Stack>
            </Stack>
            <Paper
              sx={{
                border: '1px solid #F2F3F5',
                borderRadius: 3,
                overflow: 'hidden',
              }}
            >
              {/* 정산 내역 상세 조회 테이블 */}
              <MuiTable
                rows={data?.content}
                columns={columns}
                id="id"
                selectedValues={selected}
                setRowSelection={setSelected}
                totalData={totalData}
                // sortingTarget={"datePeriod"}
                sortDirection={settlementData.sort}
              />

              {/* <Box mt={2}>
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                page={SettlementsTotal.page + 1}
                onChange={handlePage}
                sx={{ display: "flex", justifyContent: "center" }}
              />
            </Box> */}
            </Paper>
          </Box>
        </Stack>
      </AdminLayout>
    </>
  );
};

export default Settlements;
