import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from '@tanstack/react-query';
import { endOfWeek, format, startOfWeek, subWeeks } from 'date-fns';
import koLocale from 'date-fns/locale/ko';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import AdminLayout from '../../../../components/Layout/Admin/Layout';
import LoadingButton from '../../../../components/LoadingButton';
import ModalTriggerButton from '../../../../components/PopupTriggerButton';
import MuiTable from '../../../../components/Table/MuiTable';
import Title from '../../../../components/Title';
import useRenewal from '../../../../hooks/useRenewal';
import excelDownload from '../../../../libs/excel-download';
import { getDate } from '../../../../libs/get-date';
import isModalOpenAtom from '../../../../recoil/isModalOpen';
import SettlementChargeModal from '../Charges/components/SettlementChargeModal';
import ChangePayoutDateModal from './components/ChangePayoutDateModal';
import PayoutSettlementModal from './components/PayoutSettlementModal';
import SendPdfExcelModal from './components/SendPdfExcelModal';
import SettlementConfirmModal from './components/SettlementConfirmModal';
import SettlementStatement from './components/SettlementStatement';
import SettlementsTotal from './components/SettlementsTotal';
import SettlementUnitModal from './components/SettlementUnitModal';
import { getSettlementsV2, getSettlementsV2Total } from './libs';
import { SettlementsData, SettlementsV2Params } from './libs/types';
import { useMerchantNames } from '../../../../hooks/useMerchantNames';
import SearchControls from '../../../../components/Common/SearchControls';

interface FormInputs extends SettlementsV2Params {}

interface SettlementColumnHeadCell {
  id: keyof SettlementsData;
  label: string;
  isTotalData: boolean;
  isSort: boolean;
}

const columns: SettlementColumnHeadCell[] = [
  {
    id: 'payoutDate',
    label: '정산 지급 일자',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'isConfirm',
    label: '정산 확정',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'isPayOut',
    label: '정산 지급',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'transactionDate',
    label: '정산주기',
    isTotalData: true,
    isSort: true,
  },
  {
    id: 'mallId',
    label: '가맹점 ID',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'mallName',
    label: '가맹점명',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'businessName',
    label: '사업자 등록증 상호명',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'transactionTotalAmount',
    label: '거래합계',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'transactionTotalCount',
    label: '건수',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'completeTotalAmount',
    label: '승인합계',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'completeTotalCount',
    label: '건수',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'cancelTotalAmount',
    label: '취소합계',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'cancelTotalCount',
    label: '건수',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'cardFee',
    label: '카드사 수수료',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'optatumFee',
    label: '옵타움 수수료',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'merchantFee',
    label: '가맹점 수수료',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'vat',
    label: '부가세',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'totalFee',
    label: '총 수수료',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'totalPayoutAmount',
    label: '정산 금액',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'actualAmount',
    label: '실 정산 금액',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'chargesAmount',
    label: '청구금액',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'difference',
    label: '차액',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'settlementLimit',
    label: '남은한도',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'unpaid',
    label: '미지급 금액',
    isTotalData: false,
    isSort: false,
  },
];

const getSettlementPeriod = () => ({
  startDate: format(
    startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 }),
    'yyyy-MM-dd',
  ),
  endDate: format(
    endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 }),
    'yyyy-MM-dd',
  ),
});

const defaultFormValues = {
  ...getSettlementPeriod(),
  payoutStatus: 'null',
  confirmStatus: 'null',
  mallId: '',
  mallName: null,
  sort: 'ASC',
  dateStatus: '1',
};

const SettlementsV2 = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [settlementData, setSettlementData] =
    useState<FormInputs>(defaultFormValues);
  const [selected, setSelected] = useState<SettlementsData[]>([]);

  // 가맹점명 입력값 스테이트
  const [searchTerm, setSearchTerm] = useState('');
  const { search } = useLocation();

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  // form 기본 값
  const { register, handleSubmit, control, reset, getValues, setValue } =
    useForm<FormInputs>({
      defaultValues: {
        ...defaultFormValues,
      },
    });

  // 폼 초기화
  const handleReset = () => {
    reset(defaultFormValues);
  };

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 현재 운영중인 가맹점 목록 조회
  const { uniqueMerchantsNames, isMerchantsLoading } = useMerchantNames();

  const handleInputChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  // 정산 내역 조회
  const { isLoading, data, refetch } = useQuery(
    ['/v3/settlements', settlementData],
    () => getSettlementsV2(settlementData),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setSelected([]);
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
      select: data => ({
        content: data.content.map((data: SettlementsData) => ({
          ...data,
          isConfirm: data.isConfirm ? '확정' : '미확정',
          isPayOut: data.isPayOut ? '지급' : '미지급',
        })),
      }),
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'error',
          message: error?.response?.data?.message,
        });
      },
    },
  );

  // 총 합계 데이터 조회
  const { isLoading: totalIsLoading, data: totalData } = useQuery(
    ['/v3/settlements/total', settlementData],
    () => getSettlementsV2Total(settlementData),
    {
      keepPreviousData: true,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'error',
          message: error?.response?.data?.message,
        });
      },
      refetchOnWindowFocus: false,
      select: totalData => ({
        ...totalData.content,
      }),
    },
  );

  useEffect(() => {
    if (search !== '') {
      const receivedDate = search.split('=')[1];
      setSettlementData(prev => ({
        ...prev,
        startDate: receivedDate,
        endDate: receivedDate,
      }));
      setValue('startDate', receivedDate);
      setValue('endDate', receivedDate);
    }
  }, [search, setValue]);

  // 정산 페이지 조회
  const handleForm = (data: FormInputs) => {
    const isInvalidData =
      data.startDate?.includes('NaN') || data.endDate?.includes('NaN');
    const isInvalidPeriod = data.startDate > data.endDate;

    if (isInvalidData || isInvalidPeriod) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '정산 지급 일자를 확인해주세요.',
      });
      return;
    }
    if (isLoading) return;
    setSettlementData(prevValue => ({
      ...prevValue,
      ...data,
    }));
  };

  // const totalPages = data?.content?.totalPages
  //   ? data?.pageable?.totalPages
  //   : 0;
  // const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
  //   setSettlementTotal((prevValue) => ({ ...prevValue, page: page - 1 }));
  // };

  // ===================================================================================================================
  // 정산 상세 내역 조회 - 엑셀 다운로드
  // ===================================================================================================================
  const handleExcel = () => {
    const values = getValues();
    const isInvalidData =
      values.startDate?.includes('NaN') || values.endDate?.includes('NaN');
    const isInvaildPeriod = values.startDate > values.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '정산 지급 일자를 확인해주세요.',
      });
      return;
    }
    const content = data?.content;
    if (!content || content.length === 0) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '데이터가 없습니다.',
      });
      return;
    } else {
      const list = content.map((d: SettlementsData) => ({
        '정산 지급 일자': d.payoutDate,
        '정산 확정': d.isConfirm,
        '거래일자(주단위)': d.transactionDate,
        '가맹점 ID': d.mallId,
        가맹점명: d.mallName,
        '사업자 등록증 상호명': d.businessName,
        거래합계: d.transactionTotalAmount,
        '거래합계 건수': d.transactionTotalCount,
        승인합계: d.completeTotalAmount,
        '승인합계 건수': d.completeTotalCount,
        취소합계: d.cancelTotalAmount,
        '취소합계 건수': d.cancelTotalCount,
        '카드 수수료': d.cardFee,
        '옵타움 수수료': d.optatumFee,
        '가맹점 수수료': d.merchantFee,
        부가세: d.vat,
        '총 수수료': d.totalFee,
        '정산 금액': d.totalPayoutAmount,
        '실 정산 금액': d.actualAmount,
        '청구 금액': d.chargesAmount,
        차액: d.difference,
        '남은 한도': d.settlementLimit,
        '미지급 금액': d.unpaid,
      }));

      // 상세 조회 테이블 아래 총합계 row도 같이 저장
      const totalList = {
        '정산 지급 일자': '합계',
        '정산 확정': '',
        '거래일자(주단위)': totalData?.transactionDate,
        '가맹점 ID': '',
        가맹점명: '',
        '사업자 등록증 상호명': '',
        거래합계: totalData?.transactionTotalAmount,
        '거래합계 건수': totalData?.transactionTotalCount,
        승인합계: '',
        '승인합계 건수': '',
        취소합계: '',
        '취소합계 건수': '',
        '카드 수수료': '',
        '옵타움 수수료': '',
        '가맹점 수수료': '',
        부가세: '',
        '총 수수료': '',
        '정산 금액': '',
        '실 정산 금액': '',
        '청구 금액': '',
        차액: '',
        '남은 한도': '',
        '미지급 금액': '',
      };
      const combinedList = [...list, totalList];
      excelDownload({
        list: combinedList,
        sheetName:
          settlementData.mallName === ''
            ? '정산 내역 상세 조회'
            : `정산 내역 상세 조회_${settlementData.mallName}`,
        fileName:
          settlementData.mallName === ''
            ? '정산 내역 상세 조회.xlsx'
            : `정산 내역 상세 조회_${settlementData.mallName}.xlsx`,
      });
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'success',
        message: '다운로드를 시작합니다.',
      });
    }
  };

  const isUnitBtnDisabled = selected.length !== 1;

  const 지급상태일때 = getValues('payoutStatus') === '1';

  // 지급일자변경 버튼 비활성화 조건
  const isAllUnconfirmed =
    selected.length > 0 && selected.every(row => row.isConfirm === '미확정');

  /*********************************************************************************************************************
   * 페이징
   ********************************************************************************************************************/
  // // 총 페이지 수
  // const totalPages = data?.pageable?.totalPages
  //   ? data?.pageable?.totalPages
  //   : 0;

  // // 페이지 변경 함수
  // const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
  //   setParams((prev) => ({ ...prev, page: page - 1 }));
  // };

  // "이메일 전송" 버튼 비활성화 조건 설정
  const isEmailButtonDisabled =
    selected.length === 0 || selected.some(row => row.isConfirm !== '확정');

  // 선택된 row의 아이디들
  const idList = selected.map(list => list.id);

  return (
    <>
      <Title title="정산" />
      <AdminLayout>
        <Stack spacing={3}>
          <Paper
            sx={{
              p: 3,
              border: '1px solid #F2F3F5',
              borderRadius: 3,
              overflow: 'hidden',
            }}
          >
            <form onSubmit={handleSubmit(handleForm)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <FormControl>
                    <RadioGroup row defaultValue={1}>
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="거래일자"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="지급일자"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={2}>
                  <LocalizationProvider
                    adapterLocale={koLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label="시작 날짜"
                          inputFormat="yyyy-MM-dd"
                          value={value}
                          onChange={value => onChange(getDate(value))}
                          renderInput={param => (
                            <TextField
                              fullWidth
                              size="small"
                              required
                              {...param}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={2}>
                  <LocalizationProvider
                    adapterLocale={koLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label="종료 날짜"
                          inputFormat="yyyy-MM-dd"
                          value={value}
                          onChange={value => onChange(getDate(value))}
                          renderInput={param => (
                            <TextField
                              fullWidth
                              size="small"
                              required
                              {...param}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6}>
                  <SearchControls loading={isLoading} onReset={handleReset} />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="confirmStatus"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="input-payOutStatus"
                        label="정산 확정 상태"
                        required
                        fullWidth
                        size="small"
                        select
                        value={value || 'null'}
                        onChange={onChange}
                      >
                        <MenuItem value={'null'}>전체</MenuItem>
                        <MenuItem value={'1'}>확정</MenuItem>
                        <MenuItem value={'2'}>미확정</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="payoutStatus"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="input-confirmStatus"
                        label="정산 지급 상태"
                        required
                        fullWidth
                        size="small"
                        select
                        value={value || 'null'}
                        onChange={onChange}
                      >
                        <MenuItem value={'null'}>전체</MenuItem>
                        <MenuItem value={'1'}>지급</MenuItem>
                        <MenuItem value={'2'}>미지급</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="mallName"
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <Autocomplete
                        {...field}
                        id="input-mallName"
                        value={value}
                        autoSelect
                        autoComplete
                        options={uniqueMerchantsNames}
                        onChange={(_, newValue) => onChange(newValue)}
                        loading={isMerchantsLoading}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="가맹점명"
                            fullWidth
                            size="small"
                            // onChange={handleInputChange}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    id="input-mallId"
                    label="가맹점 ID"
                    fullWidth
                    size="small"
                    {...register('mallId')}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>

          {/* 정산 내역 총합계 */}
          {totalData && (
            <SettlementsTotal data={totalData} isLoading={totalIsLoading} />
          )}

          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={'end'}
              mb={1}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                정산 내역 상세 조회
              </Typography>
              <Stack direction="row" spacing={2}>
                {/* 정산 상태 변경 버튼 */}
                {!지급상태일때 && (
                  <ModalTriggerButton
                    disabled={
                      isUnitBtnDisabled || selected[0]?.isPayOut === '지급'
                    }
                    size="small"
                    modalType="charge"
                    modal={
                      <SettlementConfirmModal
                        payoutDate={selected[0]?.payoutDate}
                        isConfirm={selected[0]?.isConfirm}
                        settlementId={selected[0]?.id}
                      />
                    }
                  >
                    정산 상태 변경
                  </ModalTriggerButton>
                )}

                {/* 지급 일자 변경 모달 */}
                <ModalTriggerButton
                  disabled={!isAllUnconfirmed}
                  size="small"
                  modalType="charge"
                  modal={
                    <ChangePayoutDateModal settlementId={selected[0]?.id} />
                  }
                >
                  지급일자변경
                </ModalTriggerButton>

                {/* 정산내역서 미리보기 */}
                <ModalTriggerButton
                  disabled={
                    selected[0]?.isConfirm === '미확정' || isUnitBtnDisabled
                  }
                  size="small"
                  modalType="charge"
                  modal={
                    <SettlementStatement
                      settlementId={selected[0]?.id}
                      merchantId={selected[0]?.merchantId}
                    />
                  }
                >
                  정산내역서 미리보기
                </ModalTriggerButton>

                {/* 정산내역서 & 개별내역조회 엑셀 이메일 보내기 */}
                <ModalTriggerButton
                  disabled={isEmailButtonDisabled}
                  size="small"
                  modalType="charge"
                  modal={<SendPdfExcelModal idList={idList} />}
                >
                  이메일 전송
                </ModalTriggerButton>

                {/* 청구금 등록 & 조회 모달 */}
                <ModalTriggerButton
                  disabled={isUnitBtnDisabled}
                  size="small"
                  modalType="charge"
                  modal={
                    <SettlementChargeModal
                      settlementId={selected[0]?.id}
                      isConfirm={selected[0]?.isConfirm}
                      onButtonSubmit={refetch}
                    />
                  }
                >
                  {selected.length === 0 || selected[0]?.isConfirm === '미확정'
                    ? '청구금 등록'
                    : '청구금 조회'}
                </ModalTriggerButton>

                {/* 개별 내역 조회 모달 */}
                <ModalTriggerButton
                  disabled={isUnitBtnDisabled}
                  size="small"
                  modal={
                    <SettlementUnitModal
                      selectedData={selected[0]}
                      isConfirm={selected[0]?.isConfirm}
                    />
                  }
                >
                  개별 내역 조회
                </ModalTriggerButton>

                {/* 정산 지급 모달 */}
                {!지급상태일때 && (
                  <ModalTriggerButton
                    disabled={
                      isUnitBtnDisabled ||
                      selected[0]?.isPayOut === '지급' ||
                      selected[0]?.isConfirm === '미확정'
                    }
                    size="small"
                    modal={
                      <PayoutSettlementModal
                        settlementId={selected[0]?.id}
                        totalPayoutAmount={selected[0]?.totalPayoutAmount}
                      />
                    }
                  >
                    정산 지급
                  </ModalTriggerButton>
                )}

                {/* 엑셀 다운로드 모달 */}
                <LoadingButton
                  icon={<SaveIcon />}
                  size="medium"
                  fullWidth={false}
                  color="secondary"
                  variant="outlined"
                  loading={false}
                  handleClick={handleExcel}
                >
                  EXCEL 다운로드
                </LoadingButton>
              </Stack>
            </Stack>
            <Paper
              sx={{
                border: '1px solid #F2F3F5',
                borderRadius: 3,
                overflow: 'hidden',
              }}
            >
              <Paper
                sx={{
                  border: '1px solid #F2F3F5',
                  borderRadius: 3,
                  overflow: 'hidden',
                }}
              >
                {/* 정산 내역 상세 조회 테이블 */}
                <MuiTable
                  rows={data?.content}
                  columns={columns}
                  id="id"
                  selectedValues={selected}
                  setRowSelection={setSelected}
                  totalData={totalData}
                  // sortingTarget={"datePeriod"}
                  sortDirection={settlementData.sort}
                />

                {/* <Box my={2}>
                  <Pagination
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={params.page + 1}
                    onChange={changePage}
                    sx={{ display: "flex", justifyContent: "center" }}
                  ></Pagination>
                </Box> */}
              </Paper>
            </Paper>
          </Box>
        </Stack>
      </AdminLayout>
    </>
  );
};

export default SettlementsV2;
