import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import useRenewal from "../../../hooks/useRenewal";
import ozicpayText from "../../../images/ozicpay_logo_text.png";
import { displayTime } from "../../../libs/display-time";
import loginInfoAtom from "../../../recoil/loginInfo";
import TimerAtom, { TimeExtentionDefault } from "../../../recoil/timer";
import ChangePwModal from "../../Common/ChangePwModal";
import NotificationAppBar from "../../Notification/NotificationAppBar";
import TimeExtensionModal from "../../TimeExtensionModal";
import {
  ManagementListItems,
  PaymentListItems,
  SettlementListItems
} from "./Navigation";

interface ILayout {
  children: React.ReactNode;
}

const ClientLayout = ({ children }: ILayout) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const loginInfo = useRecoilValue(loginInfoAtom);
  const [timeLeft, setTimeLeft] = useRecoilState(TimerAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [isShowTimeExtensionModal, setIsShowTimeExtensionModal] =
    useState(false);
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  const timerRef = useRef<NodeJS.Timer | null>(null);

  const [dropDownMenu, setDropDownMenu] = useState<null | HTMLElement>(null);

  // 비밀번호 변경 모달 상태 및 핸들러
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const handleOpenPasswordModal = () => setIsPasswordModalOpen(true);
  const handleClosePasswordModal = () => setIsPasswordModalOpen(false);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft > 0) {
          return prevTimeLeft - 1000;
        }
        return prevTimeLeft;
      });
    }, 1000);
    return () => {
      clearInterval(timerRef.current as NodeJS.Timer);
    };
  }, [setTimeLeft]);

  const handleRenewal = () => {
    if (isRenewalTokenLoading) return;
    mutateRenewalToken();
  };

  const handleLogout = useCallback(() => {
    sessionStorage.removeItem("loginInfo");
    window.location.href = "/login";
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) {
      handleLogout();
    }
  }, [timeLeft, handleLogout]);

  useEffect(() => {
    if (timeLeft === TimeExtentionDefault) {
      setIsShowTimeExtensionModal(true);
    }
  }, [timeLeft]);

  // ===================================================================================================================
  // 아이콘 클릭 핸들러
  // ===================================================================================================================
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDropDownMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setDropDownMenu(null);
  };

  return (
    <>
      {isShowTimeExtensionModal && (
        <TimeExtensionModal
          onClose={() => setIsShowTimeExtensionModal(false)}
        />
      )}
      {/* 전체 레이아웃 */}
      <Grid container sx={{ height: "100vh", overflow: "hidden" }}>
        {/* 메뉴 바 ------------------------------------------------------------------------------------------ 메뉴 바*/}
        <Grid
          item
          sx={{
            height: "100vh",
            overflow: "hidden",
            position: "sticky",
            borderRight: "1px dashed #E4E8EB",
            px: 2
          }}
          xs={1.5}
        >
          <Link to="/">
            <IconButton sx={{ ml: 1.5, mt: 2 }}>
              <Box component="img" src={ozicpayText} sx={{ height: 35 }} />
            </IconButton>
          </Link>
          <List>
            <PaymentListItems />
          </List>
          <List>
            <SettlementListItems />
          </List>
          <List>
            <ManagementListItems />
          </List>
        </Grid>

        {/* 유저 앱 바 ----------------------------------------------------------------------------------- 유저 앱 바 */}
        <Grid
          item
          sx={{
            height: "100vh",
            overflowY: "auto",
            backgroundColor: "#fcfcfc"
          }}
          xs={10.5}
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            py={2}
            sx={{
              position: "sticky",
              top: 0,
              backdropFilter: "blur(6px)",
              zIndex: 10,
              px: 5
            }}
          >
            <Stack direction="row" gap={1}>
              {/* 로그인 시간 --------------------------------------------------------------------------- 로그인 시간 */}
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography variant="body1">{displayTime(timeLeft)}</Typography>
                <IconButton
                  size="medium"
                  edge="start"
                  color="inherit"
                  aria-label="time extention"
                  onClick={handleRenewal}
                >
                  <MoreTimeIcon />
                </IconButton>
              </Stack>
              {/* 알림 내역 ------------------------------------------------------------------------------- 알림 내역 */}
              <Stack direction="row" alignItems="center" gap={2}>
                <NotificationAppBar />

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    pl: 1.5,
                    borderRadius: 20,
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                  }}
                >
                  <Typography variant="body1">
                    {loginInfo.loginUser?.email}
                  </Typography>
                  <IconButton onClick={(e) => handleMenuOpen(e)}>
                    {dropDownMenu ? (
                      <KeyboardArrowDownRoundedIcon />
                    ) : (
                      <KeyboardArrowUpRoundedIcon />
                    )}
                  </IconButton>
                  <Menu
                    anchorEl={dropDownMenu}
                    open={Boolean(dropDownMenu)}
                    onClose={handleMenuClose}
                    sx={{
                      ".MuiMenu-paper": {
                        boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        border: "1px solid #F2F3F5"
                      }
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left"
                    }}
                  >
                    <MenuItem onClick={handleOpenPasswordModal}>
                      비밀번호 변경
                    </MenuItem>
                    {/* 나중에 필요하면 여기 메뉴 추가하세요 🚨 */}
                    {/* <MenuItem>메뉴 - 1</MenuItem> */}
                    {/* <MenuItem>메뉴 - 2</MenuItem> */}
                  </Menu>

                  <ChangePwModal
                    open={isPasswordModalOpen}
                    onClose={handleClosePasswordModal}
                  />
                </Stack>
                <IconButton
                  size="large"
                  edge="start"
                  aria-label="time extention"
                  onClick={handleLogout}
                >
                  <LogoutIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>

          {/* 페이지 UI ------------------------------------------------------------------------------------ 페이지 UI*/}
          <Box
            sx={{
              flexGrow: 1,
              p: 5,
              pt: 0
            }}
          >
            {children}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientLayout;
