import React from 'react';
import LoadingButton, { ILoadingButton } from '../LoadingButton';
import SearchIcon from '@mui/icons-material/Search';
import Button, { ButtonProps } from '@mui/material/Button';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Stack, { StackProps } from '@mui/material/Stack';

interface SearchControlsProps {
  onReset: () => void;
  loading?: boolean;
  containerProps?: StackProps;
  searchBtnProps?: Partial<Omit<ILoadingButton, 'loading'>>;
  resetBtnProps?: Omit<ButtonProps, 'onClick'>;
  searchText?: string;
  resetText?: string;
}

const SearchControls = ({
  onReset,
  loading = false,
  containerProps,
  searchBtnProps,
  resetBtnProps,
  searchText = '조회',
  resetText = '초기화',
}: SearchControlsProps) => {
  return (
    <Stack
      {...containerProps}
      direction={containerProps?.direction ?? 'row'}
      flexWrap={containerProps?.flexWrap ?? 'wrap'}
      justifyContent={containerProps?.justifyContent ?? 'flex-end'}
      alignItems={containerProps?.alignItems ?? 'center'}
      rowGap={containerProps?.rowGap ?? 1}
      spacing={containerProps?.spacing ?? 1}
    >
      <LoadingButton
        {...searchBtnProps}
        icon={searchBtnProps?.icon ?? <SearchIcon />}
        size={searchBtnProps?.size ?? 'medium'}
        fullWidth={!!searchBtnProps?.fullWidth}
        loading={loading}
      >
        {searchText}
      </LoadingButton>
      <Button
        {...resetBtnProps}
        variant={resetBtnProps?.variant ?? 'outlined'}
        endIcon={resetBtnProps?.endIcon ?? <RestartAltIcon />}
        onClick={onReset}
      >
        {resetText}
      </Button>
    </Stack>
  );
};

export default SearchControls;
