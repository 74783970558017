import { useQuery } from '@tanstack/react-query';
import { getMerchantNames } from '../api/v2/Payments';
import { useMemo } from 'react';

export function useMerchantNames() {
  // 현재 운영중인 가맹점 목록 조회
  const { data, isLoading: isMerchantsLoading } = useQuery(
    ['/merchants/names'],
    () => getMerchantNames(),
  );

  // 중복 제거
  const uniqueMerchantsNames = useMemo(() => {
    return data?.content ? [...new Set(data.content)] : [];
  }, [data?.content]);

  return { uniqueMerchantsNames, isMerchantsLoading };
}
