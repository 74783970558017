import { atom } from "recoil";

export const getDefaultTime = () => {
  const d = new Date();
  const year = d.getFullYear();
  const month =
    d.getMonth() + 1 > 9 ? `${d.getMonth() + 1}` : `0${d.getMonth() + 1}`;
  const day = d.getDate() > 9 ? `${d.getDate()}` : `0${d.getDate()}`;
  const hours = d.getHours() > 9 ? `${d.getHours()}` : `0${d.getHours()}`;
  const minutes =
    d.getMinutes() > 9 ? `${d.getMinutes()}` : `0${d.getMinutes()}`;
  const seconds =
    d.getSeconds() > 9 ? `${d.getSeconds()}` : `0${d.getSeconds()}`;
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const loginInfoDefault = {
  accessToken: "",
  createdTime: getDefaultTime(),
  loginUser: {
    email: "",
    firstLogin: "N",
    loginFailCount: null,
    otpQrUrl: "",
    otpSecretKey: "",
    passwordUpdateFlag: "N",
    role: ""
  }
};

interface LoginUser {
  email: string;
  firstLogin: string;
  loginFailCount: null;
  otpQrUrl: string;
  otpSecretKey: string;
  passwordUpdateFlag: string;
  role: string;
  collectPersonal?: boolean;
  name?: string;
}

interface LoginInfo {
  accessToken: string;
  createdTime: string;
  loginUser: LoginUser;
}

const loginInfo = atom<LoginInfo>({
  key: "loginInfo",
  default: sessionStorage.getItem("loginInfo")
    ? JSON.parse(sessionStorage.getItem("loginInfo") as string)
    : {
        ...loginInfoDefault
      }
});

export default loginInfo;
