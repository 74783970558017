import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import { postMerchantCardLimit } from "../../api/CardLimitManagement";
import { RegistCardLimitParams } from "../../api/CardLimitManagement/types";
import { inputPriceFormat } from "../../libs/format-utils";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { getBusinessCategories } from "../../api/BusinessCategory";
import { HelperTextMessage } from "../../pages/components/HelperTextMessage";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const RegistCardLimitModal = ({ isOpen, onClose }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm<RegistCardLimitParams>({
    defaultValues: {
      cardCompanyId: 4,
      grade: "일반",
      limitAmount: "0",
      lumpsumInstallmentGubun: "일시불",
      mccType: "일반"
    }
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 한도 등록
  const { mutate } = useMutation(postMerchantCardLimit, {
    onSuccess: () => {
      // 데이터 새로고침
      queryClient.invalidateQueries();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "한도 등록을 완료했습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  /*********************************************************************************************************************
   * 업종 조회
   ********************************************************************************************************************/
  const { data: businessCategories } = useQuery(
    ["/businessCategories"],
    getBusinessCategories,
    {}
  );

  // ===================================================================================================================
  // 모달창 닫기 + 폼 리셋
  // ===================================================================================================================
  const handleClose = () => {
    reset();
    onClose();
  };

  // ===================================================================================================================
  // 한도 업데이트
  // ===================================================================================================================
  const onSubmit = (formData: RegistCardLimitParams) => {
    const limitAmount = parseInt(
      formData.limitAmount.toString().replace(/,/g, "")
    );
    if (limitAmount < 1) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "한도 금액은 1 이상이어야 합니다."
      });
      return;
    }

    const params = {
      ...formData,
      limitAmount
    };
    mutate(params);
    handleClose();
  };

  return (
    <>
      {/* 관리자가 가맹점별 한도를 등록하는 모달창 ----------------------------------------------------------------------*/}
      <Dialog open={isOpen} fullWidth maxWidth="sm">
        <DialogTitle>한도 등록</DialogTitle>

        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText gutterBottom></DialogContentText>
          {/* 폼 -----------------------------------------------------------------------------------------------------*/}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={3} justifyContent={"space-between"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <InputLabel htmlFor="cardCompanyId" sx={{ fontWeight: "bold" }}>
                  카드사명
                </InputLabel>
                <Controller
                  name="cardCompanyId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="cardCompanyId"
                      label="카드사"
                      fullWidth
                      size="small"
                      select
                      sx={{ width: 300 }}
                      value={value}
                      onChange={onChange}
                    >
                      <MenuItem value={"4"}>삼성카드</MenuItem>
                      <MenuItem value={"3"}>하나카드</MenuItem>
                    </TextField>
                  )}
                />
              </Stack>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <InputLabel htmlFor="grade" sx={{ fontWeight: "bold" }}>
                  등급
                </InputLabel>
                <Controller
                  name="grade"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="grade"
                      label="등급"
                      fullWidth
                      size="small"
                      select
                      sx={{ width: 300 }}
                      value={value}
                      onChange={onChange}
                    >
                      <MenuItem value={"일반"}>일반</MenuItem>
                      <MenuItem value={"영세"}>영세</MenuItem>
                      <MenuItem value={"중소1"}>중소1</MenuItem>
                      <MenuItem value={"중소2"}>중소2</MenuItem>
                      <MenuItem value={"중소3"}>중소3</MenuItem>
                    </TextField>
                  )}
                />
              </Stack>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <InputLabel htmlFor="mccType" sx={{ fontWeight: "bold" }}>
                  업종
                </InputLabel>
                <Controller
                  name="mccType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="결제 업종"
                      value={value}
                      onChange={onChange}
                      required
                      size="small"
                      sx={{ width: 300 }}
                    >
                      {businessCategories?.content.map((category) => (
                        <MenuItem value={category} key={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                ></Controller>
              </Stack>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <InputLabel
                  htmlFor="lumpsumInstallmentGubun"
                  sx={{ fontWeight: "bold" }}
                >
                  일시불/할부
                </InputLabel>
                <Controller
                  name="lumpsumInstallmentGubun"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="lumpsumInstallmentGubun"
                      label="일시불/할부"
                      fullWidth
                      size="small"
                      select
                      sx={{ width: 300 }}
                      value={value}
                      onChange={onChange}
                    >
                      <MenuItem value={"일시불"}>일시불</MenuItem>
                      <MenuItem value={"할부"}>할부</MenuItem>
                    </TextField>
                  )}
                />
              </Stack>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <InputLabel htmlFor="limitAmount" sx={{ fontWeight: "bold" }}>
                  한도 금액
                </InputLabel>
                <Controller
                  name="limitAmount"
                  control={control}
                  rules={{
                    required: "한도 금액은 필수값 입니다."
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id="limitAmount"
                      label="한도금액"
                      required
                      value={value}
                      size="small"
                      sx={{ width: 300 }}
                      onChange={(e) => {
                        const formattedValue = inputPriceFormat(e.target.value);
                        onChange(formattedValue);
                        setValue("limitAmount", formattedValue, {
                          shouldValidate: true
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">원</InputAdornment>
                        )
                      }}
                      error={!!errors.limitAmount}
                      helperText={
                        <HelperTextMessage>
                          {errors.limitAmount?.message}
                        </HelperTextMessage>
                      }
                    />
                  )}
                />
              </Stack>
              {/* 등록 완료 버튼 --------------------------------------------------------------------------------------*/}
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{ color: "white" }}
                disabled={!watch("limitAmount")}
              >
                등록 완료
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RegistCardLimitModal;
