import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../../../..";
import isModalOpenAtom from "../../../../recoil/isModalOpen";
import { getPayoutHistoryById, postPayoutHistoryItems } from "../libs";
import { PayoutHistorDetailData } from "../libs/types";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  selectedId: number;
}

interface SettlementColumnHeadCell {
  id: keyof PayoutHistorDetailData;
  label: string;
}

const columns: SettlementColumnHeadCell[] = [
  {
    id: "settlementCycle",
    label: "정산 주기"
  },
  {
    id: "payoutDate",
    label: "지급 일자"
  },
  {
    id: "payoutAmount",
    label: "지급할 금액"
  },
  {
    id: "paidAmount",
    label: "지급된 금액"
  },
  {
    id: "unpaidAmount",
    label: "미지급 금액"
  },
  {
    id: "payoutType",
    label: "정산지급 종류"
  },
  {
    id: "note",
    label: "비고"
  }
];

const PayoutDetailModal = ({ isOpen, closeModal, selectedId }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  const id = selectedId;

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 정산 지급 내역 상세조회
  const { isLoading, data } = useQuery(
    [`/payout-histories/${id}`, id],
    () => getPayoutHistoryById(id),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: isOpen,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // 남은 정산금 지급 처리
  const { mutate } = useMutation(postPayoutHistoryItems, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "잔금 처리가 완료되었습니다."
      });
      queryClient.invalidateQueries([`/payout-histories/${id}`]);
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 정산 잔금 처리
  // ===================================================================================================================
  const onPayoutHistory = (id: number) => {
    mutate(id);
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="lg">
      <DialogTitle>정산 지급 내역 상세조회</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {/* 정산 지급 상세 조회 테이블 */}

        <TableContainer sx={{ minHeight: 550 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FBFBFB" }}>
                {columns.map((column) => (
                  <TableCell
                    variant="head"
                    align="center"
                    key={column.id}
                    sx={{ borderTop: "none" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content.length === 0 ? (
                <>
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography align="center">
                        조회된 데이터가 없습니다.
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  {!data &&
                    isLoading &&
                    [...Array(7)].map((_, index) => (
                      <TableRow key={index}>
                        {columns.map((column) => (
                          <TableCell key={column.id}>
                            <Skeleton variant="rounded" animation="wave" />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  {data?.content &&
                    !isLoading &&
                    data.content.map((row) => (
                      <TableRow key={row.id} hover>
                        {/* <TableCell align="center">
                              {row.id}
                            </TableCell> */}
                        <TableCell align="center">
                          {row.settlementCycle}
                        </TableCell>
                        <TableCell align="center">{row.payoutDate}</TableCell>
                        <TableCell align="center">{row.payoutAmount}</TableCell>
                        <TableCell align="center">{row.paidAmount}</TableCell>
                        <TableCell align="center">{row.unpaidAmount}</TableCell>
                        <TableCell align="center" sx={{ py: 0.8 }}>
                          {row.payoutType === "일부지급" ? (
                            <Button
                              variant="outlined"
                              onClick={() => onPayoutHistory(row.id)}
                            >
                              잔금처리
                            </Button>
                          ) : (
                            row.payoutType
                          )}
                        </TableCell>
                        <TableCell align="center">{row.note}</TableCell>
                      </TableRow>
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default PayoutDetailModal;
