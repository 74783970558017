import { instanceWithAuth } from "../../../../api/Instance";
import { CommonApi } from "../../../../api/type";
import {
  PayoutHistorDetailData,
  PayoutHistoryData,
  PayoutHistoryParams
} from "./types";

// =====================================================================================================================
// 정산 지급 내역
// ====================================================================================================================
/**
 * 정산 지급 내역 조회
 */
export const getPayoutHistories = async (params: PayoutHistoryParams) => {
  const url = "/payout-histories";
  const { data } = await instanceWithAuth.get<CommonApi<PayoutHistoryData[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 정산 지급 내역 상세 조회
 */
export const getPayoutHistoryById = async (id: number) => {
  const url = `/payout-histories/${id}`;
  const { data } = await instanceWithAuth.get<
    CommonApi<PayoutHistorDetailData[]>
  >(url);
  return data;
};

/**
 * 정산 잔금 지급 처리
 */
export const postPayoutHistoryItems = async (id: number) => {
  const url = `/payout-history-items/${id}`;
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, id);
  return data;
};
