import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import isModalOpen from "../../../../../recoil/isModalOpen/atom";
import { useSetRecoilState } from "recoil";
import { HelperTextMessage } from "../../../../components/HelperTextMessage";
import Stack from "@mui/material/Stack";
import { queryClient } from "../../../../..";
import { useMutation } from "@tanstack/react-query";
import { confirmSettlementV2 } from "../libs";
import DialogContentText from "@mui/material/DialogContentText";

interface Props {
  settlementId: number;
  closeModal?: () => void;
  documentNumber: string | undefined;
}

interface UpdateDocNumberForm {
  datePart: string;
  seqPart: string;
}

const UpdateDocNumberModal = ({
  closeModal,
  settlementId,
  documentNumber
}: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // 하이픈으로 분리된 문서번호에서 수정 가능한 마지막 두 부분 추출
  const [firstDocNum, secondDocNum, thirdDocNum, fourthDocNum] =
    documentNumber?.split("-") || [];

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid }
  } = useForm<UpdateDocNumberForm>({
    mode: "onChange",
    defaultValues: {
      datePart: thirdDocNum,
      seqPart: fourthDocNum
    }
  });

  // ===========================================================================
  // 리액트 쿼리 - 문서번호 변경
  // ===========================================================================
  const { mutate } = useMutation(confirmSettlementV2, {
    onSuccess: () => {
      queryClient.invalidateQueries([`/v3/settlements/${settlementId}`]);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "문서번호가 변경되었습니다."
      });
      closeModal?.();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 문서번호 업데이트
  // ===================================================================================================================
  const onSubmit = (formData: UpdateDocNumberForm) => {
    const updatedDocNumber = `${firstDocNum}-${secondDocNum}-${formData.datePart}-${formData.seqPart}`;
    const params = [
      {
        documentNumber: updatedDocNumber,
        id: settlementId,
        isComplete: null,
        isConfirm: null,
        note: null,
        payoutDate: null
      }
    ];

    mutate(params);
  };

  return (
    <>
      <Dialog
        open={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px"
            }
          }
        }}
      >
        <DialogTitle>문서번호 수정하기</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              문서번호의 마지막 두 부분을 수정할 수 있습니다.
            </DialogContentText>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mt={3}
            >
              <Typography>
                {firstDocNum} - {secondDocNum} -
              </Typography>

              <TextField
                label="날짜 부분 (예: 20241025)"
                size="small"
                {...register("datePart", {
                  required: "날짜 부분은 필수입니다.",
                  maxLength: {
                    value: 8,
                    message: "날짜 부분은 최대 8자까지 가능합니다."
                  }
                })}
                helperText={
                  <HelperTextMessage>
                    {errors.datePart?.message}
                  </HelperTextMessage>
                }
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/-/g, ""); // '-' 제거
                  setValue("datePart", sanitizedValue, {
                    shouldValidate: true
                  });
                }}
              />
              <span>-</span>
              <TextField
                label="일련번호 부분 (예: 01)"
                size="small"
                {...register("seqPart", {
                  required: "일련번호 부분은 필수입니다.",
                  maxLength: {
                    value: 4,
                    message: "일련번호 부분은 최대 4자까지 가능합니다."
                  }
                })}
                helperText={
                  <HelperTextMessage>
                    {errors.seqPart?.message}
                  </HelperTextMessage>
                }
                onChange={(e) => {
                  const sanitizedValue = e.target.value.replace(/-/g, "");
                  setValue("seqPart", sanitizedValue, {
                    shouldValidate: true
                  });
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ mx: 2, mb: 1 }}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ color: "white" }}
              disabled={!isValid}
            >
              수정 완료
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export default UpdateDocNumberModal;
