import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SettlementsTotalData } from "../libs/types";
import SettlementsState from "../../../../../recoil/settlements/atom";
import excelDownload from "../../../../../libs/excel-download";
import LoadingButton from "../../../../../components/LoadingButton";
import { addCommas, removeCommas } from "../../../../../libs/thousands-commas";
import isModalOpen from "../../../../../recoil/isModalOpen/atom";

const columns = [
  {
    id: "mallId",
    label: "가맹점 ID"
  },
  {
    id: "mallName",
    label: "가맹점명"
  },
  {
    id: "transactionDate",
    label: "거래일자"
  },
  {
    id: "transactionPrice",
    label: "거래합계 (건수)"
  },
  {
    id: "completePrice",
    label: "승인합계 (건수)"
  },
  {
    id: "cancelPrice",
    label: "취소합계 (건수)"
  },
  {
    id: "cardFee",
    label: "카드사 수수료"
  },
  {
    id: "optatumFee",
    label: "옵타움 수수료"
  },
  {
    id: "merchantFee",
    label: "가맹점 수수료"
  },
  {
    id: "vat",
    label: "부가세"
  },
  {
    id: "commissionFee",
    label: "총수수료"
  },
  {
    id: "settlementPrice",
    label: "정산금액"
  }
];

interface Props {
  data: SettlementsTotalData;
  isLoading: boolean;
}

const SettlementsTotal = ({ data, isLoading }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);
  const [SettlementsTotal, setSettlementsTotal] =
    useRecoilState(SettlementsState);

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const handleTotalSettlementExcel = () => {
    // 거래합계 데이터가 "(0)"을 포함할 경우 함수 종료
    if (!data || data.transactionTotalAmount === "0") {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "데이터가 없습니다."
      });
      return;
    } else {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "다운로드를 시작합니다."
      });
    }
    const list = [
      {
        가맹점Id: data.mallId,
        가맹점명: data.mallName,
        거래일자: data.transactionDate,
        거래합계: data.transactionTotalAmount,
        "거래합계 건수": data.transactionTotalCount,
        승인합계: data.completeTotalAmount,
        "승인합계 건수": data.completeTotalCount,
        취소합계: data.cancelTotalAmount,
        "취소합계 건수": data.cancelTotalCount,
        "카드사 수수료": data.cardFee,
        "옵타움 수수료": data.optatumFee,
        "가맹점 수수료": data.merchantFee,
        부가세: data.vat,
        총수수료: data.commissionFee,
        정산금액: data.settlementPrice
      }
    ];
    excelDownload({
      list,
      sheetName:
        SettlementsTotal.mallName === ""
          ? "정산내역 총합계"
          : `정산내역 총합계_${SettlementsTotal.mallName}`,
      fileName:
        SettlementsTotal.mallName === ""
          ? "정산내역 총합계.xlsx"
          : `정산내역 총합계_${SettlementsTotal.mallName}.xlsx`
    });
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>총합계</Typography>
        <LoadingButton
          icon={<SaveIcon />}
          size="medium"
          fullWidth={false}
          color="secondary"
          variant="outlined"
          loading={false}
          handleClick={handleTotalSettlementExcel}
        >
          EXCEL 다운로드
        </LoadingButton>
      </Stack>

      <Paper
        sx={{
          border: "1px solid #F2F3F5",
          borderRadius: 3,
          overflow: "hidden"
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FBFBFB" }}>
                {columns.map((column) => (
                  <TableCell
                    variant="head"
                    align="center"
                    key={column.id}
                    sx={{ borderTop: "none" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {data.mallId}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {data.mallName}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {data.transactionDate}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {data.transactionTotalAmount} ({data.transactionTotalCount})
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {data.completeTotalAmount} ({data.completeTotalCount})
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {data.cancelTotalAmount} ({data.cancelTotalCount})
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {data.cardFee}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {data.optatumFee}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {data.merchantFee}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {data.vat}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {data.commissionFee}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: "none" }}>
                  {data.settlementPrice}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
export default SettlementsTotal;
