import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { inputPriceFormat } from "../../../../../libs/format-utils";
import { addCommas, removeCommas } from "../../../../../libs/thousands-commas";
import { HelperTextMessage } from "../../../../../pages/components/HelperTextMessage";
import isModalOpenAtom from "../../../../../recoil/isModalOpen";
import { getCharges, postCharges } from "../libs";
import { AddChargeSaveParams, ChargeListResponse } from "../libs/types";

interface ChargeModalProps {
  isConfirm: string;
  settlementId: number;
  isModalOpen?: boolean;
  closeModal?: () => void;
  onButtonSubmit: () => void;
}

const SettlementChargeModal = ({
  isConfirm,
  settlementId,
  isModalOpen = false,
  closeModal,
  onButtonSubmit
}: ChargeModalProps) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [renderData, setRenderData] = useState<ChargeListResponse[]>([]);
  const [chargeData, setChargeData] = useState<ChargeListResponse[]>([]);
  const [sum, setSum] = useState(0);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { control, getValues, setValue } = useForm<AddChargeSaveParams>({
    defaultValues: {
      type: "",
      reason: "",
      amount: 0
    }
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { isLoading } = useQuery(
    [`/v3/charges/${settlementId}`, settlementId],
    () => getCharges(settlementId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data: ChargeListResponse[]) => {
        setRenderData(data);
        setChargeData(data);
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  /**
   * 청구금 등록
   */
  const { mutate } = useMutation(postCharges, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "청구금 내역을 저장하였습니다."
      });
      closeModal?.();
      onButtonSubmit();
    },
    onError: (error) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "청구금 저장에 실패하였습니다."
      });
    }
  });

  // ===================================================================================================================
  // 빼기, 더하기 버튼
  // ===================================================================================================================
  /**
   * 입력 폼 초기화
   */
  const initInputForm = () => {
    setValue("type", "");
    setValue("reason", "");
    setValue("amount", 0);
  };

  /**
   * 더하기 버튼 눌렀을 때
   */
  const addClicked = () => {
    const type = getValues("type");
    const reason = getValues("reason");
    let amount = getValues("amount");

    if (!type || !reason || !amount || amount === 0) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: !type
          ? "청구 타입을 입력해 주세요."
          : !reason
          ? "청구 이유를 입력해 주세요."
          : "청구 금액을 입력해 주세요."
      });
      return;
    }

    // claimAmount 에서 콤마 제거 후 숫자로 변환
    amount =
      typeof amount === "string" ? parseInt(removeCommas(amount)) : amount;

    const newData = {
      type,
      reason,
      amount,
      id: 0
    };

    setChargeData((prev) => [...prev, newData]);
    setRenderData((prev) => [...prev, newData]);
    initInputForm();
  };

  /**
   * 빼기 버튼을 눌렀을 때
   */
  const removeClicked = (id: number) => {
    // chargeData 가 없으면 종료
    if (!chargeData) return;

    // 화면에 그려지는 데이터 수정
    // 배열에서 해당 flag 의 데이터를 지운다 -> flag 가 다른 데이터들로 새로운 배열을 만든다
    const newDatas = renderData.filter((item) => item.id !== id);
    setRenderData((prev) => newDatas);
    setChargeData((prev) => newDatas);
  };

  /**
   * 합계 구하기
   */
  useEffect(() => {
    const getSum = () => {
      let sum = 0;
      renderData.forEach((item) => {
        const amount =
          typeof item.amount !== "number" ? parseInt(item.amount) : item.amount;
        sum = sum + amount;
      });
      setSum(sum);
    };
    getSum();
  }, [renderData]);

  // ===================================================================================================================
  // 청구금 등록
  // ===================================================================================================================
  const save = (e: React.MouseEvent<HTMLButtonElement>) => {
    // chargeData 가 없으면 바로 종료
    if (!chargeData) return;

    // chargeData 를 saveForm 에 맞게 변형해야 한다
    const saveForms: AddChargeSaveParams[] = chargeData.map(
      ({ id, amount, reason, type }) => ({ id: id || 0, amount, reason, type })
    );
    mutate({ id: settlementId, charges: saveForms });
  };

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      fullWidth={true}
      maxWidth="md"
      scroll={"paper"}
    >
      <DialogTitle>청구금 내역</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => closeModal?.()}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <form>
        <DialogContent>
          {/* 입력 인풋 */}
          {isConfirm === "확정" ? (
            <DialogContentText>
              정산 확정인 경우 청구금을 등록할 수 없습니다. 등록된 청구금은 아래
              내역을 확인해주세요.
            </DialogContentText>
          ) : (
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              gap={2}
              mb={3}
            >
              <Grid item xs={3}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label={"청구 타입"}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          청구 타입을 선택하세요.
                        </HelperTextMessage>
                      }
                      size="small"
                    >
                      <MenuItem key={"1"} value={"연회비"}>
                        연회비
                      </MenuItem>
                      <MenuItem key={"2"} value={"관리비"}>
                        관리비
                      </MenuItem>
                      <MenuItem key={"3"} value={"기타"}>
                        기타
                      </MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={"reason"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label={"청구 이유"}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          청구 이유를 입력하세요.
                        </HelperTextMessage>
                      }
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name={"amount"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label={"청구 금액"}
                      value={value}
                      helperText={
                        <HelperTextMessage>
                          청구 금액을 입력하세요.
                        </HelperTextMessage>
                      }
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₩</InputAdornment>
                        )
                      }}
                      onChange={(e) => {
                        const rawValue = removeCommas(e.target.value); // 콤마 제거
                        const formattedValue = inputPriceFormat(rawValue); // 포맷 적용하여 콤마 추가
                        setValue("amount", parseInt(rawValue)); // 콤마 제거된 숫자 상태에 저장
                        onChange(formattedValue); // 콤마가 추가된 값으로 인풋 업데이트
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  textAlign: "center"
                }}
              >
                <IconButton onClick={addClicked} size="large" color="primary">
                  <AddCircleOutlineRoundedIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}

          {/* 청구금 내역 */}
          <Grid container sx={{ my: 2 }} gap={1}>
            {isLoading && !renderData ? (
              <>
                <Grid item container sx={{ pb: 2 }}>
                  {[3, 5, 3, 1].map((xs, idx) => (
                    <Grid item xs={xs} key={idx} sx={{ pl: 3, pt: 2 }}>
                      <Skeleton variant="text" animation="wave" />
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <>
                {renderData.length === 0 ? (
                  ""
                ) : (
                  <Grid item container gap={2} ml={2}>
                    <Grid item xs={3}>
                      <Typography>청구 타입</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>청구 이유</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography align="right">청구 금액</Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                  </Grid>
                )}

                {renderData?.map((item, index) => (
                  <Grid
                    key={`chargeList-${index}`}
                    item
                    container
                    gap={2}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ background: "#f2f2f2", borderRadius: 2 }}
                  >
                    <Grid item xs={3}>
                      <Typography sx={{ p: 1 }}>{item.type}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>{item.reason}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography align="right">{`${addCommas(
                        item.amount
                      )} 원`}</Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: "center" }}>
                      {isConfirm === "확정" ? (
                        <></>
                      ) : (
                        <IconButton onClick={() => removeClicked(item.id)}>
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </Grid>

          {/* 청구금 합계 */}
          <Divider sx={{ mt: 1 }}>
            <Chip label="합계" />
          </Divider>
          <Grid
            container
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={3} textAlign={"right"}>
              {`${addCommas(sum)} 원`}
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </DialogContent>
        {isConfirm === "확정" ? (
          <></>
        ) : (
          <>
            <DialogActions>
              <Button
                variant="contained"
                sx={{
                  color: "white"
                }}
                fullWidth={true}
                onClick={save}
              >
                저장 완료
              </Button>
            </DialogActions>
          </>
        )}
      </form>
    </Dialog>
  );
};

export default SettlementChargeModal;
