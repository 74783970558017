import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { StepData } from "../../api/MerchantRegistration/types";
import EPaymentService from "../TermsAndConditions/EPaymentService";
import FinancialTransaction from "../TermsAndConditions/FinancialTransaction";
import PrivacyPolicy from "../TermsAndConditions/PrivacyPolicy";
import PrivacyPolicyOthers from "../TermsAndConditions/PrivacyPolicyOthers";

interface PrivacyPolicyProps {
  nextEvent: ({ currentStep }: StepData) => void;
}

// 약관 동의 아코디언
const ACCORDION_ITEMS = [
  {
    id: "전자결제약관",
    label: "전자결제서비스 이용약관",
    component: <EPaymentService />
  },
  {
    id: "개인정보약관",
    label: "개인정보 수집 및 이용동의",
    component: <PrivacyPolicy />
  },
  {
    id: "제3자제공약관",
    label: "개인정보 제3자 제공안내 동의",
    component: <PrivacyPolicyOthers />
  },
  {
    id: "금융거래약관",
    label:
      "특정 금융거래정보의 보고 및 이용 등에 관한 법률에 따른 개인정보 수집 이용 내역",
    component: <FinancialTransaction />
  }
];

const TermsAndConditionsPage = ({ nextEvent }: PrivacyPolicyProps) => {
  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // 아코디언 스테이트
  const [expanded, setExpanded] = useState<string | false>(false);

  // 각 체크박스의 id를 관리
  const [checkedButtons, setCheckedButtons] = useState([""]);

  // ===================================================================================================================
  // 체크박스 핸들러
  // ===================================================================================================================
  // 체크박스 상태 변경 핸들러
  const handleCheckboxChange = (id: string, isChecked: boolean) => {
    setCheckedButtons((prev) =>
      isChecked ? [...prev, id] : prev.filter((item) => item !== id)
    );
  };

  // 아코디언 상태 변경
  const handleAccordionChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  // 전체 동의 체크 핸들러 - 모든 id가 checkedButtons 추가되거나 비워진다
  const handleAllCheck = (isChecked: boolean) => {
    setCheckedButtons(isChecked ? ACCORDION_ITEMS.map((item) => item.id) : []);
  };

  // ===================================================================================================================
  //  다음페이지 이동
  // ===================================================================================================================
  const nextStep = () => {
    nextEvent({ currentStep: 1 });
  };

  // 모든 체크박스가 체크되었는지 여부를 계산
  const isAllChecked = checkedButtons.length === ACCORDION_ITEMS.length;

  // 다음 버튼 활성화 여부
  const isNextDisabled = checkedButtons.length !== ACCORDION_ITEMS.length;

  return (
    <>
      <Typography
        sx={{
          fontWeight: "bold",
          mb: 1
        }}
      >
        약관 동의 페이지
      </Typography>
      <Paper
        sx={{
          p: 3,
          border: "1px solid #F2F3F5",
          borderRadius: 3
        }}
      >
        {/* 전체 동의 체크박스 */}
        <FormControlLabel
          control={
            <Checkbox
              checked={isAllChecked}
              onChange={(e) => handleAllCheck(e.target.checked)}
            />
          }
          label="전체 동의"
          sx={{ ml: 1, mb: 1 }}
        />
        {ACCORDION_ITEMS.map(({ id, label, component }) => (
          <Accordion
            key={id}
            expanded={expanded === id}
            onChange={handleAccordionChange(id)}
            sx={{ border: "1px solid #F2F3F5" }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                control={
                  <Checkbox
                    checked={checkedButtons.includes(id)}
                    onChange={(e) => handleCheckboxChange(id, e.target.checked)}
                  />
                }
                label={label}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  height: 300,
                  overflowY: "scroll",
                  background: "#f2f2f2",
                  p: 3,
                  borderRadius: 2
                }}
              >
                {component}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}

        <Button
          variant="outlined"
          onClick={nextStep}
          type="submit"
          disabled={isNextDisabled}
          sx={{ mt: 2 }}
        >
          다음
          <KeyboardArrowRightRoundedIcon />
        </Button>
      </Paper>
    </>
  );
};
export default TermsAndConditionsPage;
