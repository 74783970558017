// ===============================================================================================
// 문자열, 숫자를 입력받아 콤마가 포함된 가격의 형식으로 변환해주는 함수
// ===============================================================================================
export const inputPriceFormat = (str: string | number): string => {
  // 숫자에 콤마를 추가
  const comma = (str: string | number) => {
    str = String(str);
    if (str === "0") return "0";
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/(^0+)/, "");
  };

  // 숫자 이외의 문자 제거
  const uncomma = (str: string | number): string => {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
  };

  // uncomma 로 숫자가 아닌 모든 문자를 제거하고, comma로 숫자 사이에 콤마를 추가
  return comma(uncomma(str));
};
