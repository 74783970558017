import { Autocomplete } from '@mui/material';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from '@tanstack/react-query';
import koLocale from 'date-fns/locale/ko';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getPaymentsTotal } from '../../../api/v2/Payments';
import {
  PaymentsDetailParams,
  PaymentsTotalParams,
} from '../../../api/v2/Payments/types';
import AdminLayout from '../../../components/Layout/Admin/Layout';
import CardTotal from '../../../components/Payments/CardsTotal';
import TransactionDetail from '../../../components/Payments/TransactionDetail';
import TransactionTotal from '../../../components/Payments/TransactionTotal';
import Title from '../../../components/Title';
import useRenewal from '../../../hooks/useRenewal';
import { getDate } from '../../../libs/get-date';
import { getToday } from '../../../libs/get-today';
import isModalOpenAtom from '../../../recoil/isModalOpen';
import PaymentsState, {
  PaymentsDetailState,
} from '../../../recoil/payments/atom';
import { useMerchantNames } from '../../../hooks/useMerchantNames';
import SearchControls from '../../../components/Common/SearchControls';

const CARD_COMPANY = [
  { name: '전체', value: '기본' },
  // { name: "ISP", value: "ISP" },
  { name: '신한카드', value: '신한카드' },
  { name: '현대카드', value: '현대카드' },
  { name: '삼성카드', value: '삼성카드' },
  { name: '우리카드', value: '우리카드' },
  { name: 'KB국민카드', value: 'KB국민카드' },
  { name: '롯데카드', value: '롯데카드' },
  { name: 'NH농협카드', value: 'NH농협카드' },
  { name: '하나카드', value: '하나카드' },
  { name: '비씨카드', value: '비씨카드' },
  { name: '씨티카드', value: '씨티카드' },
  { name: '카카오뱅크', value: '카카오뱅크' },
  // { name: "KDB산업은행", value: "KDB산업은행" },
  // { name: "SH수협은행", value: "SH수협은행" },
  // { name: "전북은행", value: "전북은행" },
  // { name: "우체국예금보험", value: "우체국예금보험" },
  // { name: "새마을금고", value: "새마을금고" },
  // { name: "저축은행SB", value: "저축은행SB" },
  // { name: "제주은행", value: "제주은행" },
  // { name: "광주은행", value: "광주은행" },
  // { name: "신협", value: "신협" },
  // { name: "JCB", value: "JCB" },
  // { name: "유니온페이", value: "유니온페이" },
  // { name: "마스터카드", value: "마스터카드" },
  // { name: "비자카드", value: "비자카드" },
  // { name: "다이너스클럽", value: "다이너스클럽" },
  // { name: "디스커버", value: "디스커버" }
];

const PURCHASE_STATUS = [
  { name: '전체', value: 'DEFAULT' },
  { name: '매출 정상', value: '매출정상' },
  { name: '매출 반송', value: '매출반송' },
  { name: '매출 보류', value: '매출보류' },
  { name: '매출 보류 해제', value: '매출보류해제' },
  { name: '취소 반송', value: '취소반송' },
  { name: '취소 보류', value: '취소보류' },
  { name: '취소 보류 해제', value: '취소보류해제' },
  { name: '취소 정상', value: '취소정상' },
  { name: '매입 대기', value: '매입대기' },
];

interface FormInputs extends PaymentsTotalParams {
  cardCompany: string;
  status: string;
  paymentKey: string;
  transactionKey: string;
  approvalNum: string;
  purchaseStatus: string;
  merchantGrade: string;
}

const Payments = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===============================================================================================
  // 리코일 스테이트
  // ===============================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  const [paymentsDetail, setPaymentsDetail] =
    useRecoilState(PaymentsDetailState);
  const [PaymentsTotal, setPaymentsTotal] = useRecoilState(PaymentsState);

  const defaultFormValues = {
    startDate: getToday(),
    endDate: getToday(),
    cardCompany: '기본',
    status: 'DEFAULT',
    mallId: '',
    mallName: null,
    transactionKey: '',
    paymentKey: '',
    approvalNum: '',
    purchaseStatus: 'DEFAULT',
    merchantGrade: '',
  };

  // ===============================================================================================
  // 리액트 훅 폼
  // ===============================================================================================
  const { register, handleSubmit, control, reset } = useForm<FormInputs>({
    defaultValues: {
      ...defaultFormValues,
    },
  });
  // 조회 초기화
  const handleReset = () => {
    reset(defaultFormValues);
  };
  // 리코일 스테이트 초기화
  useEffect(() => {
    setPaymentsTotal(defaultFormValues);
    setPaymentsDetail({
      ...defaultFormValues,
      merchantGrade: '',
      page: 0,
      size: 50,
      sort: paymentsDetail.sort,
    });
  }, [setPaymentsDetail, setPaymentsTotal]);

  // ===============================================================================================
  // 리액트 쿼리
  // ===============================================================================================
  // 현재 운영중인 가맹점 목록 조회
  const { uniqueMerchantsNames, isMerchantsLoading } = useMerchantNames();

  // 가맹점별 총합계 조회
  const { isLoading, data } = useQuery(
    ['/payments/total', PaymentsTotal],
    () => getPaymentsTotal(PaymentsTotal),
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'error',
          message: error?.response?.data?.message,
        });
      },
      refetchOnWindowFocus: false,
      select: data => ({
        ...data,
        content: data.content.map((data: any) => ({
          ...data,
          mallId: data.mallId,
          createdAt: data.datePeriod,
          price: data.transactionTotal,
        })),
      }),
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
    },
  );

  // ===============================================================================================
  // 검색 조회
  // ===============================================================================================
  const handleForm = (data: FormInputs) => {
    const isInvalidData =
      data.startDate?.includes('NaN') || data.endDate?.includes('NaN');
    const isInvaildPeriod = data.startDate > data.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '거래 일자를 확인해주세요.',
      });
      return;
    }
    const formattedData = {
      ...data,
      merchantGrade: data.merchantGrade === '전체' ? '' : data.merchantGrade,
    };
    setPaymentsDetail((prevValue: PaymentsDetailParams) => ({
      ...prevValue,
      ...formattedData,
      page: 0,
    }));

    setPaymentsTotal(formattedData);
  };

  return (
    <>
      <Title title="거래" />
      <AdminLayout>
        <Stack spacing={3}>
          <Paper
            sx={{
              p: 3,
              border: '1px solid #F2F3F5',
              borderRadius: 3,
            }}
          >
            <form onSubmit={handleSubmit(handleForm)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <LocalizationProvider
                      adapterLocale={koLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <Grid item xs={2}>
                        <Controller
                          name="startDate"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              label="거래 시작 날짜"
                              inputFormat="yyyy-MM-dd"
                              value={value}
                              onChange={value => onChange(getDate(value))}
                              renderInput={param => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  required
                                  {...param}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Controller
                          name="endDate"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              label="거래 종료 날짜"
                              inputFormat="yyyy-MM-dd"
                              value={value}
                              onChange={value => onChange(getDate(value))}
                              renderInput={param => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  required
                                  {...param}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </LocalizationProvider>
                    <Grid item xs={2}>
                      <Controller
                        name="cardCompany"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            id="input-card-company"
                            label="카드사"
                            required
                            fullWidth
                            size="small"
                            select
                            value={value}
                            onChange={onChange}
                          >
                            {CARD_COMPANY.map(c => (
                              <MenuItem key={c.value} value={c.value}>
                                {c.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            id="input-status"
                            label="거래 상태"
                            required
                            fullWidth
                            size="small"
                            select
                            value={value}
                            onChange={onChange}
                          >
                            <MenuItem value="DEFAULT">전체</MenuItem>
                            <MenuItem value="COMPLETE">승인</MenuItem>
                            <MenuItem value="CANCEL">취소</MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="merchantGrade"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="거래 등급"
                            required
                            fullWidth
                            size="small"
                            select
                            value={value || '전체'}
                            onChange={onChange}
                          >
                            <MenuItem value="전체">전체</MenuItem>
                            <MenuItem value="일반">일반</MenuItem>
                            <MenuItem value="영세">영세</MenuItem>
                            <MenuItem value="중소1">중소1</MenuItem>
                            <MenuItem value="중소2">중소2</MenuItem>
                            <MenuItem value="중소3">중소3</MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SearchControls onReset={handleReset} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    control={control}
                    name="mallName"
                    render={({ field: { value, onChange, ...field } }) => (
                      <Autocomplete
                        {...field}
                        id="input-mallName"
                        value={value}
                        autoSelect
                        autoComplete
                        options={uniqueMerchantsNames}
                        loading={isMerchantsLoading}
                        onChange={(_, newValue) => onChange(newValue)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="가맹점명"
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="input-mallId"
                    label="가맹점 ID"
                    fullWidth
                    size="small"
                    {...register('mallId')}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    name="purchaseStatus"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="input-purchase-status"
                        label="매입 상태"
                        fullWidth
                        size="small"
                        select
                        value={value}
                        onChange={onChange}
                      >
                        {PURCHASE_STATUS.map(c => (
                          <MenuItem key={c.name} value={c.value}>
                            {c.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="input-approvalNum"
                    label="승인 번호"
                    fullWidth
                    size="small"
                    {...register('approvalNum')}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    id="input-transactionKey"
                    label="거래 KEY"
                    fullWidth
                    size="small"
                    {...register('transactionKey')}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="input-paymentKey"
                    label="거래고유KEY"
                    fullWidth
                    size="small"
                    {...register('paymentKey')}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>

          {/* 가맹점별 합계 */}
          <TransactionTotal data={data} isLoading={isLoading} />

          {/* 카드사별 합계 */}
          <CardTotal />

          {/* 거래 상세 조회  */}
          <TransactionDetail totalData={data} />
        </Stack>
      </AdminLayout>
    </>
  );
};

export default Payments;
export { CARD_COMPANY };
