import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useRecoilState, useSetRecoilState } from "recoil";
import useRenewal from "../../../../hooks/useRenewal";
import { getToday } from "../../../../libs/get-today";
import isModalOpenAtom from "../../../../recoil/isModalOpen";
import { PaymentsDetailState } from "../../../../recoil/payments/atom";
import { getPurchasePaymnets } from "../libs";
import { PurchasePaymentsData } from "../libs/types";
import { getDate } from "date-fns";

interface PaymentColumnHeadCell {
  id: keyof PurchasePaymentsData;
  label: string;
  isTotalData: boolean;
  isSort: boolean;
}

const columns: PaymentColumnHeadCell[] = [
  {
    id: "id",
    label: "매입 신청 ID",
    isTotalData: true,
    isSort: false
  },
  {
    id: "createAt",
    label: "신청 날짜",
    isTotalData: false,
    isSort: false
  },
  {
    id: "updateAt",
    label: "처리 날짜",
    isTotalData: false,
    isSort: false
  },
  {
    id: "paymentId",
    label: "거래고유 ID",
    isTotalData: false,
    isSort: false
  },
  {
    id: "status",
    label: "거래 상태",
    isTotalData: true,
    isSort: true
  },

  {
    id: "isComplete",
    label: "처리여부",
    isTotalData: false,
    isSort: false
  }
];

const PurchasePayments = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();

  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const [paymentsDetail, setPaymentsDetail] =
    useRecoilState(PaymentsDetailState);

  // ===================================================================================================================
  // 파라미터
  // ===================================================================================================================
  const params = {
    startDate: paymentsDetail.startDate,
    endDate: getToday(),
    page: 0,
    size: 0,
    sort: "",
    status: ""
  };

  // ===================================================================================================================
  // 리액트 쿼리 - 매입 등록 데이터 조회
  // ===================================================================================================================
  const { data, isLoading } = useQuery(
    ["/missing-purchase-payments", params],
    () => getPurchasePaymnets(params),
    {
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
      select: (data) => ({
        ...data,
        content: data.content.map((purchaseData: PurchasePaymentsData) => ({
          ...purchaseData
          // status: (() => {
          //   switch (purchaseData.status) {
          //     case "DEFAULT":
          //       return "default";
          //     case "READY":
          //       return "준비";
          //     case "PROGRESS":
          //       return "진행";
          //     case "VOID":
          //       return "망상취소";
          //     case "COMPLETE":
          //       return "완료";
          //     case "CANCEL":
          //       return "취소";
          //     case "FAIL":
          //       return "실패";
          //     case "EXPIRED":
          //       return "만료";
          //     case "PARTIAL_CANCEL":
          //       return "부분취소";
          //     case "CAPTURE_REJECT":
          //       return "매출 반송";
          //     default:
          //       return "알 수 없음";
          //   }
          // })()
        }))
      }),
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // ===================================================================================================================
  // 페이징
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPaymentsDetail((prevValue: any) => ({ ...prevValue, page: page - 1 }));
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>매입 등록 거래 목록</Typography>
      </Stack>

      <Paper
        sx={{
          border: "1px solid #F2F3F5",
          borderRadius: 3,
          overflow: "hidden"
        }}
      >
        {/* 정산 내역 상세 조회 테이블 */}
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FBFBFB" }}>
                {columns.map((column) => (
                  <TableCell
                    variant="head"
                    align="center"
                    key={column.id}
                    sx={{ borderTop: "none" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Typography align="center">데이터가 없습니다.</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {!data &&
                    isLoading &&
                    [...Array(1)].map((_, index) => (
                      <TableRow key={index}>
                        {columns.map((column) => (
                          <TableCell key={column.id}>
                            <Skeleton variant="rounded" animation="wave" />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  {data?.content &&
                    !isLoading &&
                    data.content.map((i: PurchasePaymentsData) => (
                      <TableRow key={i.id}>
                        <TableCell align="center">{i.id}</TableCell>
                        <TableCell align="center">
                          {i.createAt.split("T")[0]}
                        </TableCell>
                        <TableCell align="center">
                          {i.updateAt.split("T")[0]}
                        </TableCell>
                        <TableCell align="center">{i.paymentId}</TableCell>
                        <TableCell align="center">{i.status}</TableCell>
                        <TableCell align="center">
                          {i.isComplete ? "확정" : "미확정"}
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box py={2}>
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            page={paymentsDetail.page + 1}
            onChange={handlePage}
            sx={{ display: "flex", justifyContent: "center" }}
          />
        </Box>
      </Paper>
    </Box>
  );
};
export default PurchasePayments;
