import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMutation, useQuery } from '@tanstack/react-query';
import koLocale from 'date-fns/locale/ko';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { queryClient } from '../..';
import {
  downloadFile,
  getMerchantsDetail,
  putMerchantContractDate,
  putMerchantStatus,
} from '../../api/Merchants';
import { MerchantContractDateParams } from '../../api/Merchants/types';
import { addCommas } from '../../libs/thousands-commas';
import TestPage from '../../pages/PreviewPage';
import isModalOpen from '../../recoil/isModalOpen/atom';
import ModalTriggerButton from '../PopupTriggerButton';
import ContractHistoryModal from './ContractHistoryModal';
import DeleteMerchantDataModal from './DeleteMerchantDataModal';
import MerchantsAdminEdit from './MerchantsAdminEdit';
import RegistMerchantFeeModal from './RegistMerchantFeeModal';
import TerminateContractModal from './TerminateContractModal';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  merchantId: any;
}

const MerchantsDetailModal = ({ isOpen, closeModal, merchantId }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  const [openPrompt, setOpenPrompt] = useState(null);
  const handleOpenPrompt = (type: any) => {
    setOpenPrompt(type);
  };
  const handleClosePrompt = () => {
    setOpenPrompt(null);
  };

  // ===================================================================================================================
  // 리액트 쿼리: 그룹 조회, 심사 상태 업데이트
  // ===================================================================================================================
  const { data, isLoading } = useQuery(
    [`/merchants/${merchantId}`],
    () => getMerchantsDetail({ merchantId }),
    {
      enabled: isOpen,
    },
  );

  // 심사 상태 업데이트 - 계약 심사중
  const { mutate: updateStatus } = useMutation(putMerchantStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(merchantId);
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'success',
        message: '심사 상태가 변경 되었습니다.',
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: error?.response?.data?.message,
      });
    },
  });

  const close = () => {
    closeModal();
  };

  // 계약 날짜 업데이트
  const { mutate: updateDate } = useMutation(putMerchantContractDate, {
    onSuccess: () => {
      // merchantId로 시작하는 키로 모든 쿼리 무효화
      // 심사상태 값이 바뀌면 가맹 현황 refresh
      queryClient.invalidateQueries(merchantId);
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'success',
        message: '계약 날짜가 업데이트되었습니다.',
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: error?.response?.data?.message,
      });
    },
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const newDate = new Date();
  const { control, handleSubmit, getValues, setValue, watch } = useForm<any>({
    defaultValues: {
      startDate: data?.content.startDate ? data.content.startDate : newDate,
      endDate: data?.content.endDate ? data.content.endDate : newDate,
      payType: '1',
    },
    mode: 'onChange',
  });

  // ===================================================================================================================
  // 파일 다운로드
  // ===================================================================================================================
  // 인코딩된 파일 이름을 디코딩한다
  // 파일 이름이 빈 문자열이 아니면 decodeURIComponent함수로 디코딩된 문자열 반환
  const decodeFileName = (incodedFileName: string) => {
    if (incodedFileName === '') return;
    return decodeURIComponent(incodedFileName);
  };

  //파일 다운로드
  const { isLoading: isFileDownloading, mutate } = useMutation(downloadFile, {
    onSuccess: response => {
      // 파일 이름을 가져온다
      const contentDisposition = response.headers['content-disposition'];
      if (!contentDisposition) return;

      // 파일 이름을 추출한다
      const [fileNameMatch] = contentDisposition
        .split(';')
        .filter((str: any) => str.includes('filename'));

      if (!fileNameMatch) return;

      // 인코딩된 파일이름을 디코딩한다
      const [, incodedFileName] = fileNameMatch.split('=');
      const decodedFileName = decodeFileName(incodedFileName);

      if (!decodedFileName) return;

      // 다운로드할 파일의 url을 생성한다
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // 앵커 태그를 생성하고 setAttribute로 다운로드 속성을 설정한다
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${decodedFileName.trim().replaceAll('"', '')}`,
      );
      // 링크를 문서에 추가하고 `link.click`으로 다운로드를 시작한다
      document.body.appendChild(link);
      link.click();
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'success',
        message: '파일 다운로드가 시작 되었습니다.',
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: error?.response?.data?.message,
      });
    },
  });

  const onClickFileDownload = (fileId: number) => {
    if (isFileDownloading) return;
    mutate({ fileId: fileId });
  };

  // ===================================================================================================================
  // 심사 상태 업데이트
  // ===================================================================================================================
  const onClickReview = async () => {
    // 어드민 심사 상태 업데이트 - 계약심사중
    const merchantStatusUpdateForm = {
      id: merchantId,
      status: '계약심사중',
    };
    updateStatus(merchantStatusUpdateForm);
    closeModal();
  };

  // 어드민 심사 상태 업데이트 - 계약심사반려
  const onClickDeny = () => {
    const merchantStatusUpdateForm = {
      id: merchantId,
      status: '계약심사반려',
    };
    updateStatus(merchantStatusUpdateForm);
    closeModal();
  };
  // 어드민 심사 상태 업데이트 - 계약승인
  const onClickApprove = () => {
    const merchantStatusUpdateForm = {
      id: merchantId,
      status: '계약승인',
      payType: [getValues('payType')],
    };
    updateStatus(merchantStatusUpdateForm);
    handleClosePrompt();
    onSubmit(getValues());
    closeModal();
  };

  // ====================================================================================================================
  // 계약 시작일, 종료일 컨트롤
  // ====================================================================================================================
  const startDate = watch('startDate');
  const contractPeriod = data?.content.merchantInformation.contractPeriod;

  useEffect(() => {
    if (startDate && contractPeriod) {
      const endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + Number(contractPeriod));
      // 종료일 업데이트
      setValue('endDate', endDate);
    }
  }, [startDate, contractPeriod, setValue]);

  const onSubmit = (formData: MerchantContractDateParams) => {
    // 포맷팅 함수를 간소화하여 ISO 8601 형식으로 변환
    const formatDate = (date: Date) => date.toISOString().slice(0, 10);

    const formattedDate = formatDate(formData.startDate);
    const formattedEndDate = formatDate(new Date(formData.endDate));

    const params = {
      id: merchantId,
      startDate: formattedDate,
      endDate: formattedEndDate,
    };
    updateDate(params);
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{ boxShadow: '0px 5px 14px -1px rgba(204, 204, 204, 0.5)' }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          가맹점 상세 정보
          <Stack direction={'row'} spacing={3}>
            {data?.content.status === '계약승인' && (
              <>
                <ModalTriggerButton
                  size="medium"
                  color="primary"
                  modal={
                    <TerminateContractModal
                      merchantId={merchantId}
                      onClose={close}
                    />
                  }
                >
                  계약 강제 해지
                </ModalTriggerButton>
                <ModalTriggerButton
                  size="medium"
                  modal={<RegistMerchantFeeModal merchantId={merchantId} />}
                >
                  가맹 수수료 등록
                </ModalTriggerButton>
                <ModalTriggerButton
                  size="medium"
                  color="secondary"
                  modal={<ContractHistoryModal merchantId={merchantId} />}
                >
                  계약 연장 정보 조회
                </ModalTriggerButton>
              </>
            )}
            {(data?.content.status === '가맹심사반려' ||
              data?.content.status === '계약심사반려') && (
              <ModalTriggerButton
                size="medium"
                modal={
                  <DeleteMerchantDataModal
                    merchantId={merchantId}
                    onClose={close}
                  />
                }
              >
                가맹점 데이터 삭제
              </ModalTriggerButton>
            )}

            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        {!isLoading && data?.content ? (
          <>
            <DialogContent>
              <Stack gap={2} sx={{ pt: 2.5 }}>
                {/* 사업자 정보 ====================================================================================== */}
                <Box
                  sx={{
                    border: '1px solid #E4E4E7',
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  <Stack
                    direction={'row'}
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    sx={{
                      py: 1,
                      borderBottom: '1px solid #E4E4E7',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    <Typography>사업자 정보</Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: '#666',
                      }}
                    >
                      ({data?.content?.merchantInformation?.businessType}
                      사업자)
                    </Typography>
                  </Stack>

                  <Stack
                    gap={1.5}
                    sx={{
                      px: 4,
                      py: 3,
                    }}
                  >
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>가맹점명:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.mallName}
                        </Typography>
                      </Stack>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>| 사업자 등록증 상호명:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content.merchantInformation.businessName}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography>| 사업자등록번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {
                            data?.content?.merchantInformation
                              ?.registrationNumber
                          }
                        </Typography>
                      </Stack>
                    </Stack>

                    {data?.content?.merchantInformation?.businessType ===
                    '법인' ? (
                      <Stack direction="row" sx={{ pl: 1 }}>
                        <Stack direction="row" sx={{ width: 350 }}>
                          <Typography>영문상호:</Typography>
                          <Typography sx={{ ml: 1 }}>
                            {data?.content?.mallNameEn}
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography>| 법인등록번호:</Typography>
                          <Typography sx={{ ml: 1 }}>
                            {
                              data?.content?.merchantInformation
                                ?.corporationNumber
                            }
                          </Typography>
                        </Stack>
                      </Stack>
                    ) : (
                      ''
                    )}

                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Typography>사업장 주소:</Typography>
                      <Typography sx={{ ml: 1 }}>
                        {data?.content?.merchantInformation?.address
                          .split(' | ')
                          .join(', ')}
                      </Typography>
                    </Stack>

                    {data.content.merchantInformation.headOfficeAddress ? (
                      <Stack direction="row" sx={{ pl: 1 }}>
                        <Typography>본점 주소:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantInformation?.headOfficeAddress
                            .split(' | ')
                            .join(', ')}
                        </Typography>
                      </Stack>
                    ) : (
                      <></>
                    )}

                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>가맹점 전화번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantInformation?.phone}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography>| 가맹점 팩스번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantInformation?.fax}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Typography>업태:</Typography>
                      <Typography sx={{ ml: 1 }}>
                        {data?.content?.merchantInformation?.businessConditions}
                      </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Typography>업종:</Typography>
                      <Typography sx={{ ml: 1 }}>
                        {data?.content?.merchantInformation?.merchantCategory}
                      </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>통신판매번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {
                            data?.content?.merchantInformation
                              ?.mailOrderSalesNumber
                          }
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography>| 홈페이지:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.mallUrl}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>가맹점 IP:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.mallIp}
                        </Typography>
                      </Stack>
                      {data.content.merchantInformation.introductionCompany ? (
                        <Stack direction="row">
                          <Typography>| 호스팅:</Typography>
                          <Typography sx={{ ml: 1 }}>
                            {
                              data?.content?.merchantInformation
                                ?.introductionCompany
                            }
                          </Typography>
                        </Stack>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>테스트 ID:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantInformation?.testId}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography>| 테스트 PW:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantInformation?.testPassword}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>

                {/* 대표자 정보 ====================================================================================== */}
                <Box
                  sx={{
                    border: '1px solid #E4E4E7',
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 1,
                      borderBottom: '1px solid #E4E4E7',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    <Typography>대표자 정보</Typography>
                  </Stack>
                  <Stack
                    sx={{
                      px: 4,
                      py: 3,
                    }}
                  >
                    {data?.content?.merchantOwnerInformation?.map(
                      (owner, index) => (
                        <Stack key={owner.id} gap={1.5}>
                          <Stack direction={'row'} sx={{ pl: 1 }}>
                            <Stack direction={'row'} sx={{ width: 350 }}>
                              <Typography>성명:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.name}
                              </Typography>
                            </Stack>
                            <Stack direction={'row'}>
                              <Typography>| 영문성명:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.nameEn}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack direction={'row'} sx={{ pl: 1 }}>
                            <Stack direction={'row'} sx={{ width: 350 }}>
                              <Typography>전화번호:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.phone}
                              </Typography>
                            </Stack>
                            <Stack direction={'row'} sx={{ width: 320 }}>
                              <Typography>| 생년월일:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.birth}
                              </Typography>
                            </Stack>
                            <Stack direction={'row'}>
                              <Typography>| 국적:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.nationality}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack direction={'row'} sx={{ pl: 1 }}>
                            <Typography>주소:</Typography>
                            <Typography sx={{ ml: 1 }}>
                              {owner.address.split(' | ').join(', ')}
                            </Typography>
                          </Stack>
                          {index !==
                            data?.content?.merchantOwnerInformation?.length -
                              1 && <Divider sx={{ mb: 3, mt: 1 }} />}
                        </Stack>
                      ),
                    )}
                  </Stack>
                </Box>

                {/* 은행 정보 ===============================================================================-======= */}
                <Box
                  sx={{
                    border: '1px solid #E4E4E7',
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  <Stack
                    alignItems="center"
                    sx={{
                      py: 1,
                      borderBottom: '1px solid #E4E4E7',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    <Typography>은행 정보</Typography>
                  </Stack>
                  <Stack
                    sx={{
                      px: 4,
                      py: 5,
                    }}
                  >
                    <Stack direction={'row'} sx={{ pl: 1 }}>
                      <Stack direction={'row'} sx={{ width: 350 }}>
                        <Typography>은행명:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantBankInformation?.bankName}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} sx={{ width: 320 }}>
                        <Typography>| 은행 계좌번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantBankInformation?.accountCode}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'}>
                        <Typography>| 계좌 소유주:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {
                            data?.content?.merchantBankInformation
                              ?.accountHolder
                          }
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>

                {/* 담당자 정보 ===================================================================================== */}
                <Box
                  sx={{
                    border: '1px solid #E4E4E7',
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  <Stack
                    alignItems="center"
                    sx={{
                      py: 1,
                      borderBottom: '1px solid #E4E4E7',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    <Typography>담당자 정보</Typography>
                  </Stack>
                  <Stack
                    gap={1.5}
                    sx={{
                      pt: 3,
                      px: 4,
                    }}
                  >
                    {data.content.merchantInChargeInformation?.map(
                      (inCharge, index) => (
                        <div key={index}>
                          <Stack direction={'row'} sx={{ pl: 1 }}>
                            <Stack direction={'row'} sx={{ width: 350 }}>
                              <Typography>성명:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {inCharge.name}
                              </Typography>
                            </Stack>
                            <Stack direction={'row'}>
                              <Typography>| 담당역할:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {inCharge.role}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            direction={'row'}
                            sx={{ pl: 1, mt: 1.5, mb: 2.5 }}
                          >
                            <Stack direction={'row'} sx={{ width: 350 }}>
                              <Typography>전화번호:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {inCharge.phone}
                              </Typography>
                            </Stack>
                            <Stack direction={'row'}>
                              <Typography>| 이메일:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {inCharge.email}
                              </Typography>
                            </Stack>
                          </Stack>

                          {/* Divider는 마지막 요소 이후에는 렌더링되지 않도록 조건 추가 */}
                          {index !==
                            data.content.merchantInChargeInformation.length -
                              1 && <Divider sx={{ my: 1 }} />}
                        </div>
                      ),
                    )}
                  </Stack>
                </Box>

                {/* 파일 정보 ======================================================================================= */}
                <Box
                  sx={{
                    border: '1px solid #E4E4E7',
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                      py: 1,
                      borderBottom: '1px solid #E4E4E7',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    <Typography>파일 정보</Typography>
                  </Stack>
                  <Box
                    sx={{
                      py: 2,
                      px: 4,
                    }}
                  >
                    {data.content.merchantFileDto &&
                      data.content.merchantFileDto.map(file => (
                        <Stack
                          direction={'row'}
                          alignItems="center"
                          key={file.id}
                        >
                          <Stack direction={'row'}>
                            <Typography sx={{ ml: 1 }}>{file.type}:</Typography>
                            <Typography sx={{ ml: 1 }}>
                              {file.fileName}
                            </Typography>
                          </Stack>
                          <IconButton
                            color="primary"
                            onClick={() => onClickFileDownload(file.id)}
                          >
                            <FileDownloadIcon />
                          </IconButton>
                        </Stack>
                      ))}
                  </Box>
                </Box>

                {/* 계약 정보 ======================================================================================= */}
                {data.content.status === '가맹심사중' ||
                data.content.status === '가맹심사반려' ? (
                  <></>
                ) : (
                  <>
                    <Box
                      sx={{
                        border: '1px solid #E4E4E7',
                        borderRadius: 2,
                        overflow: 'hidden',
                      }}
                    >
                      <Stack
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                          py: 1,
                          borderBottom: '1px solid #E4E4E7',
                          backgroundColor: '#f7f7f7',
                        }}
                      >
                        <Typography>계약 정보</Typography>
                      </Stack>
                      <Box
                        sx={{
                          py: 2,
                          px: 4,
                        }}
                      >
                        <Stack gap={2}>
                          <Stack direction={'row'} sx={{ pl: 1 }}>
                            <Stack direction={'row'} sx={{ width: 350 }}>
                              <Typography>계약 담당자:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {data.content.merchantInformation.inChargeName}
                              </Typography>
                            </Stack>
                            <Stack direction={'row'} sx={{ width: 320 }}>
                              <Typography>| 계약년수:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {
                                  data.content.merchantInformation
                                    .contractPeriod
                                }
                                년
                              </Typography>
                            </Stack>
                            <Stack direction={'row'}>
                              <Typography>| 수수료 등급:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {data.content.grade}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack direction="row" sx={{ pl: 1 }}>
                            <Stack direction="row" sx={{ width: 350 }}>
                              <Typography>결제 업종:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {data.content.mccType}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack direction={'row'} sx={{ pl: 1 }}>
                            <Stack direction={'row'} sx={{ width: 350 }}>
                              <Typography>신규 가입비:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {`${addCommas(
                                  data.content.merchantInformation
                                    .registrationFee,
                                )}원`}
                              </Typography>
                            </Stack>
                            <Stack direction={'row'} sx={{ width: 320 }}>
                              <Typography>| 보증 보험 금액:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {`${addCommas(data.content.deposit)}원`}
                              </Typography>
                            </Stack>
                            <Stack direction={'row'}>
                              <Typography>| 연회비:</Typography>
                              {data.content?.annualFee?.map((fee, index) => (
                                <Typography sx={{ ml: 1 }} key={fee.id}>
                                  {`${addCommas(fee.price)}원`}
                                </Typography>
                              ))}
                            </Stack>
                          </Stack>

                          <Stack
                            direction={'row'}
                            sx={{ pl: 1, flexWrap: 'wrap' }}
                          >
                            <Typography>수수료:</Typography>
                            {data.content.merchantFee?.map((fee, index) => (
                              <Typography sx={{ ml: 1 }} key={fee.id}>
                                {fee.grade}:{' '}
                                {`${addCommas(fee.optatumFee)}${
                                  fee.feeType === 'WON' ? '원' : '%'
                                }`}
                                {index !== data.content.merchantFee?.length - 1
                                  ? ' |'
                                  : ''}
                              </Typography>
                            ))}
                          </Stack>
                        </Stack>
                      </Box>
                    </Box>
                  </>
                )}

                {/* 계약심사중 버튼 ================================================================================== */}
                {data.content.status === '계약심사대기' ||
                data.content.status === '계약심사반려' ? (
                  <>
                    <Stack direction={'row'} sx={{ mt: 1, gap: 2 }}>
                      <TestPage data={data} />
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{ color: 'white', flex: 1 }}
                        onClick={() => handleOpenPrompt('계약심사중')}
                      >
                        계약심사시작으로 심사 상태 업데이트
                      </Button>
                    </Stack>

                    <Dialog
                      open={openPrompt === '계약심사중'}
                      fullWidth
                      maxWidth="xs"
                    >
                      <DialogTitle>가맹점 심사 상태 변경</DialogTitle>
                      <IconButton
                        onClick={handleClosePrompt}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 12,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <DialogContent>
                        <DialogContentText gutterBottom>
                          심사상태를 변경하면 수정이 불가 합니다. <br />
                          <Typography variant="caption" color={'error'}>
                            * 반드시 등기로 계약서를 받은 이후에 심사 상태를
                            변경해주세요.
                          </Typography>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          sx={{ color: 'white' }}
                          fullWidth
                          onClick={onClickReview}
                        >
                          계약심사중
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : (
                  <></>
                )}

                {/* 계약승인 및 계약심사반려 버튼 ===================================================================== */}
                {data.content.status === '계약심사중' ? (
                  <>
                    <Stack direction={'row'} gap={3}>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => handleOpenPrompt('계약심사반려')}
                      >
                        계약심사반려
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{ color: 'white' }}
                        onClick={() => handleOpenPrompt('계약승인')}
                      >
                        계약승인
                      </Button>
                    </Stack>

                    {/* 계약심사반려 */}
                    <Dialog
                      open={openPrompt === '계약심사반려'}
                      fullWidth
                      maxWidth="xs"
                    >
                      <DialogTitle>가맹점 심사 상태 변경</DialogTitle>
                      <IconButton
                        onClick={handleClosePrompt}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 12,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <DialogContent>
                        <DialogContentText gutterBottom>
                          심사상태를 변경하면 수정이 불가 합니다.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          sx={{ color: 'white' }}
                          fullWidth
                          onClick={onClickDeny}
                        >
                          계약심사반려
                        </Button>
                      </DialogActions>
                    </Dialog>

                    {/* 계약승인 */}
                    <Dialog
                      open={openPrompt === '계약승인'}
                      fullWidth
                      maxWidth={'md'}
                    >
                      <DialogTitle>계약 승인 및 계약일 입력</DialogTitle>
                      <IconButton
                        onClick={handleClosePrompt}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 12,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <DialogContent>
                        <Stack>
                          <form onSubmit={handleSubmit(onClickApprove)}>
                            <Stack
                              direction={'row'}
                              gap={3}
                              alignItems="center"
                            >
                              <InputLabel sx={{ width: 300 }}>
                                계약 시작일
                              </InputLabel>
                              <LocalizationProvider
                                adapterLocale={koLocale}
                                dateAdapter={AdapterDateFns}
                              >
                                <Controller
                                  name="startDate"
                                  control={control}
                                  render={({ field }) => (
                                    <DatePicker
                                      label="시작 날짜"
                                      inputFormat="yyyy-MM-dd"
                                      {...field}
                                      renderInput={param => (
                                        <TextField
                                          fullWidth
                                          size="small"
                                          required
                                          {...param}
                                        />
                                      )}
                                    />
                                  )}
                                />
                                <InputLabel sx={{ width: 300 }}>
                                  계약 종료일
                                </InputLabel>
                                <Controller
                                  name="endDate"
                                  control={control}
                                  render={({ field }) => (
                                    <DatePicker
                                      {...field}
                                      label="종료 날짜"
                                      inputFormat="yyyy-MM-dd"
                                      renderInput={param => (
                                        <TextField
                                          fullWidth
                                          size="small"
                                          required
                                          {...param}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Stack>
                            {/* 결제 타입 */}
                            {/* <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={8}
                              mt={2}
                            >
                              <FormLabel>결제 타입</FormLabel>
                              <FormControl>
                                <Controller
                                  name="payType"
                                  control={control}
                                  rules={{
                                    required: "결제 타입은 필수값 입니다."
                                  }}
                                  render={({ field: { value, onChange } }) => (
                                    <RadioGroup
                                      row
                                      value={value}
                                      onChange={onChange}
                                    >
                                      <FormControlLabel
                                        value="1"
                                        control={<Radio />}
                                        label="인증"
                                      />
                                      <FormControlLabel
                                        value="2"
                                        control={<Radio />}
                                        label="비인증"
                                      />
                                      <FormControlLabel
                                        value="3"
                                        control={<Radio />}
                                        label="구독"
                                      />
                                    </RadioGroup>
                                  )}
                                />
                              </FormControl>
                            </Stack> */}
                          </form>
                        </Stack>
                        <Typography
                          variant={'subtitle2'}
                          mt={2}
                          color={'error'}
                        >
                          * 심사상태를 변경하면 수정이 불가 합니다.
                        </Typography>
                      </DialogContent>

                      <DialogActions>
                        <Button
                          variant="contained"
                          sx={{ color: 'white' }}
                          fullWidth
                          onClick={onClickApprove}
                        >
                          계약승인
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : (
                  <></>
                )}
              </Stack>
            </DialogContent>
            {/* 어드민 수정폼 ======================================================================================== */}
            {data?.content.status === '가맹심사중' ? (
              <MerchantsAdminEdit
                merchantId={merchantId}
                closeModal={closeModal}
              />
            ) : (
              <></>
            )}
            {/* 어드민 계약날짜 입력폼 ==================================== */}
            {/* {data.content.status === "계약승인" && !data.content.endDate ? (
              <MerchantContractDate
                merchantId={merchantId}
                closeModal={closeModal}
              />
            ) : (
              <></>
            )} */}
          </>
        ) : (
          <Stack
            justifyContent={'center'}
            alignItems="center"
            sx={{ height: 900 }}
          >
            <Typography>데이터를 조회 중입니다.</Typography>
          </Stack>
        )}
      </Dialog>
    </>
  );
};

export default MerchantsDetailModal;
