import CloseIcon from "@mui/icons-material/Close";
import LoginIcon from "@mui/icons-material/Login";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getRSA, updatePassword } from "../../api/Password";
import LoadingButton from "../../components/LoadingButton";
import PasswordStrength from "../../components/PasswordStrength";
import { encrypting } from "../../libs/encryping-password";
import passwordStrengthCheck from "../../libs/password-strength-check";
import isModalOpenAtom from "../../recoil/isModalOpen";

interface ChangePasswordForm {
  password: string;
  passwordCheck: string;
}

interface CancelPaymentModalProps {
  open: boolean;
  onClose: () => void;
}

const ChangePwModal = ({ open, onClose }: CancelPaymentModalProps) => {
  const navigate = useNavigate();
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  const [isHide, setIsHide] = useState(true);
  const [isHidePasswordCheck, setIsHidePasswordCheck] = useState(true);

  const handleHideButton = (inputName: string) => {
    if (inputName === "password") setIsHide((prevValue) => !prevValue);
    if (inputName === "passwordCheck")
      setIsHidePasswordCheck((prevValue) => !prevValue);
  };

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { dirtyFields: registerDirtyField }
  } = useForm<ChangePasswordForm>({
    defaultValues: {
      password: "",
      passwordCheck: ""
    }
  });

  // ===================================================================================================================
  // 리액트 쿼리 - 비밀번호 변경
  // ===================================================================================================================
  const { mutate, isLoading } = useMutation(updatePassword, {
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    },
    onSuccess: (data) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message:
          "비밀번호가 변경되었습니다. 변경된 비밀번호로 다시 로그인해주세요."
      });
      sessionStorage.removeItem("loginInfo");
      navigate("/login", { replace: true });
    }
  });

  const { isFetching: isRSAFeching, refetch } = useQuery(
    ["rsa", "register"],
    getRSA,
    {
      enabled: false,
      onSuccess: (res) => {
        const { password } = getValues();
        const encryptedPassword = encrypting({
          password,
          publicKey: res?.content?.public_key
        });
        if (!encryptedPassword) return;
        mutate({
          encryptedPassword,
          publicKey: res?.content?.public_key
        });
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  const onVaild = (data: ChangePasswordForm) => {
    if (isLoading || isRSAFeching) return;
    refetch();
  };

  const onInValid = (errors: FieldErrors<ChangePasswordForm>) => {
    const { password, passwordCheck } = errors;
    const errorMessage = password?.message || passwordCheck?.message;
    setIsModalOpen({
      value: true,
      position: "top",
      alertSeverity: "error",
      message: errorMessage!
    });
  };

  const passwordStrengthStatus = passwordStrengthCheck(watch("password"));

  return (
    <>
      <Dialog open={open} fullWidth maxWidth={"xs"}>
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          비밀번호 변경
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onVaild, onInValid)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="비밀번호"
                  required
                  fullWidth
                  type={isHide ? "password" : "text"}
                  autoComplete="on"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleHideButton("password")}
                        >
                          {isHide ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  {...register("password", {
                    required: "비밀번호를 입력해주세요.",
                    minLength: {
                      value: 8,
                      message: "비밀번호는 8자 이상이어야 합니다."
                    },
                    validate: (value) => {
                      const checkResult = passwordStrengthCheck(value);
                      return (
                        checkResult === "성공" ||
                        "사용할 수 없는 비밀번호 입니다."
                      );
                    }
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="비밀번호 확인"
                  required
                  fullWidth
                  type={isHidePasswordCheck ? "password" : "text"}
                  autoComplete="on"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleHideButton("passwordCheck")}
                        >
                          {isHidePasswordCheck ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  {...register("passwordCheck", {
                    required: "비밀번호 확인을 입력해주세요.",
                    validate: (value) => {
                      const { password } = getValues();
                      return (
                        password === value || "비밀번호가 일치하지 않습니다."
                      );
                    }
                  })}
                />
              </Grid>
            </Grid>
            <Stack>
              <PasswordStrength status={passwordStrengthStatus} />
            </Stack>
            <Box
              sx={{
                marginTop: 5
              }}
            >
              <LoadingButton
                icon={<LoginIcon />}
                fullWidth={true}
                size="large"
                loading={isLoading || isRSAFeching}
                disabled={
                  !registerDirtyField?.password ||
                  !registerDirtyField?.passwordCheck
                }
              >
                비밀번호 변경하기
              </LoadingButton>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ChangePwModal;
