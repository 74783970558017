import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useMutation, useQuery } from '@tanstack/react-query';
import koLocale from 'date-fns/locale/ko';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import AdminLayout from '../../../components/Layout/Admin/Layout';
import ModalTriggerButton from '../../../components/PopupTriggerButton';
import MuiTable from '../../../components/Table/MuiTable';
import Title from '../../../components/Title';
import useRenewal from '../../../hooks/useRenewal';
import { getDate } from '../../../libs/get-date';
import { getToday } from '../../../libs/get-today';
import isModalOpenAtom from '../../../recoil/isModalOpen';
import CreateSettlementModal from './components/CreateSettlementModal';
import {
  getUnSettledItems,
  postUnSettledItems,
  putUnSettlementStatus,
} from './libs';
import { UnSettledItemData, UnSettledItemParams } from './libs/types';
import { subYears } from 'date-fns';
import MenuItem from '@mui/material/MenuItem';
import { queryClient } from '../../..';
import Pagination from '@mui/material/Pagination';
import { useMerchantNames } from '../../../hooks/useMerchantNames';
import SearchControls from '../../../components/Common/SearchControls';

interface FormInputs extends UnSettledItemParams {
  isActive: string;
  isSettled: string;
}

interface SettlementColumnHeadCell {
  id: keyof UnSettledItemData['items'][number];
  label: string;
  isTotalData: boolean;
  isSort: boolean;
}

const columns: SettlementColumnHeadCell[] = [
  {
    id: 'transactionDate',
    label: '거래일자(주단위)',
    isTotalData: true,
    isSort: true,
  },
  {
    id: 'mallId',
    label: '가맹점 ID',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'isActive',
    label: '활성 상태',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'mallName',
    label: '가맹점명',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'cardCompany',
    label: '카드사명',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'amount',
    label: '거래금액',
    isTotalData: true,
    isSort: false,
  },
  {
    id: 'approvalNum',
    label: '승인번호',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'cardFee',
    label: '카드사 수수료',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'optatumFee',
    label: '옵타움 수수료',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'merchantFee',
    label: '가맹점 수수료',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'vat',
    label: '부가세',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'totalFee',
    label: '총 수수료',
    isTotalData: false,
    isSort: false,
  },
  {
    id: 'payoutAmount',
    label: '정산 금액',
    isTotalData: false,
    isSort: false,
  },
];

const getOneYearAgo = () => {
  const today = new Date();
  return getDate(subYears(today, 1));
};

const defaultFormValues = {
  startDate: getOneYearAgo(),
  endDate: getToday(),
  mallId: '',
  mallName: null,
  isSettled: '2',
  isActive: 'null',
  page: 0,
  pageSize: 10,
};

const UnsettledPayments = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [settlementData, setSettlementData] =
    useState<FormInputs>(defaultFormValues);
  const [selected, setSelected] = useState<UnSettledItemData['items']>([]);

  // 가맹점명 입력값 스테이트
  const [searchTerm, setSearchTerm] = useState('');
  const { search } = useLocation();

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  // form 기본 값
  const { register, handleSubmit, control, reset, setValue } =
    useForm<FormInputs>({
      defaultValues: {
        ...defaultFormValues,
      },
    });
  // 폼 초기화
  const handleReset = () => {
    reset(defaultFormValues);
  };

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 현재 운영중인 가맹점 목록 조회
  const { uniqueMerchantsNames, isMerchantsLoading } = useMerchantNames();

  const handleInputChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  // 미정산 내역 조회
  const { isLoading, data, refetch } = useQuery(
    ['/settlement-items', settlementData],
    () => getUnSettledItems(settlementData),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setSelected([]);
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
      select: response => {
        const items = response.content?.items || [];
        const totalItemsAmount = response.content?.totalItemsAmount || {};

        const mappedItems = items.map(item => ({
          ...item,
          isActive: item.isActive ? '활성' : '비활성',
        }));

        return {
          items: mappedItems,
          totalItemsAmount,
          pageable: response.pageable,
        };
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'error',
          message: error?.response?.data?.message,
        });
      },
    },
  );

  // 정산 추가
  const { mutate, isLoading: isPayoutSettlementLoading } = useMutation(
    postUnSettledItems,
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'error',
          message: error?.response?.data?.message,
        });
      },
      onSuccess: () => {
        refetch();
        setSelected([]);
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'success',
          message: '정산 추가가 완료되었습니다.',
        });
      },
    },
  );

  // 정산 활성 상태 변경
  const { mutate: mutateStatus } = useMutation(putUnSettlementStatus, {
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: error?.response?.data?.message,
      });
    },
    onSuccess: () => {
      refetch();
      setSelected([]);
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'success',
        message: '정산 활성 상태가 변경되었습니다.',
      });
      queryClient.invalidateQueries([`/settlement-items`]);
    },
  });

  useEffect(() => {
    if (search !== '') {
      const receivedDate = search.split('=')[1];
      setSettlementData(prev => ({
        ...prev,
        startDate: receivedDate,
        endDate: receivedDate,
      }));
      setValue('startDate', receivedDate);
      setValue('endDate', receivedDate);
    }
  }, [search, setValue]);

  // ===================================================================================================================
  // 조회 & 정산 추가
  // ===================================================================================================================
  // 미정산 페이지 조회
  const handleForm = (data: FormInputs) => {
    const isInvalidData =
      data.startDate?.includes('NaN') || data.endDate?.includes('NaN');
    const isInvaildPeriod = data.startDate > data.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '날짜를 확인해주세요.',
      });
      return;
    }
    if (isLoading) return;
    setSettlementData(prevValue => ({
      ...prevValue,
      ...data,
    }));
  };

  // 정산 추가
  const handlePayoutSettlements = (payoutDate: string) => {
    if (isPayoutSettlementLoading) return;
    const params = {
      payoutDate,
      unSettleIdList: selected?.map(item => item.id),
    };
    mutate(params);
  };

  // 활성 상태 및 비활성 상태 확인
  const allRowsAreActive = selected?.every(item => item.isActive === '활성');
  const allRowsAreInactive = selected?.every(
    item => item.isActive === '비활성',
  );

  // 버튼 활성화 여부
  const isButtonDisabled =
    selected?.length === 0 || (!allRowsAreActive && !allRowsAreInactive);

  const handleUpdateSettlementStatus = () => {
    const updatedStatus = allRowsAreActive ? false : true; // 활성 -> 비활성 or 비활성 -> 활성
    const params = selected?.map(item => ({
      id: item.id,
      isActive: updatedStatus,
    }));

    mutateStatus(params);
  };

  // 버튼 활성화 여부를 결정하는 변수 수정
  const isSettlementPayout =
    selected?.length === 0 || // 선택된 row가 없거나
    (!allRowsAreActive && !allRowsAreInactive) ||
    allRowsAreInactive; // 활성/비활성 상태가 혼합된 경우

  // ===================================================================================================================
  // 페이징
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setSettlementData((prevValue: any) => ({ ...prevValue, page: page - 1 }));
  };

  return (
    <>
      <Title title="미정산" />
      <AdminLayout>
        <Stack spacing={3}>
          <Paper
            sx={{
              p: 3,
              border: '1px solid #F2F3F5',
              borderRadius: 3,
              overflow: 'hidden',
            }}
          >
            <form onSubmit={handleSubmit(handleForm)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={1.5}>
                  <LocalizationProvider
                    adapterLocale={koLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label="시작 날짜"
                          inputFormat="yyyy-MM-dd"
                          value={value}
                          onChange={value => onChange(getDate(value))}
                          renderInput={param => (
                            <TextField
                              fullWidth
                              size="small"
                              required
                              {...param}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={1.5}>
                  <LocalizationProvider
                    adapterLocale={koLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label="종료 날짜"
                          inputFormat="yyyy-MM-dd"
                          value={value}
                          onChange={value => onChange(getDate(value))}
                          renderInput={param => (
                            <TextField
                              fullWidth
                              size="small"
                              required
                              {...param}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={1.5}>
                  <Controller
                    name="mallName"
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <Autocomplete
                        {...field}
                        id="mallName"
                        value={value}
                        autoSelect
                        autoComplete
                        options={uniqueMerchantsNames}
                        onChange={(_, newValue) => onChange(newValue)}
                        loading={isMerchantsLoading}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="가맹점명"
                            fullWidth
                            size="small"
                            // onChange={handleInputChange}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={1.5}>
                  <TextField
                    id="input-mallId"
                    label="가맹점 ID"
                    fullWidth
                    size="small"
                    {...register('mallId')}
                  />
                </Grid>
                <Grid item xs={12} md={1.5}>
                  <Controller
                    name="isActive"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="정산 활성 상태"
                        required
                        fullWidth
                        size="small"
                        select
                        value={value || 'null'}
                        onChange={onChange}
                      >
                        <MenuItem value={'null'}>전체</MenuItem>
                        <MenuItem value={'1'}>활성</MenuItem>
                        <MenuItem value={'2'}>비활성</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4.5}>
                  <SearchControls loading={isLoading} onReset={handleReset} />
                </Grid>
              </Grid>
            </form>
          </Paper>

          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={'end'}
              mb={1}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                미정산 내역 상세 조회
              </Typography>
              <Stack direction="row" spacing={2}>
                {/* 미정산 거래 숨기기 */}
                <Button
                  variant="outlined"
                  disabled={isButtonDisabled}
                  onClick={handleUpdateSettlementStatus}
                >
                  {allRowsAreActive ? '비활성화' : '활성화'}
                </Button>

                {/* 미정산 추가 */}
                <ModalTriggerButton
                  disabled={isSettlementPayout}
                  modalType="charge"
                  modal={
                    <CreateSettlementModal
                      onButtonSubmit={handlePayoutSettlements}
                      loading={isPayoutSettlementLoading}
                    />
                  }
                >
                  정산 생성
                </ModalTriggerButton>
              </Stack>
            </Stack>

            <Paper
              sx={{
                border: '1px solid #F2F3F5',
                borderRadius: 3,
                overflow: 'hidden',
              }}
            >
              {/* 정산 내역 상세 조회 테이블 */}
              <MuiTable
                rows={data?.items}
                columns={columns}
                id="id"
                selectedValues={selected}
                setRowSelection={setSelected}
                totalData={false}
                // sortingTarget={"datePeriod"}
                //sortDirection={settlementData.sort}
              />

              <Box my={2}>
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  page={settlementData.page + 1}
                  onChange={handlePage}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                />
              </Box>
            </Paper>
          </Box>
        </Stack>
      </AdminLayout>
    </>
  );
};

export default UnsettledPayments;
