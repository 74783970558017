import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../../../../..";
import ModalTriggerButton from "../../../../../components/PopupTriggerButton";
import MuiTable from "../../../../../components/Table/MuiTable";
import useRenewal from "../../../../../hooks/useRenewal";
import excelDownload from "../../../../../libs/excel-download";
import isModalOpenAtom from "../../../../../recoil/isModalOpen";
import { deleteSettlementV2Items, getSettlementsV2Detail } from "../libs";
import {
  SettlementDetailData,
  SettlementsData,
  SettlementsDetailParams
} from "../libs/types.js";
import SettlementItemsModal from "./SettlementItemsModal";

interface SettlementUnitColumnHeadCell {
  id: string;
  label: string;
  isTotalData: boolean;
}

interface SettlementUnitModalProps {
  selectedData: SettlementsData;
  closeModal?: () => void;
  isConfirm: string;
}

const unitColumns: SettlementUnitColumnHeadCell[] = [
  {
    id: "mallId",
    label: "가맹점 ID",
    isTotalData: false
  },
  {
    id: "mallName",
    label: "가맹점명",
    isTotalData: false
  },
  {
    id: "transactionDate",
    label: "거래 일자",
    isTotalData: true
  },
  {
    id: "approvalNum",
    label: "승인 번호",
    isTotalData: false
  },
  {
    id: "cardCompany",
    label: "카드사",
    isTotalData: false
  },
  {
    id: "status",
    label: "거래 상태",
    isTotalData: false
  },
  {
    id: "paymentKey",
    label: "거래 고유KEY",
    isTotalData: false
  },
  {
    id: "amount",
    label: "거래 금액",
    isTotalData: true
  },
  {
    id: "purchaseAmount",
    label: "매입 금액",
    isTotalData: true
  },
  {
    id: "cardFee",
    label: "카드사 수수료",
    isTotalData: true
  },
  {
    id: "optatumFee",
    label: "옵타움 수수료",
    isTotalData: true
  },
  {
    id: "merchantFee",
    label: "가맹점 수수료",
    isTotalData: true
  },
  {
    id: "vat",
    label: "부가세",
    isTotalData: true
  },
  {
    id: "totalFee",
    label: "총 수수료",
    isTotalData: true
  },
  {
    id: "payoutAmount",
    label: "정산 금액",
    isTotalData: true
  }
];

const SettlementUnitModal = ({
  selectedData,
  closeModal,
  isConfirm
}: SettlementUnitModalProps) => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();

  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [selected, setSelected] = useState<SettlementDetailData["items"]>([]);

  // 파라미터 스테이트
  const [params, setParams] = useState<SettlementsDetailParams>({
    id: selectedData.id,
    isExcel: false,
    page: 0,
    pageSize: 10,
    sort: "ASC"
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 정산 개별 내역 조회
  const { data, isLoading } = useQuery(
    [`/v3/settlements/${selectedData.id}/settlement-items`, params],
    () => getSettlementsV2Detail(params),
    {
      keepPreviousData: true,
      onSuccess: () => {
        setSelected([]);
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // 정산내역 데이터 제외
  const { mutate } = useMutation(deleteSettlementV2Items, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "정산제외가 완료되었습니다."
      });
      queryClient.invalidateQueries([
        `/v3/settlements/${selectedData.id}/settlement-items`
      ]);
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  const onClose = () => {
    queryClient.invalidateQueries([`/v3/settlements`]);
    queryClient.invalidateQueries([`/v3/settlements/total`]);
    closeModal?.();
  };

  // ===================================================================================================================
  // 정산 제거
  // ===================================================================================================================
  const onDeleteSettlementItems = () => {
    const formattedItems = selected.map((item) => ({
      amount: item.amount,
      approvalNum: item.approvalNum,
      cardCompany: item.cardCompany,
      cardFee: item.cardFee,
      id: item.id,
      mallId: item.mallId,
      mallName: item.mallName,
      merchantFee: item.merchantFee,
      optatumFee: item.optatumFee,
      paymentKey: item.paymentKey,
      payoutAmount: item.payoutAmount,
      purchaseAmount: item.purchaseAmount,
      status: item.status,
      totalFee: item.totalFee,
      transactionDate: item.transactionDate,
      vat: item.vat
    }));

    mutate({ id: selectedData.id, settlementItems: formattedItems });
  };

  // ===================================================================================================================
  // 엑셀 다운로드
  // ===================================================================================================================
  // 엑셀 다운로드 버튼 핸들러
  const handleExcelDownload = async () => {
    try {
      const response = await getSettlementsV2Detail({
        ...params,
        isExcel: true
      });
      const list = response.content.items.map((data) => ({
        "가맹점 ID": data.mallId,
        가맹점명: data.mallName,
        "거래 일자": data.transactionDate,
        "승인 번호": data.approvalNum,
        카드사: data.cardCompany,
        "거래 상태": data.status,
        "거래 고유KEY": data.paymentKey,
        "거래 금액": data.amount,
        "매입 금액": data.purchaseAmount,
        "카드사 수수료": data.cardFee,
        "옵타움 수수료": data.optatumFee,
        "가맹점 수수료": data.merchantFee,
        부가세: data.vat,
        "총 수수료": data.totalFee,
        "정산 금액": data.payoutAmount
      }));

      // 상세 조회 테이블 아래 합계 row도 같이 저장
      const totalItems = response.content.totalItemsAmount;
      const totalList = {
        "가맹점 ID": "합계",
        가맹점명: "-",
        "거래 일자": "-",
        "승인 번호": "-",
        카드사: "-",
        "거래 상태": "-",
        "거래 고유KEY": "-",
        "거래 금액": totalItems.amount,
        "매입 금액": totalItems.purchaseAmount,
        "카드사 수수료": totalItems.cardFee,
        "옵타움 수수료": totalItems.optatumFee,
        "가맹점 수수료": totalItems.merchantFee,
        부가세: totalItems.vat,
        "총 수수료": totalItems.totalFee,
        "정산 금액": totalItems.payoutAmount
      };

      // totalList를 데이터에 추가
      list.push(totalList);

      excelDownload({
        list,
        sheetName: `정산 개별 내역_${selectedData.mallName}`,
        fileName: `정산개별내역_${selectedData.mallName}.xlsx`
      });

      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "다운로드를 시작합니다."
      });
    } catch (error: any) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message || "엑셀 다운로드 실패"
      });
    }
  };

  // ===================================================================================================================
  // 페이지네이션
  // ===================================================================================================================
  // 총 페이지 수
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;

  // 페이지 변경 함수
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev) => ({ ...prev, page: page - 1 }));
  };

  return (
    <Dialog open={true} maxWidth="xl" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        {selectedData.payoutDate} 개별 내역 조회
        <Stack direction={"row"} gap={2}>
          {/* 정산 상태가 `미확정`인 경우에만 보이기 */}
          {isConfirm === "미확정" && (
            <>
              <ModalTriggerButton
                size="small"
                modalType="charge"
                modal={
                  <SettlementItemsModal
                    settlementId={selectedData.id}
                    mallId={selectedData.mallId}
                  />
                }
                color="success"
              >
                미정산 내역 확인
              </ModalTriggerButton>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onDeleteSettlementItems}
              >
                정산 제외
              </Button>
            </>
          )}

          <MuiLoadingButton
            color="primary"
            // loading={isFetching}
            onClick={handleExcelDownload}
            variant="outlined"
          >
            엑셀 다운로드
          </MuiLoadingButton>

          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Box>
          <MuiTable
            rows={data?.content.items}
            columns={unitColumns}
            id="id"
            selectedValues={selected}
            setRowSelection={setSelected}
            redlineKey="status"
            redlineValue={["취소", "부분취소"]}
            totalData={data?.content.totalItemsAmount}
          />
        </Box>
        <Box mt={2}>
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            page={params.page + 1}
            onChange={changePage}
            sx={{ display: "flex", justifyContent: "center" }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SettlementUnitModal;
