import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { styled } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getMerchantRegistrationTemp } from "../../api/MerchantRegistration";
import {
  MerchantRegistrationTemp,
  StepData
} from "../../api/MerchantRegistration/types";
import { CommonApi } from "../../api/type";
import ClientLayout from "../../components/Layout/Client/Layout";
import Bridge from "../../components/MerchantRegistration/Bridge";
import Completion from "../../components/MerchantRegistration/Completion";
import MerchantInformations from "../../components/MerchantRegistration/MerchantInformations";
import TermsAndConditionsPage from "../../components/MerchantRegistration/TermsAndConditionsPage";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";

export interface UserInformation {
  hasMerchant: boolean;
  role: string;
}

const initData = {
  currentStep: 1,
  isUse: false,
  // 스텝 2 checkBox
  isCheckedAddress: false,
  // 스텝 6 - 정산 담당 checkBox
  isChecked: false,
  // 스텝 6 - 세금 담당 checkBox
  isCheckedTax: false,
  // 스텝 3 - 테스트 아이디
  isCheckedTestId: false,
  // 스텝 3 - 테스트 비밀번호
  isCheckedTestPW: false,

  // undefined 를 스프레드 하면 에러가 발생하기 때문에,
  // 빈배열을 초기값으로 세팅한다
  merchantOwnerInformation: [
    {
      name: "",
      nameEn: "",
      birth: "",
      nationality: "대한민국",
      postCode: "",
      address: "",
      detailAddress: "",
      phone: ""
    }
  ],
  merchantInChargeInformation: [
    {
      name: "",
      role: "계약",
      email: "",
      phone: ""
    },
    {
      name: "",
      role: "정산",
      email: "",
      phone: ""
    },
    {
      name: "",
      role: "계산서",
      email: "",
      phone: ""
    }
  ]
};

export const ErrorMessage = styled("span")({
  color: "#d94452",
  marginLeft: "10px",
  fontSize: "12px"
});

const MerchantRegistration = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  const [tempData, setTempData] = useState<StepData>(initData);

  const dataChangeHandler = (stepData: any) => {
    setTempData((prev) => {
      const updatedStepData = {
        // commom
        currentStep: stepData.calculatedStep,
        isChecked: stepData.isChecked ?? prev.isChecked,
        isCheckedAddress: stepData.isCheckedAddress ?? prev.isCheckedAddress,
        isCheckedTax: stepData.isCheckedTax ?? prev.isCheckedTax,
        isCheckedTestId: stepData.isCheckedTestId ?? prev.isCheckedTestId,
        isCheckedTestPW: stepData.isCheckedTestPW ?? prev.isCheckedTestPW,

        // step2
        mallName: stepData.mallName ?? prev.mallName,
        mallNameEn: stepData.mallNameEn ?? prev.mallNameEn,
        merchantInformation: {
          businessType:
            stepData.businessType ?? prev.merchantInformation?.businessType,
          businessName:
            stepData.businessName ?? prev.merchantInformation?.businessName,
          registrationNumber:
            stepData.registrationNumber ??
            prev.merchantInformation?.registrationNumber,
          corporationNumber:
            stepData.corporationNumber ??
            prev.merchantInformation?.corporationNumber,
          postCode: stepData.postCode ?? prev.merchantInformation?.postCode,
          mallAddress:
            stepData.mallAddress ?? prev.merchantInformation?.mallAddress,
          detailAddress:
            stepData.detailAddress ?? prev.merchantInformation?.detailAddress,
          headOfficePostCode:
            stepData.headOfficePostCode ??
            prev.merchantInformation?.headOfficePostCode,
          headOfficeAddress:
            stepData.headOfficeAddress ??
            prev.merchantInformation?.headOfficeAddress,
          headOfficeDetailAddress:
            stepData.headOfficeDetailAddress ??
            prev.merchantInformation?.headOfficeDetailAddress,
          phone: stepData.phone ?? prev.merchantInformation?.phone,
          fax: stepData.fax ?? prev.merchantInformation?.fax,

          // step3
          merchantCategory:
            stepData.merchantCategory ??
            prev.merchantInformation?.merchantCategory,
          businessConditions:
            stepData.businessConditions ??
            prev.merchantInformation?.businessConditions,
          mailOrderSalesNumber:
            stepData.mailOrderSalesNumber ??
            prev.merchantInformation?.mailOrderSalesNumber,
          introductionCompany:
            stepData.introductionCompany ??
            prev.merchantInformation?.introductionCompany,
          testId: stepData.testId ?? prev.merchantInformation?.testId,
          testPassword:
            stepData.testPassword ?? prev.merchantInformation?.testPassword
        },
        // step3
        mallUrl: stepData.mallUrl ?? prev.mallUrl,
        mallIp: stepData.mallIp ?? prev.mallIp,

        // step4
        merchantBankInformation: {
          bankName: stepData.bankName ?? prev.merchantBankInformation?.bankName,
          bankCode: stepData.bankCode ?? prev.merchantBankInformation?.bankCode,
          accountCode:
            stepData.accountCode ?? prev.merchantBankInformation?.accountCode,
          accountHolder:
            stepData.accountHolder ??
            prev.merchantBankInformation?.accountHolder
        },

        // step5
        merchantOwnerInformation: [
          ...(stepData.merchantOwnerInformation ??
            prev.merchantOwnerInformation)
        ],

        // step6
        merchantInChargeInformation: [
          ...(stepData.merchantInChargeInformation ??
            prev.merchantInChargeInformation)
        ]
      };
      return updatedStepData;
    });
  };

  // 스텝이동
  const onClickNextButton = (stepData: any) => {
    dataChangeHandler({
      ...stepData,
      calculatedStep: stepData.currentStep + 1
    });
  };

  // 임시 저장 한것이 있는지 조회
  const { data, refetch, isLoading } = useQuery(
    ["/merchant/temporary"],
    () => getMerchantRegistrationTemp("CLIENT"),
    {
      // refetchOnWindowFocus는 데이터가 stale 상태일 경우
      // 윈도우 포커싱 될 때 마다 refetch를 실행하는 옵션
      refetchOnWindowFocus: false,
      // retry는 실패한 쿼리를 재시도하는 옵션이다.
      // false로 설정하면 재시도를 하지 않는다.
      retry: false,
      onSuccess: (result: CommonApi<MerchantRegistrationTemp>) => {
        if (result.content?.id) {
          const parsedObject = JSON.parse(result.content.jsonString);
          setTempData((prev) => {
            return {
              ...parsedObject,
              id: result.content.id,
              isUse: result.content.isUse,
              userId: result.content.userId,
              currentStep: 0
            };
          });
        } else {
          setTempData(initData);
        }
        if (!isRenewalTokenLoading) {
          mutateRenewalToken();
        }
      },
      onError: (error) => {
        setTempData((prev) => {
          return initData;
        });
      }
    }
  );

  // 조회 결과가 있으면, setTempData 에 결과를 넣는다
  // 조회 결과가 없으면, 브릿지 페이지를 보여준다
  const refresh = () => {
    refetch();
  };

  return (
    <>
      <Title title="가맹 등록" />
      <ClientLayout>
        {isLoading ? (
          <Backdrop open={isLoading} sx={{ background: " rgba(0, 0, 0, 0.2)" }}>
            <CircularProgress
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ml: 25
              }}
            />
          </Backdrop>
        ) : (
          <>
            {/* 브릿지 페이지 */}
            {tempData.currentStep === 0 && (
              <Bridge
                nextEvent={onClickNextButton}
                tempDataProp={tempData}
                refreshEvent={refresh}
              />
            )}
            {/* 약관 동의 */}
            {tempData.currentStep === 1 && (
              <TermsAndConditionsPage nextEvent={onClickNextButton} />
            )}
            {/* 기본 정보 */}
            {tempData.currentStep === 2 && (
              <MerchantInformations
                tempData={tempData}
                nextEvent={onClickNextButton}
              />
            )}
            {/* 완료 페이지 */}
            {tempData.currentStep === 3 && <Completion />}
          </>
        )}
      </ClientLayout>
    </>
  );
};

export default MerchantRegistration;
