import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import React, { Fragment, useState } from "react";
import { getCardLimitTotals } from "../../api/CardLimitManagement";
import ModifyCardLimitModal from "../../components/CardLimitManagement/ModifyCardLimitModal";
import AdminLayout from "../../components/Layout/Admin/Layout";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";
import { addCommas } from "../../libs/thousands-commas";

interface Columns {
  id: string;
  label: string;
}

const columns: Columns[] = [
  { id: "arrowIcon", label: "" },
  { id: "id", label: "ID" },
  { id: "cardName", label: "카드사명" },
  { id: "limitAmount", label: "한도금액 (원)" },
  { id: "usedAmount", label: "사용금액 (원)" },
  { id: "useAmountRatio", label: "사용금액(%)" },
  { id: "modifyLimit", label: "한도수정" }
];

const CardLimitManagement = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  // userId 스테이트
  const [selectedId, setSelectedId] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);

  // 아코디언 스테이트
  const [expandedRowId, setExpandedRowId] = useState<number | null>(null);
  const [params, setParams] = useState({
    email: "",
    isUse: "",
    page: 0,
    size: 0,
    sort: "ASC",
    hasMerchant: false
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 카드사 전체 한도 목록 조회
  const { data, isLoading } = useQuery(
    ["/card-limit-manejernt"],
    () => getCardLimitTotals(),
    {
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );

  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev) => ({ ...prev, page: page - 1 }));
  };

  // ===================================================================================================================
  // 모달창 열기
  // ===================================================================================================================
  const handleRowClick = async (cardLimitTotalId: number) => {
    setSelectedId(cardLimitTotalId);
    setIsOpen(true);
  };

  // ===================================================================================================================
  // 아코디언 토글
  // ===================================================================================================================
  const toggleRow = (cardLimitTotalId: number) => {
    const row = data?.content.find(
      (row) => row.cardLimitTotalId === cardLimitTotalId
    );
    if (
      row &&
      row.cardLimitTotalReservations &&
      row.cardLimitTotalReservations.length > 0
    ) {
      setExpandedRowId((prev) =>
        prev === cardLimitTotalId ? null : cardLimitTotalId
      );
    }
  };

  return (
    <>
      <Title title="전체 카드 한도 관리" />
      <AdminLayout>
        <Typography sx={{ fontWeight: "bold", mb: 1 }}>
          전체 카드 한도 관리
        </Typography>
        <Paper
          sx={{
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden"
          }}
        >
          <TableContainer sx={{ maxHeight: 650 }}>
            <Table
              stickyHeader
              sx={{
                ...((!data || data?.content?.length === 0) && {
                  height: "auto"
                })
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      align="center"
                      sx={{ background: "#FBFBFB" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.content.length === 0 ? (
                  <TableRow>
                    <TableCell sx={{ height: 500 }} colSpan={7}>
                      <Typography align="center">
                        조회된 데이터가 없습니다.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {!data &&
                      isLoading &&
                      [1, 2, 3, 4, 5, 6].map((index) => (
                        <TableRow key={index} sx={{ cursor: "pointer" }}>
                          {[
                            { id: "arrowIcon" },
                            { id: "id" },
                            { id: "cardName" },
                            { id: "limitAmount" },
                            { id: "usedAmount" },
                            { id: "useAmountRatio" },
                            { id: "modifyLimit" }
                          ].map((value) => {
                            return (
                              <TableCell
                                component="th"
                                align="center"
                                key={value.id}
                              >
                                <Skeleton
                                  variant={"rectangular"}
                                  width={"100%"}
                                  height={30}
                                ></Skeleton>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}

                    {/* row 데이터 -------------------------------------------------------------------------------- */}
                    {data &&
                      data.content.length > 0 &&
                      data.content.map((row) => (
                        <Fragment key={row.cardLimitTotalId}>
                          <TableRow
                            onClick={() => toggleRow(row.cardLimitTotalId)}
                          >
                            <TableCell component="th" align="center">
                              <IconButton size="small">
                                {expandedRowId === row.cardLimitTotalId ||
                                row.cardLimitTotalReservations.length === 0 ? (
                                  <KeyboardArrowUpRoundedIcon />
                                ) : (
                                  <KeyboardArrowDownRoundedIcon color="error" />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell component="th" align="center">
                              {row.cardLimitTotalId}
                            </TableCell>
                            <TableCell align="center">
                              {row.cardCompanyName}
                            </TableCell>
                            <TableCell align="center">
                              {addCommas(row.limitAmount)}
                            </TableCell>
                            <TableCell align="center">
                              {addCommas(row.useAmount)}
                            </TableCell>
                            <TableCell align="center">
                              {row.useAmountRatio}%
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                variant="outlined"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRowClick(row.cardLimitTotalId);
                                }}
                              >
                                수정하기
                              </Button>
                            </TableCell>
                          </TableRow>

                          {/* 아코디언 row -------------------------------------------------------------------------*/}
                          {row.cardLimitTotalReservations.length > 0 && (
                            <TableRow>
                              <TableCell
                                colSpan={columns.length}
                                sx={{
                                  pb: 0,
                                  pt: 0,
                                  backgroundColor: "#fbfbfb",
                                  border: "none"
                                }}
                              >
                                <Collapse
                                  in={expandedRowId === row.cardLimitTotalId}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Table sx={{ minWidth: 650 }}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          align="center"
                                          sx={{ width: 132 }}
                                        />
                                        <TableCell
                                          align="center"
                                          sx={{ width: 91 }}
                                        />
                                        <TableCell
                                          align="center"
                                          sx={{
                                            color: "error.main",
                                            width: 214
                                          }}
                                        >
                                          한도 수정 예정 내용
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: 243 }}
                                        >
                                          한도금액 (원)
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: 243 }}
                                        >
                                          적용 예정일
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: 219 }}
                                        />
                                        <TableCell
                                          align="center"
                                          sx={{ width: 244 }}
                                        />
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {row.cardLimitTotalReservations
                                        .slice()
                                        .sort(
                                          (a, b) =>
                                            Number(
                                              new Date(a.reservationDate)
                                            ) -
                                            Number(new Date(b.reservationDate))
                                        )
                                        .map((r, index) => (
                                          <TableRow
                                            key={index}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                { border: 0 }
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                            />
                                            <TableCell align="center" />
                                            <TableCell align="center" />
                                            <TableCell align="center">
                                              {addCommas(r.limitAmount)}
                                            </TableCell>
                                            <TableCell align="center">
                                              {r.reservationDate}
                                            </TableCell>
                                            <TableCell align="center" />
                                            <TableCell align="center" />
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </Fragment>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* 카드 한도 수정 모달창 ---------------------------------------------------------------------------------*/}
          <ModifyCardLimitModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            selectedId={selectedId}
          />
          {/* 페이지네이션 -----------------------------------------------------------------------------------------*/}
          <Box mt={2} mb={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={params.page + 1}
              onChange={changePage}
              sx={{ display: "flex", justifyContent: "center" }}
            ></Pagination>
          </Box>
        </Paper>
      </AdminLayout>
    </>
  );
};
export default CardLimitManagement;
