import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import isModalOpen from "../../../../../recoil/isModalOpen/atom";
import { queryClient } from "../../../../..";
import { SettlementUpdateParams } from "../libs/types";
import { confirmSettlementV2 } from "../libs";

interface Props {
  payoutDate: string;
  isConfirm: string;
  closeModal?: () => void;
  settlementId: number;
}
const SettlementConfirmModal = ({
  payoutDate,
  isConfirm,
  closeModal,
  settlementId
}: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { register, handleSubmit, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      documentNumber: null,
      id: settlementId,
      payoutDate: payoutDate,
      note: "",
      isConfirm: true,
      isComplete: null
    }
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { mutate } = useMutation(confirmSettlementV2, {
    onSuccess: () => {
      queryClient.invalidateQueries(["/v3/settlements"]);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "정산 상태가 변경되었습니다."
      });
      closeModal?.();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // 정산 확정 메모 & 정산 확정
  const onSubmit = (formData: SettlementUpdateParams) => {
    const params = [{ ...formData }];
    mutate(params);
  };

  // 정산 취소 메모 & 정산 취소
  const cancelSettlement = (formData: SettlementUpdateParams) => {
    const params = [
      {
        ...formData,
        isConfirm: false
      }
    ];
    mutate(params);
  };

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>정산 상태 변경</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Typography sx={{ mb: 1.5 }}>
              정산 상태를 변경하시겠습니까?
            </Typography>
            <TextField label="비고" {...register("note")} fullWidth multiline />
          </DialogContent>
          <DialogActions sx={{ mx: 2, mb: 1 }}>
            <Button
              variant="outlined"
              type="button"
              fullWidth
              disabled={isConfirm === "미확정"}
              onClick={() => cancelSettlement(getValues())}
            >
              정산 확정 취소
            </Button>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ color: "white" }}
              disabled={isConfirm === "확정"}
            >
              정산 확정
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export default SettlementConfirmModal;
