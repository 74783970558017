import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { postCancelPayment } from "../../api/v2/Payments";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { queryClient } from "../..";
import { HelperTextMessage } from "../../pages/components/HelperTextMessage";

interface PaymentData {
  cardCompanyName: string;
  cardNumber: string;
  buyerName: string;
  createdAt: string;
  orderName: string;
  installmentMonth: string;
  approvalNum: string;
  taxAmount: string;
  vat: string;
  price: string;
  mallName: string;
  mallId: string;
  orderId: string;
  paymentKey: string;
}

interface CancelPaymentModalProps {
  closeModal?: () => void;
  payment: PaymentData;
  onClosePaymentView: () => void;
  // isOpen: boolean;
  // onClose: () => void;
}

const CancelPaymentModal = ({
  // isOpen,
  // onClose,
  closeModal,
  payment,
  onClosePaymentView
}: CancelPaymentModalProps) => {
  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      cancelReason: ""
    }
  });

  // 모달이 닫힐 때 필드 초기화
  // useEffect(() => {
  //   if (!isOpen) {
  //     reset();
  //   }
  // }, [isOpen, reset]);

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 결제 취소
  const { mutate, isLoading } = useMutation(postCancelPayment, {
    onSuccess: (data) => {
      const { status, message } = data.content;
      // 성공 시 토스트 알림
      if (status === 200) {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: message
        });
        queryClient.invalidateQueries(["/payments/detail"]);
        // 결제 취소 모달창 닫기
        closeModal?.();
        // 영수증 모달창 닫기
        onClosePaymentView();
      } else {
        // 실패 또는 경고 메시지가 있을 경우
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: message
        });
      }
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 결제 취소
  // ===================================================================================================================
  const onSubmit = () => {
    if (isLoading) return;
    const params = {
      orderId: payment.orderId,
      price: payment.price,
      paymentKey: payment.paymentKey,
      cancelReason: getValues("cancelReason")
    };
    mutate(params);
  };

  return (
    <>
      <Dialog open={true} fullWidth maxWidth={"xs"}>
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          결제 취소
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack pt={3} gap={3}>
              <TextField
                id="cancelReason"
                multiline
                placeholder="사유를 입력해주세요."
                {...register("cancelReason", {
                  required: "사유는 필수값 입니다.",
                  maxLength: {
                    value: 50,
                    message: "사유는 50자 이하로 입력하세요."
                  }
                })}
                helperText={
                  <HelperTextMessage>
                    {errors.cancelReason?.message}
                  </HelperTextMessage>
                }
                error={!!errors.cancelReason}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{ color: "white" }}
                disabled={!isValid}
              >
                결제 취소
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CancelPaymentModal;
