import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { format, subDays } from 'date-fns';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { queryClient } from '../..';
import { getBusinessCategories } from '../../api/BusinessCategory';
import { getMerchants } from '../../api/Merchants';
import { AdminMerchantsParams } from '../../api/Merchants/types';
import AdminLayout from '../../components/Layout/Admin/Layout';
import MerchantMemoModal from '../../components/Merchants/MerchantMemoModal';
import MerchantsDetailModal from '../../components/Merchants/MerchantsDetailModal';
import Title from '../../components/Title';
import useRenewal from '../../hooks/useRenewal';
import { CONTRACT_EXTENSION_STATUSES } from '../../api/ContractManagement/constants';
import { useMerchantNames } from '../../hooks/useMerchantNames';
import { DateParams, MallParams, SearchParams } from '../../types/search-params';
import SearchControls from '../../components/Common/SearchControls';

interface Columns {
  id: string;
  label: string;
}

const columns: Columns[] = [
  { id: 'mallId', label: '가맹점ID' },
  { id: 'mallName', label: '가맹점 명' },
  { id: 'businessName', label: '사업자 등록증 상호명' },
  { id: 'ownerName', label: '가맹점 대표자 명' },
  { id: 'phone', label: '가맹점 대표 번호' },
  { id: 'createAt', label: '가맹등록일' },
  { id: 'startDate', label: '계약 시작일' },
  { id: 'endDate', label: '계약 종료일' },
  { id: 'contractExtensionStatus ', label: '계약 상태' },
  { id: 'mccType', label: '결제 업종' },
  { id: 'registrant', label: '등록자' },
  { id: 'memo', label: '메모' },
];

interface FormInputs extends SearchParams<DateParams & MallParams> {
  contractExtensionStatus: (typeof CONTRACT_EXTENSION_STATUSES)[number];
  registrationNumber: string;
  registrant?: 'CLIENT' | 'ADMIN' | string;
  mccType?: '일반' | '학원' | '병원' | '문화' | '비영리기부금' | '비영리강의료' | '정기' | string;
}

const ContractManagement = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // params 스테이트
  const [params, setParams] = useState<AdminMerchantsParams>({
    mallId: '',
    mallName: '',
    status: '계약승인',
    page: 0,
    size: 10,
  });

  // 선택된 row 데이터 스테이트
  const [selectedData, setSelectedData] = useState<any>({
    setIsModalOpen: false,
    merchantId: '',
    mallId: '',
    mallName: '',
    ownerName: '',
    phone: '',
    createdAt: '',
    startDate: '',
    endDate: '',
  });

  // 메모 모달 상태
  const [memoModal, setMemoModal] = useState<{
    isOpen: boolean;
    memo: string;
    merchantId: string;
  }>({
    isOpen: false,
    memo: '',
    merchantId: '',
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { register, handleSubmit, reset, control } = useForm<FormInputs>({
    defaultValues: {
      ...params,
    },
  });

  // row 선택
  const selectRow = (row: any) => {
    setSelectedData({ ...row, setIsModalOpen: true });
  };

  // 모달창 닫기
  const closeModal = () => {
    setSelectedData({
      setIsModalOpen: false,
      merchantId: '',
      mallId: '',
      mallName: '',
      ownerName: '',
      phone: '',
      createdAt: '',
      startDate: '',
      endDate: '',
    });
  };

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // "계약 승인"된 사용자 리스트 조회
  const { data, isLoading } = useQuery(['/merchants', params], () => getMerchants(params), {
    onSuccess: () => {
      if (isRenewalTokenLoading) return;
      mutateRenewalToken();
      !!selectedData.merchantId &&
        queryClient.invalidateQueries({
          queryKey: [`/merchants/${selectedData.merchantId}`],
        });
    },
    select: response => {
      return {
        ...response,
        content: response.content.map((row: any) => ({
          ...row,
          endDate: row.endDate ? format(subDays(new Date(row.endDate), 1), 'yyyy-MM-dd') : '',
        })),
      };
    },
  });

  /*********************************************************************************************************************
   * 업종 조회
   ********************************************************************************************************************/
  const { data: businessCategories } = useQuery(['/businessCategories'], getBusinessCategories, {});

  // 현재 운영중인 가맹점 목록 조회
  const { uniqueMerchantsNames } = useMerchantNames();

  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages ? data?.pageable?.totalPages : 0;
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev: any) => ({ ...prev, page: page - 1 }));
  };

  // ===================================================================================================================
  // 가맹점 신청 현황 조회 및 초기화
  // ===================================================================================================================
  // 조회
  const handleForm = (formData: FormInputs) => {
    if (isLoading) return;
    setParams((prevValue: any) => ({
      ...prevValue,
      page: 0,
      mallName: formData.mallName,
      mallId: formData.mallId,
      registrant: formData.registrant === '전체' ? '' : formData.registrant,
      mccType: formData.mccType === '전체' ? '' : formData.mccType,
      contractExtensionStatus:
        formData.contractExtensionStatus === '전체' ? '' : formData.contractExtensionStatus,
    }));
  };

  // 초기화
  const handleReset = () => {
    reset();
  };

  // 메모 모달 열기
  const openMemoModal = (memo: string, merchantId: string) => {
    setMemoModal({ isOpen: true, memo, merchantId });
  };

  // 메모 모달 닫기
  const closeMemoModal = () => {
    setMemoModal(prev => ({
      isOpen: false,
      memo: prev.memo,
      merchantId: prev.merchantId,
    }));
  };

  return (
    <>
      <Title title="계약 관리" />
      <AdminLayout>
        <Paper
          elevation={0}
          sx={{
            p: 3,
            border: '1px solid #F2F3F5',
            borderRadius: 3,
            mb: 3,
          }}
        >
          <form onSubmit={handleSubmit(handleForm)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <TextField
                  id="mallId"
                  label="가맹점 ID"
                  fullWidth
                  size="small"
                  {...register('mallId')}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="mallName"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Autocomplete
                      {...field}
                      id="input-mallName"
                      value={value}
                      freeSolo
                      autoSelect
                      autoComplete
                      options={uniqueMerchantsNames}
                      onChange={(_, newValue) => onChange(newValue)}
                      renderInput={params => (
                        <TextField {...params} label="가맹점명" fullWidth size="small" />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="registrant"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="등록자"
                      size="small"
                      fullWidth
                      onChange={onChange}
                      value={value || '전체'}
                    >
                      <MenuItem value={'전체'}>전체</MenuItem>
                      <MenuItem value={'ADMIN'}>ADMIN</MenuItem>
                      <MenuItem value={'CLIENT'}>CLIENT</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="mccType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="업종"
                      size="small"
                      fullWidth
                      onChange={onChange}
                      value={value || '전체'}
                    >
                      <MenuItem value={'전체'}>전체</MenuItem>
                      {businessCategories?.content.map(category => (
                        <MenuItem value={category} key={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="contractExtensionStatus"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="계약 상태"
                      size="small"
                      fullWidth
                      onChange={onChange}
                      value={value || CONTRACT_EXTENSION_STATUSES[0]}
                    >
                      {CONTRACT_EXTENSION_STATUSES.map(category => (
                        <MenuItem value={category} key={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <SearchControls onReset={handleReset} />
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
          계약 관리 목록
        </Typography>
        <Paper
          sx={{
            border: '1px solid #F2F3F5',
            borderRadius: 3,
            overflow: 'hidden',
          }}
        >
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                ...((!data || data?.content?.length === 0) && {
                  height: 550,
                }),
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      align="center"
                      sx={{ background: '#FBFBFB' }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.content.length === 0 && !isLoading ? (
                  <TableRow>
                    <TableCell sx={{ height: 500 }} colSpan={columns.length}>
                      <Typography align="center">조회된 데이터가 없습니다.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {!data &&
                      isLoading &&
                      [...Array(10)].map((_, index) => (
                        <TableRow key={index} hover={true} sx={{ cursor: 'pointer' }}>
                          {columns.map(column => (
                            <TableCell component="th" align="center" key={column.id}>
                              <Skeleton variant={'rounded'} width={'100%'} height={30}></Skeleton>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    {data &&
                      data.content.length > 0 &&
                      data.content.map(row => (
                        <TableRow
                          key={row.merchantId}
                          hover={true}
                          onClick={() => selectRow(row)}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell component="th" align="center">
                            {row.mallId}
                          </TableCell>
                          <TableCell align="center">{row.mallName}</TableCell>
                          <TableCell align="center">{row.businessName}</TableCell>
                          <TableCell align="center">{row.ownerName}</TableCell>
                          <TableCell align="center">{row.phone}</TableCell>
                          <TableCell align="center">{row.createdAt.split(' ')[0]}</TableCell>
                          <TableCell align="center">{row.startDate}</TableCell>
                          <TableCell align="center">{row.endDate}</TableCell>
                          <TableCell align="center">{row.contractExtensionStatus}</TableCell>
                          <TableCell align="center">{row.mccType}</TableCell>
                          <TableCell align="center">{row.registrant}</TableCell>
                          <TableCell component="th" align="center" sx={{ py: 0.8 }}>
                            <IconButton
                              color={!!row.memo ? 'primary' : 'default'}
                              onClick={e => {
                                e.stopPropagation();
                                openMemoModal(row.memo, row.merchantId);
                              }}
                            >
                              <CommentOutlinedIcon
                              // onClick={(e) => {
                              //   e.stopPropagation();
                              //   openMemoModal(row.memo, row.merchantId);
                              // }}
                              // sx={{ cursor: "pointer" }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    {data && (
                      <MerchantsDetailModal
                        isOpen={selectedData.setIsModalOpen}
                        closeModal={closeModal}
                        merchantId={selectedData.merchantId}
                      />
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box my={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={params.page + 1}
              onChange={changePage}
              sx={{ display: 'flex', justifyContent: 'center' }}
            ></Pagination>
          </Box>
        </Paper>
      </AdminLayout>

      {/* 메모 모달창 */}
      <MerchantMemoModal
        isOpen={memoModal.isOpen}
        closeModal={closeMemoModal}
        // closeModal={closeMemoModal}
        memo={memoModal.memo}
        merchantId={memoModal.merchantId}
      />
    </>
  );
};
export default ContractManagement;
