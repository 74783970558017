import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getMerchantCardLimit } from '../../api/CardLimitManagement';
import { MerchantCardLimitParams } from '../../api/CardLimitManagement/types';
import { getMerchantNames } from '../../api/v2/Payments';
import ModifyMerchantCardLimitModal from '../../components/CardLimitManagement/ModifyMerchantCardLimitModal';
import RegistCardLimitModal from '../../components/CardLimitManagement/RegistCardLimitModal';
import AdminLayout from '../../components/Layout/Admin/Layout';
import Title from '../../components/Title';
import useRenewal from '../../hooks/useRenewal';
import { addCommas } from '../../libs/thousands-commas';
import SearchControls from '../../components/Common/SearchControls';

interface Columns {
  id: string;
  label: string;
}

const columns: Columns[] = [
  { id: 'arrowIcon', label: '' },
  { id: 'optatumRegistrationNumber', label: '카드사 가맹번호' },
  { id: 'cardCompanyName', label: '카드사' },
  { id: 'grade', label: '등급' },
  { id: 'mccType', label: '업종' },
  { id: 'lumpsumInstallmentGubun', label: '일시불/할부' },
  { id: 'limitAmount', label: '한도금액(원)' },
  { id: 'useAmount', label: '사용금액' },
  { id: 'useAmountRatio', label: '사용금액(%)' },
  { id: 'modifyLimit', label: '한도수정' },
];

const MerchantCardLimit = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [selectedRowId, setSelectedRowId] = useState<number>(0);
  const [registModalOpen, setRegistModalOpen] = useState(false);
  const [modifyModalOpen, setModifyModalOpen] = useState(false);

  // 아코디언 스테이트
  const [expandedRowId, setExpandedRowId] = useState<number | null>(null);

  // 파라미터 스테이트
  const [params, setParams] = useState<MerchantCardLimitParams>({
    cardCompanyName: '',
    lumpsumInstallmentGubun: '',
    optatumRegistrationNumber: '',
    page: 0,
    pageSize: 50,
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { handleSubmit, register, control, reset } =
    useForm<MerchantCardLimitParams>({
      defaultValues: {
        ...params,
      },
    });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 카드사 가맹점별 한도 목록 조회
  const { data, isLoading } = useQuery(
    ['/merchant-card-limit', params],
    () => getMerchantCardLimit(params),
    {
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
    },
  );

  // 현재 운영중인 가맹점 목록 조회
  const { data: getMerchantsNameData } = useQuery(['/merchants/names'], () =>
    getMerchantNames(),
  );

  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams(prev => ({ ...prev, page: page - 1 }));
  };

  // ===================================================================================================================
  // 조회 및 초기화
  // ===================================================================================================================
  // 초기화
  const handleReset = () => {
    reset();
  };

  const onSubmit = (formData: MerchantCardLimitParams) => {
    setParams((prev: any) => ({
      ...prev,
      ...formData,
      cardCompanyName:
        formData.cardCompanyName === '전체' ? '' : formData.cardCompanyName,
      lumpsumInstallmentGubun:
        formData.lumpsumInstallmentGubun === '전체'
          ? ''
          : formData.lumpsumInstallmentGubun,
    }));
  };

  // ===================================================================================================================
  // 모달창 열기
  // ===================================================================================================================
  const handleRowClick = async (cardLimitGradeId: number) => {
    setSelectedRowId(cardLimitGradeId);
    setModifyModalOpen(true);
  };

  // ===================================================================================================================
  // 아코디언 토글
  // ===================================================================================================================
  const toggleRow = (cardLimitGradeId: number) => {
    const row = data?.content.find(
      row => row.cardLimitGradeId === cardLimitGradeId,
    );
    if (
      row &&
      row.cardLimitGradeReservations &&
      row.cardLimitGradeReservations.length > 0
    ) {
      setExpandedRowId(prev =>
        prev === cardLimitGradeId ? null : cardLimitGradeId,
      );
    }
  };

  return (
    <>
      <Title title="가맹점별 한도 관리" />
      <AdminLayout>
        <Stack spacing={3}>
          {/* 조회 필터 ---------------------------------------------------------------------------------------------*/}
          <Paper
            sx={{
              p: 3,
              border: '1px solid #F2F3F5',
              borderRadius: 3,
              overflow: 'hidden',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="optatumRegistrationNumber"
                    label="카드사 가맹번호"
                    fullWidth
                    size="small"
                    {...register('optatumRegistrationNumber')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="cardCompanyName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="cardCompanyName"
                        label="카드사"
                        fullWidth
                        size="small"
                        select
                        value={value || '전체'}
                        onChange={onChange}
                      >
                        <MenuItem value={'전체'}>전체</MenuItem>
                        <MenuItem value={'삼성카드'}>삼성카드</MenuItem>
                        <MenuItem value={'하나카드'}>하나카드</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="lumpsumInstallmentGubun"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="lumpsumInstallmentGubun"
                        label="일시불/할부"
                        fullWidth
                        size="small"
                        select
                        value={value || '전체'}
                        onChange={onChange}
                      >
                        <MenuItem value={'전체'}>전체</MenuItem>
                        <MenuItem value={'일시불'}>일시불</MenuItem>
                        <MenuItem value={'할부'}>할부</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <SearchControls onReset={handleReset} />
                </Grid>
              </Grid>
            </form>
          </Paper>

          <Box>
            {/* 한도 등록 버튼 ---------------------------------------------------------------------------------------*/}
            <Stack
              direction={'row'}
              alignItems={'end'}
              justifyContent={'space-between'}
              mb={1}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                가맹점별 한도 관리
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setRegistModalOpen(true)}
              >
                한도 등록
              </Button>
            </Stack>
            {/* 테이블 ----------------------------------------------------------------------------------------------*/}
            <Paper
              sx={{
                border: '1px solid #F2F3F5',
                borderRadius: 3,
                overflow: 'hidden',
              }}
            >
              <TableContainer
                sx={{
                  maxHeight: 550,
                  minHeight: 550,
                }}
              >
                <Table
                  stickyHeader
                  sx={{
                    ...((!data || data?.content?.length === 0) && {
                      height: 550,
                    }),
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column: any) => (
                        <TableCell
                          variant="head"
                          key={column.id}
                          align="center"
                          sx={{ backgroundColor: '#FBFBFB' }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.content.length === 0 ? (
                      <TableRow>
                        <TableCell sx={{ height: 500 }} colSpan={10}>
                          <Typography align="center">
                            조회된 데이터가 없습니다.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {!data &&
                          isLoading &&
                          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(index => (
                            <TableRow
                              key={index}
                              hover={true}
                              sx={{ cursor: 'pointer' }}
                            >
                              {[
                                { id: 'arrowIcon' },
                                { id: 'optatumRegistrationNumber' },
                                { id: 'cardCompanyName' },
                                { id: 'grade' },
                                { id: 'mccType' },
                                { id: 'lumpsumInstallmentGubun' },
                                { id: 'limitAmount' },
                                { id: 'useAmount' },
                                { id: 'useAmountRatio' },
                                { id: 'modifyLimit' },
                              ].map(value => {
                                return (
                                  <TableCell
                                    component="th"
                                    align="center"
                                    key={value.id}
                                  >
                                    <Skeleton
                                      variant={'rectangular'}
                                      width={'100%'}
                                      height={30}
                                    ></Skeleton>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          ))}
                        {data &&
                          data.content.length > 0 &&
                          data.content.map(row => (
                            <Fragment key={row.cardLimitGradeId}>
                              <TableRow
                                onClick={() => toggleRow(row.cardLimitGradeId)}
                              >
                                <TableCell component="th" align="center">
                                  <IconButton size="small">
                                    {expandedRowId ===
                                      row.cardLimitMerchantId ||
                                    row.cardLimitGradeReservations.length ===
                                      0 ? (
                                      <KeyboardArrowUpRoundedIcon />
                                    ) : (
                                      <KeyboardArrowDownRoundedIcon color="error" />
                                    )}
                                  </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                  {row.optatumRegistrationNumber}
                                </TableCell>
                                <TableCell component="th" align="center">
                                  {row.cardCompanyName}
                                </TableCell>
                                <TableCell align="center">
                                  {row.grade}
                                </TableCell>
                                <TableCell align="center">
                                  {row.mccType}
                                </TableCell>
                                <TableCell align="center">
                                  {row.lumpsumInstallmentGubun}
                                </TableCell>
                                <TableCell align="center">
                                  {addCommas(row.limitAmount)}
                                </TableCell>
                                <TableCell align="center">
                                  {addCommas(row.useAmount)}
                                </TableCell>
                                <TableCell align="center">
                                  {row.useAmountRatio}%
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    variant="outlined"
                                    onClick={e => {
                                      e.stopPropagation();
                                      handleRowClick(row.cardLimitGradeId);
                                    }}
                                  >
                                    수정하기
                                  </Button>
                                </TableCell>
                              </TableRow>

                              {/* 아코디언 row -------------------------------------------------------------------------*/}
                              {row.cardLimitGradeReservations &&
                                row.cardLimitGradeReservations.length > 0 && (
                                  <TableRow>
                                    <TableCell
                                      colSpan={columns.length}
                                      sx={{
                                        pb: 0,
                                        pt: 0,
                                        bgcolor: '#f1f1f1',
                                        border: 'none',
                                      }}
                                    >
                                      <Collapse
                                        in={
                                          expandedRowId === row.cardLimitGradeId
                                        }
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Table sx={{ minWidth: 650 }}>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell
                                                align="center"
                                                sx={{ width: 123.58 }}
                                              />
                                              <TableCell
                                                align="center"
                                                sx={{ width: 165 }}
                                              />
                                              <TableCell
                                                align="center"
                                                sx={{
                                                  color: 'error.main',
                                                  width: 240,
                                                }}
                                              >
                                                한도 수정 예정 내용
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{ width: 217 }}
                                              >
                                                한도금액 (원)
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{ width: 210 }}
                                              >
                                                적용 예정일
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{ width: 210 }}
                                              />
                                              <TableCell
                                                align="center"
                                                sx={{ width: 203 }}
                                              />
                                              <TableCell
                                                align="center"
                                                sx={{ width: 227 }}
                                              />
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {row.cardLimitGradeReservations
                                              .slice()
                                              .sort(
                                                (a, b) =>
                                                  Number(
                                                    new Date(a.reservationDate),
                                                  ) -
                                                  Number(
                                                    new Date(b.reservationDate),
                                                  ),
                                              )
                                              .map((r, index) => (
                                                <TableRow
                                                  key={index}
                                                  sx={{
                                                    '&:last-child td, &:last-child th':
                                                      { border: 0 },
                                                  }}
                                                >
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                  />
                                                  <TableCell align="center" />
                                                  <TableCell align="center" />
                                                  <TableCell align="center">
                                                    {addCommas(r.limitAmount)}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    {r.reservationDate}
                                                  </TableCell>
                                                  <TableCell align="center" />
                                                  <TableCell align="center" />
                                                  <TableCell align="center" />
                                                </TableRow>
                                              ))}
                                          </TableBody>
                                        </Table>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                )}
                            </Fragment>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* 한도 등록 모달 -------------------------------------------------------------------------------------*/}
              {registModalOpen && getMerchantsNameData && (
                <RegistCardLimitModal
                  isOpen={registModalOpen}
                  onClose={() => setRegistModalOpen(false)}
                />
              )}

              {/* 가맹별 한도 예약 수정 모달 --------------------------------------------------------------------------*/}
              {modifyModalOpen && (
                <ModifyMerchantCardLimitModal
                  isOpen={modifyModalOpen}
                  onClose={() => setModifyModalOpen(false)}
                  selectedId={selectedRowId}
                />
              )}
              {/* 페이지네이션 ---------------------------------------------------------------------------------------*/}
              <Box my={2}>
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  page={params.page + 1}
                  onChange={changePage}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                ></Pagination>
              </Box>
            </Paper>
          </Box>
        </Stack>
      </AdminLayout>
    </>
  );
};
export default MerchantCardLimit;
