import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import AdminLayout from '../../../../components/Layout/Admin/Layout';
import ModalTriggerButton from '../../../../components/PopupTriggerButton';
import Title from '../../../../components/Title';
import useRenewal from '../../../../hooks/useRenewal';
import isModalOpen from '../../../../recoil/isModalOpen/atom';
import AddGroupModal from './components/AddGroupModal';
import DeleteGroupModal from './components/DeleteGroupModal';
import DetailGroupModal from './components/DetailGroupModal';
import ModifyGroupModal from './components/ModifyGroupModal';
import { getGroups } from './libs';
import { GetGroupListParmas, GroupListData } from './libs/types';
import SearchControls from '../../../../components/Common/SearchControls';

const GroupManagement = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  /*********************************************************************************************************************
   * 스테이트
   ********************************************************************************************************************/
  // 리코일 스테이트
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // 파라미터 스테이트
  const [params, setParams] = useState<GetGroupListParmas>({
    groupName: '',
    page: 0,
    pageSize: 9,
    sort: 'DESC',
  });

  const [selectedRowData, setSelectedRowData] = useState<GroupListData>();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false); // 상세 모달
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // 수정 모달
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // 삭제 모달

  /*********************************************************************************************************************
   * 리액트 훅 폼
   ********************************************************************************************************************/
  const { handleSubmit, register, reset } = useForm<GetGroupListParmas>({
    defaultValues: {
      groupName: params.groupName,
    },
  });

  /*********************************************************************************************************************
   * 필터 초기화
   ********************************************************************************************************************/
  const handleReset = () => {
    reset({
      groupName: '',
    });
    setParams(prev => ({
      ...prev,
      groupName: '',
    }));
  };

  /*********************************************************************************************************************
   * 필터 조회
   ********************************************************************************************************************/
  const onSubmit = (formData: GetGroupListParmas) => {
    setParams(prev => ({
      ...prev,
      groupName: formData.groupName,
    }));
  };

  /*********************************************************************************************************************
   * 리액트 쿼리
   ********************************************************************************************************************/
  // 그룹 관리 목록 조회
  const { data, isLoading } = useQuery(
    ['/v2/groups', params],
    () => getGroups(params),
    {
      onSuccess: data => {
        if (!data.content.length && params.page > 0) {
          // 현재 페이지에 데이터가 없으면 페이지를 1로 이동
          setParams(prev => ({ ...prev, page: 0 }));
        }
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'error',
          message: error?.response?.data?.message,
        });
      },
    },
  );

  /*********************************************************************************************************************
   * 페이징
   ********************************************************************************************************************/
  // 총 페이지 수
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;

  // 페이지 변경 함수
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams(prev => ({ ...prev, page: page - 1 }));
  };

  /*********************************************************************************************************************
   * 모달 열기, 닫기
   ********************************************************************************************************************/
  const handleModalOpen = (
    row: GroupListData,
    modalType: 'detail' | 'edit' | 'delete',
  ) => {
    setSelectedRowData(row);
    modalType === 'detail'
      ? setIsDetailModalOpen(true)
      : modalType === 'edit'
      ? setIsEditModalOpen(true)
      : setIsDeleteModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedRowData(undefined);
    setIsDetailModalOpen(false);
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <Title title={'그룹 관리'} />
      <AdminLayout>
        <Stack spacing={3}>
          {/* 필터 */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                border: '1px solid #F2F3F5',
                borderRadius: 3,
                overflow: 'hidden',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="그룹명"
                    fullWidth
                    size="small"
                    {...register('groupName')}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <SearchControls onReset={handleReset} />
                </Grid>
              </Grid>
            </Paper>
          </form>
          {/* 필터 끝 */}

          <Box>
            {/* 액션 영역 */}
            <Stack
              direction={'row'}
              alignItems={'end'}
              justifyContent={'space-between'}
              mb={1}
            >
              <Typography sx={{ fontWeight: 'bold' }}>그룹 관리</Typography>
              <ModalTriggerButton color="primary" modal={<AddGroupModal />}>
                그룹 추가
              </ModalTriggerButton>
            </Stack>

            {/* 카드 영역 시작 */}
            <Paper
              sx={{
                p: 3,
                border: '1px solid #F2F3F5',
                borderRadius: 3,
                overflow: 'hidden',
              }}
            >
              {data?.content.length === 0 ? (
                <>
                  <Stack
                    height={550}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Typography>조회된 데이터가 없습니다.</Typography>
                  </Stack>
                </>
              ) : (
                <>
                  {!data && isLoading && (
                    <Grid container spacing={3} sx={{ px: 3, mb: 3 }}>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(index => (
                        <Grid item xs={12} sm={4} key={index}>
                          <Box
                            sx={{
                              backgroundColor: '#f2f2f2',
                              p: 2,
                              borderRadius: 2,
                            }}
                          >
                            <Skeleton
                              variant="text"
                              width={'60%'}
                              height={30}
                            />
                            <Skeleton
                              variant="text"
                              width={'80%'}
                              height={30}
                            />
                            <Skeleton
                              variant="text"
                              width={'40%'}
                              height={30}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  {data && data.content.length > 0 && (
                    <Grid container spacing={3}>
                      {data.content.map(d => (
                        <Grid item xs={12} sm={4} key={d.id}>
                          <Stack
                            sx={{
                              backgroundColor: '#f7f7f7',
                              pl: 2,
                              pb: 2,
                              pr: 0.5,
                              pt: 1,
                              gap: 1,
                              cursor: 'pointer',
                              border: '1px solid #E4E4E7',
                              borderRadius: 2,
                            }}
                            onClick={() => handleModalOpen(d, 'detail')}
                          >
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <Typography
                                variant="subtitle2"
                                fontWeight={'bold'}
                              >
                                {d.createdAt.split('T')[0]}
                              </Typography>
                              <Stack direction={'row'} gap={1}>
                                <IconButton
                                  size="small"
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleModalOpen(d, 'delete');
                                  }}
                                >
                                  <DeleteRoundedIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleModalOpen(d, 'edit');
                                  }}
                                >
                                  <EditRoundedIcon />
                                </IconButton>
                              </Stack>
                            </Stack>

                            <Typography variant="body2">
                              그룹명: {d.groupName}
                            </Typography>
                            <Typography variant="body2">
                              그룹멤버수: {d.memberCnt}
                            </Typography>
                            <Typography variant="body2">
                              수정일: {d.updatedAt.split('T')[0]}
                            </Typography>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </>
              )}

              {/* 페이지네이션 */}
              <Box mt={3}>
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  page={params.page + 1}
                  onChange={changePage}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                ></Pagination>
              </Box>
            </Paper>
          </Box>
          {/* 카드 영역 끝 */}
        </Stack>

        {/* 모달 영역 */}
        {/* 그룹 추가 모달창 */}
        {selectedRowData && (
          <DetailGroupModal
            isOpen={isDetailModalOpen}
            onClose={handleModalClose}
            selectedData={selectedRowData}
          />
        )}

        {/* 수정 모달 */}
        <ModifyGroupModal
          isOpen={isEditModalOpen}
          onClose={handleModalClose}
          selectedData={selectedRowData}
        />

        {/* 삭제 모달 */}
        <DeleteGroupModal
          isOpen={isDeleteModalOpen}
          onClose={handleModalClose}
          selectedData={selectedRowData}
        />

        {/* 모달 영역 끝 */}
      </AdminLayout>
    </>
  );
};

export default GroupManagement;
