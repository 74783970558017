import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import { postMerchentContractInfo } from "../../api/ContractManagement";
import isModalOpen from "../../recoil/isModalOpen/atom";
import { HelperTextMessage } from "../../pages/components/HelperTextMessage";

interface Props {
  merchantId: any;
  closeModal?: () => void;
}

const CancelContractModal = ({ closeModal, merchantId }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트 & 컴포넌트 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // 사용자 계약 해지
  const { mutate } = useMutation(postMerchentContractInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "계약 해지가 완료되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, isDirty, errors }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      closeReason: "",
      dissatisfactionReason: "",
      grade: "",
      note: ""
    }
  });

  // 계약 연장 해지
  const onSubmit = (formData: any) => {
    const params = {
      ...formData,
      contractExtensionStatus: "해지",
      merchantId: merchantId
    };
    mutate(params);
    closeModal?.();
  };

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>사용자 계약 해지</DialogTitle>
        <IconButton
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Typography sx={{ mb: 3 }}>
              그동안 오직 페이와 함께해 주신 고객님,
              <br />
              다시 함께할 수 있기를 기다리겠습니다. 감사합니다.
            </Typography>
            <Typography component={"legend"} sx={{ mb: 1 }}>
              <span style={{ color: "#FF6634", fontWeight: "bold" }}>Q1 </span>
              서비스의 전반적인 만족도를 평가해주세요.
            </Typography>

            <Controller
              name="grade"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Rating
                  id="grade"
                  value={Number(value)}
                  onChange={onChange}
                  precision={0.5}
                  size="large"
                />
              )}
            />

            <Typography component={"legend"} sx={{ mt: 3, mb: 1 }}>
              <span style={{ color: "#FF6634", fontWeight: "bold" }}>Q2 </span>
              해지 사유 (택 1)
            </Typography>

            <Controller
              name="closeReason"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange } }) => (
                <RadioGroup
                  id="closeReason"
                  value={watch("closeReason")}
                  onChange={(e) => {
                    onChange(e);
                  }}
                >
                  <Stack>
                    <Stack direction={"row"}>
                      <FormControlLabel
                        value="service"
                        control={<Radio />}
                        label="서비스 불만족"
                      />
                      {watch("closeReason") === "service" && (
                        <Controller
                          name="dissatisfactionReason"
                          rules={{ required: true }}
                          control={control}
                          render={({ field: { onChange } }) => (
                            <TextField
                              id="dissatisfactionReason"
                              select
                              size="small"
                              sx={{ width: 200 }}
                              label="선택 사항"
                              value={watch("dissatisfactionReason")}
                              onChange={(e) => onChange(e)}
                            >
                              <MenuItem value={"fee"}>수수료</MenuItem>
                              <MenuItem value={"feedback"}>문의 대응</MenuItem>
                              <MenuItem value={"admin"}>전산</MenuItem>
                              <MenuItem value={"otherReason"}>
                                기타 (세부 내용 필수 입력)
                              </MenuItem>
                            </TextField>
                          )}
                        />
                      )}
                    </Stack>
                    <FormControlLabel
                      value="useOtherPg"
                      control={<Radio />}
                      label="타 PG사 이용 (세부 내용 필수 입력)"
                    />
                    <FormControlLabel
                      value="close"
                      control={<Radio />}
                      label="폐업"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="기타 (세부 내용 필수 입력)"
                    />
                  </Stack>
                </RadioGroup>
              )}
            />

            {(watch("closeReason") === "useOtherPg" ||
              watch("closeReason") === "other" ||
              watch("dissatisfactionReason") === "otherReason") && (
              <Controller
                name="note"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message: "유효한 사유를 입력해주세요."
                  },
                  maxLength: {
                    value: 50,
                    message: "50자 이하로 입력해 주세요."
                  }
                }}
                render={({ field }) => (
                  <TextField
                    id="note"
                    {...field}
                    label="기타"
                    fullWidth
                    multiline
                    maxRows={2}
                    helperText={
                      <HelperTextMessage>
                        {errors.note?.message}
                      </HelperTextMessage>
                    }
                    sx={{ mt: 1 }}
                  />
                )}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              sx={{ color: "white" }}
              fullWidth
              disabled={!isValid || !isDirty}
            >
              계약 해지
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CancelContractModal;
