import { instanceWithAuth } from "../../../../../api/Instance";
import { CommonApi } from "../../../../../api/type";
import {
  GetCardCompaniesResponse,
  UpdateMerchantUseStatusPayload,
  UpdateMerchantUseStatusResult
} from "./types";
import { QueryFunctionContext } from "@tanstack/react-query";

/**
 * 레유태 카드사별 거래 금액 조회
 * @description 범용사용을 위해 merchantId param 사용
 * @param merchantId
 */
export const getCardCompanies = async ({
  queryKey: [_, merchantId, startDateTime, endDateTime]
}: QueryFunctionContext) => {
  const url = `/v3/payments/${merchantId}/card-companies`;
  const { data } = await instanceWithAuth.get<
    CommonApi<GetCardCompaniesResponse>
  >(url, { params: { startDateTime, endDateTime } });

  return data;
};

/**
 * 가맹점 사용여부 업데이트
 * @param merchantId
 */
export const updateMerchantUseStatus = async ({
  merchantId
}: UpdateMerchantUseStatusPayload): Promise<
  CommonApi<UpdateMerchantUseStatusResult>
> => {
  const url = `/v3/merchants/${merchantId}/is-use`;
  const { data } = await instanceWithAuth.patch<
    CommonApi<UpdateMerchantUseStatusResult>
  >(url);

  return data;
};
