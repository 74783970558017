import CloseIcon from "@mui/icons-material/Close";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import {
  getAdminUsersList,
  postAdminMerchantTemp,
  postAdminMerchantV3
} from "../../api/AdminRegistUser";
import {
  getBankList,
  getBusinessNumber,
  merchantFileUpload
} from "../../api/MerchantRegistration";
import {
  AdminUsersListParams,
  Bank
} from "../../api/MerchantRegistration/types";
import { uploadFiles } from "../../libs/file-mapping";
import { FileUploadForm } from "../../libs/file-upload";
import { engValidate, numberValidate } from "../../libs/input-validation";
import { HelperTextMessage } from "../../pages/components/HelperTextMessage";
import isModalOpenAtom from "../../recoil/isModalOpen";
import RegistUserModal from "../ClientManagement/RegistUserModal";
import { Merchant } from "../ClientMerchant/ClientEditModal";
import { CountryList } from "../CountryList";
import Loading from "../Loadings/Loading";
import ModalTriggerButton from "../PopupTriggerButton";
import PostCode from "../PostCode";

interface RegisterForm extends Merchant {
  user: string;
}

interface Props {
  openModal: boolean;
  onClose: () => void;
}

const RegistMerechantModal = ({ openModal, onClose }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // 사업자 정보 주소 스테이트
  const [openPostCode, setOpenPostCode] = useState({
    isOpen: false,
    referenceIndex: 0
  });
  // 대표자 주소 스테이트
  const [openOwnerPostCode, setOpenOwnerPostCode] = useState({
    isOpen: false,
    referenceIndex: 0
  });

  // 주소 체크박스 스테이트
  const [isCheckedAddress, setIsCheckedAddress] = useState(false);

  // 종목 스테이트
  const [businessList, setBusinessList] = useState<string[]>([]);
  // 업태 스테이트
  const [businessStatus, setBusinesStatus] = useState<string[]>([]);

  // 체크박스 스테이트
  const [isCheckedTestId, setIsCheckedTestId] = useState(false);
  const [isCheckedTestPW, setIsCheckedTestPW] = useState(false);

  // 은행 정보 스테이트
  const [selectedBank, setSelectedBank] = useState<Bank>({
    code: "",
    name: ""
  });

  // 담당자 정보 체크박스 스테이트
  const [isChecked, setIsChecked] = useState(false);
  // 세금 계산 담당자 체크박스 스테이트
  const [isCheckedTax, setIsCheckedTax] = useState(false);

  // 파일 데이터 스테이트
  const [fileData, setFileData] = useState<FileUploadForm>({
    // 개인 사업자
    businessLicense: undefined,
    bankBookCopy: undefined,
    sealCertificate: undefined,
    사용인감계: undefined,
    idCardFront: undefined,
    idCardBack: undefined,
    gradeConfirmation: undefined,
    // 법인사업자
    corporateRegistration: undefined,
    actualOwnerConfirmation: undefined,
    corporateSealCertificate: undefined
  });

  // 선택된 사용자 userId 스테이트
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  // 파라미터 스테이트
  const [params, setParams] = useState<AdminUsersListParams>({
    page: "",
    size: "100",
    sort: "ASC"
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    setFocus,
    reset,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm<RegisterForm>({
    defaultValues: {
      // 사업자 정보
      businessType: "개인",
      businessName: "",
      mallName: "",
      mallNameEn: "",
      registrationNumber: "",
      corporationNumber: "",
      postCode: "",
      mallAddress: "",
      detailAddress: "",
      headOfficePostCode: "",
      headOfficeAddress: "",
      headOfficeDetailAddress: "",
      mallPhone: "",
      fax: "",
      merchantCategory: "",
      businessConditions: "",
      mailOrderSalesNumber: "",
      mallUrl: "",
      mallIp: "",
      introductionCompany: "",
      testId: "",
      testPassword: "",

      // 대표자 정보
      merchantOwnerInformation: [
        {
          address: "",
          birth: "",
          name: "",
          nameEn: "",
          nationality: "대한민국",
          phone: "",
          ownerPostCode: "",
          ownerAddress: "",
          ownerDetailAddress: ""
        }
      ],

      // 은행 정보
      bankName: "",
      accountCode: "",
      accountHolder: "",

      // 담당자 정보
      name: "",
      phone: "",
      email: "",
      managerName: "",
      managerPhone: "",
      managerEmail: "",
      taxName: "",
      taxPhone: "",
      taxEmail: "",

      // 파일 정보 - 개인사업자
      businessLicense: "",
      bankBookCopy: "",
      sealCertificate: "",
      idCardFront: "",
      idCardBack: "",
      gradeConfirmation: "",

      // 파일 정보 - 법인사업자
      corporateRegistration: "",
      actualOwnerConfirmation: "",
      corporateSealCertificate: "",
      사용인감계: ""
    },
    mode: "onChange"
  });

  const close = () => {
    reset();
    onClose();
  };

  // ===================================================================================================================
  // 대표자 양식 추가, 삭제
  // ===================================================================================================================
  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: "merchantOwnerInformation"
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 가맹점 임시 저장
  const { mutate, isLoading: mutateLoading } = useMutation(
    postAdminMerchantTemp,
    {
      onSuccess: () => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: "임시 저장이 완료 되었습니다."
        });
        queryClient.invalidateQueries(["/admin/temporaries"]);
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // 파일 업로드
  // `mutateAsync`는 Promise를 반환하는 비동기 함수다. `await`를 사용해 작업이 완료될 때 까지 기다릴 수 있다.
  const { mutateAsync: fileMutate, isLoading: fileLoading } = useMutation(
    merchantFileUpload,
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error.message
        });
      }
    }
  );

  // 가맹점 등록
  const { mutate: registMutate } = useMutation(postAdminMerchantV3, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "가맹등록 저장이 완료 되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error.response.data.message
      });
    }
  });

  // 은행 리스트 가져오기
  const { data: bankNameList } = useQuery(["getBankList"], () => getBankList());

  // 사업자 번호 검증
  const { refetch } = useQuery(
    [`/bypass/business-number/validate`, watch("registrationNumber")],
    () => getBusinessNumber(watch("registrationNumber")),
    {
      enabled: false,
      retry: false,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: "등록된 사업자 번호가 아닙니다."
        });
        setFocus("registrationNumber");
      }
    }
  );

  // 사용자 리스트 조회
  const { data, isLoading } = useQuery(
    ["/adminUsers/list", params],
    () => getAdminUsersList(params),
    {
      onSuccess: () => {},
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // 개인, 법인 구분
  const currentBusinessType = watch("businessType");

  // ===================================================================================================================
  // 주소 검색 모달창
  // ===================================================================================================================
  // 가맹점 주소 검색 모달창 열기
  const openPostCodeModal = (index: number) => {
    setOpenPostCode({
      isOpen: true,
      referenceIndex: index
    });
  };
  // 대표자 주소 검색 모달창 열기
  const openOwnerPostCodeModal = (index: number) => {
    setOpenOwnerPostCode({
      isOpen: true,
      referenceIndex: index
    });
  };

  // 검색된 주소값 가맹점 주소 인풋에 담기
  const postCodeHandler = (data: any, index?: number) => {
    if (index === 0) {
      setValue("postCode", data.zonecode);
      setValue("mallAddress", data.address);
      clearErrors("postCode");
      clearErrors("mallAddress");
    } else {
      setValue("headOfficePostCode", data.zonecode);
      setValue("headOfficeAddress", data.address);
    }
  };

  // 검색된 주소값 대표자 주소 인풋에 담기
  const ownerAddressHandler = (data: any, index?: number) => {
    if (index === undefined) return;
    setValue(`merchantOwnerInformation.${index}.ownerPostCode`, data.zonecode);
    setValue(`merchantOwnerInformation.${index}.ownerAddress`, data.address);
    clearErrors(`merchantOwnerInformation.${index}.ownerPostCode`);
    clearErrors(`merchantOwnerInformation.${index}.ownerAddress`);
  };

  // ===================================================================================================================
  // 체크박스 내용 붙여넣기
  // ===================================================================================================================
  // 주소 체크박스
  const checkBox = (e: any) => {
    setIsCheckedAddress(e.target.checked);
    if (e.target.checked) {
      const getPostCode = getValues("postCode");
      setValue("headOfficePostCode", getPostCode);
      const getAddress = getValues("mallAddress");
      setValue("headOfficeAddress", getAddress);
      const getDetailAddress = getValues("detailAddress");
      setValue("headOfficeDetailAddress", getDetailAddress);
    }
  };

  // 관리자 체크박스
  const managerCheckBox = (e: any) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      const getName = getValues("name");
      setValue("managerName", getName);
      clearErrors("managerName");
      const getEmail = getValues("email");
      setValue("managerEmail", getEmail);
      clearErrors("managerEmail");
      const getPhone = getValues("phone");
      setValue("managerPhone", getPhone);
      clearErrors("managerPhone");
    }
  };

  // 세금 담당자 체크박스
  const taxCheckBox = (e: any) => {
    setIsCheckedTax(e.target.checked);
    if (e.target.checked) {
      const getName = getValues("managerName");
      setValue("taxName", getName);
      clearErrors("taxName");
      const getEmail = getValues("managerEmail");
      setValue("taxEmail", getEmail);
      clearErrors("taxEmail");
      const getPhone = getValues("managerPhone");
      setValue("taxPhone", getPhone);
      clearErrors("taxPhone");
    }
  };

  // ===================================================================================================================
  // 종목
  // ===================================================================================================================
  // 종목 추가
  const addList = () => {
    if (businessList.length > 4) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "종목은 5개 이하로 작성해 주세요."
      });
      setValue("merchantCategory", "");
      return;
    }
    const businessValue = getValues("merchantCategory");
    if (businessValue.trim() === "") return;
    setBusinessList((prev) => [...prev, businessValue]);
    setValue("merchantCategory", "");
  };

  // 종목 삭제
  const deleteItem = (index: number) => {
    let temp = businessList.filter((item, i) => i !== index);
    setBusinessList(temp);
  };

  // 종목 keypress
  const handleOnKeyPressItem = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addList();
    }
  };

  // ===================================================================================================================
  // 업태
  // ===================================================================================================================
  // 업태 추가
  const addStatusList = () => {
    if (businessStatus.length > 4) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "업태는 5개 이하로 작성해 주세요."
      });
      setValue("merchantCategory", "");
      return;
    }
    const businessStatusValue = getValues("businessConditions");
    if (businessStatusValue.trim() === "") return;
    setBusinesStatus((prev) => [...prev, businessStatusValue]);
    setValue("businessConditions", "");
  };
  // 업태 삭제
  const deleteStatus = (index: number) => {
    let temp = businessStatus.filter((item, i) => i !== index);
    setBusinesStatus(temp);
  };
  // 업태 keypress
  const handleOnKeyPressStatus = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addStatusList();
    }
  };

  // ===================================================================================================================
  // 테스트용 ID, 비밀번호 체크박스
  // ===================================================================================================================
  const watchTestId = watch("testId");

  useEffect(() => {
    if (watchTestId !== "비회원 결제가능") {
      setIsCheckedTestId(false);
    } else {
      setIsCheckedTestId(true);
    }
  }, [watchTestId]);

  // 테스트용 아이디/비밀번호 체크박스 핸들러
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedTestId(event.target.checked);
    if (event.target.checked) {
      setValue("testId", "비회원 결제가능");
      setValue("testPassword", "비회원 결제가능");
      clearErrors("testId");
      clearErrors("testPassword");
    } else {
      setValue("testId", "");
      setValue("testPassword", "");
    }
  };

  // ===================================================================================================================
  // 은행 선택
  // - 이벤트에서 밸류(뱅크네임)를 가져온다
  // - 뱅크네임으로 뱅크 리스트에서 뱅크 오브젝트를 찾는다
  // - 뱅크 오브젝트를 스테이트에 할당한다
  // ===================================================================================================================
  const bankSelectHandler = (event: any, field: any) => {
    const selectedBankName = event.target.value;

    if (!bankNameList) return;

    const selectedBank = bankNameList.content.find(
      (db) => db.name === selectedBankName
    );

    if (!selectedBank) return;
    setSelectedBank(selectedBank);
    field.onChange(selectedBankName); // react-hook-form에 업데이트 알리기
  };

  // ===================================================================================================================
  // 파일 확장자 검사
  // ===================================================================================================================
  // 파일 확장자 검사
  const checkFileExtension = (fileName: string) => {
    const fileExtension = fileName.split(".").pop()?.toLowerCase();
    return ["jpg", "jpeg", "gif", "png", "pdf"].includes(fileExtension ?? "");
  };

  const fileValidation = useCallback(() => {
    const fileFields: { name: keyof FileUploadForm; label: string }[] = [
      { name: "businessLicense", label: "사업자등록증" },
      { name: "bankBookCopy", label: "통장사본" },
      { name: "sealCertificate", label: "대표자 인감증명서" },
      { name: "사용인감계", label: "사용인감계" },
      { name: "corporateRegistration", label: "등기사항전부증명서" },
      { name: "idCardFront", label: "신분증 앞면" },
      { name: "idCardBack", label: "신분증 뒷면" },
      { name: "gradeConfirmation", label: "영중소 등급확인서" },
      { name: "actualOwnerConfirmation", label: "실제소유자확인서" },
      { name: "corporateSealCertificate", label: "법인인감증명서" }
    ];

    let isError = false;

    fileFields.forEach(({ name, label }) => {
      const file = fileData[name];
      if (file && !checkFileExtension(file.name)) {
        setError(name, {
          type: "required",
          message: `${label} 첨부 파일 종류는 jpg, jpeg, gif, png, pdf 입니다.`
        });
        isError = true;
      }
    });

    return isError;
  }, [fileData, setError]);

  const [buttonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    const isError = fileValidation();
    setButtonDisable(isError);
  }, [fileData, fileValidation]);

  // ===================================================================================================================
  // 파일 업로드
  // ===================================================================================================================
  const 파일선택체크 = (
    inputName:
      | "businessLicense"
      | "bankBookCopy"
      | "sealCertificate"
      | "corporateRegistration"
      | "idCardFront"
      | "idCardBack"
      | "actualOwnerConfirmation"
      | "corporateSealCertificate"
      | "gradeConfirmation"
      | "사용인감계"
  ) => {
    const selectedFileName = getValues(inputName);
    if (selectedFileName === "") {
      return false;
    } else {
      return true;
    }
  };
  const 파일선택텍스트 = (
    inputName:
      | "businessLicense"
      | "bankBookCopy"
      | "sealCertificate"
      | "corporateRegistration"
      | "idCardFront"
      | "idCardBack"
      | "actualOwnerConfirmation"
      | "corporateSealCertificate"
      | "gradeConfirmation"
      | "사용인감계"
  ) => {
    if (파일선택체크(inputName)) {
      return "파일 수정";
    } else {
      return "파일 선택";
    }
  };
  const 사업자등록증 = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("businessLicense", name);
    setFileData((prev) => {
      return { ...prev, businessLicense: file };
    });
    if (errors.businessLicense) {
      clearErrors("businessLicense");
    }
  };
  const 통장사본 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("bankBookCopy", name);
    setFileData((prev) => {
      return { ...prev, bankBookCopy: file };
    });
    if (errors.bankBookCopy) {
      clearErrors("bankBookCopy");
    }
  };
  const 인감증명서 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("sealCertificate", name);
    setFileData((prev) => {
      return { ...prev, sealCertificate: file };
    });
    if (errors.sealCertificate) {
      clearErrors("sealCertificate");
    }
  };
  const 등기사항전부증명서 = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("corporateRegistration", name);
    setFileData((prev) => {
      return { ...prev, corporateRegistration: file };
    });
    if (errors.corporateRegistration) {
      clearErrors("corporateRegistration");
    }
  };
  const 신분증앞 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("idCardFront", name);
    setFileData((prev) => {
      return { ...prev, idCardFront: file };
    });
    if (errors.idCardFront) {
      clearErrors("idCardFront");
    }
  };
  const 신분증뒤 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("idCardBack", name);
    setFileData((prev) => {
      return { ...prev, idCardBack: file };
    });
    if (errors.idCardBack) {
      clearErrors("idCardBack");
    }
  };
  const 실제소유자확인서 = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("actualOwnerConfirmation", name);
    setFileData((prev) => {
      return { ...prev, actualOwnerConfirmation: file };
    });
    if (errors.actualOwnerConfirmation) {
      clearErrors("actualOwnerConfirmation");
    }
  };
  const 법인인감증명서 = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("corporateSealCertificate", name);
    setFileData((prev) => {
      return { ...prev, corporateSealCertificate: file };
    });
    if (errors.corporateSealCertificate) {
      clearErrors("corporateSealCertificate");
    }
  };
  const 등급확인서 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("gradeConfirmation", name);
    if (errors.gradeConfirmation) {
      clearErrors("gradeConfirmation");
    }
  };
  const 사용인감계 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("사용인감계", name);
    setFileData((prev) => {
      return { ...prev, 사용인감계: file };
    });
    if (errors.사용인감계) {
      clearErrors("사용인감계");
    }
  };

  // ===================================================================================================================
  // 사용자 임시 저장
  // ===================================================================================================================
  const onTempSave = () => {
    const formData = { ...getValues() };

    // 선택한 사용자 데이터
    const selectedUser = data?.content.find(
      (user) => user.userId === selectedUserId
    );

    const tempData = {
      // 체크박스 데이터
      isChecked: isChecked, // 담당자 정보
      isCheckedAddress: isCheckedAddress, // 주소 체크박스
      isCheckedTax: isCheckedTax, // 세금 당당자
      isCheckedTestId: isCheckedTestId,
      isCheckedTestPW: isCheckedTestPW,
      ...formData,
      merchantInformation: {
        merchantCategory: businessList.join(" | "),
        businessConditions: businessStatus.join(" | ")
      },
      bankName: selectedBank.name,
      bankCode: selectedBank.code,

      // 파일 정보 - 개인사업자
      businessLicense: "",
      bankBookCopy: "",
      sealCertificate: "",
      idCardFront: "",
      idCardBack: "",
      gradeConfirmation: "",

      // 파일 정보 - 법인사업자
      corporateRegistration: "",
      actualOwnerConfirmation: "",
      corporateSealCertificate: ""
    };

    // 이미 가맹점 데이터가 임시 저장되어 있는 사용자 확인
    if (selectedUser?.hasTemporary) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "이미 가맹점 데이터가 임시 저장된 사용자입니다."
      });
      return;
    }
    // 사용자와 가맹점명 입력 확인
    if (!formData.user || !formData.mallName) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "사용자와 가맹점명은 반드시 입력해주세요."
      });
      return;
    }
    const params = {
      userId: Number(selectedUserId),
      isUse: true,
      jsonString: JSON.stringify(tempData),
      registrant: "ADMIN"
    };
    // 임시 저장 데이터 업데이트
    mutate(params);
    onClose();
  };

  // 종목, 업태 ref
  const businessCategoryRef = useRef<HTMLInputElement | null>(null);
  const businessConditionsRef = useRef<HTMLInputElement | null>(null);

  // ===================================================================================================================
  // 가맹점 등록
  // ===================================================================================================================
  const onSubmit = async (formData: RegisterForm) => {
    let hasError = false;

    // 업태 배열이 비어 있는지 확인하고 에러 설정
    if (businessStatus.length === 0) {
      businessCategoryRef.current?.scrollIntoView({ behavior: "smooth" });
      setError("businessConditions", {
        type: "manual",
        message: "업태는 최소 1개 이상 등록해야 합니다."
      });
      hasError = true;
    } else {
      clearErrors("businessConditions");
    }

    // 종목 배열이 비어 있는지 확인하고 에러 설정
    if (businessList.length === 0) {
      businessConditionsRef.current?.scrollIntoView({ behavior: "smooth" });
      setError("merchantCategory", {
        type: "manual",
        message: "종목은 최소 1개 이상 등록해야 합니다."
      });
      hasError = true;
    } else {
      clearErrors("merchantCategory");
    }

    // 에러가 있을 경우 제출 중지
    if (hasError) return;

    if (mutateLoading) return;

    // 사업자 등록 번호
    const { isError } = await refetch();
    if (isError) {
      // focusFirstErrorField();
      return;
    }

    // 대표자 정보 묶어서 보내기
    const formMerchantOwnerInfo =
      formData.merchantOwnerInformation?.map((info) => {
        const fullAddress = `${info.ownerPostCode} | ${info.ownerAddress} | ${info.ownerDetailAddress}`;
        const year = info.birth.substring(0, 4);
        const month = info.birth.substring(4, 6);
        const day = info.birth.substring(6, 8);
        const birthString = `${year}-${month}-${day}`;

        return {
          ...info,
          address: fullAddress,
          birth: birthString
        };
      }) ?? [];

    let newHeadOfficeAddress: string | undefined;
    if (formData.headOfficePostCode) {
      newHeadOfficeAddress = `${formData.headOfficePostCode} | ${formData.headOfficeAddress} | ${formData.headOfficeDetailAddress}`;
    } else {
      newHeadOfficeAddress = undefined;
    }

    const merchantFileDto = await uploadFiles(
      fileData,
      formData.businessType,
      fileMutate
    );

    // 폼 데이터
    const newFormData = {
      registrant: "ADMIN",
      userId: Number(selectedUserId),
      mallIp: formData.mallIp,
      mallName: formData.mallName,
      mallNameEn: formData.mallNameEn,
      mallUrl: formData.mallUrl,
      merchantInformation: {
        address: `${formData.postCode} | ${formData.mallAddress} | ${formData.detailAddress}`,
        headOfficeAddress: newHeadOfficeAddress,
        merchantCategory: businessList.join(" | "),
        businessConditions: businessStatus.join(" | "),
        businessType: formData.businessType,
        businessName: formData.businessName,
        corporationNumber: formData.corporationNumber,
        fax: formData.fax,
        introductionCompany: formData.introductionCompany,
        mailOrderSalesNumber: formData.mailOrderSalesNumber,
        phone: formData.mallPhone,
        registrationNumber: formData.registrationNumber,
        testId: formData.testId,
        testPassword: formData.testPassword
      },
      merchantBankInformation: {
        accountCode: formData.accountCode,
        accountHolder: formData.accountHolder,
        bankCode: selectedBank.code,
        bankName: selectedBank.name
      },
      merchantInChargeInformation: [
        {
          email: formData.email,
          name: formData.name,
          phone: formData.phone,
          role: "계약"
        },
        {
          email: formData.managerEmail,
          name: formData.managerName,
          phone: formData.managerPhone,
          role: "정산"
        },
        {
          email: formData.taxEmail,
          name: formData.taxName,
          phone: formData.taxPhone,
          role: "계산서"
        }
      ],
      merchantOwnerInformation: [...formMerchantOwnerInfo],
      merchantFileDto: merchantFileDto
    };
    registMutate(newFormData);
    onClose();
  };

  return (
    <>
      {/* 가맹점 등록 모달창 */}
      <Dialog open={openModal} fullWidth maxWidth="lg">
        {/* 로딩 컴포넌트 ============================================================================================*/}
        {fileLoading && <Loading isLoading={fileLoading} />}
        <DialogTitle
          sx={{
            boxShadow: "0px 5px 14px -1px rgba(204, 204, 204, 0.5)",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          가맹점 등록
          <Stack direction={"row"} gap={3} mr={6}>
            <Button type="button" variant="outlined" onClick={onTempSave}>
              임시 저장
            </Button>
            <ModalTriggerButton
              size="medium"
              color="secondary"
              modal={<RegistUserModal />}
            >
              사용자 등록
            </ModalTriggerButton>
          </Stack>
        </DialogTitle>

        <IconButton
          type="button"
          onClick={close}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Stack gap={3}>
              {/* 사업자 등록 ====================================================================================== */}
              <Box
                sx={{
                  border: "1px solid #E4E4E7",
                  borderRadius: 2,
                  overflow: "hidden"
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: 1,
                    borderBottom: "1px solid #E4E4E7",
                    backgroundColor: "#f7f7f7"
                  }}
                >
                  <Typography>사업자 정보</Typography>
                </Stack>

                <Grid container spacing={3} p={3}>
                  <Grid item xs={12} sm={8}>
                    <Controller
                      name="user"
                      control={control}
                      rules={{
                        required: "사용자를 선택해주세요."
                      }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Autocomplete
                          id="merchantId"
                          options={data?.content ?? []}
                          getOptionLabel={(option) =>
                            `${option.name} (${option.storeName}) - ${option.email}`
                          }
                          onChange={(event, newValue) => {
                            setSelectedUserId(newValue?.userId ?? null);
                            onChange(newValue?.userId);
                          }}
                          loading={isLoading}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="사용자명"
                              fullWidth
                              required
                              size="small"
                              helperText={
                                <HelperTextMessage>
                                  {errors.user?.message}
                                </HelperTextMessage>
                              }
                              error={!!errors.user}
                              ref={register("user").ref}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl>
                      <Controller
                        name="businessType"
                        control={control}
                        rules={{
                          required: "개인/법인 구분 확인은 필수값 입니다."
                        }}
                        render={({ field: { value, onChange } }) => (
                          <RadioGroup row value={value} onChange={onChange}>
                            <FormControlLabel
                              value="개인"
                              control={<Radio />}
                              label="개인"
                              sx={{ width: 100 }}
                            />
                            <FormControlLabel
                              value="법인"
                              control={<Radio />}
                              label="법인"
                            />
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="mallName"
                      label="가맹점명"
                      required
                      size="small"
                      {...register("mallName", {
                        required: "가맹점명은 필수값 입니다."
                      })}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          {errors.mallName?.message}
                        </HelperTextMessage>
                      }
                      error={!!errors.mallName}
                      ref={register("mallName").ref}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="businessName"
                      label="사업자 등록증 상호명"
                      required
                      size="small"
                      {...register("businessName", {
                        required: "사업자 등록증 상호명은 필수값 입니다."
                      })}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          {errors.businessName?.message}
                        </HelperTextMessage>
                      }
                      error={!!errors.businessName}
                      ref={register("businessName").ref}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="registrationNumber"
                      label="사업자등록번호"
                      required
                      size="small"
                      {...register("registrationNumber", {
                        required: "사업자등록번호는 필수값 입니다.",
                        maxLength: {
                          value: 10,
                          message: "사업자등록번호는 10자리 입니다."
                        }
                      })}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          {errors.registrationNumber?.message}
                        </HelperTextMessage>
                      }
                      onInput={numberValidate}
                      error={!!errors.registrationNumber}
                      ref={register("registrationNumber").ref}
                    />
                  </Grid>

                  {/* 법인: 가맹점 영문명, 법인등록번호 */}
                  {currentBusinessType === "법인" ? (
                    <>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="mallNameEn"
                          type="text"
                          label="가맹점 영문명"
                          required
                          size="small"
                          fullWidth
                          {...register("mallNameEn", {
                            required: "가맹점 영문명은 필수값 입니다."
                          })}
                          onInput={engValidate}
                          helperText={
                            <HelperTextMessage>
                              {errors.mallNameEn?.message}
                            </HelperTextMessage>
                          }
                          error={!!errors.mallNameEn}
                          ref={register("mallNameEn").ref}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          placeholder="등록번호 13자리를 입력해주세요."
                          label="법인등록번호"
                          required
                          size="small"
                          fullWidth
                          {...register("corporationNumber", {
                            required: "법인등록번호는 필수값 입니다.",
                            maxLength: {
                              value: 13,
                              message: "법인등록번호는 13자리 입니다."
                            }
                          })}
                          onInput={numberValidate}
                          helperText={
                            <HelperTextMessage>
                              {errors.corporationNumber?.message}
                            </HelperTextMessage>
                          }
                          error={!!errors.corporationNumber}
                          ref={register("corporationNumber").ref}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} />
                    </>
                  ) : (
                    <></>
                  )}

                  <Grid item xs={12} sm={12}>
                    <InputLabel sx={{ mb: 1 }}>가맹점 주소</InputLabel>
                    <Stack direction={"row"} gap={3} alignItems={"center"}>
                      <Button
                        type="button"
                        variant="contained"
                        sx={{
                          color: "white",
                          width: 100
                        }}
                        onClick={() => openPostCodeModal(0)}
                      >
                        주소 검색
                      </Button>
                      <Controller
                        name={"postCode"}
                        rules={{ required: "우편번호는 필수값 입니다." }}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            id="postCode"
                            size="small"
                            label="우편번호"
                            required
                            value={value}
                            sx={{ width: 219 }}
                            onChange={onChange}
                            helperText={
                              <HelperTextMessage>
                                {errors.postCode?.message}
                              </HelperTextMessage>
                            }
                            error={!!errors.postCode}
                            onInput={numberValidate}
                            ref={register("postCode").ref}
                          />
                        )}
                      ></Controller>

                      <Controller
                        name={"mallAddress"}
                        rules={{ required: "주소는 필수값 입니다." }}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            id="mallAddress"
                            size="small"
                            label="주소"
                            required
                            sx={{ width: 346 }}
                            value={value}
                            onChange={onChange}
                            helperText={
                              <HelperTextMessage>
                                {errors.mallAddress?.message}
                              </HelperTextMessage>
                            }
                            error={!!errors.mallAddress}
                            ref={register("mallAddress").ref}
                          />
                        )}
                      ></Controller>
                      <TextField
                        id="detailAddress"
                        size="small"
                        label="상세주소"
                        {...register("detailAddress", {
                          required: "상세주소는 필수값 입니다."
                        })}
                        required
                        sx={{ width: 346 }}
                        helperText={
                          <HelperTextMessage>
                            {errors.detailAddress?.message}
                          </HelperTextMessage>
                        }
                        error={!!errors.detailAddress}
                        ref={register("detailAddress").ref}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack direction={"row"} gap={3} alignItems={"center"}>
                      <InputLabel sx={{ width: 100 }}>본점 주소</InputLabel>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isCheckedAddress}
                            onChange={checkBox}
                          />
                        }
                        label="가맹점 주소와 동일"
                        labelPlacement="end"
                      />
                    </Stack>

                    <Stack direction={"row"} gap={3} alignItems={"center"}>
                      <Button
                        type="button"
                        variant="contained"
                        sx={{ color: "white", width: 100 }}
                        onClick={() => openPostCodeModal(1)}
                      >
                        주소 검색
                      </Button>
                      <Controller
                        name={"headOfficePostCode"}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            id="headOfficePostCode"
                            size="small"
                            label="우편번호"
                            value={isCheckedAddress ? watch("postCode") : value}
                            onChange={onChange}
                            disabled={isCheckedAddress}
                            sx={{ width: 219 }}
                          />
                        )}
                      ></Controller>

                      <Controller
                        name={"headOfficeAddress"}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            id="headOfficeAddress"
                            size="small"
                            label="주소"
                            sx={{ width: 346 }}
                            value={
                              isCheckedAddress ? watch("mallAddress") : value
                            }
                            onChange={onChange}
                            disabled={isCheckedAddress}
                            fullWidth
                          />
                        )}
                      ></Controller>
                      <Controller
                        name={"headOfficeDetailAddress"}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            id="headOfficeDetailAddress"
                            size="small"
                            label="상세주소"
                            sx={{ width: 346 }}
                            value={
                              isCheckedAddress ? watch("detailAddress") : value
                            }
                            onChange={onChange}
                            disabled={isCheckedAddress}
                          />
                        )}
                      ></Controller>
                    </Stack>
                  </Grid>
                  {/* 주소 검색 모달창 */}
                  <PostCode
                    openPostCode={openPostCode}
                    setOpenPostCode={setOpenPostCode}
                    PostCodeHandler={postCodeHandler}
                  />

                  {/* 종목, 업태  */}
                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="businessConditions" sx={{ mb: 1 }}>
                      업태&nbsp;
                      <span style={{ fontSize: 14 }}>
                        (등록할 수 있는 업태의 개수는 최대 5개입니다.)
                      </span>
                    </InputLabel>

                    <Controller
                      name={"businessConditions"}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="businessConditions"
                          size="small"
                          label="업태 *"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.businessConditions?.message}
                            </HelperTextMessage>
                          }
                          ref={businessCategoryRef}
                          onKeyDown={handleOnKeyPressStatus}
                          onBlur={() => {
                            if (value !== "") {
                              addStatusList();
                            }
                          }}
                          // error={businessStatus.length === 0}
                          error={!!errors.businessConditions}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={addStatusList}>추가</Button>
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} sm={8} container>
                    <Stack direction={"row"} alignItems={"cetner"} pt={5}>
                      {businessStatus.length > 0 &&
                        businessStatus.map((item, i) => (
                          <Chip
                            id="businessConditions"
                            key={`status-${i}`}
                            label={item}
                            size="small"
                            variant="outlined"
                            sx={{ mr: 1 }}
                            onDelete={() => deleteStatus(i)}
                          ></Chip>
                        ))}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="merchantCategory" sx={{ mb: 1 }}>
                      종목&nbsp;
                      <span style={{ fontSize: 14 }}>
                        (등록할 수 있는 종목의 개수는 최대 5개입니다.)
                      </span>
                    </InputLabel>

                    <Controller
                      name={"merchantCategory"}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="merchantCategory"
                          label="종목 *"
                          size="small"
                          type="text"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          onBlur={() => {
                            if (value !== "") {
                              addList();
                            }
                          }}
                          helperText={
                            <HelperTextMessage>
                              {errors.merchantCategory?.message}
                            </HelperTextMessage>
                          }
                          onKeyDown={handleOnKeyPressItem}
                          error={!!errors.merchantCategory}
                          ref={businessConditionsRef}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={addList}>추가</Button>
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} sm={8} container alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"cetner"} pt={4}>
                      {businessList.length > 0 &&
                        businessList.map((item, i) => (
                          <Chip
                            id="merchantCategory"
                            key={`business-${i}`}
                            label={item}
                            size="small"
                            variant="outlined"
                            onDelete={() => deleteItem(i)}
                          ></Chip>
                        ))}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="mallPhone"
                      label="가맹점 전화번호"
                      required
                      size="small"
                      {...register("mallPhone", {
                        required: "가맹점 전화번호는 필수값 입니다.",
                        maxLength: {
                          value: 12,
                          message: "12자 이하로 입력해 주세요."
                        }
                      })}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          {errors.mallPhone?.message}
                        </HelperTextMessage>
                      }
                      onInput={numberValidate}
                      error={!!errors.mallPhone}
                      ref={register("mallPhone").ref}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="fax"
                      label="가맹점 팩스번호"
                      size="small"
                      {...register("fax", {
                        maxLength: {
                          value: 12,
                          message: "12자 이하로 입력해 주세요."
                        }
                      })}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          {errors.fax?.message}
                        </HelperTextMessage>
                      }
                      onInput={numberValidate}
                      error={!!errors.fax}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="mailOrderSalesNumber"
                      label="통신판매번호"
                      required
                      size="small"
                      {...register("mailOrderSalesNumber", {
                        required: "통신판매번호는 필수값 입니다."
                      })}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          {errors.mailOrderSalesNumber?.message}
                        </HelperTextMessage>
                      }
                      error={!!errors.mailOrderSalesNumber}
                      ref={register("mailOrderSalesNumber").ref}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="mallUrl"
                      label="홈페이지 주소"
                      required
                      size="small"
                      {...register("mallUrl", {
                        required: "홈페이지 주소는 필수값 입니다."
                      })}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          {errors.mallUrl?.message}
                        </HelperTextMessage>
                      }
                      error={!!errors.mallUrl}
                      ref={register("mallUrl").ref}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="mallIp"
                      label="가맹점 IP"
                      required
                      size="small"
                      {...register("mallIp", {
                        required: "가맹점 IP는 필수값 입니다.",
                        pattern: {
                          value:
                            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                          message: "형식에 맞는 IP주소를 입력해 주세요."
                        }
                      })}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          {errors.mallIp?.message}
                        </HelperTextMessage>
                      }
                      error={!!errors.mallIp}
                      ref={register("mallIp").ref}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="introductionCompany"
                      label="소개업체 (호스팅사)"
                      size="small"
                      fullWidth
                      {...register("introductionCompany")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="testId"
                      control={control}
                      rules={{
                        required: "테스트용 ID 는 필수값 입니다."
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="testId"
                          label="테스트용 ID (카드사 심사용)"
                          required
                          size="small"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.testId?.message}
                            </HelperTextMessage>
                          }
                          error={!!errors.testId}
                          ref={register("testId").ref}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={
                                    <Typography
                                      variant="subtitle2"
                                      fontSize={11}
                                    >
                                      ID는 연동 신청하는 쇼핑몰에 로그인 가능한
                                      테스트용 아이디 입니다.
                                    </Typography>
                                  }
                                  placement="right"
                                  arrow
                                >
                                  <IconButton type="button">
                                    <ErrorOutline />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="testPassword"
                      control={control}
                      rules={{
                        required: "테스트용 비밀번호는 필수값 입니다."
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="testPassword"
                          label="테스트용 비밀번호 (카드사 심사용)"
                          required
                          size="small"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.testPassword?.message}
                            </HelperTextMessage>
                          }
                          error={!!errors.testPassword}
                          ref={register("testPassword").ref}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={
                                    <Typography
                                      variant="subtitle2"
                                      fontSize={11}
                                    >
                                      비밀번호는 연동 신청하는 쇼핑몰에 로그인
                                      가능한 아이디의 비밀번호 입니다.
                                    </Typography>
                                  }
                                  placement="right"
                                  arrow
                                >
                                  <IconButton type="button">
                                    <ErrorOutline />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckedTestId}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="비회원 결제가능"
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* 대표자 등록 ======================================================================================= */}
              <Box
                sx={{
                  border: "1px solid #E4E4E7",
                  borderRadius: 2,
                  overflow: "hidden",
                  pb: 3
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: 1,
                    borderBottom: "1px solid #E4E4E7",
                    backgroundColor: "#f7f7f7"
                  }}
                >
                  <Typography> 대표자 정보</Typography>
                </Stack>
                <Typography variant="body2" sx={{ pl: 3, py: 2 }}>
                  * 공동 대표인 경우 공동 대표 2인 전부 필수 입력
                </Typography>

                {fields.map((owner, index) => (
                  <Grid container spacing={3} key={`owner-${owner.id}`} px={3}>
                    <Grid item xs={12} sm={12}>
                      {index !== 0 && (
                        <Divider sx={{ borderStyle: "dashed", my: 3 }} />
                      )}
                      <Stack direction="row" alignItems="center" gap={3}>
                        <TextField
                          id={`merchantOwnerInformation.${index}.name`}
                          label="대표자 한글이름"
                          required
                          sx={{ width: 346 }}
                          size="small"
                          {...register(
                            `merchantOwnerInformation.${index}.name`,
                            {
                              required: "대표자명은 필수값 입니다."
                            }
                          )}
                          helperText={
                            <HelperTextMessage>
                              {
                                errors?.merchantOwnerInformation?.[index]?.name
                                  ?.message
                              }
                            </HelperTextMessage>
                          }
                          error={
                            !!errors.merchantOwnerInformation?.[index]?.name
                          }
                          ref={
                            register(`merchantOwnerInformation.${index}.name`)
                              .ref
                          }
                        />

                        <TextField
                          id={`merchantOwnerInformation.${index}.nameEn`}
                          size="small"
                          label="대표자 영문성명"
                          required
                          {...register(
                            `merchantOwnerInformation.${index}.nameEn`,
                            {
                              required: "대표자 영문성명은 필수값 입니다."
                            }
                          )}
                          sx={{ width: 346 }}
                          helperText={
                            <HelperTextMessage>
                              {
                                errors?.merchantOwnerInformation?.[index]
                                  ?.nameEn?.message
                              }
                            </HelperTextMessage>
                          }
                          onInput={engValidate}
                          error={
                            !!errors.merchantOwnerInformation?.[index]?.nameEn
                          }
                          ref={
                            register(`merchantOwnerInformation.${index}.nameEn`)
                              .ref
                          }
                        />

                        <Button
                          type="button"
                          variant="contained"
                          size="small"
                          onClick={() => {
                            append({
                              name: "",
                              nameEn: "",
                              birth: "",
                              nationality: "대한민국",
                              phone: "",
                              address: "",
                              ownerPostCode: "",
                              ownerAddress: "",
                              ownerDetailAddress: ""
                            });
                          }}
                          sx={{
                            color: "white",
                            height: 35
                          }}
                        >
                          공동 대표자 추가
                        </Button>
                        <Button
                          type="button"
                          variant="outlined"
                          onClick={() => remove(index)}
                          disabled={index === 0}
                        >
                          양식삭제
                        </Button>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id={`merchantOwnerInformation.${index}.birth`}
                        label="대표자 생년월일"
                        required
                        fullWidth
                        size="small"
                        {...register(
                          `merchantOwnerInformation.${index}.birth`,
                          {
                            required: "생년월일은 필수값 입니다.",
                            pattern: {
                              value:
                                /^(19[0-9][0-9]|20\d{2})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
                              message: "정확한 생년월일을 입력해 주세요."
                            },
                            maxLength: {
                              value: 8,
                              message: "생년월일 8자리를 입력해 주세요."
                            },
                            minLength: {
                              value: 8,
                              message: "생년월일 8자리를 입력해 주세요."
                            }
                          }
                        )}
                        helperText={
                          <HelperTextMessage>
                            {
                              errors?.merchantOwnerInformation?.[index]?.birth
                                ?.message
                            }
                          </HelperTextMessage>
                        }
                        onInput={numberValidate}
                        error={
                          !!errors.merchantOwnerInformation?.[index]?.birth
                        }
                        ref={
                          register(`merchantOwnerInformation.${index}.birth`)
                            .ref
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name={`merchantOwnerInformation.${index}.nationality`}
                        control={control}
                        rules={{
                          required: "대표자 국적은 필수값 입니다."
                        }}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            id={`merchantOwnerInformation.${index}.nationality`}
                            label="대표자 국적"
                            select
                            required
                            fullWidth
                            size="small"
                            value={value}
                            onChange={onChange}
                          >
                            {CountryList.map(({ value, text }) => (
                              <MenuItem key={value} value={text}>
                                {text}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      ></Controller>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id={`merchantOwnerInformation.${index}.phone`}
                        label="대표자 연락처"
                        required
                        fullWidth
                        size="small"
                        {...register(
                          `merchantOwnerInformation.${index}.phone`,
                          {
                            required: "대표자 연락처는 필수값 입니다.",
                            maxLength: {
                              value: 12,
                              message: "전화번호는 12자리 이하로 입력 해주세요."
                            }
                          }
                        )}
                        helperText={
                          <HelperTextMessage>
                            {
                              errors?.merchantOwnerInformation?.[index]?.phone
                                ?.message
                            }
                          </HelperTextMessage>
                        }
                        onInput={numberValidate}
                        error={
                          !!errors.merchantOwnerInformation?.[index]?.phone
                        }
                        ref={
                          register(`merchantOwnerInformation.${index}.phone`)
                            .ref
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Stack direction="row" alignItems="center" gap={3}>
                        <PostCode
                          openPostCode={openOwnerPostCode}
                          setOpenPostCode={setOpenOwnerPostCode}
                          PostCodeHandler={ownerAddressHandler}
                        />
                        <Button
                          type="button"
                          variant="contained"
                          sx={{
                            color: "white",
                            width: 100
                          }}
                          onClick={() => openOwnerPostCodeModal(index)}
                        >
                          주소 검색
                        </Button>
                        <Controller
                          name={`merchantOwnerInformation.${index}.ownerPostCode`}
                          rules={{
                            required: "우편번호는 필수값 입니다."
                          }}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              size="small"
                              label="우편번호"
                              required
                              value={value}
                              onChange={onChange}
                              sx={{ width: 220 }}
                              helperText={
                                <HelperTextMessage>
                                  {
                                    errors?.merchantOwnerInformation?.[index]
                                      ?.ownerPostCode?.message
                                  }
                                </HelperTextMessage>
                              }
                              error={
                                !!errors.merchantOwnerInformation?.[index]
                                  ?.ownerPostCode
                              }
                              ref={
                                register(
                                  `merchantOwnerInformation.${index}.ownerPostCode`
                                ).ref
                              }
                            />
                          )}
                        ></Controller>

                        <Controller
                          name={`merchantOwnerInformation.${index}.ownerAddress`}
                          rules={{ required: "대표자 주소는 필수값 입니다." }}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              size="small"
                              label="대표자 주소"
                              required
                              sx={{ width: 351 }}
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {
                                    errors?.merchantOwnerInformation?.[index]
                                      ?.ownerAddress?.message
                                  }
                                </HelperTextMessage>
                              }
                              error={
                                !!errors.merchantOwnerInformation?.[index]
                                  ?.ownerAddress
                              }
                              ref={
                                register(
                                  `merchantOwnerInformation.${index}.ownerAddress`
                                ).ref
                              }
                            />
                          )}
                        ></Controller>
                        <Controller
                          name={`merchantOwnerInformation.${index}.ownerDetailAddress`}
                          rules={{
                            required: "상세주소는 필수값 입니다."
                          }}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              size="small"
                              label="상세 주소"
                              required
                              sx={{ width: 352 }}
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {
                                    errors?.merchantOwnerInformation?.[index]
                                      ?.ownerDetailAddress?.message
                                  }
                                </HelperTextMessage>
                              }
                              error={
                                !!errors.merchantOwnerInformation?.[index]
                                  ?.ownerDetailAddress
                              }
                              ref={
                                register(
                                  `merchantOwnerInformation.${index}.ownerDetailAddress`
                                ).ref
                              }
                            />
                          )}
                        ></Controller>
                      </Stack>
                    </Grid>
                  </Grid>
                ))}
              </Box>

              {/* 은행 정보 등록 ======================================================================================= */}
              <Box
                sx={{
                  border: "1px solid #E4E4E7",
                  borderRadius: 2,
                  overflow: "hidden"
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: 1,
                    borderBottom: "1px solid #E4E4E7",
                    backgroundColor: "#f7f7f7"
                  }}
                >
                  <Typography>은행 정보 등록</Typography>
                </Stack>
                <Typography variant="body2" sx={{ pl: 3, pt: 3 }}>
                  * 정산 은행의 경우 개인사업자는 대표자 명의, 법인사업자는 법인
                  명의의 계좌만 이용 가능합니다. <br />* 예금주의 경우,
                  개인사업자는 대표자 또는 상호명, 법인사업자는 상호명과
                  일치해야 합니다.
                </Typography>
                <Grid container spacing={3} p={3}>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="bankName"
                      control={control}
                      rules={{
                        required: "은행명은 필수값 입니다."
                      }}
                      render={({ field }) => (
                        <TextField
                          id="bankName"
                          label="은행명"
                          required
                          size="small"
                          select
                          {...field}
                          value={selectedBank.name}
                          onChange={(event) => bankSelectHandler(event, field)}
                          fullWidth
                          error={!!errors.bankName}
                          ref={register("bankName").ref}
                          helperText={
                            <HelperTextMessage>
                              {errors.bankName?.message}
                            </HelperTextMessage>
                          }
                        >
                          {bankNameList?.content?.map((bank) => (
                            <MenuItem key={bank.code} value={bank.name}>
                              {bank.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="accountCode"
                      control={control}
                      rules={{
                        required: "은행 계좌번호는 필수값 입니다."
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="accountCode"
                          label="은행 계좌번호"
                          required
                          fullWidth
                          size="small"
                          value={value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.accountCode?.message}
                            </HelperTextMessage>
                          }
                          onInput={numberValidate}
                          error={!!errors.accountCode}
                          ref={register("accountCode").ref}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="accountHolder"
                      control={control}
                      rules={{
                        required: "계좌 소유주는 필수값 입니다."
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="accountHolder"
                          label="소유주 이름"
                          required
                          fullWidth
                          size="small"
                          value={value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.accountHolder?.message}
                            </HelperTextMessage>
                          }
                          error={!!errors.accountHolder}
                          ref={register("accountHolder").ref}
                        />
                      )}
                    ></Controller>
                  </Grid>
                </Grid>
              </Box>

              {/* 담당자 등록 ====================================================================================== */}
              <Box
                sx={{
                  border: "1px solid #E4E4E7",
                  borderRadius: 2,
                  overflow: "hidden"
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: 1,
                    borderBottom: "1px solid #E4E4E7",
                    backgroundColor: "#f7f7f7"
                  }}
                >
                  <Typography>담당자 등록</Typography>
                </Stack>
                <Grid container spacing={3} p={3}>
                  {/* 계약 담당자 */}
                  <Grid item xs={12} sm={12}>
                    <Chip label="계약 담당자" sx={{ fontWeight: "bold" }} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: "담당자 성명은 필수값 입니다."
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="name"
                          label="담당자 성명"
                          required
                          fullWidth
                          size="small"
                          value={value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.name?.message}
                            </HelperTextMessage>
                          }
                          error={!!errors.name}
                          ref={register("name").ref}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="phone"
                      control={control}
                      rules={{
                        required: "전화번호는 필수값 입니다.",
                        maxLength: {
                          value: 12,
                          message: "전화번호는 12자리 이하로 입력 해주세요."
                        }
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="phone"
                          label="전화번호"
                          required
                          fullWidth
                          size="small"
                          value={value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.phone?.message}
                            </HelperTextMessage>
                          }
                          onInput={numberValidate}
                          error={!!errors.phone}
                          ref={register("phone").ref}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "이메일은 필수값 입니다.",
                        pattern: {
                          value:
                            /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                          message: "형식에 맞지 않는 이메일 주소입니다."
                        }
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="email"
                          label="이메일"
                          required
                          fullWidth
                          size="small"
                          value={value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.email?.message}
                            </HelperTextMessage>
                          }
                          error={!!errors.email}
                          ref={register("email").ref}
                        />
                      )}
                    ></Controller>
                  </Grid>

                  {/* 정산 담당자 */}
                  <Grid item xs={12} sm={12}>
                    <Divider sx={{ borderStyle: "dashed", mb: 2.5 }} />
                    <Chip label="정산 담당자" sx={{ fontWeight: "bold" }} />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={managerCheckBox}
                        />
                      }
                      label="계약 담당자 정보와 동일"
                      labelPlacement="end"
                      sx={{ ml: 4 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="managerName"
                      control={control}
                      rules={{
                        required: "담당자 성명은 필수값 입니다."
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="managerName"
                          label="담당자 성명"
                          required
                          fullWidth
                          size="small"
                          value={isChecked ? watch("name") : value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.managerName?.message}
                            </HelperTextMessage>
                          }
                          disabled={isChecked}
                          error={!!errors.managerName}
                          ref={register("managerName").ref}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="managerPhone"
                      control={control}
                      rules={{
                        required: "전화번호는 필수값 입니다.",
                        maxLength: {
                          value: 12,
                          message: "전화번호는 12자리 이하로 입력 해주세요."
                        }
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="managerPhone"
                          label="전화번호"
                          required
                          fullWidth
                          size="small"
                          value={isChecked ? watch("phone") : value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.managerPhone?.message}
                            </HelperTextMessage>
                          }
                          onInput={numberValidate}
                          disabled={isChecked}
                          error={!!errors.managerPhone}
                          ref={register("managerPhone").ref}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="managerEmail"
                      control={control}
                      rules={{
                        required: "이메일은 필수값 입니다.",
                        pattern: {
                          value:
                            /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                          message: "형식에 맞지 않는 이메일 주소입니다."
                        }
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="managerEmail"
                          label="이메일"
                          required
                          fullWidth
                          size="small"
                          value={isChecked ? watch("email") : value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.managerEmail?.message}
                            </HelperTextMessage>
                          }
                          disabled={isChecked}
                          error={!!errors.managerEmail}
                          ref={register("managerEmail").ref}
                        />
                      )}
                    ></Controller>
                  </Grid>

                  {/* 세금 계산 담당자 */}
                  <Grid item xs={12} sm={12}>
                    <Divider sx={{ borderStyle: "dashed", mb: 2.5 }} />
                    <Chip
                      label="세금 계산 담당자"
                      sx={{ fontWeight: "bold" }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckedTax}
                          onChange={taxCheckBox}
                        />
                      }
                      label="정산 담당자 정보와 동일"
                      labelPlacement="end"
                      sx={{ ml: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="taxName"
                      control={control}
                      rules={{
                        required: "담당자 성명은 필수값 입니다."
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="taxName"
                          label="담당자 성명"
                          required
                          fullWidth
                          size="small"
                          value={isCheckedTax ? watch("managerName") : value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.taxName?.message}
                            </HelperTextMessage>
                          }
                          disabled={isCheckedTax}
                          error={!!errors.taxName}
                          ref={register("taxName").ref}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="taxPhone"
                      control={control}
                      rules={{
                        required: "전화번호는 필수값 입니다.",
                        maxLength: {
                          value: 12,
                          message: "전화번호는 12자리 이하로 입력 해주세요."
                        }
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="taxPhone"
                          label="전화번호"
                          required
                          fullWidth
                          size="small"
                          value={isCheckedTax ? watch("managerPhone") : value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.taxPhone?.message}
                            </HelperTextMessage>
                          }
                          onInput={numberValidate}
                          disabled={isCheckedTax}
                          error={!!errors.taxPhone}
                          ref={register("taxPhone").ref}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="taxEmail"
                      control={control}
                      rules={{
                        required: "이메일은 필수값 입니다.",
                        pattern: {
                          value:
                            /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                          message: "형식에 맞지 않는 이메일 주소입니다."
                        }
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          id="taxEmail"
                          label="이메일"
                          required
                          fullWidth
                          size="small"
                          value={isCheckedTax ? watch("managerEmail") : value}
                          onChange={onChange}
                          helperText={
                            <HelperTextMessage>
                              {errors.taxEmail?.message}
                            </HelperTextMessage>
                          }
                          disabled={isCheckedTax}
                          error={!!errors.taxEmail}
                          ref={register("taxEmail").ref}
                        />
                      )}
                    ></Controller>
                  </Grid>
                </Grid>
              </Box>

              {/* 파일 등록 ======================================================================================= */}
              <Box
                sx={{
                  border: "1px solid #E4E4E7",
                  borderRadius: 2,
                  overflow: "hidden"
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: 1,
                    borderBottom: "1px solid #E4E4E7",
                    backgroundColor: "#f7f7f7"
                  }}
                >
                  <Typography>파일 등록</Typography>
                </Stack>
                <Box pl={3} pt={2}>
                  <Typography variant="body2" sx={{ mt: 1.5 }}>
                    * 제출 서류의 첨부파일 최대 용량은 10MB 입니다. <br />* 제출
                    서류의 첨부 파일 종류는 jpg, jpeg, gif, png, pdf 입니다.
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1.5 }} color={"error"}>
                    * 파일은 임시저장할 수 없습니다.
                  </Typography>
                </Box>
                {/* 개인 사업자 파일 등록 ---------------------------------------------------------------------------- */}
                {currentBusinessType === "개인" ? (
                  <Grid container spacing={2} p={3}>
                    <Grid item xs={12} sm={12}>
                      <InputLabel>사업자등록증</InputLabel>
                      <Stack direction={"row"} alignItems="center">
                        <TextField
                          size="small"
                          sx={{ width: 350 }}
                          error={!!errors.businessLicense}
                          disabled
                          {...register("businessLicense", {
                            required: "사업자등록증은 필수 파일 입니다."
                          })}
                        />
                        <Button
                          component="label"
                          sx={{
                            mx: 2,
                            background: "#eee",
                            color: "black",
                            p: 1
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*, .pdf"
                            hidden
                            onChange={사업자등록증}
                          />
                          {파일선택텍스트("businessLicense")}
                        </Button>
                        {errors.businessLicense && (
                          <Typography variant="caption" color="error">
                            {errors.businessLicense.message}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <InputLabel>통장 사본</InputLabel>
                        <Typography sx={{ fontSize: "12px", color: "gray" }}>
                          (대표자 명의)
                        </Typography>
                      </Stack>
                      <TextField
                        size="small"
                        disabled
                        sx={{ width: 350 }}
                        error={!!errors.bankBookCopy}
                        {...register("bankBookCopy", {
                          required: "통장 사본은 필수 파일 입니다."
                        })}
                      />
                      <Button
                        component="label"
                        sx={{ mx: 2, background: "#eee", color: "black", p: 1 }}
                      >
                        <input
                          type="file"
                          accept="image/*, .pdf"
                          hidden
                          onChange={통장사본}
                        />
                        {파일선택텍스트("bankBookCopy")}
                      </Button>
                      {errors.bankBookCopy && (
                        <Typography variant="caption" color="error">
                          {errors.bankBookCopy.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <InputLabel>대표자 인감증명서</InputLabel>
                        <Typography sx={{ fontSize: "12px", color: "gray" }}>
                          (원본 1부)
                        </Typography>
                        <Typography
                          sx={{ fontSize: "12px", color: "error.main" }}
                        >
                          * 서류는 제출기한 기준 최근 3개월 이내 발급 받은 것
                        </Typography>
                      </Stack>
                      <TextField
                        size="small"
                        disabled
                        sx={{ width: 350 }}
                        error={!!errors.sealCertificate}
                        {...register("sealCertificate", {
                          required: "대표자 인감증명서는 필수 파일 입니다."
                        })}
                      />
                      <Button
                        component="label"
                        sx={{
                          mx: 2,
                          background: "#eee",
                          color: "black",
                          p: 1
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*, .pdf"
                          hidden
                          onChange={인감증명서}
                        />
                        {파일선택텍스트("sealCertificate")}
                      </Button>
                      {errors.sealCertificate && (
                        <Typography variant="caption" color="error">
                          {errors.sealCertificate.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <InputLabel>대표자 신분증 (앞면)</InputLabel>
                        <Typography sx={{ fontSize: "12px", color: "gray" }}>
                          (앞면 사본 1부)
                        </Typography>
                      </Stack>
                      <TextField
                        size="small"
                        disabled
                        sx={{ width: 350 }}
                        error={!!errors.idCardFront}
                        {...register("idCardFront", {
                          required: "대표자 신분증은 필수 파일 입니다."
                        })}
                      />
                      <Button
                        component="label"
                        sx={{ background: "#eee", color: "black", p: 1, mx: 2 }}
                      >
                        <input
                          type="file"
                          accept="image/*, .pdf"
                          hidden
                          onChange={신분증앞}
                        />
                        {파일선택텍스트("idCardFront")}
                      </Button>
                      {errors.idCardFront && (
                        <Typography variant="caption" color="error">
                          {errors.idCardFront.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <InputLabel>대표자 신분증 (뒷면)</InputLabel>
                        <Typography sx={{ fontSize: "12px", color: "gray" }}>
                          (뒷면 사본 1부)
                        </Typography>
                      </Stack>
                      <TextField
                        size="small"
                        disabled
                        sx={{ width: 350 }}
                        error={!!errors.idCardBack}
                        {...register("idCardBack", {
                          required: "대표자 신분증은 필수 파일 입니다."
                        })}
                      />
                      <Button
                        component="label"
                        sx={{ background: "#eee", color: "black", p: 1, mx: 2 }}
                      >
                        <input
                          type="file"
                          accept="image/*, .pdf"
                          hidden
                          onChange={신분증뒤}
                        />
                        {파일선택텍스트("idCardBack")}
                      </Button>
                      {errors.idCardBack && (
                        <Typography variant="caption" color="error">
                          {errors.idCardBack.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <InputLabel>영중소 등급확인서</InputLabel>
                      <TextField
                        size="small"
                        sx={{ width: 350 }}
                        disabled
                        error={!!errors.gradeConfirmation}
                        {...register("gradeConfirmation")}
                      />
                      <Button
                        component="label"
                        sx={{ ml: 2, background: "#eee", color: "black", p: 1 }}
                      >
                        <input
                          type="file"
                          accept="image/*, .pdf"
                          hidden
                          onChange={등급확인서}
                        />
                        {파일선택텍스트("gradeConfirmation")}
                      </Button>
                      {errors.gradeConfirmation && (
                        <Typography variant="caption" color="error">
                          {errors.gradeConfirmation.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {/* 법인 사업자 파일 등록 ---------------------------------------------------------------------------- */}
                    <Grid container spacing={3} p={3}>
                      <Grid item xs={12} sm={12}>
                        <InputLabel>사업자등록증</InputLabel>
                        <Stack direction={"row"} alignItems="center">
                          <TextField
                            size="small"
                            sx={{ width: 350 }}
                            error={!!errors.businessLicense}
                            disabled
                            {...register("businessLicense", {
                              required: "사업자등록증은 필수 파일 입니다."
                            })}
                          />
                          <Button
                            component="label"
                            sx={{
                              mx: 2,
                              background: "#eee",
                              color: "black",
                              p: 1
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*, .pdf"
                              hidden
                              onChange={사업자등록증}
                            />
                            {파일선택텍스트("businessLicense")}
                          </Button>
                          {errors.businessLicense && (
                            <Typography variant="caption" color="error">
                              {errors.businessLicense.message}
                            </Typography>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <InputLabel>통장 사본</InputLabel>
                          <Typography sx={{ fontSize: "12px", color: "gray" }}>
                            (법인 명의)
                          </Typography>
                        </Stack>
                        <TextField
                          size="small"
                          disabled
                          sx={{ width: 350 }}
                          error={!!errors.bankBookCopy}
                          {...register("bankBookCopy", {
                            required: "통장 사본은 필수 파일 입니다."
                          })}
                        />
                        <Button
                          component="label"
                          sx={{
                            mx: 2,
                            background: "#eee",
                            color: "black",
                            p: 1
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*, .pdf"
                            hidden
                            onChange={통장사본}
                          />
                          {파일선택텍스트("bankBookCopy")}
                        </Button>
                        {errors.bankBookCopy && (
                          <Typography variant="caption" color="error">
                            {errors.bankBookCopy.message}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <InputLabel>법인인감증명서</InputLabel>
                        <Stack direction={"row"} gap={2} alignItems={"center"}>
                          <TextField
                            size="small"
                            sx={{ width: 350 }}
                            disabled
                            error={!!errors.corporateSealCertificate}
                            {...register("corporateSealCertificate", {
                              required: "법인인감증명서는 필수 파일 입니다."
                            })}
                          />
                          <Button
                            component="label"
                            sx={{
                              background: "#eee",
                              color: "black",
                              p: 1
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*, .pdf"
                              hidden
                              onChange={법인인감증명서}
                            />
                            {파일선택텍스트("corporateSealCertificate")}
                          </Button>
                          {errors.corporateSealCertificate && (
                            <Typography variant="caption" color="error">
                              {errors.corporateSealCertificate.message}
                            </Typography>
                          )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                          <InputLabel>사용인감계</InputLabel>
                          <Typography sx={{ fontSize: "12px", color: "gray" }}>
                            (원본 1부)
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", color: "error.main" }}
                          >
                            * 서류는 제출기한 기준 최근 3개월 이내 발급 받은 것
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} gap={2} alignItems={"center"}>
                          <TextField
                            size="small"
                            sx={{ width: 350 }}
                            disabled
                            {...register("사용인감계")}
                          />
                          <Button
                            component="label"
                            sx={{
                              mr: 2,
                              background: "#eee",
                              color: "black",
                              p: 1
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*, .pdf"
                              hidden
                              onChange={사용인감계}
                            />
                            {파일선택텍스트("사용인감계")}
                          </Button>
                          {errors.사용인감계 && (
                            <Typography variant="caption" color="error">
                              {errors.사용인감계.message}
                            </Typography>
                          )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <InputLabel>등기사항전부증명서</InputLabel>
                          <Typography sx={{ fontSize: "12px", color: "gray" }}>
                            (원본 1부)
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", color: "error.main" }}
                          >
                            * 서류는 제출기한 기준 최근 3개월 이내 발급 받은 것
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"}>
                          <TextField
                            size="small"
                            sx={{ width: 350 }}
                            disabled
                            error={!!errors.corporateRegistration}
                            {...register("corporateRegistration", {
                              required: "등기사항전부증명서는 필수 파일입니다."
                            })}
                          />
                          <Button
                            component="label"
                            sx={{
                              mx: 2,
                              background: "#eee",
                              color: "black",
                              p: 1
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*, .pdf"
                              hidden
                              onChange={등기사항전부증명서}
                            />
                            {파일선택텍스트("corporateRegistration")}
                          </Button>
                          {errors.corporateRegistration && (
                            <Typography variant="caption" color="error">
                              {errors.corporateRegistration.message}
                            </Typography>
                          )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <InputLabel>실제소유자확인서</InputLabel>
                          <Typography sx={{ fontSize: "12px", color: "gray" }}>
                            (주주명부, 정관, 사원명부 중 택 1)
                          </Typography>
                        </Stack>
                        <TextField
                          size="small"
                          sx={{ width: 350 }}
                          disabled
                          error={!!errors.actualOwnerConfirmation}
                          {...register("actualOwnerConfirmation")}
                        />
                        <Button
                          component="label"
                          sx={{
                            mx: 2,
                            background: "#eee",
                            color: "black",
                            p: 1
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*, .pdf"
                            hidden
                            onChange={실제소유자확인서}
                          />
                          {파일선택텍스트("actualOwnerConfirmation")}
                        </Button>
                        {errors.actualOwnerConfirmation && (
                          <Typography variant="caption" color="error">
                            {errors.actualOwnerConfirmation.message}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                          <InputLabel>대표자 신분증 (앞면)</InputLabel>
                          <Typography sx={{ fontSize: "12px", color: "gray" }}>
                            (앞면 사본 1부)
                          </Typography>
                        </Stack>
                        <TextField
                          size="small"
                          disabled
                          sx={{ width: 350 }}
                          error={!!errors.idCardFront}
                          {...register("idCardFront", {
                            required: "대표자 신분증은 필수 파일 입니다."
                          })}
                        />
                        <Button
                          component="label"
                          sx={{
                            background: "#eee",
                            color: "black",
                            p: 1,
                            mx: 2
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*, .pdf"
                            hidden
                            onChange={신분증앞}
                          />
                          {파일선택텍스트("idCardFront")}
                        </Button>
                        {errors.idCardFront && (
                          <Typography variant="caption" color="error">
                            {errors.idCardFront.message}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                          <InputLabel>대표자 신분증 (뒷면)</InputLabel>
                          <Typography sx={{ fontSize: "12px", color: "gray" }}>
                            (뒷면 사본 1부)
                          </Typography>
                        </Stack>
                        <TextField
                          size="small"
                          disabled
                          sx={{ width: 350 }}
                          error={!!errors.idCardBack}
                          {...register("idCardBack", {
                            required: "대표자 신분증은 필수 파일 입니다."
                          })}
                        />
                        <Button
                          component="label"
                          sx={{
                            background: "#eee",
                            color: "black",
                            p: 1,
                            mx: 2
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*, .pdf"
                            hidden
                            onChange={신분증뒤}
                          />
                          {파일선택텍스트("idCardBack")}
                        </Button>
                        {errors.idCardBack && (
                          <Typography variant="caption" color="error">
                            {errors.idCardBack.message}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <InputLabel>영중소 등급확인서</InputLabel>
                        <TextField
                          size="small"
                          sx={{ width: 350 }}
                          disabled
                          error={!!errors.gradeConfirmation}
                          {...register("gradeConfirmation")}
                        />
                        <Button
                          component="label"
                          sx={{
                            mx: 2,
                            background: "#eee",
                            color: "black",
                            p: 1
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*, .pdf"
                            hidden
                            onChange={등급확인서}
                          />
                          {파일선택텍스트("gradeConfirmation")}
                        </Button>
                        {errors.gradeConfirmation && (
                          <Typography variant="caption" color="error">
                            {errors.gradeConfirmation.message}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>

              <Button
                type="submit"
                variant="contained"
                sx={{ color: "white" }}
                // disabled={!validBusiness() || !validStatus()}
                fullWidth
              >
                가맹 등록
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RegistMerechantModal;
