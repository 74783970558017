import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import {
  default as LoadingButton,
  default as MuiLoadingButton
} from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../../../../..";
import { getUsersByKeyword } from "../../../../../api/User";
import { UserSearchKeywordParam } from "../../../../../api/User/types";
import { User } from "../../../../../components/Notification/NotificationSendModal";
import isModalOpenAtom from "../../../../../recoil/isModalOpen";
import { HelperTextMessage } from "../../../../components/HelperTextMessage";
import { addGroupMember } from "../libs";
import Pagination from "@mui/material/Pagination";
import MuiTable from "../../../../../components/Table/MuiTable";
import { AddGroupMemberParams } from "../libs/types";

interface Props {
  closeModal?: () => void;
  groupId: number;
}

interface Form {
  addGroupMember: {
    authority: string;
    email: string;
    name: string;
    note: string;
    phoneNumber: string;
  }[];
}

interface Column {
  id: string;
  label: string;
}

const COLUMNS: Column[] = [
  {
    id: "name",
    label: "이름"
  },
  {
    id: "storeName",
    label: "대표 가맹점"
  },
  {
    id: "email",
    label: "사용자 이메일"
  }
];

// 사용자 권한 common_code
export const AUTHORITY_CODE = {
  ADMIN: { value: "000194", label: "ADMIN" },
  CLIENT: { value: "000196", label: "CLIENT" },
  DIRECT_INPUT: { value: "000245", label: "직접입력" }
} as const;

const AddUserToGroupModal = ({ closeModal, groupId }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [selectedFormType, setSelectedFormType] = useState<
    "직접입력" | "사용자검색"
  >("사용자검색");

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
    setSelectedFormType(newValue === 0 ? "사용자검색" : "직접입력");
  };

  // 사용자검색 훅폼
  const {
    register: formRegister,
    getValues,
    handleSubmit: registerHandleSubmit,
    reset: searchReset
    // control
  } = useForm<UserSearchKeywordParam>({
    defaultValues: { searchKeyword: "" }
  });

  // 직접입력 훅폼
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors }
  } = useForm<Form>({
    defaultValues: {
      addGroupMember: [
        {
          authority: AUTHORITY_CODE.DIRECT_INPUT.value,
          email: "",
          name: "",
          note: "",
          phoneNumber: ""
        }
      ]
    }
  });

  const { fields, append, remove } = useFieldArray<Form>({
    control,
    name: "addGroupMember"
  });

  // 파라미터 스테이트
  const [params, setParams] = useState<UserSearchKeywordParam>({
    searchKeyword: getValues("searchKeyword"),
    page: 0,
    size: 10
    // sort: "DESC"
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { mutate } = useMutation(addGroupMember, {
    onSuccess: () => {
      queryClient.invalidateQueries([`/v2/groups/${groupId}`]);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "사용자 추가가 완료되었습니다."
      });
      closeModal?.();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // 사용자 검색
  const { data, refetch, isFetching } = useQuery(
    [`/users/search/v2`, { ...params }],
    () => getUsersByKeyword(params),
    {
      enabled: false,
      cacheTime: 0,
      onSuccess: (data) => {
        const updatedTotalPages = data?.pageable?.totalPages || 0;
        if (!!params.page && params.page >= updatedTotalPages) {
          // 현재 페이지가 총 페이지 수보다 크면 마지막 페이지로 이동
          setParams((prev) => ({
            ...prev,
            page: Math.max(updatedTotalPages - 1, 0)
          }));
        }
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  useEffect(() => {
    setSelectedUsers([]);
    refetch();
  }, [params]);

  /*********************************************************************************************************************
   * 페이징
   ********************************************************************************************************************/
  // 총 페이지 수
  const totalPages = useMemo(
    () => (data?.pageable?.totalPages ? data?.pageable?.totalPages : 0),
    [data, params]
  );

  // 페이지 변경 함수
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev: any) => ({ ...prev, page: page - 1 }));
  };

  // ===================================================================================================================
  // 폼 리셋 처리
  // ===================================================================================================================
  useEffect(() => {
    if (selectedFormType === "직접입력") {
      reset({
        addGroupMember: [
          {
            authority: AUTHORITY_CODE.DIRECT_INPUT.value,
            email: "",
            name: "",
            note: "",
            phoneNumber: ""
          }
        ]
      });
    } else {
      searchReset({ searchKeyword: "" });
    }
  }, [selectedFormType, reset, searchReset]);

  // NOTE: 2025-01-14 프론트에서 필터링 하지 않고 받아온 데이터 모두 표시.
  // 테이블에 사용할 데이터 필터링 --- 검색된 데이터가 name이 없는 경우 테이블에 보이지 않기
  // const filteredRows = data?.content?.filter((row) => !!row.name?.trim());

  // ===================================================================================================================
  // 그룹 멤버 추가
  // ===================================================================================================================
  const onValid = (data: Form) => {
    let params: AddGroupMemberParams[];

    if (selectedFormType === "직접입력") {
      params = data.addGroupMember;
    } else {
      // selectedFormType === "사용자검색"
      if (!selectedUsers.length) {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: "추가할 사용자를 선택해 주세요."
        });

        return;
      }

      params = selectedUsers.map((user) => ({
        email: user.email,
        name: "",
        authority: "",
        note: "",
        phoneNumber: "",
        userId: user.id
      }));
    }

    mutate({
      id: groupId,
      params
    });
  };

  const onSubmit = () => {
    setParams((prev) => ({
      ...prev,
      searchKeyword: getValues("searchKeyword")
    }));
  };

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      {/* 헤더 */}
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        사용자 검색 및 추가
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Box sx={{ px: 3 }}>
        <Tabs
          value={selectedTabIndex}
          onChange={handleTabChange}
          aria-label="user input tabs"
          variant="fullWidth"
        >
          <Tab label="사용자검색" />
          <Tab label="직접입력" />
        </Tabs>
      </Box>

      <DialogContent sx={{ height: 550, pt: 0 }}>
        {selectedFormType === "직접입력" ? (
          <>
            <Stack direction="row" justifyContent="flex-end" pt={2}>
              <Button
                variant="outlined"
                onClick={() =>
                  append({
                    authority: AUTHORITY_CODE.DIRECT_INPUT.value,
                    email: "",
                    name: "",
                    note: "",
                    phoneNumber: ""
                  })
                }
              >
                양식 추가
              </Button>
            </Stack>
            {/* 멤버 추가 직접 입력 필드 */}
            {fields.map((field, index) => (
              <Box key={field.id}>
                {index !== 0 && (
                  <Divider sx={{ borderStyle: "dashed", mt: 3, mb: 1 }} />
                )}
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction={"row"} alignItems="center" gap={3}>
                    <Typography color="primary" sx={{ mb: 1 }}>
                      사용자 #{index + 1}
                    </Typography>
                  </Stack>
                  {index > 0 && (
                    <IconButton onClick={() => remove(index)} size="small">
                      <DeleteForeverRoundedIcon />
                    </IconButton>
                  )}
                </Stack>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="이름"
                      fullWidth
                      size="small"
                      required
                      {...register(`addGroupMember.${index}.name`, {
                        required: "이름은 필수값 입니다."
                      })}
                      helperText={
                        <HelperTextMessage>
                          {errors?.addGroupMember?.[index]?.name?.message}
                        </HelperTextMessage>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="이메일"
                      fullWidth
                      size="small"
                      required
                      {...register(`addGroupMember.${index}.email`, {
                        required: "이메일은 필수값 입니다.",
                        pattern: {
                          value:
                            /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                          message: "형식에 맞지 않는 이메일 주소입니다."
                        }
                      })}
                      helperText={
                        <HelperTextMessage>
                          {errors?.addGroupMember?.[index]?.email?.message}
                        </HelperTextMessage>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="전화번호"
                      fullWidth
                      size="small"
                      {...register(`addGroupMember.${index}.phoneNumber`, {
                        maxLength: {
                          value: 12,
                          message: "전화번호는 12자리 이하로 입력 해주세요."
                        }
                      })}
                      helperText={
                        <HelperTextMessage>
                          {
                            errors?.addGroupMember?.[index]?.phoneNumber
                              ?.message
                          }
                        </HelperTextMessage>
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="비고"
                      fullWidth
                      size="small"
                      multiline
                      maxRows={2}
                      {...register(`addGroupMember.${index}.note`, {
                        maxLength: {
                          value: 200,
                          message: "200자 이하로 입력해 주세요."
                        }
                      })}
                      helperText={
                        <HelperTextMessage>
                          {errors?.addGroupMember?.[index]?.note?.message}
                        </HelperTextMessage>
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
          </>
        ) : (
          <>
            {/* 멤버 추가 검색 필드 */}
            <Stack spacing={2} pt={2}>
              <form onSubmit={registerHandleSubmit(onSubmit)}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <TextField
                    autoFocus={true}
                    label={"이름/대표 가맹점/사용자 이메일/사업자 등록번호"}
                    fullWidth
                    size="small"
                    {...formRegister("searchKeyword")}
                    placeholder="이름/대표 가맹점/사용자 이메일/사업자 등록번호를 입력해주세요."
                  ></TextField>
                  <LoadingButton
                    loading={isFetching}
                    variant="outlined"
                    type={"submit"}
                  >
                    검색
                  </LoadingButton>
                </Stack>
              </form>
              <Box>
                <Divider />
                <MuiTable
                  id="id"
                  rows={data?.content || []}
                  columns={COLUMNS}
                  setRowSelection={setSelectedUsers}
                  selectedValues={selectedUsers}
                  totalData={false}
                  emptyCellValue={"-"}
                  sx={{
                    container: {
                      maxHeight: 400,
                      minHeight: 400
                    },
                    table: {
                      ...(!(data?.content || []).length && { height: 400 })
                    }
                  }}
                ></MuiTable>
              </Box>
              <Box my={2}>
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  page={(params?.page || 0) + 1}
                  onChange={changePage}
                  sx={{ display: "flex", justifyContent: "center" }}
                ></Pagination>
              </Box>
            </Stack>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <MuiLoadingButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={handleSubmit(onValid)}
          sx={{ color: "white" }}
        >
          추가 완료
        </MuiLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserToGroupModal;
