import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../../../../..";
import isModalOpenAtom from "../../../../../recoil/isModalOpen";
import { addGroup } from "../libs";

interface Props {
  closeModal?: () => void;
}

interface Form {
  groupName: string;
}

const AddGroupModal = ({ closeModal }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { errors, isValid }
  } = useForm({
    defaultValues: {
      groupName: ""
    }
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { mutate } = useMutation(addGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(["/v2/groups"]);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "그룹 추가가 완료되었습니다."
      });
      closeModal?.();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 그룹 추가
  // ===================================================================================================================
  const onValid = (data: Form) => {
    mutate(data);
    closeModal?.();
  };

  return (
    <Dialog open={true} fullWidth maxWidth="xs">
      <DialogTitle>그룹 추가</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeModal?.();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={handleSubmit(onValid)}>
        <DialogContent>
          <TextField
            type="text"
            label="그룹명"
            fullWidth
            required
            {...register("groupName")}
          />
        </DialogContent>
        <DialogActions>
          <MuiLoadingButton
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              color: "white"
            }}
            disabled={!watch("groupName")}
          >
            그룹 추가
          </MuiLoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddGroupModal;
