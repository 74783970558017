import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import {
  NotificationForAdmin,
  NotificationUpdateParams
} from "../../api/Notification/type";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  selectedData: NotificationForAdmin;
}

const NotificationDetailModal = ({
  isOpen,
  closeModal,
  selectedData
}: Props) => {
  // ===========================================================================
  // 리액트 훅 폼
  // ===========================================================================
  const { control, setValue } = useForm<NotificationUpdateParams>({
    defaultValues: {
      priority: "",
      type: "",
      message: ""
    }
  });

  useEffect(() => {
    setValue("type", selectedData.type);
    setValue("priority", selectedData.priority);
    setValue("message", selectedData.message);
  }, [setValue, selectedData]);

  const close = () => {
    closeModal();
  };

  return (
    <Dialog open={isOpen} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography>알림 상세</Typography>
          <IconButton type="button" onClick={close}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction={"column"} spacing={2}>
          <Stack direction={"row"} spacing={2} sx={{ pt: 1 }}>
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="알림 타입"
                  required
                  size="medium"
                  select
                  value={value}
                  onChange={onChange}
                  fullWidth
                  disabled
                >
                  <MenuItem value="NOTICE">공지</MenuItem>
                  <MenuItem value="SETTLEMENT">정산</MenuItem>
                </TextField>
              )}
            />
            <Controller
              name="priority"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="우선 순위"
                  required
                  size="medium"
                  select
                  value={value}
                  onChange={onChange}
                  fullWidth
                  disabled
                >
                  <MenuItem value="URGENT">긴급</MenuItem>
                  <MenuItem value="IMPORTANT">중요</MenuItem>
                  <MenuItem value="GENERAL">일반</MenuItem>
                </TextField>
              )}
            />
          </Stack>

          <Controller
            name="message"
            control={control}
            render={({ field: { value } }) => (
              <TextField
                label="보낼 메세지"
                required
                multiline
                rows={5}
                value={value}
                fullWidth
                disabled
              ></TextField>
            )}
            rules={{ required: true }}
          />

          <Accordion
            sx={{
              flex: 6,
              border: "1px solid rgba(0, 0, 0, 0.22)",
              borderRadius: "4px",
              boxShadow: "unset"
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                수신자: {selectedData.receiverInformationList?.length}명 선택됨
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ borderTop: "1px solid rgba(0, 0, 0, .125)" }}
            >
              <Grid container wrap={"wrap"} spacing={1}>
                {selectedData.receiverInformationList?.map((user, index) => (
                  <Grid key={`receiverInformationList ${index}`} item>
                    <Chip
                      variant="outlined"
                      icon={
                        user.isRead ? (
                          <NotificationsNoneIcon />
                        ) : (
                          <NotificationsPausedIcon />
                        )
                      }
                      label={user.mallName}
                    ></Chip>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationDetailModal;
