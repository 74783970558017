import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import { DialogContent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../../../../..";
import isModalOpen from "../../../../../recoil/isModalOpen/atom";
import { modifyGroup } from "../libs";
import { GroupListData } from "../libs/types";
import { useEffect } from "react";

interface Form {
  groupName: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedData?: GroupListData;
}

const DetailGroupModal = ({ onClose, selectedData, isOpen }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  // 리액트 훅 폼
  const { handleSubmit, register, watch, reset } = useForm<Form>({
    defaultValues: { groupName: "" }
  });

  // selectedData 변경 시 groupName 업데이트
  useEffect(() => {
    if (selectedData?.groupName) {
      reset({ groupName: selectedData.groupName });
    }
  }, [selectedData, reset]);

  /*********************************************************************************************************************
   * 리액트 쿼리
   ********************************************************************************************************************/
  // 그룹 수정
  const { mutate } = useMutation(modifyGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(["/v2/groups"]);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "그룹 수정이 완료되었습니다."
      });
      onClose();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 그룹 수정
  // ===================================================================================================================
  const onValid = (data: Form) => {
    if (!selectedData?.id) return; // id가 없으면 실행하지 않음
    mutate({ id: selectedData.id, params: data });
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="xs">
        <DialogTitle>그룹 수정</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit(onValid)}>
          <DialogContent>
            <TextField
              type="text"
              label="그룹명"
              fullWidth
              required
              {...register("groupName")}
            />
          </DialogContent>
          <DialogActions>
            <MuiLoadingButton
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                color: "white"
              }}
              disabled={!watch("groupName")}
            >
              수정 완료
            </MuiLoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DetailGroupModal;
