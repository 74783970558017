import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import {
  deleteCardBins,
  getCardBins,
  getCardCompanies,
} from '../../api/CardBinManagement';
import {
  CardBinFilters,
  CardBinParams,
  CardBinResponse,
} from '../../api/CardBinManagement/types';
import CardBinAddModal from '../../components/CardBinManagement/CardBinAddModal';
import CardBinEditModal from '../../components/CardBinManagement/CardBinEditModal';
import AdminLayout from '../../components/Layout/Admin/Layout';
import Title from '../../components/Title';
import isModalOpen from '../../recoil/isModalOpen/atom';
import { numberValidate } from '../../libs/input-validation';
import SearchControls from '../../components/Common/SearchControls';

interface Columns {
  id: string;
  label: string;
}

const columns: Columns[] = [
  { id: 'checkbox', label: '' },
  { id: 'issuer', label: '카드사명' },
  { id: 'createdAt', label: '생성 날짜' },
  { id: 'binCode', label: 'BIN' },
  { id: 'brand', label: '브랜드 구분' },
  { id: 'businessType', label: '회원 구분' },
  { id: 'gradeType', label: '등급 구분' },
  { id: 'creditOrCheck', label: '카드 구분' },
  { id: 'typeGubun', label: '필터 타입' },
  { id: 'etc', label: '비고' },
];

const CardBinManagement = () => {
  /*********************************************************************************************************************
   * 스테이트
   ********************************************************************************************************************/
  // 리코일 스테이트
  const toastPopup = useSetRecoilState(isModalOpen);

  // 파라미터 스테이트
  const [params, setParams] = useState<CardBinParams>({
    issuer: '기본',
    binCode: '',
    typeGubun: 'ALL',
    page: 0,
    pageSize: 10,
  });

  // 모달 스테이트
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const defaultRowData = {
    binCode: '',
    brand: '',
    businessType: '',
    creditOrCheck: '',
    etc: '',
    gradeType: '',
    issuer: '',
    typeGubun: 'ALL',
  };
  const [isEditModalState, setIsEditModalState] = useState<{
    isOpen: boolean;
    data: CardBinResponse;
  }>({
    isOpen: false,
    data: defaultRowData,
  });

  /*********************************************************************************************************************
   * 리액트 훅 폼
   ********************************************************************************************************************/
  const { handleSubmit, register, control, reset } = useForm<CardBinFilters>({
    defaultValues: {
      cardCompanyName: params.issuer,
      cardBinNumber: params.binCode,
      typeGubun: params.typeGubun,
    },
  });

  /*********************************************************************************************************************
   * 필터 초기화
   ********************************************************************************************************************/
  const handleReset = () => {
    reset();
    setParams(prev => {
      return {
        ...prev,
        issuer: '기본',
        binCode: '',
        typeGubun: 'ALL',
      };
    });
  };

  /*********************************************************************************************************************
   * 필터 조회
   ********************************************************************************************************************/
  const onSubmit = (formData: CardBinFilters) => {
    setParams(prev => {
      return {
        ...prev,
        issuer: formData.cardCompanyName,
        binCode: formData.cardBinNumber,
        typeGubun: formData.typeGubun,
      };
    });
  };

  /*********************************************************************************************************************
   * 리액트 쿼리
   ********************************************************************************************************************/
  // 카드사 목록 조회
  const { data: cardCompanies, isLoading: isCardCompaniesLoading } = useQuery(
    ['/cardBin/cardCompanies'],
    () => getCardCompanies(),
    {
      onSuccess: () => {},
      onError: () => {
        toastPopup(prev => {
          return {
            ...prev,
            value: true,
            message: 'Card company list lookup failed.',
          };
        });
      },
    },
  );

  // 카드 BIN 목록 조회
  const { data, isLoading, refetch } = useQuery(
    ['/cardBins', params],
    () => getCardBins(params),
    {
      onSuccess: () => {},
      onError: (error: any) => {
        toastPopup(prev => {
          return {
            ...prev,
            value: true,
            alertSeverity: 'error',
            message: error.response.data.message,
          };
        });
      },
    },
  );

  // 카드 BIN 삭제
  const { mutate } = useMutation(deleteCardBins);

  /*********************************************************************************************************************
   * 체크박스
   ********************************************************************************************************************/
  const [checkedBins, setCheckedBins] = useState<string[]>([]);
  const handleCheckbox = (binCode: string) => {
    // 체크 배열에 있으면 빼고, 체크 배열에 없으면 추가하고

    setCheckedBins(prev => {
      // 배열에 있는 지 확인
      if (prev.includes(binCode)) {
        // 배열에 있으면 제거
        return prev.filter(bin => bin !== binCode);
      } else {
        // 배열에 없으면 추가
        return [...prev, binCode];
      }
    });
  };

  // 배열에 빈코드가 있는지 확인하는 함수
  const checkBinCode = (binCode: string) => {
    return checkedBins.includes(binCode);
  };

  /*********************************************************************************************************************
   * 삭제
   ********************************************************************************************************************/
  const deleteBinCodes = () => {
    mutate(checkedBins, {
      onSuccess: () => {
        toastPopup({
          value: true,
          position: 'top',
          alertSeverity: 'success',
          message: '카드 BIN 삭제를 완료했습니다.',
        });
        refresh();
        setCheckedBins([]);
      },
      onError: (error: any) => {
        toastPopup({
          value: true,
          position: 'top',
          alertSeverity: 'error',
          message: error?.response?.data?.message,
        });
      },
    });
  };

  /*********************************************************************************************************************
   * 페이징
   ********************************************************************************************************************/
  // 총 페이지 수
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;

  // 페이지 변경 함수
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams(prev => ({ ...prev, page: page - 1 }));
  };

  /*********************************************************************************************************************
   * 등록 모달
   ********************************************************************************************************************/
  const addModalOpen = () => {
    setIsAddModalOpen(true);
  };
  const addModalClose = () => {
    setIsAddModalOpen(false);
  };

  /*********************************************************************************************************************
   * 수정 모달
   ********************************************************************************************************************/
  const editModalOpen = (row: CardBinResponse) => {
    setIsEditModalState({
      isOpen: true,
      data: row,
    });
  };
  const editModalClose = () => {
    setIsEditModalState({
      isOpen: false,
      data: defaultRowData,
    });
  };

  /*********************************************************************************************************************
   * 데이터 갱신
   ********************************************************************************************************************/
  const refresh = () => {
    refetch();
  };

  return (
    <>
      <Title title={'카드 BIN 관리'} />
      <AdminLayout>
        <Stack spacing={3}>
          {/* 필터 */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                border: '1px solid #F2F3F5',
                borderRadius: 3,
                overflow: 'hidden',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="cardCompanyName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="cardCompanyName"
                        label="카드사"
                        fullWidth
                        size="small"
                        select
                        value={value}
                        onChange={e => {
                          onChange(e.target.value);
                          setParams(prev => {
                            return {
                              ...prev,
                              issuer: e.target.value,
                            };
                          });
                        }}
                      >
                        <MenuItem value={'기본'} key={'기본'}>
                          전체
                        </MenuItem>
                        {cardCompanies &&
                          !isCardCompaniesLoading &&
                          cardCompanies?.content.cardCompanies.map(
                            (company, index) => (
                              <MenuItem value={company} key={index}>
                                {company}
                              </MenuItem>
                            ),
                          )}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    id="cardNumber"
                    label="카드번호"
                    fullWidth
                    size="small"
                    {...register('cardBinNumber')}
                    onInput={numberValidate}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="typeGubun"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="typeGubun"
                        label="필터 타입"
                        fullWidth
                        size="small"
                        select
                        value={value}
                        onChange={e => {
                          onChange(e.target.value);
                          setParams(prev => {
                            return {
                              ...prev,
                              typeGubun: e.target.value,
                            };
                          });
                        }}
                      >
                        <MenuItem value={'ALL'} key={'기본'}>
                          전체
                        </MenuItem>
                        <MenuItem value={'WHITE'} key={'WHITE'}>
                          화이트 리스트
                        </MenuItem>
                        <MenuItem value={'BLACK'} key={'BLACK'}>
                          블랙 리스트
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SearchControls onReset={handleReset} />
                </Grid>
              </Grid>
            </Paper>
          </form>
          {/* 필터 끝 */}

          <Box>
            {/* 액션 영역 */}
            <Stack
              direction={'row'}
              alignItems={'end'}
              justifyContent={'space-between'}
              mb={1}
            >
              <Typography sx={{ fontWeight: 'bold' }}>카드 BIN 관리</Typography>

              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                {/* 카드 BIN 등록 버튼 */}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={addModalOpen}
                >
                  카드 BIN 등록
                </Button>

                {/* 삭제 버튼 */}
                <Button
                  variant="outlined"
                  disabled={checkedBins.length < 1}
                  onClick={deleteBinCodes}
                >
                  삭제
                </Button>
              </Stack>
            </Stack>
            {/* 테이블 영역 시작 */}
            <Paper
              sx={{
                border: '1px solid #F2F3F5',
                borderRadius: 3,
                overflow: 'hidden',
              }}
            >
              <TableContainer>
                <Table
                  stickyHeader
                  sx={{
                    ...((!data || data?.content?.length === 0) && {
                      height: 550,
                    }),
                  }}
                >
                  {/* 테이블 헤더 */}
                  <TableHead>
                    <TableRow>
                      {columns.map((column: any) => (
                        <TableCell
                          variant="head"
                          key={column.id}
                          align="center"
                          sx={{ backgroundColor: '#FBFBFB' }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {/* 테이블 헤더 끝 */}

                  {/* 테이블 바디 */}
                  <TableBody>
                    {data && data.content.length < 1 ? (
                      // 데이터 없을 때
                      <TableRow>
                        <TableCell sx={{ height: 500 }} colSpan={10}>
                          <Typography align="center">
                            조회된 데이터가 없습니다.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {/* 데이터 조회 중 = 스켈레톤 */}
                        {!data &&
                          isLoading &&
                          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(index => (
                            <TableRow
                              key={index}
                              hover={true}
                              sx={{ cursor: 'pointer' }}
                            >
                              {columns.map(value => {
                                return (
                                  <TableCell
                                    component="th"
                                    align="center"
                                    key={value.id}
                                    width={
                                      value.id === 'checkbox'
                                        ? '50px'
                                        : value.id !== 'etc'
                                        ? '150px'
                                        : '500px'
                                    }
                                    sx={{ py: 0 }}
                                  >
                                    <Skeleton
                                      variant={'rectangular'}
                                      height={25}
                                    ></Skeleton>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          ))}

                        {/* 데이터 조회 결과 */}
                        {data &&
                          data.content.length > 0 &&
                          data.content.map(row => (
                            <TableRow
                              key={row.binCode}
                              onClick={() => editModalOpen(row)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell
                                align="center"
                                sx={{ width: '50px', py: 0.3 }}
                              >
                                <Checkbox
                                  checked={checkBinCode(row.binCode)}
                                  onChange={() => handleCheckbox(row.binCode)}
                                  onClick={e => e.stopPropagation()}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ width: '150px', py: 0.3 }}
                              >
                                {row.issuer}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ width: '150px', py: 0.3 }}
                              >
                                {row?.createdAt?.split('T').at(0)}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ width: '150px', py: 0.3 }}
                              >
                                {row.binCode}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ width: '150px', py: 0.3 }}
                              >
                                {row.brand}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ width: '150px', py: 0.3 }}
                              >
                                {row.businessType}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ width: '150px', py: 0.3 }}
                              >
                                {row.gradeType}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ width: '150px', py: 0.3 }}
                              >
                                {row.creditOrCheck}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ width: '150px', py: 0.3 }}
                              >
                                {row.typeGubun === 'WHITE'
                                  ? '화이트 리스트'
                                  : '블랙 리스트'}
                              </TableCell>
                              <TableCell align="center" sx={{ py: 0.3 }}>
                                {row.etc}
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                  {/* 테이블 바디 끝 */}
                </Table>
              </TableContainer>
              <Box my={2}>
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  page={params.page + 1}
                  onChange={changePage}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                ></Pagination>
              </Box>
            </Paper>
          </Box>
          {/* 테이블 영역 끝 */}
        </Stack>
        {/* 모달 영역 */}
        <CardBinAddModal
          isOpen={isAddModalOpen}
          onClose={addModalClose}
          cardCompanies={cardCompanies?.content.cardCompanies ?? []}
          refresh={refresh}
        />
        <CardBinEditModal
          isOpen={isEditModalState.isOpen}
          onClose={editModalClose}
          cardCompanies={cardCompanies?.content.cardCompanies ?? []}
          data={isEditModalState.data}
          refresh={refresh}
        />
      </AdminLayout>
    </>
  );
};

export default CardBinManagement;
