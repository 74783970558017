import React from "react";
import { AxiosApiError } from "../../api/type";
import IconButton from "@mui/material/IconButton";
import { Refresh } from "@mui/icons-material";
import { Alert } from "@mui/material";

interface ApiErrorMessageProps {
  error?: AxiosApiError;
  refetch?: () => void;
}

const ApiErrorMessage = ({ error, refetch }: ApiErrorMessageProps) => {
  return (
    <Alert
      severity="error"
      sx={{ ".MuiAlert-message": { display: "flex", gap: 1 } }}
    >
      {error?.response?.data?.message ?? "Error occurred"}

      <IconButton
        color="error"
        size="small"
        sx={{ p: 0 }}
        onClick={() => (!!refetch ? refetch() : null)}
      >
        <Refresh fontSize="small" />
      </IconButton>
    </Alert>
  );
};

export default ApiErrorMessage;
