import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
// import { getSettlementsDetailExcel } from "../../../../../api/v2/Settlements.ts";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { subYears } from "date-fns";
import { queryClient } from "../../../../..";
import MuiTable from "../../../../../components/Table/MuiTable";
import { getToday } from "../../../../../libs/get-today";
import isModalOpenAtom from "../../../../../recoil/isModalOpen";
import {
  getUnSettledItems,
  putUnSettledItems
} from "../../../UnsettledPayments/libs";
import { UnSettledItemData } from "../../../UnsettledPayments/libs/types";
import { Pagination } from "@mui/material";

interface SettlementUnitColumnHeadCell {
  id: keyof UnSettledItemData["items"][number];
  label: string;
  isTotalData: boolean;
}

const unitColumns: SettlementUnitColumnHeadCell[] = [
  {
    id: "mallId",
    label: "가맹점 ID",
    isTotalData: false
  },
  {
    id: "mallName",
    label: "가맹점명",
    isTotalData: false
  },
  {
    id: "transactionDate",
    label: "거래 일자",
    isTotalData: true
  },
  {
    id: "approvalNum",
    label: "승인 번호",
    isTotalData: false
  },
  {
    id: "cardCompany",
    label: "카드사",
    isTotalData: false
  },
  {
    id: "status",
    label: "거래 상태",
    isTotalData: false
  },
  {
    id: "paymentKey",
    label: "거래 고유KEY",
    isTotalData: false
  },
  {
    id: "amount",
    label: "거래 금액",
    isTotalData: true
  },
  {
    id: "purchaseAmount",
    label: "매입 금액",
    isTotalData: false
  },
  {
    id: "cardFee",
    label: "카드사 수수료",
    isTotalData: true
  },
  {
    id: "optatumFee",
    label: "옵타움 수수료",
    isTotalData: true
  },
  {
    id: "merchantFee",
    label: "가맹점 수수료",
    isTotalData: true
  },
  {
    id: "vat",
    label: "부가세",
    isTotalData: true
  },
  {
    id: "totalFee",
    label: "총 수수료",
    isTotalData: true
  },
  {
    id: "payoutAmount",
    label: "정산 금액",
    isTotalData: true
  }
];

interface SettlementUnitModalProps {
  settlementId: number;
  closeModal?: () => void;
  mallId: string;
}

const SettlementItemsModal = ({
  settlementId,
  closeModal,
  mallId
}: SettlementUnitModalProps) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  const [selected, setSelected] = useState<UnSettledItemData["items"]>([]);

  // 파라미터 스테이트
  const [params, setParams] = useState({
    startDate: subYears(new Date(), 1).toISOString().split("T")[0],
    endDate: getToday(),
    mallId: mallId,
    mallName: "",
    isActive: "1",
    isSettled: "2",
    page: 0,
    pageSize: 10
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 미정산 내역 조회
  const { data } = useQuery(
    ["/settlement-items", params],
    () => getUnSettledItems(params),
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      onSuccess: () => {
        setSelected([]);
      }
    }
  );

  // 미정산 내역 데이터 추가
  const { mutate } = useMutation(putUnSettledItems, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "정산 내역이 성공적으로 추가되었습니다."
      });
      queryClient.invalidateQueries([
        `/v3/settlements/${settlementId}/settlement-items`
      ]);
      closeModal?.();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  const isUnitBtnDisabled = selected.length === 0;

  const handleAddSettlementItems = () => {
    const formattedItems = selected.map((item) => ({
      amount: item.amount,
      approvalNum: item.approvalNum,
      cardCompany: item.cardCompany,
      cardFee: item.cardFee,
      id: item.id,
      mallId: item.mallId,
      mallName: item.mallName,
      merchantFee: item.merchantFee,
      optatumFee: item.optatumFee,
      paymentKey: item.paymentKey,
      payoutAmount: item.payoutAmount,
      purchaseAmount: item.purchaseAmount,
      status: item.status,
      totalFee: item.totalFee,
      transactionDate: item.transactionDate,
      vat: item.vat
    }));
    mutate({ id: settlementId, settlementItems: formattedItems });
    closeModal?.();
  };

  // ===================================================================================================================
  // 페이지네이션
  // ===================================================================================================================
  // 총 페이지 수
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;

  // 페이지 변경 함수
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev) => ({ ...prev, page: page - 1 }));
  };

  return (
    <Dialog open={true} maxWidth="xl" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        미정산 내역
        <Stack direction={"row"} gap={2}>
          <Button
            variant="outlined"
            onClick={handleAddSettlementItems}
            disabled={isUnitBtnDisabled}
          >
            정산 추가
          </Button>

          <IconButton
            aria-label="close"
            onClick={() => {
              closeModal?.();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Box>
          <MuiTable
            rows={data?.content.items}
            columns={unitColumns}
            id="id"
            selectedValues={selected}
            setRowSelection={setSelected}
            totalData={false}
          />
        </Box>
        <Box mt={2}>
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            // page={params.page + 1}
            onChange={changePage}
            sx={{ display: "flex", justifyContent: "center" }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SettlementItemsModal;
