import BlockIcon from '@mui/icons-material/Block';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getUsersList, getUsersMerchantList } from '../../api/Merchants';
import { UsersListParams } from '../../api/Merchants/types';
import ReadMemoModal from '../../components/ClientManagement/ReadMemoModal';
import UserLockModal from '../../components/ClientManagement/UserLockModal';
import UserMerchantListModal from '../../components/ClientManagement/UserMerchantListModal';
import UserPassModal from '../../components/ClientManagement/UserPassModal';
import UserUnLockModal from '../../components/ClientManagement/UserUnLockModal';
import AdminLayout from '../../components/Layout/Admin/Layout';
import ModalTriggerButton from '../../components/PopupTriggerButton';
import Title from '../../components/Title';
import useRenewal from '../../hooks/useRenewal';
import RegistUserModal from '../../components/ClientManagement/RegistUserModal';
import SearchControls from '../../components/Common/SearchControls';

interface Columns {
  id: string;
  label: string;
}

const columns: Columns[] = [
  { id: 'userEmail', label: '이메일' },
  { id: 'createdAt', label: '가입일' },
  { id: 'name', label: '이름' },
  { id: 'storeName', label: '등록된 대표 가맹점' },
  { id: 'merchant', label: '등록된 가맹점' },
  { id: 'tempPassword', label: '임시 비밀번호 발급' },
  { id: 'memo', label: '사유' },
  { id: 'isUserActive', label: '계정 사용 여부' },
  { id: 'isActiveButton', label: '계정 활성화' },
];

interface UsersListForm {
  email: string;
  name: string;
  storeName: string;
  isUse: 'all' | 'true' | 'false';
}

const UserAccountStatus = [
  { name: '전체', value: 'all' },
  { name: '사용가능', value: 'true' },
  { name: '사용불가능', value: 'false' },
];

const UserManagement = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  // 메모 확인 모달 스테이트
  const [isMemoModal, setIsMemoModal] = useState(false);
  // 임시 비밀번호 발급 모달 스테이트
  const [isUserPassModal, setIsUserPassModal] = useState(false);
  // userId 스테이트
  const [selectedRowId, setSelectedRowId] = useState<number>(0);
  // userEmail 스테이트
  const [selectedRowEmail, setSelectedRowEmail] = useState<string>('');
  // 등록된 머천트 모달 스테이트
  const [isUserMerchantModal, setIsUserMerchantModal] = useState(false);

  // 파라미터 스테이트
  const [params, setParams] = useState<UsersListParams>({
    email: '',
    name: '',
    isUse: '',
    storeName: '',
    page: 0,
    size: 10,
    sort: 'ASC',
  });

  // ===================================================================================================================
  // 모달창 열기
  // ===================================================================================================================
  // 메모 확인 모달
  const isMemoModalOpen = (userId: number) => {
    setSelectedRowId(userId);
    setIsMemoModal(true);
  };

  // 임시 비밀번호 발급 모달
  const isUserPassModalOpen = (userId: number, userEmail: string) => {
    setSelectedRowId(userId);
    setSelectedRowEmail(userEmail);
    setIsUserPassModal(true);
  };

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { handleSubmit, register, control, reset } = useForm<UsersListForm>({
    defaultValues: {
      email: '',
      name: '',
      storeName: '',
      isUse: 'all',
    },
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 사용자 리스트 조회
  const { data, isLoading } = useQuery(
    ['/users', params],
    () => getUsersList(params),
    {
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
    },
  );

  const { data: userMerchantList, isLoading: userMerchantLoading } = useQuery(
    [`/merchants/user/${selectedRowId}`, selectedRowId],

    () => getUsersMerchantList(selectedRowId),
    {
      enabled: isUserMerchantModal,
    },
  );

  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams(prev => ({ ...prev, page: page - 1 }));
  };

  // ===================================================================================================================
  // 가맹점 신청 현황 조회 및 초기화
  // ===================================================================================================================
  // 초기화
  const handleReset = () => {
    reset();
  };

  // 조회
  const onClickSubmit = (formData: UsersListForm) => {
    const isUse = formData.isUse === 'all' ? '' : formData.isUse;
    setParams(prev => ({
      ...prev,
      email: formData.email,
      isUse: isUse,
      name: formData.name,
      storeName: formData.storeName,
    }));
  };

  const handleRowClick = async (userId: number) => {
    setSelectedRowId(userId);
    setIsUserMerchantModal(true);
  };

  return (
    <>
      <Title title="사용자 관리" />
      <AdminLayout>
        <Paper
          sx={{
            p: 3,
            border: '1px solid #F2F3F5',
            borderRadius: 3,
            overflow: 'hidden',
            mb: 3,
          }}
        >
          <form onSubmit={handleSubmit(onClickSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <TextField
                  id="input-email"
                  label="이메일"
                  fullWidth
                  size="small"
                  {...register('email')}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  id="input-name"
                  label="이름"
                  fullWidth
                  size="small"
                  {...register('name')}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  id="input-merchant-name"
                  label="등록된 대표 가맹점"
                  fullWidth
                  size="small"
                  {...register('storeName')}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="isUse"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="사용 여부"
                      size="small"
                      fullWidth
                      onChange={onChange}
                      value={value}
                    >
                      {UserAccountStatus.map(c => (
                        <MenuItem key={c.name} value={c.value}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SearchControls onReset={handleReset} />
              </Grid>
            </Grid>
          </form>
        </Paper>

        {/* 사용자 등록 버튼 -----------------------------------------------------------------------------------------*/}
        <Stack
          direction={'row'}
          alignItems={'end'}
          justifyContent={'space-between'}
          mb={1}
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            사용자 관리 목록
          </Typography>
          <ModalTriggerButton
            size="medium"
            color="secondary"
            modal={<RegistUserModal />}
          >
            사용자 등록
          </ModalTriggerButton>
        </Stack>
        <Paper
          sx={{
            border: '1px solid #F2F3F5',
            borderRadius: 3,
            overflow: 'hidden',
          }}
        >
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                ...((!data || data?.content?.length === 0) && {
                  height: 550,
                }),
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      align="center"
                      sx={{ background: '#FBFBFB' }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.content.length === 0 ? (
                  <TableRow>
                    <TableCell sx={{ height: 500 }} colSpan={columns.length}>
                      <Typography align="center">
                        조회된 데이터가 없습니다.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {isLoading &&
                      Array.from(new Array(columns.length)).map(
                        (_, rowIndex) => (
                          <TableRow
                            key={rowIndex}
                            hover={true}
                            sx={{ cursor: 'pointer' }}
                          >
                            {columns.map((_, colIndex) => (
                              <TableCell key={colIndex} align="center">
                                <Skeleton
                                  variant="rectangular"
                                  width={'100%'}
                                  height={30}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        ),
                      )}
                    {data &&
                      data.content.length > 0 &&
                      data.content.map(row => (
                        <TableRow key={row.userId} hover={true}>
                          <TableCell
                            component="th"
                            align="center"
                            sx={{ py: 0.5 }}
                          >
                            {row.email}
                          </TableCell>
                          <TableCell align="center" sx={{ py: 0.5 }}>
                            {row.createdAt.split('T').at(0)}
                          </TableCell>
                          <TableCell align="center" sx={{ py: 0.5 }}>
                            {row.name}
                          </TableCell>
                          <TableCell align="center" sx={{ py: 0.5 }}>
                            {row.storeName}
                          </TableCell>
                          <TableCell align="center" sx={{ py: 0.5 }}>
                            {row.hasMerchant ? (
                              <IconButton
                                color="primary"
                                onClick={e => {
                                  e.stopPropagation();
                                  handleRowClick(row.userId);
                                }}
                              >
                                <CheckCircleRoundedIcon />
                              </IconButton>
                            ) : (
                              <ClearRoundedIcon />
                            )}
                          </TableCell>
                          <TableCell align="center" sx={{ py: 0.5 }}>
                            <IconButton
                              color="primary"
                              disabled={row.loginFailCount < 5}
                              onClick={e => {
                                e.stopPropagation();
                                isUserPassModalOpen(row.userId, row.email);
                              }}
                            >
                              <RefreshRoundedIcon />
                            </IconButton>
                          </TableCell>
                          {/* 사유 확인 모달 */}
                          <TableCell align="center" sx={{ py: 0.5 }}>
                            <IconButton
                              onClick={e => {
                                e.stopPropagation();
                                isMemoModalOpen(row.userId);
                              }}
                            >
                              <DescriptionOutlinedIcon />
                            </IconButton>
                          </TableCell>

                          {/* 사용 여부 확인 */}
                          <TableCell align="center" sx={{ py: 0.5 }}>
                            {row.isUse ? (
                              <CheckRoundedIcon color="primary" />
                            ) : (
                              <BlockIcon />
                            )}
                          </TableCell>

                          {/* 계정 활성화 & 비활성화 모달 */}
                          <TableCell align="center" sx={{ py: 0.5 }}>
                            <ModalTriggerButton
                              color={'primary'}
                              size="small"
                              modal={
                                row.isUse ? (
                                  <UserLockModal userId={row.userId} />
                                ) : (
                                  <UserUnLockModal userId={row.userId} />
                                )
                              }
                            >
                              {row.isUse ? '비활성화' : '활성화'}
                            </ModalTriggerButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* 메모 확인 모달 */}
          {isMemoModal && (
            <ReadMemoModal
              isOpen={isMemoModal}
              memo={
                data?.content.find(row => row.userId === selectedRowId)?.memo ||
                ''
              }
              onClose={() => setIsMemoModal(false)}
            />
          )}

          {/* 사용자 임시 비밀번호 발급 모달 */}
          {isUserPassModal && (
            <UserPassModal
              isOpen={isUserPassModal}
              onClose={() => setIsUserPassModal(false)}
              email={selectedRowEmail}
            />
          )}

          {/* 사용자 머천트 조회 모달 */}
          {!userMerchantLoading && isUserMerchantModal && userMerchantList && (
            <UserMerchantListModal
              isOpen={isUserMerchantModal}
              onClose={() => setIsUserMerchantModal(false)}
              data={userMerchantList.content}
            />
          )}

          <Box my={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={params.page + 1}
              onChange={changePage}
              sx={{ display: 'flex', justifyContent: 'center' }}
            ></Pagination>
          </Box>
        </Paper>
      </AdminLayout>
    </>
  );
};
export default UserManagement;
