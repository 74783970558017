import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from '@tanstack/react-query';
import koLocale from 'date-fns/locale/ko';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import {
  getClientSettlement,
  getClientSettlementTotal,
} from '../../api/MerchantSettlement';
import { ClientSettlementsData } from '../../api/MerchantSettlement/types';
import { getMerchants } from '../../api/Merchants';
import { MerchantsParams } from '../../api/Merchants/types';
import ClientSettlementTotal from '../../components/ClientSettlement/ClientSettlementTotal';
import ClientSettlementUnitModal from '../../components/ClientSettlement/ClientSettlementUnitModal';
import ClientLayout from '../../components/Layout/Client/Layout';
import LoadingButton from '../../components/LoadingButton';
import ModalTriggerButton from '../../components/PopupTriggerButton';
import SettlementStatement from '../../components/Settlements/SettlementStatement';
import MuiTable from '../../components/Table/MuiTable';
import Title from '../../components/Title';
import useRenewal from '../../hooks/useRenewal';
import registerImg from '../../images/counseling_img.png';
import excelDownload from '../../libs/excel-download';
import { getDate } from '../../libs/get-date';
import { getToday } from '../../libs/get-today';
import isModalOpenAtom from '../../recoil/isModalOpen/atom';
import SearchControls from '../../components/Common/SearchControls';
import { useMerchantNames } from '../../hooks/useMerchantNames';

interface FormInputs {
  startDate: string;
  endDate: string;
  isPayOut: string;
  mallId: string;
  mallName: string;
}

interface SettlementColumnHeadCell {
  id: keyof ClientSettlementsData;
  label: string;
}

const columns: SettlementColumnHeadCell[] = [
  {
    id: 'paidOutDate',
    label: '정산 지급 일자',
  },
  {
    id: 'isConfirm',
    label: '정산 확정',
  },
  {
    id: 'datePeriod',
    label: '거래일자(주단위)',
  },
  {
    id: 'mallId',
    label: '가맹점 ID',
  },
  {
    id: 'mallName',
    label: '가맹점명',
  },
  {
    id: 'businessName',
    label: '사업자 등록증 상호명',
  },
  {
    id: 'transactionTotal',
    label: '거래합계(건수)',
  },
  {
    id: 'completeTotal',
    label: '승인합계(건수)',
  },
  {
    id: 'cancelTotal',
    label: '취소합계(건수)',
  },
  {
    id: 'merchantFee',
    label: '가맹점 수수료',
  },
  {
    id: 'vat',
    label: '부가세',
  },
  {
    id: 'commissionFee',
    label: '총 수수료',
  },
  {
    id: 'settlementPrice',
    label: '정산 금액',
  },
  {
    id: 'actualAmount',
    label: '실 정산 금액',
  },
  {
    id: 'claimAmount',
    label: '청구금액',
  },
  {
    id: 'difference',
    label: '차액',
  },
  {
    id: 'unpaid',
    label: '미지급 금액',
  },
];

const Settlements = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // 정산 내역 상세 조회의 선택된 row값 스테이트
  const [selected, setSelected] = useState<ClientSettlementsData[]>([]);
  const { search } = useLocation();

  // 쿼리 스테이트
  const [queryData, setQueryData] = useState({
    startDate: getToday(),
    endDate: getToday(),
    isPayOut: '전체',
    mallId: '',
    mallName: '',
    sort: '',
  });

  // 가맹점 목록 조회 파라미터 스테이트
  const [params, setParams] = useState<MerchantsParams>({
    mallId: '',
    mallName: '',
    page: 0,
    size: 50,
    status: '계약승인',
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  // form 기본 값
  const defaultFormValues = {
    startDate: getToday(),
    endDate: getToday(),
    isPayOut: '전체',
    mallId: '',
    mallName: '',
  };
  const { register, handleSubmit, control, reset, getValues, setValue } =
    useForm<FormInputs>({
      defaultValues: {
        ...defaultFormValues,
      },
    });

  // 검색 필터 초기화
  const handleReset = () => {
    reset(defaultFormValues);
  };

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 현재 운영중인 가맹점 목록 조회
  const { uniqueMerchantsNames } = useMerchantNames();

  // 정산 내역 총합계 조회 --- 1번으로 조회
  const { isLoading: totalIsLoading, data: totalData } = useQuery(
    ['/client/settlement/total', queryData],
    () => getClientSettlementTotal(queryData),
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'error',
          message: error?.response?.data?.message,
        });
      },
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
    },
  );

  // 정산 내역 상세 조회 --- 2번으로 조회 하기
  const { isLoading, data } = useQuery(
    ['/client/settlements', queryData],
    () => getClientSettlement(queryData),
    {
      enabled: !!totalData, // --- 총합계가 호출된 다음 상세 내역 조회
      keepPreviousData: true,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: 'top',
          alertSeverity: 'error',
          message: error?.response?.data?.message,
        });
      },
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setSelected([]);
      },
      select: data => ({
        ...data,
        content: data.content.map(
          (data: ClientSettlementsData, index: number) => ({
            ...data,
            id: index,
            actualAmount: data.actualAmount,
            cancelTotal: data.cancelTotal,
            cardFee: data.cardFee,
            claimAmount: data.claimAmount,
            commissionFee: data.commissionFee,
            completeTotal: data.completeTotal,
            difference: data.difference,
            isConfirm: data.isConfirm ? '확정' : '미확정',
            isPayOut: data.isPayOut ? 'true' : 'false',
            mallId: data.mallId,
            mallName: data.mallName,
            merchantFee: data.merchantFee,
            optatumFee: data.optatumFee,
            paidOutDate: data.paidOutDate,
            settlementLimit: data.settlementLimit,
            settlementPrice: data.settlementPrice,
            transactionTotal: data.transactionTotal,
            unpaid: data.unpaid,
            vat: data.vat,
          }),
        ),
      }),
    },
  );

  useEffect(() => {
    if (search !== '') {
      const receivedDate = search.split('=')[1];
      setQueryData(prev => ({
        ...prev,
        startDate: receivedDate,
        endDate: receivedDate,
      }));
      setValue('startDate', receivedDate);
      setValue('endDate', receivedDate);
    }
  }, [search, setValue]);

  // ===================================================================================================================
  // 가맹점 목록 조회 - 등록된 가맹점 유무 확인
  // ===================================================================================================================
  const { data: merchantlistData } = useQuery(
    ['/merchants', params],
    () => getMerchants(params),
    { retry: false },
  );

  // 정산 페이지 조회
  const handleForm = (data: FormInputs) => {
    const isInvalidData =
      data.startDate?.includes('NaN') || data.endDate?.includes('NaN');
    const isInvaildPeriod = data.startDate > data.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '정산 지급 일자를 확인해주세요.',
      });
      return;
    }
    if (isLoading) return;
    setQueryData(prevValue => ({
      ...prevValue,
      ...data,
    }));
  };

  // ===================================================================================================================
  // 정산 상세 내역 조회 - 엑셀 다운로드
  // ===================================================================================================================
  const handleExcel = () => {
    const values = getValues();
    const isInvalidData =
      values.startDate?.includes('NaN') || values.endDate?.includes('NaN');
    const isInvaildPeriod = values.startDate > values.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '정산 지급 일자를 확인해주세요.',
      });
      return;
    }
    const content = data?.content;
    if (!content || content.length === 0) {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: '데이터가 없습니다.',
      });
      return;
    } else {
      const list = content.map((d: any) => ({
        '정산 지급 일자': d.paidOutDate,
        '정산 확정': d.isConfirm,
        '거래일자(주단위)': d.datePeriod,
        '가맹점 ID': d.mallId,
        가맹점명: d.mallName,
        '사업자 등록증 상호명': d.businessName,
        '거래합계(건수)': d.transactionTotal,
        '승인합계(건수)': d.completeTotal,
        '취소합계(건수)': d.cancelTotal,
        '가맹점 수수료': d.merchantFee,
        부가세: d.vat,
        '총 수수료': d.commissionFee,
        '정산 금액': d.settlementPrice,
        '실 정산 금액': d.actualAmount,
        '청구 금액': d.claimAmount,
        차액: d.difference,
        '미지급 금액': d.unpaid,
      }));
      excelDownload({
        list,
        sheetName:
          queryData.mallName === ''
            ? '정산 내역 상세 조회'
            : `정산 내역 상세 조회_${queryData.mallName}`,
        fileName:
          queryData.mallName === ''
            ? '정산 내역 상세 조회.xlsx'
            : `정산 내역 상세 조회_${queryData.mallName}.xlsx`,
      });
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'success',
        message: '다운로드를 시작합니다.',
      });
    }
  };

  const isUnitBtnDisabled = selected.length !== 1;

  return (
    <>
      <Title title="정산" />
      <ClientLayout>
        {merchantlistData && merchantlistData?.content.length > 0 ? (
          <Stack spacing={3}>
            <Paper
              sx={{
                p: 3,
                border: '1px solid #F2F3F5',
                borderRadius: 3,
              }}
            >
              <form onSubmit={handleSubmit(handleForm)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <LocalizationProvider
                      adapterLocale={koLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <Controller
                        name="startDate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            label="시작 날짜"
                            inputFormat="yyyy-MM-dd"
                            value={value}
                            onChange={value => onChange(getDate(value))}
                            renderInput={param => (
                              <TextField
                                fullWidth
                                size="small"
                                required
                                {...param}
                              />
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <LocalizationProvider
                      adapterLocale={koLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <Controller
                        name="endDate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            label="종료 날짜"
                            inputFormat="yyyy-MM-dd"
                            value={value}
                            onChange={value => onChange(getDate(value))}
                            renderInput={param => (
                              <TextField
                                fullWidth
                                size="small"
                                required
                                {...param}
                              />
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="isPayOut"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="input-isPayOut"
                          label="정산 지급 상태"
                          required
                          fullWidth
                          size="small"
                          select
                          value={value}
                          onChange={onChange}
                        >
                          <MenuItem value="전체">전체</MenuItem>
                          <MenuItem value="미지급">미지급</MenuItem>
                          <MenuItem value="지급">지급</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="mallName"
                      control={control}
                      render={({ field: { value, onChange, ...field } }) => (
                        <Autocomplete
                          {...field}
                          id="input-mallName"
                          value={value}
                          autoSelect
                          autoComplete
                          options={uniqueMerchantsNames}
                          onChange={(_, newValue) => onChange(newValue)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="가맹점명"
                              fullWidth
                              size="small"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="input-mallId"
                      label="가맹점 ID"
                      fullWidth
                      size="small"
                      {...register('mallId')}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SearchControls loading={isLoading} onReset={handleReset} />
                  </Grid>
                </Grid>
              </form>
            </Paper>

            {/* 정산 내역 총합계 */}
            <ClientSettlementTotal
              isLoading={totalIsLoading}
              data={totalData}
              mallName={queryData.mallName}
            />

            {/* 정산 내역 상세 조회 */}
            <Box>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'end'}
                mb={1}
              >
                <Typography sx={{ fontWeight: 'bold' }}>
                  정산 내역 상세 조회
                </Typography>
                <Stack direction="row" spacing={2}>
                  {/* 정산내역서 미리보기 */}
                  <ModalTriggerButton
                    disabled={
                      selected[0]?.isConfirm === '미확정' || isUnitBtnDisabled
                    }
                    size="small"
                    modalType="charge"
                    modal={
                      <SettlementStatement
                        isPayOut={selected[0]?.isPayOut}
                        mallId={selected[0]?.mallId}
                        paidOutDate={selected[0]?.paidOutDate}
                      />
                    }
                  >
                    정산내역서 미리보기
                  </ModalTriggerButton>

                  {/* 개별 내역 조회 모달 */}
                  <ModalTriggerButton
                    disabled={isUnitBtnDisabled}
                    size="small"
                    modal={
                      <ClientSettlementUnitModal
                        mallId={selected[0]?.mallId}
                        mallName={selected[0]?.mallName}
                        paidOutDate={selected[0]?.paidOutDate}
                        isConfirm={selected[0]?.isConfirm}
                        isPayOut={selected[0]?.isPayOut}
                      />
                    }
                  >
                    개별 내역 조회
                  </ModalTriggerButton>

                  {/* 엑셀 다운로드 모달 */}
                  <LoadingButton
                    icon={<SaveIcon />}
                    size="medium"
                    fullWidth={false}
                    color="secondary"
                    variant="outlined"
                    loading={false}
                    handleClick={handleExcel}
                  >
                    EXCEL 다운로드
                  </LoadingButton>
                </Stack>
              </Stack>
              <Paper
                sx={{
                  border: '1px solid #F2F3F5',
                  borderRadius: 3,
                  overflow: 'hidden',
                }}
              >
                {/* 정산 내역 상세 조회 테이블 */}
                <Box>
                  <MuiTable
                    rows={data?.content}
                    columns={columns}
                    id="id"
                    selectedValues={selected}
                    setRowSelection={setSelected}
                    totalData={false}
                  />
                </Box>
                {/* <Box mt={2}>
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                page={SettlementsTotal.page + 1}
                onChange={handlePage}
                sx={{ display: "flex", justifyContent: "center" }}
              />
            </Box> */}
              </Paper>
            </Box>
          </Stack>
        ) : (
          <Paper>
            {isLoading ? (
              <>
                <Backdrop open={isLoading}>
                  <CircularProgress
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      ml: 25,
                    }}
                  />
                </Backdrop>
              </>
            ) : (
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ height: 800 }}
                spacing={25}
              >
                <Box textAlign={'center'}>
                  <Typography variant="h6" gutterBottom>
                    가맹 등록 및 계약 완료 후 확인이 가능합니다.
                  </Typography>
                  <Link href="/registrations" underline="none">
                    <Button sx={{ fontSize: 16 }}>가맹 등록 하기</Button>
                  </Link>
                </Box>
                <Box component="img" src={registerImg} sx={{ width: '30%' }} />
              </Stack>
            )}
          </Paper>
        )}
      </ClientLayout>
    </>
  );
};

export default Settlements;
