import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import { DialogContent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useMutation } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../../../../..";
import isModalOpen from "../../../../../recoil/isModalOpen/atom";
import { deleteGroup } from "../libs";
import { GroupListData } from "../libs/types";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedData?: GroupListData;
}

const DeleteGroupModal = ({ onClose, selectedData, isOpen }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  /*********************************************************************************************************************
   * 리액트 쿼리
   ********************************************************************************************************************/
  // 그룹 삭제
  const { mutate: deleteMutate } = useMutation(deleteGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(["/v2/groups"]);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "그룹이 삭제되었습니다."
      });
      onClose();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  /*********************************************************************************************************************
   * 그룹 삭제
   ********************************************************************************************************************/
  // 그룹 삭제 핸들러
  const handleDeleteGroup = () => {
    if (!selectedData?.id) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "그룹 ID가 존재하지 않습니다."
      });
      return;
    }
    deleteMutate([selectedData.id]);
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="xs">
        <DialogTitle>그룹 삭제</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {selectedData?.groupName} 그룹을 삭제하시겠습니까?
        </DialogContent>
        <DialogActions>
          <MuiLoadingButton
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              color: "white"
            }}
            onClick={handleDeleteGroup}
          >
            삭제 완료
          </MuiLoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteGroupModal;
