import React, { useMemo, useState } from "react";
import AdminLayout from "../../../../components/Layout/Admin/Layout";
import Title from "../../../../components/Title";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addCommas } from "../../../../libs/thousands-commas";
import { DialogContent, Switch } from "@mui/material";
import { format } from "date-fns";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import { getCardCompanies, updateMerchantUseStatus } from "./libs";
import { useSetRecoilState } from "recoil";
import isModalOpen from "../../../../recoil/isModalOpen";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import { AxiosApiError } from "../../../../api/type";
import ApiErrorMessage from "../../../../components/Common/ApiErrorMessage";
import useRenewal from "../../../../hooks/useRenewal";

const LeyutaeManagement = () => {
  const setIsModalOpen = useSetRecoilState(isModalOpen);
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // default 는 레유태 merchantId(=37)
  /* fixme: prod 적용 시 아래 환경변수 조건부 삭제 */
  const [merchantId] = useState(
    process.env.REACT_APP_ENV === "development" ? 40 : 37
  );
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const {
    data,
    isLoading: isQueryLoading,
    refetch,
    error: queryError
  } = useQuery(
    [
      `/v3/payments/${merchantId}/card-companies`,
      merchantId,
      /* fixme: prod 적용 시 아래 테스트 파라미터 삭제 */
      ...(process.env.REACT_APP_ENV === "development"
        ? ["2024-02-01 00:00:00", "2024-08-01 00:00:00"]
        : [])
    ],
    getCardCompanies,
    {
      retry: 1,
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );

  const { mutate, isLoading: isMutationLoading } = useMutation({
    mutationFn: updateMerchantUseStatus,
    onSuccess: (res) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: `결제 제한을 ${res.content.isUse ? "해제" : "설정"}하였습니다.`
      });
    },
    onError: (e) => {
      const error = e as AxiosApiError;
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message ?? "Error occurred"
      });
    },
    onSettled: () => {
      setIsConfirmOpen(false);
      refetch();
    }
  });

  // 객체배열 변환
  const convertedData = useMemo(() => {
    return !!data?.content?.groupedData
      ? Object.entries(data.content.groupedData).map(([cardName, amount]) => ({
          cardName,
          amount
        }))
      : [];
  }, [data]);

  // 카드사 총합계
  const totalAmount = useMemo(() => {
    return convertedData.reduce((acc, curr) => acc + curr.amount, 0);
  }, [convertedData]);

  const handleCardLimitChange = (_: React.ChangeEvent<HTMLInputElement>) => {
    setIsConfirmOpen(true);
  };

  const handleCardLimitConfirm = () => {
    mutate({ merchantId });
  };

  return (
    <>
      <Title title="레유태 관리" />
      <AdminLayout>
        <Stack spacing={3}>
          <Box>
            <Stack
              direction={"row"}
              alignItems={"end"}
              justifyContent={"space-between"}
              mb={1}
            >
              <Typography sx={{ fontWeight: "bold" }}>레유태 관리</Typography>
            </Stack>

            <Paper
              sx={{
                p: 3,
                border: "1px solid #F2F3F5",
                borderRadius: 3,
                overflow: "hidden"
              }}
            >
              <Stack mb={3}>
                {isQueryLoading ? (
                  <Box sx={{ mb: 3 }}>
                    {Array(3)
                      .fill(null)
                      .map((_, i) => (
                        <Skeleton
                          key={i}
                          variant="text"
                          width={"30%"}
                          height={30}
                        />
                      ))}
                  </Box>
                ) : (
                  <>
                    <Box>
                      <Typography variant="subtitle1" fontWeight="600">
                        {format(new Date(), "yyyy-MM")}
                      </Typography>
                      <Typography variant="subtitle1">
                        총합계 {addCommas(totalAmount)}
                      </Typography>
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!queryError && !data?.content?.isUse}
                            onChange={handleCardLimitChange}
                            disabled={!!queryError}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            결제 제한 (
                            {!!queryError || !!data?.content?.isUse
                              ? "OFF"
                              : "ON"}
                            )
                          </Typography>
                        }
                        labelPlacement="end"
                        sx={{ my: 2 }}
                      />
                    </Box>
                  </>
                )}

                <Divider />
              </Stack>

              {isQueryLoading && (
                <Grid container spacing={3}>
                  {Array(9)
                    .fill(null)
                    .map((_, index) => (
                      <Grid item xs={12} sm={4} key={index}>
                        <Box
                          sx={{
                            backgroundColor: "#f2f2f2",
                            p: 2,
                            borderRadius: 2
                          }}
                        >
                          <Skeleton variant="text" width={"60%"} height={30} />
                          <Skeleton variant="text" width={"80%"} height={30} />
                          <Skeleton variant="text" width={"40%"} height={30} />
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              )}

              {!isQueryLoading &&
                (!data || !convertedData.length ? (
                  !!queryError ? (
                    <ApiErrorMessage
                      error={queryError as AxiosApiError}
                      refetch={refetch}
                    />
                  ) : (
                    <Stack
                      height={550}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography>조회된 데이터가 없습니다.</Typography>
                    </Stack>
                  )
                ) : (
                  <Grid container spacing={3}>
                    {convertedData.map(({ cardName, amount }, i) => (
                      <Grid item xs={12} sm={4} key={i}>
                        <Stack
                          sx={{
                            backgroundColor: "#f7f7f7",
                            pl: 2,
                            pb: 2,
                            pr: 0.5,
                            pt: 1,
                            gap: 1,
                            border: "1px solid #E4E4E7",
                            borderRadius: 2
                          }}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Typography variant="subtitle2" fontWeight={"bold"}>
                              {cardName}
                            </Typography>
                          </Stack>
                          <Typography variant="body2">
                            거래 금액 합계: {addCommas(amount)}
                          </Typography>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                ))}
            </Paper>
          </Box>
        </Stack>

        {/*결제 제한 confirm*/}
        <Dialog open={isConfirmOpen} fullWidth maxWidth="xs">
          <DialogTitle>결제 제한</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setIsConfirmOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 12
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            결제 제한을 {!!data?.content?.isUse ? "설정" : "해제"}하시겠습니까?
          </DialogContent>
          <DialogActions>
            <MuiLoadingButton
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                color: "white"
              }}
              onClick={handleCardLimitConfirm}
              loading={isMutationLoading}
            >
              확인
            </MuiLoadingButton>
          </DialogActions>
        </Dialog>
      </AdminLayout>
    </>
  );
};

export default LeyutaeManagement;
