import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMutation, useQuery } from '@tanstack/react-query';
import koLocale from 'date-fns/locale/ko';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import {
  deleteGradeFee,
  getGradeFee,
  postGradeFee,
  postGradeFeeReservation,
} from '../../api/Merchants';
import { getDate } from '../../libs/get-date';
import isModalOpenAtom from '../../recoil/isModalOpen';
import { queryClient } from '../../index';
import { GradeDataType } from '../../api/Merchants/types';
import { getToday } from '../../libs/get-today';
import { CommonApi } from '../../api/type';
import { MerchantsDetail } from '../../api/Merchant/types';

interface Props {
  merchantId: any;
  closeModal?: () => void;
}

interface FormInputs
  extends Pick<
    GradeDataType,
    'grade' | 'optatumFee' | 'reservationDate' | 'mccType'
  > {}

const BusinessSizeTypes = [
  { type: '일반' },
  { type: '영세' },
  { type: '중소1' },
  { type: '중소2' },
  { type: '중소3' },
];

const RegistMerchantFeeModal = ({ merchantId, closeModal }: Props) => {
  // ===============================================================================================
  // 리코일 스테이트
  // ===============================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===============================================================================================
  // 리액트 쿼리
  // ===============================================================================================
  // 1. 가맹점 등급 수수료 예약 정보 조회
  // 2. 정보 없으면 즉시 적용 양식 / 있으면 등록된 예약 정보
  //
  //

  // 가맹 상세 정보 from react-query cache
  const merchantDetail = useMemo(
    () =>
      queryClient.getQueryData<CommonApi<MerchantsDetail>>([
        `/merchants/${merchantId}`,
      ]),
    [merchantId],
  );

  // 가맹점 등급수수료 예약 정보 조회
  const {
    data,
    isLoading,
    refetch: refetchGradeFee,
  } = useQuery(['/gradeFee', merchantId], () => getGradeFee({ merchantId }));

  // 가맹점 등급수수료 즉시 적용
  const { mutate } = useMutation(postGradeFee, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'success',
        message: '가맹점 등급수수료가 적용 되었습니다.',
      });
      queryClient.invalidateQueries({
        queryKey: [`/merchants/${merchantId}`],
        exact: true,
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: error?.response?.data?.message,
      });
    },
  });

  // 가맹점 등급수수료 예약 정보 등록
  const { mutate: reservateGradeFee } = useMutation(postGradeFeeReservation, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'success',
        message: '가맹점 등급수수료가 예약 되었습니다.',
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: error?.response?.data?.message,
      });
    },
  });

  // 가맹점 등급수수료 예약 정보 등록
  const { mutate: deleteReservedGradeFee } = useMutation(deleteGradeFee, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'success',
        message: '가맹점 등급수수료 예약이 취소 되었습니다.',
      });
      refetchGradeFee();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: 'top',
        alertSeverity: 'error',
        message: error?.response?.data?.message,
      });
    },
  });

  // form values
  const values: FormInputs = useMemo(() => {
    // 예약 수수료 정보 존재 시
    if (!!data?.content?.length) {
      return data.content[0];
    }
    // 수수료 정보 존재 시
    if (!!merchantDetail?.content?.merchantFee?.length) {
      return {
        grade: merchantDetail.content.merchantFee[0].grade,
        optatumFee: merchantDetail.content.merchantFee[0].optatumFee + '',
        reservationDate: merchantDetail.content.merchantFee[0].startDate,
        mccType: '일반',
      };
    }
    // default
    return {
      grade: '일반',
      optatumFee: '0',
      reservationDate: getToday(),
      mccType: '일반',
    };
  }, [merchantId, data, merchantDetail]);

  // ===============================================================================================
  // 리액트 훅 폼
  // ===============================================================================================
  const { getValues, control, setValue } = useForm<FormInputs>({
    values,
    resetOptions: {
      keepDirtyValues: true, // keep dirty fields unchanged, but update defaultValues
    },
  });

  // 등급 수수료 즉시 적용
  const onSubmit = (formData: any) => {
    const params = {
      ...formData,
      merchantId: merchantId,
      feeType: 'PERCENT',
      method: 'CARD',
    };
    mutate(params);
    closeModal?.();
  };

  // 등급 수수료 예약 적용
  const onGradeFeeSubmit = (formData: any) => {
    const params = {
      ...formData,
      merchantId: merchantId,
      feeType: 'PERCENT',
      method: 'CARD',
    };
    reservateGradeFee(params);
    closeModal?.();
  };

  // 등급수수료 즉시 적용 - getValues()로 폼 데이터 가져오고 onSubmit 호출
  const handleApplyGradeFee = () => {
    onSubmit(getValues());
  };

  // 등급수수료 예약 적용
  const handleReservateGradeFee = () => {
    onGradeFeeSubmit(getValues());
  };

  // 등급수수료 예약 취소
  const handleDeleteReservedGradeFee = () => {
    deleteReservedGradeFee(data?.content[0].id);
    // closeModal?.();
  };

  // ===========================================================================
  // 유효성 검사
  // ===========================================================================
  const inputPercentFormat = (value: string) => {
    // 먼저 콤마의 개수를 파악한다
    const matched = value.match(/\./g);
    // 콤마가 2개 이상일땐 두번째 콤마는 제거하고 replace 함수를 실행한다
    if (matched !== null && matched.length >= 2) {
      const secondCommaIndex = value.lastIndexOf('.');
      const removedCommaValue =
        value.substring(0, secondCommaIndex) +
        value.substring(secondCommaIndex + 1, value.length);
      return removedCommaValue.replace(/[^0-9.]/g, '');
    } else {
      return value.replace(/[^0-9.]/g, ''); // 숫자와 콤마만 허용한다
    }
  };

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      {/* 예약된 등급별 수수료 예약 확인 모달창 */}
      {data?.content && data.content.length > 0 ? (
        <>
          <DialogTitle>가맹점 등급 수수료 예약 확인</DialogTitle>
          <IconButton
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <form>
              <Stack direction={'row'} spacing={3}>
                {/* 적용 날짜 */}
                <LocalizationProvider
                  adapterLocale={koLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <Controller
                    name="reservationDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        disabled
                        label="적용 날짜"
                        inputFormat="yyyy-MM-dd"
                        value={value}
                        onChange={e =>
                          onChange(
                            data.content.map(item => item.reservationDate),
                          )
                        }
                        renderInput={param => (
                          <TextField
                            fullWidth
                            size="small"
                            required
                            {...param}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>

                {/* 등급 */}
                <Controller
                  name="grade"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled
                      select
                      label="등급"
                      value={value}
                      onChange={e => onChange(getValues('grade'))}
                      required
                      size="small"
                      fullWidth
                    >
                      {BusinessSizeTypes.map(BusinessSizeType => (
                        <MenuItem
                          key={BusinessSizeType.type}
                          value={BusinessSizeType.type}
                        >
                          {BusinessSizeType.type}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                ></Controller>

                {/* 수수료 */}
                <Controller
                  name={'optatumFee'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, ...field } }) => (
                    <TextField
                      {...field}
                      label="수수료율"
                      required
                      size="small"
                      fullWidth
                      value={value}
                      InputProps={{
                        disabled: true,
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  )}
                ></Controller>
              </Stack>
            </form>
            <Typography variant="subtitle2" color={'error'} mt={2}>
              * 예약 내용을 변경하고 싶다면, 예약된 정보를 먼저 취소해주세요.
            </Typography>
          </DialogContent>
          <Stack direction={'row'} spacing={3} sx={{ p: 3, pt: 1 }}>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleDeleteReservedGradeFee}
            >
              등급수수료 예약 취소
            </Button>
          </Stack>
        </>
      ) : (
        <>
          {/* 등급 수수료 등록 모달창 */}
          <DialogTitle>가맹점 등급 수수료 등록</DialogTitle>
          <IconButton
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <form>
              <Stack direction={'row'} spacing={3}>
                {/* 변경 날짜 */}
                {data?.content.length === 0 && !isLoading ? (
                  <LocalizationProvider
                    adapterLocale={koLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <Controller
                      name="reservationDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label="변경 날짜"
                          inputFormat="yyyy-MM-dd"
                          value={value}
                          onChange={value => onChange(getDate(value))}
                          renderInput={param => (
                            <TextField
                              fullWidth
                              size="small"
                              required
                              {...param}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                ) : null}

                {/* 등급 */}
                <Controller
                  name="grade"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="등급"
                      value={value}
                      onChange={onChange}
                      required
                      size="small"
                      fullWidth
                    >
                      {BusinessSizeTypes.map(BusinessSizeType => (
                        <MenuItem
                          key={BusinessSizeType.type}
                          value={BusinessSizeType.type}
                        >
                          {BusinessSizeType.type}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                ></Controller>

                {/* 수수료 */}
                <Controller
                  name={'optatumFee'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      label="수수료율"
                      required
                      size="small"
                      fullWidth
                      value={value}
                      onChange={e => {
                        setValue(
                          'optatumFee',
                          inputPercentFormat(e.target.value),
                        );
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  )}
                ></Controller>
              </Stack>
            </form>
            <Typography variant="subtitle2" color={'error'} mt={2}>
              * 등급 수수료 즉시 적용 시 카드 결제 등급도 변경됩니다. <br />*
              즉시 적용 시 선택한 날짜와 상관없이 오늘 날짜로 적용됩니다.
            </Typography>
          </DialogContent>
          <Stack direction={'row'} spacing={3} sx={{ p: 3, pt: 1 }}>
            <Button variant="outlined" fullWidth onClick={handleApplyGradeFee}>
              등급수수료 즉시 적용
            </Button>
            <Button
              variant="contained"
              sx={{ color: 'white' }}
              fullWidth
              onClick={handleReservateGradeFee}
            >
              등급수수료 예약 적용
            </Button>
          </Stack>
        </>
      )}
    </Dialog>
  );
};
export default RegistMerchantFeeModal;
